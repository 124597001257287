import React, { useEffect } from 'react';

import { useHistory } from 'react-router';

import { PlusOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import {
  PurchasingHubAndSpokeType,
  SupplierTargetTypes,
  TargetGroupType,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { PurchaseGroupWithTargetDTO } from '@recurrency/core-api-schema/dist/purchaseGroups/getPurchaseGroups';
import { useDebounce } from 'use-debounce';

import { MultiSelectOption } from 'components/AsyncSelect/types';
import { useSuppliersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Container } from 'components/Container';
import { PageHeader } from 'components/PageHeader';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { useModal } from 'hooks/useModal';

import { optArrFromVal } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { objPickKeys } from 'utils/object';
import { routes, useHashState } from 'utils/routes';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { withSortedColumn } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

import { SupplierLocationWTarget } from 'types/hash-state';

import { SortResponse } from '../PurchaseTargetsPage/types';
import { getMatchingSupplierAndLocation, groupRecordToSupplierLocationsWTargets } from '../utils';
import { PurchaseGroupsFilters } from './components/PurchaseGroupsFilters';
import { PurchaseGroupsTable } from './components/PurchaseGroupsTable';
import { SavePurchaseGroupModal } from './components/SavePurchaseGroupModal';
import { usePurchaseGroupsTableColumns, usePurchaseTargets, useSuppliers } from './PurchaseGroupsPage.hooks';
import { PurchaseGroupsHashState } from './PurchaseGroupsPage.types';

const targetTypeLabels: Record<SupplierTargetTypes, string> = {
  [SupplierTargetTypes.Dollars]: 'Dollars',
  [SupplierTargetTypes.Units]: 'Units',
  [SupplierTargetTypes.Weight]: 'Weight',
  [SupplierTargetTypes.Volume]: 'Volume',
  [SupplierTargetTypes.CycleDays]: 'Cycle Days',
};

const targetTypesFilterOptions: MultiSelectOption[] = Object.entries(targetTypeLabels)
  .filter(([value]) => value !== SupplierTargetTypes.CycleDays)
  .map(([value, label]) => ({
    label,
    value,
  }));

export function PurchaseGroupsPage() {
  const { activeTenant } = useGlobalApp();

  const suppliersSelectProps = useSuppliersSelectProps();
  const history = useHistory();
  const savePurchaseGroupModal = useModal();
  const [hashState, updateHashState] = useHashState<PurchaseGroupsHashState>();
  const [debouncedSearchQuery] = useDebounce(hashState.query, 500);
  const {
    targetGroupType = TargetGroupType.Targets,
    targetTypes,
    locationIds = [],
    supplierIds = [],
    buyerIds = [],
    selectedRowKeys = [],
  } = hashState;

  const targetTypesFilter =
    targetGroupType === TargetGroupType.ReviewCycle
      ? [SupplierTargetTypes.CycleDays]
      : targetGroupType === TargetGroupType.Targets
      ? targetTypes || (targetTypesFilterOptions.map((o) => o.value) as SupplierTargetTypes[])
      : undefined;

  const tableProps = useCoreApiTableProps({
    schema: schemas.purchaseGroups.getPurchaseGroups,
    queryParams: {
      targetGroupType,
      isHubAndSpoke:
        getTenantSetting(TenantSettingKey.FeaturePurchasingHubAndSpoke) ===
        PurchasingHubAndSpokeType.EnablePurchasingHubAndSpoke,
      filter: {
        searchQuery: debouncedSearchQuery,
        purchaseLocationIds: locationIds,
        supplierIds,
        buyerIds,
        status: hashState.status,
        targetTypes: targetTypesFilter,
        specialOrder: hashState.specialOrder,
      },
    },
    initialState: {
      sortBy: 'goalPercent',
      sortDir: 'desc',
    },
  });

  const { items, reload: reloadPurchaseTargets, setPage, setSortBy, setSortDir } = tableProps;

  const { tableColumns } = usePurchaseGroupsTableColumns({
    hashState,
    handleTargetValueChange: reloadPurchaseTargets,
    reloadPurchaseGroups: tableProps.reload,
    onPressEdit: handlePressEdit,
    onPressOpen: (groupId: string) => redirectToPOBuilder([groupId]),
  });

  /** the purpose of this string is to memoize these values so that the useEffect below doesn't trigger when the values are the same.
   * The arrays in hashState are recreated per render and considered new objects, causing re-renders if added directly to the useEffect dependency array */
  const stringifyHashState = JSON.stringify(
    objPickKeys(
      hashState,
      'status',
      'targetGroupType',
      'supplierIds',
      'locationIds',
      'buyerIds',
      'targetTypes',
      'specialOrder',
    ),
  );

  const { populateGroupsWithPurchaseTargets, getCompanyIdFromPurchaseTargets } = usePurchaseTargets();
  const { getSupplierIdAndName } = useSuppliers();

  useEffect(() => {
    setPage(1);
  }, [stringifyHashState, setPage]);

  const matchingSupplierAndLocationIds = getMatchingSupplierAndLocation(selectedRowKeys, items);
  const shouldDisablePOBuilderButton = selectedRowKeys.length === 0 || matchingSupplierAndLocationIds.hasMatch;

  async function handlePressEdit(record: PurchaseGroupWithTargetDTO) {
    const suppliers = suppliersSelectProps.options;
    const supplierIdAndName = await getSupplierIdAndName(suppliers, record.supplierId);

    await showAsyncModal(SavePurchaseGroupModal, {
      ...savePurchaseGroupModal,
      purchaseGroups: items,
      reloadPurchaseGroups: tableProps.reload,
      initialFormValues: {
        ...record,
        locationIds: [record.purchaseLocationId, ...(record.spokeLocations?.map((l) => l.foreignId) || [])],
        supplierId: supplierIdAndName,
        companyId: record.companyId,
      },
      groupId: record.groupId,
    });
  }

  async function handlePressNewGroup() {
    await showAsyncModal(SavePurchaseGroupModal, {
      ...savePurchaseGroupModal,
      purchaseGroups: items,
      reloadPurchaseGroups: tableProps.reload,
    });
  }

  const setTargetGroupTypeFilter = (newTargetGroupType: TargetGroupType | undefined) => {
    updateHashState({ targetGroupType: newTargetGroupType });
    const newSort = getTargetGroupSort(newTargetGroupType);
    setSortBy?.(newSort.sortBy);
    setSortDir?.(newSort.sortDir);
    updateHashState({ targetTypes: undefined });
  };

  async function redirectToPOBuilder(groupIds: string[]) {
    const selectedGroups = groupIds.map((groupId) => items.find((item) => item.groupId === groupId)).filter(truthy);

    const groupsWithPurchaseTargets = await populateGroupsWithPurchaseTargets(selectedGroups);
    const companyId = getCompanyIdFromPurchaseTargets(groupsWithPurchaseTargets);

    const supplierLocations: SupplierLocationWTarget[] = [];
    groupsWithPurchaseTargets.forEach((group) => {
      if (group) {
        supplierLocations.push(...groupRecordToSupplierLocationsWTargets(group));
      }
    });

    history.push(
      routes.purchasing.purchaseTargetLines({
        purchaseGroups: selectedGroups.map((g) => ({
          purchaseLocationId: g.purchaseLocationId,
          purchaseLocationName: g.purchaseLocationName,
          groupId: g.groupId,
          groupName: g.groupName,
          spokeLocations: g.spokeLocations,
        })),
        company: companyId ? { foreignId: companyId, name: activeTenant.companyName } : undefined,
        companyIds: optArrFromVal(companyId),
        supplierLocations,
        groupBuy: true,
        groupIds,
      }),
    );
  }

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.PurchaseGroupsTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip
            placement="bottom"
            title="Purchase Groups allow you to quickly prioritize and execute on POs for suppliers set up using a hub and spoke model"
          >
            Purchase Groups
          </InfoTooltip>
        }
        headerActions={[
          <Button key="new-purchase-group-button" type="primary" onClick={handlePressNewGroup}>
            <PlusOutlined /> New Group
          </Button>,
          <ConditionalWrapper
            key="po-builder-button"
            condition={matchingSupplierAndLocationIds.hasMatch}
            wrapper={(children) => (
              <Tooltip
                placement="bottom"
                title={() => (
                  <>
                    Selected groups can't share a supplier-locations pair in the PO Builder. <br />
                    Groups <i>{matchingSupplierAndLocationIds.groupName1}</i> and{' '}
                    <i>{matchingSupplierAndLocationIds.groupName2}</i> both have supplier{' '}
                    <i>{matchingSupplierAndLocationIds.supplierName}</i> at location{' '}
                    <i>{matchingSupplierAndLocationIds.locationName}</i>
                  </>
                )}
              >
                <div>{children}</div>
              </Tooltip>
            )}
          >
            <AsyncButton
              type="primary"
              disabled={shouldDisablePOBuilderButton}
              onClick={() => redirectToPOBuilder(selectedRowKeys)}
            >
              PO Builder
            </AsyncButton>
          </ConditionalWrapper>,
        ]}
      />

      <PurchaseGroupsFilters
        hashState={hashState}
        updateHashState={updateHashState}
        totalCount={tableProps.totalCount}
        setTargetGroupTypeFilter={setTargetGroupTypeFilter}
      />

      <ColumnChooserSection
        columns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        setVisibleColumnKeys={setVisibleColumnKeys}
      />

      <PurchaseGroupsTable
        tableProps={tableProps}
        tableColumns={withSortedColumn(
          visibleColumnKeys.map((key) => tableColumns.find((column) => column.settingKey === key)).filter(truthy),
          tableProps.sortBy,
          tableProps.sortDir,
        )}
        selectedRowKeys={selectedRowKeys}
        updateHashState={updateHashState}
      />
    </Container>
  );
}

const getTargetGroupSort = (targetGroupType?: TargetGroupType): SortResponse => {
  if (targetGroupType === TargetGroupType.ReviewCycle) {
    return { sortBy: 'nextReviewDate', sortDir: 'asc' };
  }
  return { sortBy: 'goalPercent', sortDir: 'desc' };
};
