import React from 'react';

import { css } from '@emotion/css';

import { formatNumber, pluralize } from 'utils/formatting';

export function ResultCount({ count }: { count: number }) {
  return (
    <span
      className={css`
        min-width: 100px;
        text-align: right;
      `}
    >
      <b>{formatNumber(count)}</b> {pluralize(count, 'result')}
    </span>
  );
}
