import React from 'react';

import { Link } from 'react-router-dom';

import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PlanningUsageInheritanceType } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { GetItemUsageInheritanceDTO } from '@recurrency/core-api-schema/dist/items/getItemUsageInheritanceOverride';
import { Alert, Tooltip } from 'antd';
import { theme } from 'theme';

import { ActionButton } from 'components/Button/ActionButton';

import { useCoreApi } from 'hooks/useApi';

import { showAsyncModal } from 'utils/asyncModal';
import { makePath, routePaths } from 'utils/routes';
import { getTenantSetting } from 'utils/tenantSettings';
import { getNextUsageUpdateDate } from 'utils/usage';

import { EditUsageInheritanceModalProps, UsageInheritanceModal } from './UsageInheritanceModal';

interface UsageInheritanceInfoProps {
  /* all the usage ancestors of this item that have already been processed, i.e. their usage is added to this item's usage during the last foreacst run, and the graph currently reflects it */
  processedAncestorData: string[];
  itemUid: string;
  itemId: string;
}

export const UsageInheritanceInfo: React.FC<UsageInheritanceInfoProps> = ({
  processedAncestorData,
  itemUid,
  itemId,
}) => {
  /**
   *  Inheritance variable types documentation:
   *
   *  processedAncestorData: Passed down from the parent component, this is all the usage ancestors of this item that have already
   *  been processed, i.e. their usage is added to this item's usage during the last foreacst run, and the graph currently reflects it.
   *
   *  allAncestorData: This is fetched directly from postgres, either from the "recurrency_item_usage_inheritance" table or the
   *  "recurrency_item_usage_inheritance_override" table, depending on the tenant's setting. This reflects all the real-time ancestors of
   *  this item, some of which may not be processed yet if they were added after the last forecast run.
   *
   *  pendingAncestorData: The set difference allAncestorData \ processedAncestorData. This is all the ancestors of this item that have not
   *  been processed (added to usage/forecasts) yet.
   *
   *  pendingRemovedAncestorData: The set difference processedAncestorData \ allAncestorData. This is all the ancestors of this item that
   *  have been removed but are not reflected in the usage yet.
   */

  const usingRecurrencyInheritance =
    getTenantSetting(TenantSettingKey.FeaturePlanningUsageInheritance) === PlanningUsageInheritanceType.None;

  const { data: inheritanceData, reload: onDataChanged } = useCoreApi(
    usingRecurrencyInheritance ? schemas.items.getItemUsageInheritanceOverrides : schemas.items.getItemUsageInheritance,
    {
      pathParams: {
        itemUid,
      },
    },
  );

  const allAncestorData = {
    ...inheritanceData,
    items: inheritanceData?.items.filter((item: GetItemUsageInheritanceDTO) => item.type === 'ancestor') || [],
  };

  const allDescendantData = {
    ...inheritanceData,
    items: inheritanceData?.items.filter((item: GetItemUsageInheritanceDTO) => item.type === 'descendant') || [],
  };

  const pendingAncestorData =
    allAncestorData?.items
      .filter((item: GetItemUsageInheritanceDTO) => !processedAncestorData.includes(item.itemCode))
      .map((item: GetItemUsageInheritanceDTO) => item.itemCode) || [];

  const pendingRemovedAncestorData =
    processedAncestorData.filter(
      (itemCode) => !allAncestorData?.items.some((item: GetItemUsageInheritanceDTO) => item.itemCode === itemCode),
    ) || [];

  const hasPendingAncestorData = pendingAncestorData && pendingAncestorData.length > 0;
  const hasPendingRemovedAncestorData = pendingRemovedAncestorData && pendingRemovedAncestorData.length > 0;
  const hasProcessedAncestorData = processedAncestorData && processedAncestorData.length > 0;

  const nextUpdateDays = getNextUsageUpdateDate();

  const handleAddUsageAncestor = () => {
    showAsyncModal<EditUsageInheritanceModalProps>(UsageInheritanceModal, {
      usageInheritanceData: {
        descendantItemId: itemUid,
        descendantItemCode: itemId,
        existingOverrides: usingRecurrencyInheritance && inheritanceData ? inheritanceData.items : [],
        onDataChanged,
        nextUpdateDays,
      },
    });
  };

  return (
    <div>
      {allDescendantData.items.length > 0 && (
        <Alert
          icon={<ExclamationCircleOutlined />}
          message={
            <>
              <Tooltip title="We are still displaying this item's full usage data, but its usage is also being included in descendant items that use this data as their own. Please be cautious during planning to avoid over-purchasing.">
                This item's usage is inherited by{' '}
                {allDescendantData.items.map((item: GetItemUsageInheritanceDTO, index: number) => (
                  <React.Fragment key={item.itemCode}>
                    <Link to={makePath(routePaths.purchasing.itemDetails, { id: item.itemCode })}>{item.itemCode}</Link>
                    {index < allDescendantData.items.length - 1 ? ', ' : ''}
                  </React.Fragment>
                ))}
                .
              </Tooltip>
            </>
          }
          banner
          className={css`
            margin-bottom: 16px;
            background-color: ${theme.colors.primary[200]};
            border-radius: 10px;
            .ant-alert-icon {
              color: ${theme.colors.neutral[900]};
            }
            .ant-alert-message {
              color: ${theme.colors.neutral[900]};
            }
          `}
        />
      )}
      {(hasPendingAncestorData || hasPendingRemovedAncestorData) && (
        <div>
          {hasPendingAncestorData && (
            <div>
              Set to inherit usage from:{' '}
              {pendingAncestorData.map((item, index) => (
                <React.Fragment key={item}>
                  <Link to={makePath(routePaths.purchasing.itemDetails, { id: item })}>{item}</Link>
                  {index < pendingAncestorData.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}
            </div>
          )}
          {hasPendingRemovedAncestorData && (
            <div>
              Set to stop inheriting from:{' '}
              {pendingRemovedAncestorData.map((item, index) => (
                <React.Fragment key={item}>
                  <Link to={makePath(routePaths.purchasing.itemDetails, { id: item })}>{item}</Link>
                  {index < pendingRemovedAncestorData.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}
            </div>
          )}
          <div
            className={css`
              color: ${theme.colors.neutral[400]};
            `}
          >
            {nextUpdateDays !== null ? `Will update in ${nextUpdateDays} day${nextUpdateDays !== 1 ? 's' : ''}` : null}
          </div>
        </div>
      )}
      {hasProcessedAncestorData && (
        <>
          Inheriting usage from:{' '}
          {processedAncestorData.map((item, index) => (
            <React.Fragment key={item}>
              <Link to={makePath(routePaths.purchasing.itemDetails, { id: item })}>{item}</Link>
              {index < processedAncestorData.length - 1 ? ', ' : ''}
            </React.Fragment>
          ))}
        </>
      )}
      {!hasPendingAncestorData && !hasProcessedAncestorData && (
        <div
          className={css`
            color: ${theme.colors.neutral[400]};
          `}
        >
          No usage inheritance.
        </div>
      )}
      {usingRecurrencyInheritance && (
        <ActionButton
          label={
            <>
              <EditOutlined /> Edit
            </>
          }
          onClick={handleAddUsageAncestor}
          className={css`
            margin-top: 8px;
          `}
        />
      )}
    </div>
  );
};
