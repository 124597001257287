import React, { useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { P21LeadTimeSource, PlanningHubAndSpokeType } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';
import { Alert, Radio } from 'antd';

import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { ModalSectionTitle } from 'components/Modal/ModalSectionTitle';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';
import { ReplenishmentPathTimeline } from 'components/recipes/planning/ReplenishmentPathTimeline';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { pluralize, splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { getTenantSetting } from 'utils/tenantSettings';
import { PlanningModalType, track, TrackEvent } from 'utils/track';

import { LeadTimeHistoryChartTable } from './LeadTimeHistoryChartTable';
import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import { getPlanningListSelectionFromFilterSelection, MultiModalSelection } from './utils';

export enum ChangeType {
  Smart = 'smart',
  Override = 'override',
}

export function MultiLeadTimeModal({
  selection,
  onClose,
  searchIndexReload,
}: {
  selection: MultiModalSelection;
  onClose: (isSubmit: boolean) => void;
  searchIndexReload?: () => void;
}) {
  const { activeTenant } = useGlobalApp();
  const planningHubAndSpokeSetting = getTenantSetting(TenantSettingKey.FeaturePlanningHubAndSpoke);
  const singleRecord = selection.type === 'list' && selection.items.length === 1 ? selection.items[0] : undefined;
  const [leadTime, setLeadTime] = useState<number | undefined>(() =>
    singleRecord?.lead_time_source === P21LeadTimeSource.override ? singleRecord.lead_time : undefined,
  );

  const { data: replenishmentPathData, isLoading: isLoadingReplenishmentPath } = useCoreApi(
    schemas.itemLocations.getItemLocationReplenishmentPath,
    {
      queryParams: {
        itemId: singleRecord?.item_uid ?? '',
        locationId: singleRecord ? splitIdNameStr(singleRecord.location).foreignId : '',
      },
    },
  );

  const [changeType, setChangeType] = useState<ChangeType>(
    leadTime === undefined ? ChangeType.Smart : ChangeType.Override,
  );

  const updateChangeType = (newChangeType: ChangeType) => {
    // clear value when changeType is changed
    setLeadTime(undefined);
    setChangeType(newChangeType);
  };

  const handleLeadTimeUpdate = async () => {
    onClose(true);

    const submitNotification = createSubmissionNotification({
      entityName: 'Lead time changes',
      submittingMessage: 'Updating Lead time changes',
      expectedWaitSeconds: 15,
      erpType: activeTenant.erpType,
    });
    try {
      if (selection.type === 'filter') {
        selection = await getPlanningListSelectionFromFilterSelection(selection);
      }

      await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
        pathParams: {
          updateType: UpdateItemLocationInfoType.LeadTime,
        },
        bodyParams: {
          updates: selection.items.map((record) => ({
            itemUid: record.item_uid,
            locationId: splitIdNameStr(record.location).foreignId,
            updateValue: leadTime ?? null,
          })),
        },
      });
      track(TrackEvent.DemandPlanning_MinMaxInfo_Submission, {
        modalType: PlanningModalType.MultiLeadTime,
        itemCount: selection.count,
      });
      searchIndexReload?.();
      submitNotification.success({
        successMessage: `Successfully updated lead ${pluralize(selection.count, 'time', 'times')}`,
      });
    } catch (err) {
      submitNotification.error(err);
    }
  };

  const isSpokeLocation =
    singleRecord?.location !== singleRecord?.replenishment_location &&
    planningHubAndSpokeSetting !== PlanningHubAndSpokeType.NoHubAndSpoke;

  return (
    <Modal
      visible
      title={
        singleRecord
          ? `Update Lead Time for Item #${singleRecord.item_id} at Location ${singleRecord.location}`
          : `Update Lead Time for ${pluralize(selection.count, 'Item', 'Items', true)}`
      }
      onCancel={() => onClose(false)}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={handleLeadTimeUpdate} type="primary">
            Update
          </Button>
        </>
      }
      width={900}
    >
      <FlexSpace direction="column" gap={16}>
        <RadioGroup value={changeType} onChange={({ target: { value } }) => updateChangeType(value)}>
          <FlexSpace direction="column" gap={8}>
            <Radio value={ChangeType.Smart}>
              Smart{' '}
              <InfoTooltip title="A Recurrency recommendation generated based on historical data from this item and supplier" />
            </Radio>
            <Radio value={ChangeType.Override}>
              <FlexSpace direction="column" gap={8}>
                <div>
                  Override <InfoTooltip title="Fixed lead time override set in Recurrency" />
                </div>
                <PropertyListItem
                  label="Lead Time (days)"
                  value={
                    <Input
                      type="number"
                      disabled={changeType === ChangeType.Smart}
                      onChange={(ev) => {
                        const inputValue = ev.currentTarget.value;
                        // Set leadTime to undefined if the input is empty, otherwise convert it to a number
                        setLeadTime(inputValue === '' ? undefined : Number(inputValue));
                      }}
                      value={leadTime ?? ''}
                    />
                  }
                />
              </FlexSpace>
            </Radio>
          </FlexSpace>
        </RadioGroup>
      </FlexSpace>
      <DividerLine />
      {isSpokeLocation && (
        <>
          <ModalSectionTitle title="Replenishment Path" />
          <FlexSpace
            className={css`
              margin-top: 10px;
            `}
            direction="column"
          >
            {planningHubAndSpokeSetting === PlanningHubAndSpokeType.Rigid && (
              <Alert
                className={css`
                  background-color: #e6f4ff;
                `}
                type="info"
                showIcon
                banner
                message="The lead time for a spoke location is based on the transfer time from its replenishment location."
              />
            )}
            <ReplenishmentPathTimeline
              isLoading={isLoadingReplenishmentPath}
              location={singleRecord?.location}
              supplier={singleRecord?.primary_supplier}
              replenishmentPathData={replenishmentPathData}
            />
          </FlexSpace>
          <DividerLine />
        </>
      )}
      <MultiUpdateReplenishmentSettingsTable
        selection={selection}
        bulkUpdate={{
          type: 'leadTime',
          leadTime,
        }}
      />
      {singleRecord && (planningHubAndSpokeSetting === PlanningHubAndSpokeType.NoHubAndSpoke || !isSpokeLocation) ? (
        <>
          <DividerLine />
          <LeadTimeHistoryChartTable record={singleRecord} />
        </>
      ) : null}
    </Modal>
  );
}
