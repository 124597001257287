import React from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { P21SafetyStockType } from '@recurrency/core-api-schema/dist/common/enums';
import { ConfigProvider } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import { ChangeDeltaWithArrow } from 'components/recipes/ChangeDeltaWithArrow';
import { LastUpdated } from 'components/recipes/LastUpdatedMoment';
import { Table } from 'components/Table';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';

import { calculatePercentDifference } from 'utils/calculations';
import { splitIdNameStr, formatPercent, pluralize, formatNumber } from 'utils/formatting';
import { routes } from 'utils/routes';

import { SearchIndexMinMax } from 'types/search-collections';

import { formatSafetyStock, isSafetyStockTypeServiceLevel } from './utils';

type ReasonsData = {
  attribute: React.ReactNode;
  lastValue: React.ReactNode;
  currentValue: React.ReactNode;
  difference: React.ReactNode;
};

export function ChangesSinceLastMinMaxUpdateSection({ record }: { record: SearchIndexMinMax }) {
  const { data: reasonsData, isLoading: reasonsIsLoading } = useCoreApi(
    schemas.purchasing.getItemLocationRecommendationReasons,
    record
      ? {
          queryParams: { itemUid: record.item_uid, locationId: splitIdNameStr(record.location).foreignId },
        }
      : null,
  );

  const reasonsColumns: ColumnType<ReasonsData>[] = [
    {
      title: 'Attribute',
      dataIndex: 'attribute',
    },
    {
      title: 'Last Value',
      dataIndex: 'lastValue',
    },
    {
      title: 'Current Value',
      dataIndex: 'currentValue',
    },
    {
      title: `∆%`,
      dataIndex: 'difference',
      align: 'right',
      render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={formatPercent} />,
    },
  ];

  const reasonsRows = [
    {
      attribute: <InfoTooltip title="Forecasted daily demand over lead time">Forecasted Daily Demand</InfoTooltip>,
      lastValue: `${formatNumber(reasonsData?.avgDemand)} ${record.unit_of_measure}`,
      currentValue: `${formatNumber(record.avg_demand)} ${record.unit_of_measure}`,
      difference: calculatePercentDifference(record.avg_demand, reasonsData?.avgDemand),
    },
    {
      attribute: 'Safety Stock',
      lastValue: reasonsData?.safetyStockValue
        ? formatSafetyStock(reasonsData?.safetyStockValue, reasonsData.safetyStockType as P21SafetyStockType)
        : '-',
      currentValue: `${formatNumber(record.safety_stock_value)} ${pluralize(record.safety_stock_value, 'day', 'days')}`,
      difference:
        isSafetyStockTypeServiceLevel(reasonsData?.safetyStockType as P21SafetyStockType) ||
        isSafetyStockTypeServiceLevel(record?.safety_stock_type)
          ? null
          : calculatePercentDifference(record.safety_stock_value, reasonsData?.safetyStockValue),
    },
    {
      attribute: 'Review Cycle',
      lastValue: `${formatNumber(reasonsData?.reviewCycle)} days`,
      currentValue: `${formatNumber(record.review_cycle)} ${pluralize(record.review_cycle, 'day', 'days')}`,
      difference: calculatePercentDifference(record.review_cycle, reasonsData?.reviewCycle),
    },
    {
      attribute: 'Lead Time',
      lastValue: `${formatNumber(reasonsData?.leadTime)} days`,
      currentValue: `${formatNumber(record.lead_time)} ${pluralize(record.lead_time, 'day', 'days')}`,
      difference: calculatePercentDifference(record.lead_time, reasonsData?.leadTime),
    },
  ];

  return (
    <>
      <LastUpdated entityName="Replenishment settings" dateString={reasonsData?.createdAt.toString()} />
      <ConfigProvider
        renderEmpty={() => (
          <div
            className={css`
              margin: 36px;
              color: ${theme.colors.neutral[400]};
            `}
          >
            There is no data for this item because min/max has never been changed through Recurrency.
            <br />
            Explore the
            <Link
              to={routes.demandPlanning.forecasting({ query: record.item_id, where: { location: [record.location] } })}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              forecast for this item{' '}
            </Link>
            to learn more.
          </div>
        )}
      >
        <Table
          isLoading={reasonsIsLoading}
          className={css`
            margin-top: 16px;
          `}
          columns={reasonsColumns}
          data={reasonsIsLoading || !reasonsData ? [] : reasonsRows}
          rowKey="attribute"
        />
      </ConfigProvider>
    </>
  );
}
