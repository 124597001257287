import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TransferTargetReviewMethods } from '@recurrency/core-api-schema/dist/common/enums';
import { Form, notification, Radio, Typography } from 'antd';

import { Button } from 'components/Button';
import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input';
import { NotificationLink } from 'components/Links';
import { SmallLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes } from 'utils/routes';

import { RepeatingScheduleSelector } from '../RepeatingScheduleSelector/RepeatingScheduleSelector';

const DEFAULT_SCHEDULE = '7-0 0 * * 1-1';

export interface LocationTransferTargetSettings {
  sourceLocationId: string;
  destinationLocationId: string;
  reviewMethod: TransferTargetReviewMethods;
  reviewSchedule: string;
}

export interface SupplierLocationSettingsModalProps {
  sourceLocationId: string;
  destinationLocationId: string;
  onClose: (newSettings?: LocationTransferTargetSettings) => void;
}

interface FormData {
  reviewMethod: TransferTargetReviewMethods;
  reviewDays: number;
  schedule: string;
}

export function LocationTransferSettingsModal({
  sourceLocationId,
  destinationLocationId,
  onClose,
}: SupplierLocationSettingsModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationTransferSettingForm] = Form.useForm<FormData>();
  const [reviewType, setReviewType] = useState<TransferTargetReviewMethods>();

  const { data: prevSettingsData, isLoading } = useCoreApi(schemas.transferOrders.getLocationTransferSettings, {
    queryParams: { sourceLocationId, destinationLocationId },
  });

  const settingsData = useMemo(
    () =>
      prevSettingsData ?? {
        sourceLocationId,
        destinationLocationId,
        reviewMethod: TransferTargetReviewMethods.Cycle,
        reviewSchedule: DEFAULT_SCHEDULE,
      },
    [destinationLocationId, prevSettingsData, sourceLocationId],
  );

  const [prevReviewDays, prevSchedule, prevWeeks] = (settingsData.reviewSchedule || DEFAULT_SCHEDULE).split('-');

  async function handleSubmit(data: FormData) {
    try {
      setIsSubmitting(true);
      const newSettings: LocationTransferTargetSettings = {
        sourceLocationId,
        destinationLocationId,
        reviewMethod: reviewType as TransferTargetReviewMethods,
        reviewSchedule: `${data.reviewDays}-${data.schedule}` || '',
      };
      await coreApiFetch(schemas.transferOrders.patchLocationTransferSettings, { bodyParams: newSettings });
      notification.success({
        key: sourceLocationId,
        message: (
          <>
            <div>Updated target & review settings</div>
            <NotificationLink
              notificationKey={sourceLocationId}
              to={routes.purchasing.transferTargets({
                sourceLocationIds: [sourceLocationId],
                destinationLocationIds: [destinationLocationId],
              })}
            >
              View targets for Source #{sourceLocationId} and Destination #{destinationLocationId}
            </NotificationLink>
          </>
        ),
        duration: 5,
      });
      // update api response data after patch
      onClose(newSettings);
    } catch (err) {
      captureAndShowError(err, 'Failed to update target & review settings');
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (settingsData) {
      const initialValues = {
        reviewMethod: settingsData.reviewMethod as TransferTargetReviewMethods,
        reviewDays: parseInt(prevReviewDays, 10),
        schedule: `${prevSchedule}-${prevWeeks}`,
      };
      locationTransferSettingForm.setFieldsValue(initialValues);
      setReviewType(settingsData.reviewMethod as TransferTargetReviewMethods);
    }
  }, [settingsData, locationTransferSettingForm, prevReviewDays, prevSchedule, prevWeeks]);

  return (
    <Modal
      visible
      title={`Set Review Cycle for transfers between source location #${sourceLocationId} at destination location #${destinationLocationId}`}
      onCancel={() => onClose()}
      centered
      width={900}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            form="locationTransferSettingForm"
            htmlType="submit"
            disabled={isLoading}
            loading={isSubmitting}
          >
            Save
          </Button>
        </>
      }
    >
      {isLoading ? (
        <SmallLoader />
      ) : (
        <Form.Provider>
          <Form
            name="locationTransferSettingForm"
            form={locationTransferSettingForm}
            onFinish={handleSubmit}
            onError={console.error}
            onFinishFailed={console.error}
          >
            <RadioGroup
              value={reviewType}
              onChange={(ev) => {
                const newScheduleType = ev.target.value as TransferTargetReviewMethods;
                locationTransferSettingForm.setFieldsValue({
                  ...locationTransferSettingForm.getFieldsValue(),
                  reviewMethod: newScheduleType,
                });
                setReviewType(newScheduleType);
              }}
            >
              <FlexSpace gap={20} direction="column" fullWidth>
                <Radio value={TransferTargetReviewMethods.Schedule}>
                  <FlexSpace gap={10} direction="column" fullWidth>
                    <div className={boldText}>Review Schedule</div>
                    <Typography.Paragraph>
                      Recurrency will mark a transfer “Ready for Review” based on this configurable review schedule.
                      Every time a TO is created in Recurrency or a location pair is “Marked as Reviewed,” the cycle
                      will begin on the next day that matches this schedule.
                    </Typography.Paragraph>
                    <Form.Item name="schedule" label="Review Schedule">
                      <RepeatingScheduleSelector value={locationTransferSettingForm.getFieldValue('schedule')} />
                    </Form.Item>
                  </FlexSpace>
                </Radio>
                <Radio value={TransferTargetReviewMethods.Cycle}>
                  <FlexSpace gap={10} direction="column" fullWidth>
                    <div className={boldText}>Review Cycle</div>
                    <Typography.Paragraph>
                      Recurrency will mark a transfer “Ready for Review” based on this configurable review cadence.
                      Every time a TO is created in Recurrency or a location pair is “Marked as Reviewed,” the cycle
                      will reset to begin on that day.
                    </Typography.Paragraph>
                    <PropertyListItem label="Current Review Cycle (days)" value={prevReviewDays} />
                    <PropertyListItem
                      label="Review Cycle (days)"
                      value={
                        <Form.Item name="reviewDays">
                          <Input type="number" min={0} disabled={reviewType !== TransferTargetReviewMethods.Cycle} />
                        </Form.Item>
                      }
                    />
                  </FlexSpace>
                </Radio>
              </FlexSpace>
            </RadioGroup>
          </Form>
        </Form.Provider>
      )}
    </Modal>
  );
}

const boldText = css`
  font-weight: bold;
`;
