import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { NgrokIntegrationDTO } from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import Popover from 'antd/lib/popover';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { showAsyncModal } from 'utils/asyncModal';
import { sortableNumberColumn, sortableStringColumn } from 'utils/tables';

import { TenantIntegrationExportPage } from '../TenantIntegrationExportPage';

export interface TenantIntegrationsAgentsTableProps {
  tenant: TenantDTO;
}

export function TenantIntegrationsAgentsTable({ tenant }: TenantIntegrationsAgentsTableProps) {
  const { data: agents, isLoading } = useCoreApi(schemas.integrations.getAllAgentsByTenant, {
    pathParams: { tenantId: tenant.id },
  });

  const columns: ColumnType<NgrokIntegrationDTO>[] = [
    sortableStringColumn({
      title: 'Edge ID',
      dataIndex: 'edgeId',
    }),
    sortableStringColumn({
      title: 'Type',
      dataIndex: 'type',
    }),
    sortableStringColumn({
      title: 'Domain',
      dataIndex: 'domain',
    }),
    sortableNumberColumn({
      title: 'Port',
      dataIndex: 'port',
      render: (port: number) => port, // standard formatting inserts commas into the numeric values.  To make copy+paste easier, skip the default formatting
    }),
    sortableStringColumn({
      title: 'Tunnel Status',
      dataIndex: 'status',
      render: (status: boolean) => (status === true ? 'Online' : 'Offline'),
    }),
    {
      align: 'right',
      render: (record: NgrokIntegrationDTO) => (
        <div>
          <Popover content={record.authToken} title="Ngrok Auth Token" trigger="click">
            <Button>View Auth Token</Button>
          </Popover>
          <Button
            onClick={() =>
              showAsyncModal(TenantIntegrationExportPage, {
                agent: record,
                tenant,
              })
            }
          >
            Export Config
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} data={agents?.items || []} rowKey="id" isLoading={isLoading} />
    </>
  );
}
