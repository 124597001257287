import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { IntegratedErps, RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { ColumnType } from 'antd/lib/table';

import { ItemSetupMinMaxRadioGroup } from 'pages/planning/OnboardingPage/ItemSetupMinMaxRadioGroup';

import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { ModalSectionTitle } from 'components/Modal/ModalSectionTitle';
import { ItemLocationSettingsAuditLog } from 'components/recipes/AuditLog/ItemLocationSettingsAuditLog';
import { SupplierLocationSettingsAuditLog } from 'components/recipes/AuditLog/SupplierLocationSettingsAuditLog';
import { Table } from 'components/Table';
import { Tabs } from 'components/Tabs';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { formatNumber, getErpName, splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { MinMaxSubmissionType, PlanningModalType, track, TrackEvent } from 'utils/track';

import { SearchIndexMinMax } from 'types/search-collections';

import { shouldShowOpOqVariant } from '../../utils';
import { ChangesSinceLastMinMaxUpdateSection } from './ChangesSinceLastMinMaxUpdateSection';
import { RecommendedMaxPopover } from './RecommendedMaxPopover';
import { RecommendedMinPopover } from './RecommendedMinPopover';
import { ExportOptions, ExportValues } from './utils';

type ReplenishmentSettings = {
  setting: React.ReactNode;
  min: number | React.ReactNode;
  max: number | React.ReactNode;
  replenishmentMethod: string | React.ReactNode;
};

export function UpdateReplenishmentSettingsModal({
  record,
  isOnboardingVariant = false,
  onClose,
  searchIndexReload,
}: {
  record: SearchIndexMinMax;
  isOnboardingVariant?: boolean;
  onClose: (isSubmit: boolean) => void;
  searchIndexReload?: () => void;
}) {
  const { activeUser, activeTenant } = useGlobalApp();
  const isOpOqVariant = shouldShowOpOqVariant(activeTenant, activeUser, record.recurrency_replenishment_method);

  const [customOp, setCustomOp] = useState<number>(record.current_min);
  const [customOq, setCustomOq] = useState<number>(record.current_max);
  const [customMin, setCustomMin] = useState<number>(record.recommended_min);
  const [customMax, setCustomMax] = useState<number>(record.recommended_max);
  const [selectedRowKeys, setSelectedRowKeys] = useState([
    isOpOqVariant ? ExportOptions.RecommendedOpOq : ExportOptions.RecommendedMinMax,
  ]);

  const exportValues: ExportValues = useMemo(() => {
    switch (selectedRowKeys[0]) {
      case ExportOptions.RecommendedOpOq:
        return {
          exportedMin: record.recommended_min,
          exportedMax: record.current_max,
          exportedReplenishmentMethod: RecurrencyReplenishmentMethod.OPOQ,
          exportedSubmissionType: MinMaxSubmissionType.Recommended,
        };
      case ExportOptions.CustomOpOq:
        return {
          exportedMin: customOp,
          exportedMax: customOq,
          exportedReplenishmentMethod: RecurrencyReplenishmentMethod.OPOQ,
          exportedSubmissionType: MinMaxSubmissionType.Custom,
        };
      case ExportOptions.CustomMinMax:
        return {
          exportedMin: customMin,
          exportedMax: customMax,
          exportedReplenishmentMethod: RecurrencyReplenishmentMethod.MinMax,
          exportedSubmissionType: isOpOqVariant ? MinMaxSubmissionType.CustomMinMax : MinMaxSubmissionType.Custom,
        };
      /** Default to handle recommended min-max as the default case */
      default:
        return {
          exportedMin: record.recommended_min,
          exportedMax: record.recommended_max,
          exportedReplenishmentMethod: RecurrencyReplenishmentMethod.MinMax,
          exportedSubmissionType: isOpOqVariant
            ? MinMaxSubmissionType.RecommendedMinMax
            : MinMaxSubmissionType.Recommended,
        };
    }
  }, [
    customMax,
    customMin,
    customOp,
    customOq,
    isOpOqVariant,
    record.current_max,
    record.recommended_max,
    record.recommended_min,
    selectedRowKeys,
  ]);

  const isSubmissionDisabled = useMemo(() => {
    if (
      exportValues.exportedMin === record.current_min &&
      exportValues.exportedMax === record.current_max &&
      exportValues.exportedReplenishmentMethod === record.recurrency_replenishment_method
    ) {
      return true;
    }
    if (
      exportValues.exportedReplenishmentMethod === RecurrencyReplenishmentMethod.MinMax &&
      exportValues.exportedMin > exportValues.exportedMax
    ) {
      return true;
    }
    return false;
  }, [
    exportValues.exportedMax,
    exportValues.exportedMin,
    exportValues.exportedReplenishmentMethod,
    record.current_max,
    record.current_min,
    record.recurrency_replenishment_method,
  ]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    type: 'radio',
  };

  const handleReplenishmentUpdate = async () => {
    onClose(true);
    const locationId = splitIdNameStr(record.location).foreignId;
    const entityName = `replenishment values for Item #${record.item_id} at ${record.location}`;
    const submitNotification = createSubmissionNotification({
      entityName: entityName[0].toUpperCase() + entityName.slice(1), // capitalize first letter since it is the title
      expectedWaitSeconds: 45,
      erpType: activeTenant.erpType,
      submittingMessage:
        activeTenant.erpType === IntegratedErps.P21
          ? `Updating ${entityName} to ${activeTenant.erpType.toUpperCase()}`
          : `Saving ${entityName}`,
    });

    try {
      await coreApiFetch(schemas.purchasing.postUpdateItemLocationMinMax, {
        bodyParams: {
          updates: [
            {
              itemId: record.item_id,
              itemUid: record.item_uid,
              locationId,
              currentMin: record.current_min,
              currentMax: record.current_max,
              currentReplenishmentMethod: record.replenishment_method,
              newReplenishmentMethod: exportValues.exportedReplenishmentMethod,
              newMin: exportValues.exportedMin,
              newMax: exportValues.exportedMax,
              safetyStockType: record.safety_stock_type,
              safetyStockValue: record.safety_stock_value,
              reviewCycle: record.review_cycle,
              leadTime: record.lead_time,
              avgDemand: record.avg_demand,
            },
          ],
        },
      });
      track(TrackEvent.DemandPlanning_MinMax_Submission, {
        modalType: isOpOqVariant ? PlanningModalType.SingleOpOq : PlanningModalType.SingleMinMax,
        itemCount: 1,
        submissionType: exportValues.exportedSubmissionType,
      });
      searchIndexReload?.();
      submitNotification.success();
    } catch (err) {
      submitNotification.error(err);
    }
  };

  const tableColumns = ({
    showMinMax = true,
    isOpOq = true,
  }: {
    showMinMax?: boolean;
    isOpOq?: boolean;
  }): ColumnType<ReplenishmentSettings>[] =>
    [
      {
        title: 'Setting',
        dataIndex: 'setting',
      },
      showMinMax
        ? {
            title: !isOpOq ? 'Min' : 'Order Point',
            dataIndex: 'min',
          }
        : null,
      showMinMax
        ? {
            title: !isOpOq ? 'Max' : 'Order Quantity',
            dataIndex: 'max',
          }
        : null,
      {
        title: 'Replenishment Method',
        dataIndex: 'replenishmentMethod',
      },
    ].filter(truthy);

  const currentRow: ReplenishmentSettings[] = [
    {
      setting: ExportOptions.Current,
      min: `${formatNumber(record.current_min)} ${record.unit_of_measure}`,
      max: `${formatNumber(record.current_max)} ${record.unit_of_measure}`,
      replenishmentMethod: isOnboardingVariant ? record.replenishment_method : record.recurrency_replenishment_method,
    },
  ];

  const opOqRows: ReplenishmentSettings[] = [
    {
      setting: ExportOptions.RecommendedOpOq,
      min: (
        <>
          {formatNumber(record.recommended_min)} {record.unit_of_measure}
          <RecommendedMinPopover record={record} showOpOqVariant />
        </>
      ),
      max: `${formatNumber(record.current_max)} ${record.unit_of_measure}`,
      replenishmentMethod: RecurrencyReplenishmentMethod.OPOQ,
    },
    {
      setting: ExportOptions.CustomOpOq,
      min: (
        <Input
          type="number"
          onChange={(ev) => {
            setSelectedRowKeys([ExportOptions.CustomOpOq]);
            setCustomOp(Number(ev.currentTarget.value));
          }}
          value={customOp}
        />
      ),
      max: (
        <Input
          type="number"
          onChange={(ev) => {
            setSelectedRowKeys([ExportOptions.CustomOpOq]);
            setCustomOq(Number(ev.currentTarget.value));
          }}
          value={customOq}
        />
      ),
      replenishmentMethod: RecurrencyReplenishmentMethod.OPOQ,
    },
  ];

  const minMaxRows: ReplenishmentSettings[] = [
    {
      setting: ExportOptions.RecommendedMinMax,
      min: (
        <>
          {formatNumber(record.recommended_min)} {record.unit_of_measure}
          <RecommendedMinPopover record={record} />
        </>
      ),
      max: (
        <>
          {formatNumber(record.recommended_max)} {record.unit_of_measure}
          <RecommendedMaxPopover record={record} />
        </>
      ),
      replenishmentMethod: RecurrencyReplenishmentMethod.MinMax,
    },
    {
      setting: ExportOptions.CustomMinMax,
      min: (
        <Input
          type="number"
          onChange={(ev) => {
            setSelectedRowKeys([ExportOptions.CustomMinMax]);
            setCustomMin(Number(ev.currentTarget.value));
          }}
          value={customMin}
        />
      ),
      max: (
        <Input
          type="number"
          onChange={(ev) => {
            setSelectedRowKeys([ExportOptions.CustomMinMax]);
            setCustomMax(Number(ev.currentTarget.value));
          }}
          value={customMax}
        />
      ),
      replenishmentMethod: RecurrencyReplenishmentMethod.MinMax,
    },
  ];

  return (
    <Modal
      visible
      title={
        isOnboardingVariant
          ? `Set Up Item #${record.item_id} at Location ${record.location} to use Recurrency`
          : `Update Replenishment Settings for Item #${record.item_id} at Location ${record.location}`
      }
      onCancel={() => onClose(false)}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={handleReplenishmentUpdate} type="primary" disabled={isSubmissionDisabled}>
            {activeTenant.erpType === IntegratedErps.P21 ? `Send to ${getErpName(activeTenant.erpType)}` : 'Save'}
          </Button>
        </>
      }
      width={900}
    >
      {isOnboardingVariant && (
        <>
          <p
            className={css`
              margin-bottom: 20px;
            `}
          >
            {isTenantErpTypeP21(activeTenant.erpType)
              ? `Setting up an item to use Recurrency will change the replenishment method in your ERP to use Min/Max in addition to updating the minimum and maximum values. From there, the item will have continuous recommendations updated in the Planning page that can be reviewed. Learn more about setting up items in Recurrency here.`
              : `Setting up an item to use Recurrency will update the minimum and maximum values in Recurrency. From there, the item will have continuous recommendations updated in the Planning page that can be reviewed. Learn more about setting up items in Recurrency here.`}
          </p>
        </>
      )}

      <ModalSectionTitle title="Current Settings" />
      <ReplenishmentSettingsTable
        className={css`
          margin-top: 16px;
          margin-bottom: 24px;
        `}
        columns={tableColumns({ isOpOq: isOpOqVariant, showMinMax: !isOnboardingVariant })}
        data={currentRow}
      />
      {isOpOqVariant && (
        <>
          <ModalSectionTitle title="OP/OQ Settings" />
          <ReplenishmentSettingsTable
            className={css`
              margin-top: 16px;
            `}
            columns={tableColumns({})}
            data={opOqRows}
            rowSelection={rowSelection}
          />
        </>
      )}
      <div
        className={css`
          margin-bottom: 24px;
        `}
      />
      <ModalSectionTitle title={isOpOqVariant ? 'Change to Min/Max' : 'Min/Max Settings'} />
      {isOnboardingVariant ? (
        <ItemSetupMinMaxRadioGroup
          record={record}
          value={selectedRowKeys[0]}
          options={{ recommended: ExportOptions.RecommendedMinMax, custom: ExportOptions.CustomMinMax }}
          onChange={(newVal) => setSelectedRowKeys([newVal])}
          customMin={customMin}
          customMax={customMax}
          setCustomMin={setCustomMin}
          setCustomMax={setCustomMax}
        />
      ) : (
        <ReplenishmentSettingsTable
          className={css`
            margin-top: 16px;
          `}
          columns={tableColumns({ isOpOq: false })}
          data={minMaxRows}
          rowSelection={rowSelection}
        />
      )}
      <DividerLine marginTop={42} />
      <Tabs
        tabs={[
          !isOnboardingVariant && {
            header: 'Changes Since Last Update',
            content: <ChangesSinceLastMinMaxUpdateSection record={record} />,
          },
          {
            header: 'Item Audit Log',
            infoTooltip: `All updates for Item #${record.item_id} at Location #${record.location}`,
            content: (
              <ItemLocationSettingsAuditLog
                itemUid={record.item_uid}
                locationId={splitIdNameStr(record.location).foreignId}
              />
            ),
          },
          record.primary_supplier && {
            header: 'Supplier Audit Log',
            infoTooltip: `All updates for Supplier #${record.primary_supplier} at Location #${record.location}`,
            content: (
              <SupplierLocationSettingsAuditLog
                supplierId={splitIdNameStr(record.primary_supplier).foreignId}
                locationId={splitIdNameStr(record.location).foreignId}
              />
            ),
          },
        ].filter(truthy)}
      />
    </Modal>
  );
}

function ReplenishmentSettingsTable({
  columns,
  data,
  className,
  rowSelection,
}: {
  columns: ColumnType<ReplenishmentSettings>[];
  data: ReplenishmentSettings[];
  className: string;
  rowSelection?: any;
}) {
  return (
    <Table
      verticalAlign="center"
      className={className}
      columns={columns}
      data={data}
      rowKey="setting"
      rowSelection={rowSelection}
    />
  );
}
