import { CustomerType } from '@recurrency/core-api-schema/dist/common/enums';
import { CreateCustomerBody } from '@recurrency/core-api-schema/dist/customers/createCustomer';

import { splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';

import { ProspectHashState } from 'types/hash-state';

export function prospectRequestFromHashState(hashState: ProspectHashState, tenantId: string): CreateCustomerBody {
  return {
    name: hashState.customerName!,
    type: hashState.type || CustomerType.Prospect,
    tenantId,
    salesRepresentative: { foreignId: splitIdNameStr(hashState.salesRep!).foreignId },
    address: {
      // This can be changed to be based on a specific addresses name in the future instead of the company name
      name: hashState.customerName!,
      metadata: {},
      physical: {
        city: hashState['physical-city'],
        address1: hashState['physical-address1'],
        address2: hashState['physical-address2'],
        state: hashState['physical-state'],
        zipcode: hashState['physical-zipcode'],
      },
      mailing: hashState.addressesSame
        ? {
            city: hashState['physical-city'],
            address1: hashState['physical-address1'],
            address2: hashState['physical-address2'],
            state: hashState['physical-state'],
            zipcode: hashState['physical-zipcode'],
          }
        : {
            city: hashState['mailing-city'],
            address1: hashState['mailing-address1'],
            address2: hashState['mailing-address2'],
            state: hashState['mailing-state'],
            zipcode: hashState['mailing-zipcode'],
          },
      phone: hashState.customerPhone,
      fax: hashState.customerFax,
      email: hashState.customerEmail,
    },
    contact: hashState.contactFirstName
      ? {
          firstName: hashState.contactFirstName,
          lastName: hashState.contactLastName,
          title: hashState.contactTitle,
          phone: hashState.contactPhone,
          fax: hashState.contactFax,
          email: hashState.contactEmail,
        }
      : undefined,
    shipToLocation: hashState.makeShipTo
      ? {
          metadata: {
            defaultBranchForeignId: splitIdNameStr(hashState.shipToDefaultBranch!).foreignId,
            preferredLocationForeignId: splitIdNameStr(hashState.shipToPreferredLocation!).foreignId,
            // P21 Cloud requires the name and P21 requires the id
            freightCode: splitIfIdNameStr(hashState.shipToDefaultFreight),
          },
        }
      : undefined,
    customerIvaTax: hashState.taxRegime
      ? {
          companyPerson: hashState.companyPerson || ``,
          ivaExemptionNumber: hashState.ivaExemptionNumber?.toLocaleUpperCase(),
          taxRegistrationId: splitIdNameStr(hashState.taxRegime).foreignId,
        }
      : undefined,
    metadata: { companyId: splitIdNameStr(hashState.company!).foreignId },
  };
}
