import { SearchIndexForecast } from 'types/search-collections';

function getSummedDemandData(data: { keys: string[]; vals: number[] }[], isForecast: boolean) {
  const obj: Record<string, number> = {};

  data.forEach((hit) => {
    const { keys, vals } = hit;
    keys.forEach((key, i) => {
      obj[key] = typeof obj[key] === 'undefined' ? vals[i] : obj[key] + vals[i];
    });
  });

  if (isForecast) {
    return {
      forecast_dates: Object.keys(obj),
      forecast_demand: Object.values(obj),
    };
  }

  return {
    historical_dates: Object.keys(obj),
    historical_demand: Object.values(obj),
  };
}

export function getMergedForecastRecords(hits?: SearchIndexForecast[]): SearchIndexForecast | undefined {
  if (!hits || hits.length === 0) return undefined;

  const historicalData = hits.map((hit) => ({ keys: hit.historical_dates, vals: hit.historical_demand }));
  const forecastData = hits.map((hit) => ({ keys: hit.forecast_dates, vals: hit.forecast_demand }));

  const summedHistoricalDemand = getSummedDemandData(historicalData, false);
  const summedForecastDemand = getSummedDemandData(forecastData, true);

  return {
    ...hits[0],
    ...summedForecastDemand,
    ...summedHistoricalDemand,
  };
}
