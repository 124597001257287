import React from 'react';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SimulatedInventoryDashboardRecordDTO } from '@recurrency/core-api-schema/dist/inventory/getSimulatedInventoryDashboardHistory';
import moment from 'moment';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { breakpoints } from 'theme/breakpoints';
import { colors } from 'theme/colors';

import { Card, CardHeader } from 'components/Card';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { CenteredError, SmallLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { PropertyLabel } from 'components/PropertyListItem';

import { useCoreApi } from 'hooks/useApi';

import { formatNumber, formatPercent, formatUSDAbbreviated, getChangeColor } from 'utils/formatting';
import { objKeys } from 'utils/object';
import { useHashState } from 'utils/routes';
import { asKeyOf } from 'utils/tables';

import { InventoryDashboardHashState } from 'types/hash-state';

import { DashboardUnitSelect } from '../PurchasingDashboardPage/shared/DashboardUnitSelect';
import { InventoryDashboardFilters } from '../PurchasingDashboardPage/shared/InventoryDashboardFilters';

const chartHeight = 250;

export function InventorySimulationDashboard() {
  // TODO: change to InventorySimulationDashboardHashState
  const [hashState, updateHashState] = useHashState<InventoryDashboardHashState>();
  const { displayUnit } = hashState;

  if (!hashState || !hashState.displayUnit) {
    requestAnimationFrame(() => {
      updateHashState({ displayUnit: 'value' });
    });
  }

  const {
    data: simulatedInventoryData,
    error: simulatedInventoryDataError,
    isLoading: simulatedInventoryDataIsLoading,
  } = useCoreApi(schemas.inventory.getSimulatedInventoryDashboardHistory, {
    queryParams: {
      filters: {
        companyIds: hashState.filters?.companyIds,
        locationIds: hashState.filters?.locationIds,
        itemIds: hashState.filters?.itemIds,
        itemGroupIds: hashState.filters?.itemGroupIds,
        supplierIds: hashState.filters?.supplierIds,
        purchaseClasses: hashState.filters?.purchaseClasses,
        demandPatterns: hashState.filters?.demandPattern,
        demandPredictabilities: hashState.filters?.demandPredictability,
      },
    },
  });

  const inventoryRecords = sanitizeChartData(simulatedInventoryData?.items);

  if (simulatedInventoryDataError) {
    return <CenteredError error={simulatedInventoryDataError} />;
  }

  return (
    <Container>
      <PageHeader title="Recurrency Simulated Impact" />

      <FlexSpace gap={8} direction="column">
        <FlexSpace gap={8}>
          <div>Measured in</div>
          <DashboardUnitSelect
            state={hashState.displayUnit}
            onChange={(value) => updateHashState({ displayUnit: value })}
          />
        </FlexSpace>
        <FilterBarBox dividerLine>
          <InventoryDashboardFilters
            label="Filter"
            filterState={hashState.filters}
            onFiltersChange={(filters) => updateHashState({ ...hashState, filters })}
            showDemandPatternFilter
            showDemandPredictabilityFilter
          />
        </FilterBarBox>
      </FlexSpace>
      <div
        className={css`
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr 1fr;

          ${breakpoints.tablet} {
            grid-template-columns: 1fr;
          }
        `}
      >
        {displayUnit === 'value' ? (
          <ActualVsSimulatedLineChartCard
            title="Inventory Value"
            actualDataKey="actualInventoryValue"
            simulatedDataKey="simulatedInventoryValue"
            valueType="currency"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
            decreaseIsBetter
          />
        ) : (
          <ActualVsSimulatedLineChartCard
            title="Inventory Quantity"
            actualDataKey="actualInventoryQty"
            simulatedDataKey="simulatedInventoryQty"
            valueType="number"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
            decreaseIsBetter
          />
        )}
        {displayUnit === 'value' ? (
          <ActualVsSimulatedLineChartCard
            title="Backorder Value"
            actualDataKey="actualBackorderValue"
            simulatedDataKey="simulatedBackorderValue"
            valueType="currency"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
            decreaseIsBetter
          />
        ) : (
          <ActualVsSimulatedLineChartCard
            title="Backorder Quantity"
            actualDataKey="actualBackorderQty"
            simulatedDataKey="simulatedBackorderQty"
            valueType="number"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
            decreaseIsBetter
          />
        )}
        {displayUnit === 'value' ? (
          <ActualVsSimulatedLineChartCard
            title="Purchase Value"
            actualDataKey="actualPurchaseValue"
            simulatedDataKey="simulatedPurchaseValue"
            valueType="currency"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
            decreaseIsBetter
          />
        ) : (
          <ActualVsSimulatedLineChartCard
            title="Purchase Quantity"
            actualDataKey="actualPurchaseQty"
            simulatedDataKey="simulatedPurchaseQty"
            valueType="number"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
            decreaseIsBetter
          />
        )}
        {displayUnit === 'value' ? (
          <ActualVsSimulatedLineChartCard
            title="Overstock Value"
            actualDataKey="actualOverstockValue"
            simulatedDataKey="simulatedOverstockValue"
            valueType="currency"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
            decreaseIsBetter
          />
        ) : (
          <ActualVsSimulatedLineChartCard
            title="Overstock Quantity"
            actualDataKey="actualOverstockQty"
            simulatedDataKey="simulatedOverstockQty"
            valueType="number"
            isLoading={simulatedInventoryDataIsLoading}
            records={inventoryRecords}
          />
        )}
        <ActualVsSimulatedLineChartCard
          title="Gross Margin ROI"
          actualDataKey="actualGrossMarginRoi"
          simulatedDataKey="simulatedGrossMarginRoi"
          valueType="number"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
        <ActualVsSimulatedLineChartCard
          title="Annualized Turns"
          actualDataKey="actualAnnualizedTurns"
          simulatedDataKey="simulatedAnnualizedTurns"
          valueType="number"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
        <ActualVsSimulatedLineChartCard
          title="Fill Rate"
          actualDataKey="actualFillRate"
          simulatedDataKey="simulatedFillRate"
          valueType="percent"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
        <ActualVsSimulatedLineChartCard
          title="In Stock Rate"
          actualDataKey="actualInStockRate"
          simulatedDataKey="simulatedInStockRate"
          valueType="percent"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
      </div>
    </Container>
  );
}

/**
 * sanitize data so first month matches exactly for simulated vs actual.
 * Minor difference in calculation causes fill rate and in_stock rate charts to be slightly off.
 */
function sanitizeChartData(
  records: SimulatedInventoryDashboardRecordDTO[] | undefined,
): SimulatedInventoryDashboardRecordDTO[] {
  if (!records || records.length === 0) return [];

  // first record is first month, ensure simulated values match actual values
  const firstMonthRecord = records[0];
  for (const key of objKeys(firstMonthRecord)) {
    if (key.startsWith('simulated')) {
      const actualKey = key.replace('simulated', 'actual') as typeof key;
      if (firstMonthRecord[actualKey]) {
        // @ts-expect-error - we know this is a valid key
        firstMonthRecord[key] = firstMonthRecord[actualKey];
      }
    }
  }
  return records;
}

function ActualVsSimulatedLineChartCard({
  title,
  actualDataKey,
  simulatedDataKey,
  valueType,
  isLoading,
  records,
  decreaseIsBetter,
}: {
  title: string;
  actualDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  simulatedDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  valueType: 'currency' | 'number' | 'percent';
  isLoading: boolean;
  records: SimulatedInventoryDashboardRecordDTO[];
  decreaseIsBetter?: boolean;
}) {
  const valueFormatter: (value: Any) => string =
    valueType === 'currency'
      ? (val) => formatUSDAbbreviated(val, 0)
      : valueType === 'percent'
      ? (val) => formatPercent(val, 0)
      : (val) => formatNumber(val, 2, undefined, { notation: 'compact', useGrouping: true });

  const dateFormatter = (date: Any) => (date ? moment(date).format('MMM YY') : '-');

  return (
    <Card>
      <CardHeader title={title} />
      {isLoading ? (
        <div
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: ${chartHeight}px;
          `}
        >
          <SmallLoader />
        </div>
      ) : (
        <FlexSpace
          gap={8}
          className={css`
            padding: 10px;
          `}
        >
          <ActualVsSimulatedDeltaKPI
            records={records}
            actualDataKey={actualDataKey}
            simulatedDataKey={simulatedDataKey}
            valueFormatter={valueFormatter}
            decreaseIsBetter={decreaseIsBetter}
          />
          <ResponsiveContainer width="99.9%" height={chartHeight - 20}>
            <LineChart data={records}>
              <XAxis
                type="category"
                dataKey={asKeyOf<SimulatedInventoryDashboardRecordDTO>('simulatedDate')}
                tickFormatter={dateFormatter}
                tick={{ fill: colors.primary[600], stroke: colors.primary[600], strokeWidth: 0.2 }}
              />
              <YAxis
                type="number"
                domain={valueType === 'percent' ? [0, 1] : undefined}
                tickFormatter={valueFormatter}
                tick={{ fill: colors.primary[600], stroke: colors.primary[600], strokeWidth: 0.2 }}
              />
              <Line
                type="linear"
                dataKey={actualDataKey}
                stroke={colors.chart.sunsetPurple}
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
              <Line
                type="linear"
                dataKey={simulatedDataKey}
                stroke={colors.chart.oceanTeal}
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
              <Tooltip cursor={false} formatter={valueFormatter} labelFormatter={dateFormatter} />
              <CartesianGrid strokeDasharray="3 3" />
            </LineChart>
          </ResponsiveContainer>
        </FlexSpace>
      )}
    </Card>
  );
}

function ActualVsSimulatedDeltaKPI({
  records,
  actualDataKey,
  simulatedDataKey,
  valueFormatter,
  decreaseIsBetter,
}: {
  records: SimulatedInventoryDashboardRecordDTO[];
  actualDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  simulatedDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  valueFormatter: (value: Any) => string;
  decreaseIsBetter?: boolean;
}) {
  const lastRecord = records[records.length - 1];
  const actualValue = lastRecord[actualDataKey] as number;
  const simulatedValue = lastRecord[simulatedDataKey] as number;
  const delta = simulatedValue - actualValue;
  const deltaPercent = Math.abs(delta / actualValue);
  const changeColor = delta ? getChangeColor(delta * (decreaseIsBetter ? -1 : 1)) : colors.neutral[500];

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-left: 16px;
        width: 200px;
        align-items: center;
      `}
    >
      <div
        className={css`
          color: ${changeColor};
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <div
          className={css`
            font-size: 32px;
            font-weight: bold;
            line-height: 36px;
          `}
        >
          {delta < 0 ? <ArrowDownOutlined /> : delta > 0 ? <ArrowUpOutlined /> : null} {formatPercent(deltaPercent, 0)}
        </div>
        <div
          className={css`
            white-space: nowrap;
          `}
        >
          After 12 months
        </div>
      </div>
      <div
        className={css`
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <PropertyLabel label="Actual" />
          <div>{valueFormatter(actualValue)}</div>
        </div>
        <div>→</div>
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <PropertyLabel label="Simulated" />
          <div>{valueFormatter(simulatedValue)}</div>
        </div>
      </div>
    </div>
  );
}
