import React from 'react';

import { CheckOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { TransferTargetLineDTO } from '@recurrency/core-api-schema/dist/transferOrders/getTransferTargetLines';
import { Button } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { colors } from 'theme/colors';

import { FlexSpace } from 'components/FlexSpace';
import { ParsedInput } from 'components/Input/ParsedInput';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { Select } from 'components/Select';
import { Table } from 'components/Table';

import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD } from 'utils/formatting';
import { routes } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { asKeyOf, sortDirections } from 'utils/tables';
import { floatOrDefault } from 'utils/units';

type TransferTargetLinesTableProps = {
  targetLines: TransferTargetLineDTO[];
  focusedLine?: TransferTargetLineDTO;
  setSortDir: (order: SortOrder) => void;
  setSortField: (field: keyof TransferTargetLineDTO) => void;
  setFocusedLine: (line: TransferTargetLineDTO) => void;
};

enum TransferTargetLineUom {
  Sales = 'sales',
  Purchasing = 'purchasing',
}

function getSelectedUomAndUnitSize(record: TransferTargetLineDTO, selectedUoms: Record<string, string>) {
  const selectedUnitSize =
    selectedUoms[record.itemId] === TransferTargetLineUom.Purchasing ? record.purchasingUnitSize : record.salesUnitSize;
  const selectedUom =
    selectedUoms[record.itemId] === TransferTargetLineUom.Purchasing ? record.purchasingUom : record.salesUom;
  return { selectedUnitSize, selectedUom };
}

export function TransferTargetLinesTable({
  targetLines,
  focusedLine,
  setSortDir,
  setSortField,
  setFocusedLine,
}: TransferTargetLinesTableProps) {
  const tableColumns: PersistedColumn<TransferTargetLineDTO>[] = [
    {
      title: 'Source Location',
      dataIndex: asKeyOf<TransferTargetLineDTO>('sourceLocationId'),
      width: `200px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'sourceLocationId',
      required: true,
      render: (_: string, record: TransferTargetLineDTO) => (
        <div>
          <div>{record.sourceLocationId}</div>
          <div>{record.sourceLocationName}</div>
        </div>
      ),
    },
    {
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferTargetLineDTO>('destinationLocationId'),
      width: `200px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'destinationLocationId',
      required: true,
      render: (_: string, record: TransferTargetLineDTO) => (
        <div>
          <div>{record.destinationLocationId}</div>
          <div>{record.destinationLocationName}</div>
        </div>
      ),
    },
    {
      title: 'Item',
      dataIndex: asKeyOf<TransferTargetLineDTO>('itemId'),
      width: `300px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'itemId',
      required: true,
      render: (_: string, record: TransferTargetLineDTO) => (
        <div>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: ${record === focusedLine ? colors.primary[600] : `transparent`};
            `}
          />
          <a href={routes.purchasing.itemDetails(record.itemId)} target="_blank" rel="noreferrer">
            {record.itemId}
          </a>
          <div>{record.itemName}</div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<TransferTargetLineDTO>('status'),
      settingKey: 'status',
      render: (status: BadgeStatus) => <StatusBadge status={status} />,
    },
    {
      title: 'Quantity',
      settingKey: 'quantity',
      children: [
        {
          title: 'Required',
          dataIndex: asKeyOf<TransferTargetLineDTO>('qtyRequired'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'qtyRequired',
          render: (qtyRequired: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record, selectedUoms);
            const value = Math.ceil(qtyRequired / selectedUnitSize);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
        {
          // TODO @brunno-recurrency Implement transfer logic
          title: 'To Transfer',
          sorter: true,
          sortDirections,
          settingKey: 'qtyToTransfer',
          render: (record: TransferTargetLineDTO) => (
            <FlexSpace>
              <ParsedInput<number>
                className={css`
                  min-width: 70px;
                  max-width: 100px;
                `}
                size="small"
                valueParser={(value) => floatOrDefault(value, 0)}
                value={record.qtyRequired}
                onValueChange={() => {}}
              />
              <Button
                size="small"
                className={css`
                  border-radius: 8px;
                  height: 31px;
                `}
                disabled={record.qtyRequired === 0}
                onClick={() => {}}
              >
                {record.qtyRequired > 0 ? <CloseOutlined /> : <ReloadOutlined />}
              </Button>
            </FlexSpace>
          ),
        },
      ],
    },

    {
      title: 'UOM',
      settingKey: 'uom',
      render: (_: unknown, record: TransferTargetLineDTO) => {
        const isSameUom = record.salesUom === record.purchasingUom;
        if (isSameUom) return record.salesUom;

        return (
          <Select
            className={css`
              width: 120px;
            `}
            value={selectedUoms[record.itemId] ?? TransferTargetLineUom.Purchasing}
            onChange={(value) => setSelectedUom(record.itemId, value)}
            options={[
              { value: TransferTargetLineUom.Sales, label: record.salesUom },
              { value: TransferTargetLineUom.Purchasing, label: record.purchasingUom },
            ]}
          />
        );
      },
    },

    // Optional columns
    {
      title: 'Source',
      settingKey: 'source',
      optional: true,
      children: [
        {
          title: 'Net Stock',
          dataIndex: asKeyOf<TransferTargetLineDTO>('sourceNetStock'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'sourceNetStock',
          render: (netStock: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record, selectedUoms);
            const value = (netStock / selectedUnitSize).toFixed(2);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
        {
          title: 'Quantity Available',
          dataIndex: asKeyOf<TransferTargetLineDTO>('sourceQtyAvailable'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'sourceQtyAvailable',
          render: (qtyAvailable: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record, selectedUoms);
            const value = Math.floor(qtyAvailable / selectedUnitSize);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
        {
          title: 'Transfer Quantity Available',
          dataIndex: asKeyOf<TransferTargetLineDTO>('sourceTransferQtyAvailable'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'sourceTransferQtyAvailable',
          render: (sourceTransferQtyAvailable: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record, selectedUoms);
            const value = Math.ceil(sourceTransferQtyAvailable / selectedUnitSize);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
      ],
    },
    {
      title: 'Destination',
      settingKey: 'destination',
      optional: true,
      children: [
        {
          title: 'Net Stock',
          dataIndex: asKeyOf<TransferTargetLineDTO>('destinationNetStock'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'destinationNetStock',
          render: (netStock: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record, selectedUoms);
            const value = (netStock / selectedUnitSize).toFixed(2);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
        {
          title: 'Quantity Available',
          dataIndex: asKeyOf<TransferTargetLineDTO>('destinationQtyAvailable'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'destinationQtyAvailable',
          render: (qtyAvailable: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record, selectedUoms);
            const value = Math.floor(qtyAvailable / selectedUnitSize);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
        {
          title: 'Transfer Quantity Available',
          dataIndex: asKeyOf<TransferTargetLineDTO>('destinationTransferQtyAvailable'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'destinationTransferQtyAvailable',
          render: (destinationTransferQtyAvailable: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record, selectedUoms);
            const value = Math.ceil(destinationTransferQtyAvailable / selectedUnitSize);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
      ],
    },
    {
      title: 'Stockable',
      dataIndex: asKeyOf<TransferTargetLineDTO>('stockable'),
      align: 'center' as const,
      settingKey: 'stockable',
      optional: true,
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    },
    {
      title: 'Unit Weight',
      dataIndex: asKeyOf<TransferTargetLineDTO>('unitWeight'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'unitWeight',
      optional: true,
      render: (value: number, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record, selectedUoms);
        return formatNumber(value * selectedUnitSize, 2);
      },
    },
    {
      title: `Extended Weight`,
      dataIndex: asKeyOf<TransferTargetLineDTO>('extendedWeight'),
      align: 'right' as const,
      settingKey: 'extendedWeight',
      optional: true,
      render: (_: number, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record, selectedUoms);
        return formatNumber(record.unitWeight * record.qtyRequired * selectedUnitSize, 2);
      },
    },
    {
      title: 'Unit Volume',
      dataIndex: asKeyOf<TransferTargetLineDTO>('unitVolume'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'unitVolume',
      optional: true,
      render: (value: number, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record, selectedUoms);
        return formatNumber(value * selectedUnitSize, 2);
      },
    },
    {
      title: `Extended Volume`,
      dataIndex: asKeyOf<TransferTargetLineDTO>('extendedVolume'),
      align: 'right' as const,
      settingKey: 'extendedVolume',
      optional: true,
      render: (_: number, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record, selectedUoms);
        return formatNumber(record.unitVolume * record.qtyRequired * selectedUnitSize, 2);
      },
    },
    {
      title: `Unit Cost`,
      align: 'right' as const,
      dataIndex: 'unitCost',
      sorter: true,
      settingKey: 'unitCost',
      optional: true,
      render: (value: number) => formatUSD(value, true, 2),
    },
    {
      title: `Extended Cost`,
      align: 'right' as const,
      dataIndex: 'extendedCost',
      sorter: true,
      settingKey: 'extCost',
      optional: true,
      render: (_: null, record: TransferTargetLineDTO) => formatUSD(record.qtyRequired * record.unitCost, true, 2),
    },
  ].filter(truthy);

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.PurchaseTargetLinesTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  const [selectedUoms, setSelectedUoms] = React.useState<Record<string, string>>({});
  const setSelectedUom = (itemId: string, uom: string) => setSelectedUoms({ ...selectedUoms, [itemId]: uom });

  return (
    <>
      <ColumnChooserSection
        tableKey={ViewSettingKey.PurchaseTargetLinesTable}
        columns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        setVisibleColumnKeys={setVisibleColumnKeys}
      />
      <Table
        className={css`
          tr.ant-table-row.purchasing-row > td {
            background-color: ${colors.primary[100]};
          }
        `}
        scroll={{ x: true }}
        columns={visibleColumnKeys
          .map((key) => tableColumns.find((column) => column.settingKey === key))
          .filter(truthy)}
        data={targetLines}
        onChange={(_pagination, _filters, _sorter, ev) => {
          if (ev.action === 'sort' && !Array.isArray(_sorter)) {
            setSortDir(_sorter.order as SortOrder);
            setSortField(_sorter.field as keyof TransferTargetLineDTO);
          }
        }}
        size="small"
        pagination={{
          pageSize: 20,
          simple: true,
        }}
        onRow={(record) => ({
          onClick: () => {
            if (focusedLine?.itemId !== record.key) {
              setFocusedLine({ ...record, placeholderRequiredDate: undefined });
            }
          },
        })}
      />
    </>
  );
}
