import React, { useState } from 'react';

import { css } from '@emotion/css';
import { NgrokIntegrationDTO } from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Form } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import JSZip from 'jszip';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { InputFormItem, RadioFormItem, responsiveFormLayout2 } from 'components/FormItems';
import { Modal } from 'components/Modal';

import { captureAndShowError } from 'utils/error';

const enum ServerArchTypes {
  '32bit' = '32bit',
  '64bit' = '64bit',
}
const NgrokDownloadUrls = {
  [ServerArchTypes['32bit']]: 'https://assets.recurrency.com/ngrok/32bit/ngrok.exe',
  [ServerArchTypes['64bit']]: 'https://assets.recurrency.com/ngrok/64bit/ngrok.exe',
};

function createConfig(agent: NgrokIntegrationDTO | null, serverIP: string, serverPort: number) {
  // trim off any whitespace from the Server IP.  Since it's a free form text field it would be easy to fat finger extra spaces
  serverIP = serverIP ? serverIP.trim() : serverIP;
  return `
server_addr: tunnel.us.agents.recurrency.com:443
root_cas: host
crl_noverify: true
version: "2"
authtoken: ${agent?.authToken}
tunnels:
  sqlserver: #tunnel name
    labels:
      - edge=${agent?.edgeId}
    addr: ${serverIP ? `${serverIP}:` : ''}${serverPort}`.trim();
}

const generateZip = async (config: string, serverArchType: ServerArchTypes, tenantName: string) => {
  const zip = new JSZip();

  // fetch ngrok exe file based on the selected server architecture (32 vs 64bit)
  // we sometimes run into issues fetching the ngrok exe
  // there's a lot of error handling to try and point to where the problem is
  // we still allow you to download the ZIP file even if there are issues with getting the exe file
  // and internal users can manually rebuild the ZIP file with the right exe from there
  // TODO:  add Linux support.  We don't see it very often, but would be good to have our bases covered
  let ngrokExe;
  try {
    const response = await fetch(NgrokDownloadUrls[serverArchType]);
    if (!response.ok) {
      captureAndShowError(
        response,
        `Error downloading ngrok exe: ${response.status}.   ZIP file should still generate but you will need to manually grab a new exe file`,
      );
    } else {
      ngrokExe = await response.blob();
    }
  } catch (err) {
    captureAndShowError(
      err,
      `Error accessing ngrok exe.  ZIP file should still generate but you will need to manually grab a new exe file`,
    );
  }

  // Create files based on user inputs
  // allow the zip to be created even if the exe file can't be included
  if (ngrokExe !== undefined) {
    try {
      zip.file('ngrok.exe', ngrokExe, { binary: true, compression: 'DEFLATE' });
    } catch (err) {
      captureAndShowError(
        err,
        `Error zipping ngrok.exe.  ZIP file should still generate but you will need to manually grab a new exe file`,
      );
    }
  }
  zip.file('config.yml', config);
  zip.file(
    'install-agent.bat',
    `@echo off
ngrok service install --config=./config.yml
ngrok service start`,
  );
  zip.file(
    'uninstall-agent.bat',
    `@echo off
ngrok service uninstall`,
  );

  // Generate the ZIP file
  const zipBlob = await zip.generateAsync({ type: 'blob' });

  // Create a download link for the ZIP file
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(zipBlob);
  downloadLink.download = `recurrency_agent_${tenantName}_${serverArchType}.zip`;
  downloadLink.click();
};

export function TenantIntegrationExportPage({
  agent,
  tenant,
  onClose,
}: {
  agent: NgrokIntegrationDTO | null;
  tenant: TenantDTO;
  onClose: () => void;
}) {
  const [form] = Form.useForm<NgrokIntegrationDTO>();
  const [refresh, setRefresh] = useState(false);

  const serverArchTypes = [
    { value: ServerArchTypes['32bit'], label: '32bit' },
    { value: ServerArchTypes['64bit'], label: '64bit' },
  ];

  const serverIP = form.getFieldValue('serverIP');
  const serverPort = form.getFieldValue('serverPort');
  const serverArchType = form.getFieldValue('serverArch');

  const config = createConfig(agent, serverIP, serverPort);
  return (
    <Modal visible width="600px" title="Manage Integration" onCancel={onClose} centered>
      <Form {...responsiveFormLayout2} form={form}>
        <InputFormItem
          name="serverIP"
          label="Server IP"
          rules={[
            {
              required: false,
              message: 'IP for DB/API server.  If hosting agent on same server as DB/API server you can leave blank.',
            },
          ]}
          onChange={() => setRefresh(!refresh)}
        />
        <InputFormItem
          name="serverPort"
          label="Server Port"
          type="number"
          rules={[
            {
              required: true,
              message: 'Port for DB/API',
            },
          ]}
          onChange={() => setRefresh(!refresh)}
        />
        <RadioFormItem
          name="serverArch"
          label="Server Architecture"
          values={serverArchTypes}
          rules={[
            {
              required: true,
              message: 'Server Architecture (32bit or 64 bit)',
            },
          ]}
          onChange={() => setRefresh(!refresh)}
          optionType="default"
        />
      </Form>
      <div>
        {serverPort !== undefined ? (
          <div>
            <Paragraph copyable={{ text: config }}>Copy Config</Paragraph>
            <pre
              className={css`
                padding: 8px;
                background: ${theme.colors.neutral[100]};
                border: 2px solid ${theme.colors.neutral[300]};
                border-radius: 8px;
                width: 100%;
                height: 100%;
              `}
            >
              <code>{config}</code>
            </pre>
          </div>
        ) : null}

        {serverPort !== undefined && serverArchType !== undefined ? (
          <Button
            onClick={async () => {
              await generateZip(config, serverArchType, tenant.databaseSchema);
            }}
          >
            Download
          </Button>
        ) : null}
      </div>
    </Modal>
  );
}
