import { PurchaseTargetLineStatus, SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import {
  CreatePurchaseOrderBody,
  POItemLinePayload,
  POTransferBackorderPayload,
  PurchaseOrderResponseDTO,
} from '@recurrency/core-api-schema/dist/purchaseOrders/createPurchaseOrder';
import { TransferDaysDTO } from '@recurrency/core-api-schema/dist/transferDays/getTransferDays';
import {
  CreateTransferOrderBody,
  TransferOrderResponseDTO,
} from '@recurrency/core-api-schema/dist/transferOrders/createTransferOrder';

import { arrUnique } from 'utils/array';
import { FulfilledResult } from 'utils/concurrentPromiseQueue';
import { roundTo2Decimals } from 'utils/formatting';
import { PurchaseOrderEntryFlowFormConfig } from 'utils/tenantConf/default';

import {
  PurchaseGroupHashState,
  PurchasingLineInput,
  PurchasingPOFinalizeInput,
  PurchasingTOFinalizeInput,
  SupplierLocationWTarget,
} from 'types/hash-state';

import {
  DocSummaryType,
  GenericTargetLineDTO,
  GenericTargetLineRow,
  GroupedPOSummary,
  POSummary,
  PurchaseTargetLineRow,
  TransferSummary,
} from './types';

export type PurchaseTargetLineRowKey = keyof PurchaseTargetLineRow;

export const TargetFieldSumMap: Record<SupplierTargetTypes, PurchaseTargetLineRowKey> = {
  [SupplierTargetTypes.CycleDays]: 'unitCost',
  [SupplierTargetTypes.Dollars]: 'userUnitCost',
  [SupplierTargetTypes.Units]: 'qtyToOrder',
  [SupplierTargetTypes.Weight]: 'unitWeight',
  [SupplierTargetTypes.Volume]: 'unitVolume',
};

export function calculateFieldQtyTotal(
  item: GenericTargetLineRow,
  field: keyof GenericTargetLineRow,
  qty: number,
): number {
  const multiple = field === 'qtyToOrder' ? 1 : parseFloat(String(item[field]));
  if (!isNaN(multiple)) return multiple * qty;
  return 0;
}

export function getFieldTotal(lineItems: GenericTargetLineRow[], field: keyof GenericTargetLineRow): number {
  return roundTo2Decimals(
    lineItems.reduce((total: number, item) => calculateFieldQtyTotal(item, field, item.userQtyToOrder) + total, 0),
  );
}

export function getLineKey(line: GenericTargetLineDTO): string {
  // using . as separator since it is url safe character, and yields shorter url strings
  if (line.locationId) return `${line.supplierId}.${line.locationId}.${line.itemId}`;
  return `${line.groupId}.${line.itemId}`;
}

/** Used summary/hdr data in the hash state at the line level. This is useful when hdr level info that has been
 *  modified by the user is needed at the line level
 * Ex: poFinalizeInputByKey[line.poKey].dueDate
 * where poFinalizeInputByKey is the hash state, and dueDate is needed at the line level
 */
export function getPoSummaryKeyByLine(line: GenericTargetLineDTO): string {
  if (line.locationId) return `${line.supplierId}|${line.locationId}`;
  return `${line.supplierId}|${line.purchaseLocationId}`;
}

export function getMergedPurchaseLines(
  targetLines: GenericTargetLineDTO[],
  purchasingLinesById: Obj<PurchasingLineInput>,
) {
  return targetLines.map((line) => {
    const key = getLineKey(line);
    const purchasingLine = purchasingLinesById[key];
    const poKey = getPoSummaryKeyByLine(line);
    return {
      ...line,
      key,
      poKey,
      userQtyToOrder: purchasingLine?.qtyToOrder ?? 0,
      userUnitCost: purchasingLine?.unitCost ?? line.unitCost,
      userTransfers: purchasingLine?.transfers ?? [],
      userRequiredDate: purchasingLine?.requiredDate ?? undefined,
    };
  });
}

export function getPOSummaries(
  lines: GenericTargetLineRow[],
  supplierLocations: SupplierLocationWTarget[],
): POSummary[] {
  return supplierLocations
    .map((sl) => {
      const poLines = lines.filter(
        (item) =>
          (item.locationId === sl.locationId || item.purchaseLocationId === sl.locationId) &&
          item.supplierId === sl.supplierId &&
          item.userQtyToOrder > 0,
      );
      const summaryStats: POSummary = {
        kind: DocSummaryType.PurchaseOrder,
        requirementLocation: { foreignId: sl.locationId, name: sl.locationName },
        purchasingLocation:
          sl.purchasingLocationId && sl.purchasingLocationName
            ? { foreignId: sl.purchasingLocationId, name: sl.purchasingLocationName }
            : { foreignId: sl.locationId, name: sl.locationName },
        supplier: { foreignId: sl.supplierId, name: sl.supplierName },
        vendor: { foreignId: sl.vendorId, name: sl.vendorName },
        targetType: sl.targetType,
        targetValue: sl.targetValue,
        currentValue: getFieldTotal(poLines, TargetFieldSumMap[sl.targetType]),
        totalPOCost: getFieldTotal(poLines, TargetFieldSumMap[SupplierTargetTypes.Dollars]),
        totalLines: poLines.length,
        lines: poLines,
        /** all lines for the same location have the same company ID */
        companyId: poLines.length > 0 ? poLines[0].companyId : undefined,
        groupName: sl.groupName,
        groupId: sl.groupId,
      };

      return summaryStats;
    })
    .sort((a, b) => {
      // sort by supplier, then purchasing location
      let cmp = a.supplier.foreignId.localeCompare(b.supplier.foreignId);
      if (cmp === 0) cmp = a.purchasingLocation.foreignId.localeCompare(b.purchasingLocation.foreignId);
      return cmp;
    });
}

export function getHubAndSpokePOSummaries(
  lines: GenericTargetLineRow[],
  supplierLocations: SupplierLocationWTarget[],
  purchaseGroups: PurchaseGroupHashState[],
): POSummary[] {
  return purchaseGroups
    .map((pg) => {
      // Assumption: hub and spoke locations will always have groupId set
      const sl = supplierLocations.find((l) => l.groupId === pg.groupId && l.locationId === pg.purchaseLocationId)!;
      const poLines = lines.filter((item) => item.userQtyToOrder > 0 && item.groupId === pg.groupId);

      const summaryStats: POSummary = {
        kind: DocSummaryType.PurchaseOrder,
        requirementLocation: { foreignId: pg.purchaseLocationId, name: pg.purchaseLocationName },
        purchasingLocation: { foreignId: pg.purchaseLocationId, name: pg.purchaseLocationName },
        supplier: { foreignId: sl.supplierId, name: sl.supplierName },
        vendor: { foreignId: sl.vendorId, name: sl.vendorName },
        targetType: sl.targetType,
        targetValue: sl.targetValue,
        currentValue: getFieldTotal(poLines, TargetFieldSumMap[sl.targetType]),
        totalPOCost: getFieldTotal(poLines, TargetFieldSumMap[SupplierTargetTypes.Dollars]),
        totalLines: poLines.length,
        lines: poLines,
        groupName: sl.groupName,
        groupId: sl.groupId,
        /** all lines for the same location have the same company ID */
        companyId: poLines.length > 0 ? poLines[0].companyId : undefined,
      };

      return summaryStats;
    })
    .sort((a, b) => {
      // sort by supplier, then purchasing location
      let cmp = a.supplier.foreignId.localeCompare(b.supplier.foreignId);
      if (cmp === 0) cmp = a.purchasingLocation.foreignId.localeCompare(b.purchasingLocation.foreignId);
      return cmp;
    });
}

/** groups PO summary into parent -> children, where parent is supplierId|purchasingLocationId */
export function getGroupedPOSummaries(poSummaries: POSummary[]): GroupedPOSummary[] {
  const groupedPOSummaries: GroupedPOSummary[] = [];
  for (const po of poSummaries) {
    const existingGroup = groupedPOSummaries.find(
      (group) =>
        group.supplier.foreignId === po.supplier.foreignId &&
        group.purchasingLocation.foreignId === po.purchasingLocation.foreignId,
    );
    if (existingGroup) {
      existingGroup.children.push(po);
      if (!existingGroup.companyId) existingGroup.companyId = po.companyId; // Avoid undefined companyId
    } else {
      // remove requirementLocation for group parent
      groupedPOSummaries.push({ ...po, children: [po], requirementLocation: undefined });
    }
  }

  // for any groups with children, remove replenishmentLocation and calculate group costs
  for (const groupedPO of groupedPOSummaries) {
    if (groupedPO.children && groupedPO.children.length > 1) {
      groupedPO.lines = groupedPO.children.flatMap((child) => child.lines);
      groupedPO.currentValue = getFieldTotal(groupedPO.lines, TargetFieldSumMap[groupedPO.targetType]);
      groupedPO.totalPOCost = getFieldTotal(groupedPO.lines, TargetFieldSumMap[SupplierTargetTypes.Dollars]);
      groupedPO.totalLines = groupedPO.lines.length;

      // update target of group to matching requirement location
      const matchingTarget = poSummaries.find(
        (po) =>
          po.supplier.foreignId === groupedPO.supplier.foreignId &&
          po.requirementLocation?.foreignId === groupedPO.purchasingLocation.foreignId,
      );

      if (matchingTarget) {
        groupedPO.targetType = matchingTarget.targetType;
        groupedPO.targetValue = matchingTarget.targetValue;
      }
    }
  }

  return groupedPOSummaries;
}

export function getFinalizedPOSummaries(
  lines: GenericTargetLineRow[],
  supplierLocations: SupplierLocationWTarget[],
  poFinalizeInputByKey: Obj<PurchasingPOFinalizeInput>,
): GroupedPOSummary[] {
  const poSummaries = getGroupedPOSummaries(getPOSummaries(lines, supplierLocations));

  for (const poSummary of poSummaries) {
    const finalizeInput = poFinalizeInputByKey[getPOSummaryKey(poSummary)];
    if (finalizeInput) {
      poSummary.buyer = finalizeInput.buyer;
      poSummary.carrier = finalizeInput.carrier;
      poSummary.approved = finalizeInput.approved;
      poSummary.dueDate = finalizeInput.dueDate;
    }
  }
  return poSummaries;
}

export function getTransferSummaries(
  lines: GenericTargetLineRow[],
  transferDaysList: TransferDaysDTO[],
  toFinalizeInputByKey: Obj<PurchasingTOFinalizeInput>,
): TransferSummary[] {
  const transferSummariesByKey: Obj<TransferSummary> = {};
  const linesWithTransfers = lines.filter((line) => line.userTransfers && line.userTransfers?.length > 0);
  const transferDaysByKey = Object.fromEntries(
    transferDaysList.map((td) => [getTransferSummaryKey(td.fromLocationId, td.toLocationId), td]),
  );

  for (const transferLine of linesWithTransfers) {
    // ! (non-null assertion) because we filtered out lines without transfers
    for (const userTransfer of transferLine.userTransfers!) {
      const transferSummaryKey = getTransferSummaryKey(
        userTransfer.transferFromLocationId,
        (transferLine.locationId || transferLine.purchaseLocationId) as string,
      );
      const transferDaysForLocPair = transferDaysByKey[transferSummaryKey];
      const transferSummary =
        transferSummariesByKey[transferSummaryKey] ||
        (transferSummariesByKey[transferSummaryKey] = {
          kind: DocSummaryType.TransferOrder,
          companyId: transferLine.companyId,
          sourceLocation: {
            foreignId: userTransfer.transferFromLocationId,
            name: userTransfer.transferFromLocationName || '-',
          },
          destinationLocation: {
            foreignId: (transferLine.locationId || transferLine.purchaseLocationId) as string,
            name: (transferLine.locationName || transferLine.purchaseLocationName) as string,
          },
          transferDays: transferDaysForLocPair?.days,
          totalLines: 0,
          totalCost: 0,
          totalWeight: 0,
          lines: [],
        });

      transferSummary.approved = toFinalizeInputByKey[transferSummaryKey]?.approved;
      transferSummary.carrier = toFinalizeInputByKey[transferSummaryKey]?.carrier;
      transferSummary.totalLines += 1;
      transferSummary.totalCost += userTransfer.qtyToTransfer * transferLine.unitCost;
      transferSummary.totalWeight += userTransfer.qtyToTransfer * transferLine.unitWeight;
      transferSummary.lines.push({
        itemId: transferLine.itemId,
        itemName: transferLine.itemName,
        itemUid: transferLine.itemUid,
        unitOfMeasure: transferLine.unitOfMeasure,
        unitQuantity: userTransfer.qtyToTransfer,
        unitSize: transferLine.unitSize,
        qtyAvailable: transferLine.qtyAvailable,
        purchaseLineKey: transferLine.key,
      });
    }
  }

  return Object.values(transferSummariesByKey).sort((a, b) =>
    a.sourceLocation.foreignId.localeCompare(b.sourceLocation.foreignId),
  );
}

export function updatePurchasingLinesWithUpdatedTransferSummary(
  newTransferSummary: TransferSummary,
  purchasingLinesById: Obj<PurchasingLineInput>,
): boolean {
  let madeChanges = false;
  for (const line of newTransferSummary.lines) {
    const purchasingLine = purchasingLinesById[line.purchaseLineKey];
    if (purchasingLine) {
      for (const transfer of purchasingLine.transfers || []) {
        if (
          transfer.transferFromLocationId === newTransferSummary.sourceLocation.foreignId &&
          transfer.qtyToTransfer !== line.unitQuantity
        ) {
          if (line.unitQuantity === 0) {
            purchasingLine.transfers = purchasingLine.transfers?.filter(
              (transfer) => transfer.transferFromLocationId !== newTransferSummary.sourceLocation.foreignId,
            );
          } else {
            transfer.qtyToTransfer = line.unitQuantity;
          }
          madeChanges = true;
        }
      }
    }
  }
  return madeChanges;
}

export function getPOSummaryKey(po: POSummary): string {
  return `${po.supplier.foreignId}|${po.purchasingLocation.foreignId}`;
}

export function getGroupedPOSummaryKey(po: POSummary): string {
  return `${po.supplier.foreignId}|${po.purchasingLocation.foreignId}|${po.requirementLocation?.foreignId || ''}`;
}

export function getTransferSummaryKey(fromLocationId: string, toLocationId: string): string {
  return `${fromLocationId}|${toLocationId}`;
}

export function getLineStatusCounts(lineItems: GenericTargetLineRow[]): Record<PurchaseTargetLineStatus, number> {
  const lineStatusCounts: Obj<number> = {};

  for (const lineItem of lineItems) {
    lineStatusCounts[lineItem.status] = (lineStatusCounts[lineItem.status] ?? 0) + 1;
  }

  return lineStatusCounts as Record<PurchaseTargetLineStatus, number>;
}

/** return child supplier-requirementLocation pairs if grouped */
export function getSupplierLocationsForGroupedPOSummary(
  supplierLocations: SupplierLocationWTarget[],
  record: GroupedPOSummary,
  isHubAndSpoke: boolean,
): SupplierLocationWTarget[] {
  return supplierLocations.filter(
    (sl) =>
      sl.supplierId === record.supplier.foreignId &&
      (record.children
        ? record.children.some((childPO) => childPO.requirementLocation?.foreignId === sl.locationId)
        : isHubAndSpoke
        ? record.purchasingLocation.foreignId === sl.purchasingLocationId
        : record.requirementLocation?.foreignId === sl.locationId),
  );
}

export function getUniqueSuppliersAndLocations(supplierLocations: SupplierLocationWTarget[]): {
  uniqueLocations: string[];
  uniqueSuppliers: string[];
} {
  const uniqueLocations = arrUnique(supplierLocations.map((sl) => `${sl.locationId}: ${sl.locationName}`));
  const uniqueSuppliers = arrUnique(supplierLocations.map((sl) => `${sl.supplierId}: ${sl.supplierName}`));
  return { uniqueLocations, uniqueSuppliers };
}

export function createPurchaseOrderBodyFromPOSummary(
  poSummary: GroupedPOSummary,
  formConfig: PurchaseOrderEntryFlowFormConfig,
  shouldCreateTransferBackOrders: boolean,
  shouldUseRequiredDate = false,
): CreatePurchaseOrderBody {
  return {
    // we always want to send the pdf email after submit when submitting bulk POs
    sendPdfEmailAfterSubmit: true,
    companyId: poSummary.companyId,
    locationId: poSummary.purchasingLocation?.foreignId || '',
    vendorId: poSummary.vendor?.foreignId,
    supplierId: poSummary.supplier?.foreignId || '',
    buyerId: poSummary.buyer?.foreignId,
    carrierId: poSummary.carrier?.foreignId,
    approved: poSummary.approved ?? false,
    dueDate: poSummary.dueDate,
    email: formConfig.email.defaultValue,
    lineItems: createPOLinesBody(poSummary.lines, shouldUseRequiredDate),
    transferBackorders: shouldCreateTransferBackOrders ? createTransferBackOrdersBody(poSummary) : undefined,
  };
}

export function createTransferOrderBodyFromTransferSummary(transferSummary: TransferSummary): CreateTransferOrderBody {
  return {
    companyId: transferSummary.companyId,
    fromLocationId: transferSummary.sourceLocation.foreignId,
    toLocationId: transferSummary.destinationLocation.foreignId,
    approved: transferSummary.approved ?? false,
    carrierId: transferSummary.carrier?.foreignId,
    carrierName: transferSummary.carrier?.name,
    lines: transferSummary.lines.map((line) => ({
      itemId: line.itemId,
      unitOfMeasure: line.unitOfMeasure,
      unitQuantity: line.unitQuantity,
      unitSize: line.unitSize,
      invMastUid: line.itemUid,
    })),
  };
}

function createPOLinesBody(lines: GenericTargetLineRow[], shouldUseRequiredDate = false): POItemLinePayload[] {
  const itemLines: POItemLinePayload[] = [];

  for (const line of lines) {
    const existingItemLine = itemLines.find(
      (itemLine) => itemLine.itemId === line.itemId && itemLine.unitOfMeasure === line.unitOfMeasure,
    );
    if (existingItemLine) {
      // add to quantity of existing line, instead of creating a duplicate line with same itemId
      existingItemLine.unitQuantity! += line.userQtyToOrder;
    } else {
      itemLines.push({
        itemId: line.itemId,
        unitOfMeasure: line.unitOfMeasure,
        unitQuantity: line.userQtyToOrder,
        unitPrice: line.userUnitCost,
        requiredDate: (shouldUseRequiredDate && line?.userRequiredDate) || undefined,
      });
    }
  }

  // sort lines by itemId
  itemLines.sort((a, b) => a.itemId.localeCompare(b.itemId));

  return itemLines;
}

function createTransferBackOrdersBody(poSummary: GroupedPOSummary): POTransferBackorderPayload[] {
  const transferBackorders: POTransferBackorderPayload[] = [];
  const purchasingLocationId = poSummary.purchasingLocation.foreignId;

  for (const childPO of poSummary.children) {
    const childRequirementLocationId = childPO.requirementLocation?.foreignId;
    if (childRequirementLocationId && purchasingLocationId !== childRequirementLocationId) {
      const transferBackOrder: POTransferBackorderPayload = {
        toLocationId: childRequirementLocationId,
        lines: childPO.lines.map((childLine) => ({
          itemId: childLine.itemId,
          invMastUid: childLine.itemUid,
          unitQuantity: childLine.userQtyToOrder,
          unitOfMeasure: childLine.unitOfMeasure,
          unitSize: childLine.unitSize,
        })),
      };

      // only create TBOs if there are lines to transfer
      if (transferBackOrder.lines.length > 0) {
        transferBackorders.push(transferBackOrder);
      }
    }
  }
  return transferBackorders;
}

export function removeSuccessfulLinesFromPurchasingLinesById(
  purchasingLinesById: Obj<PurchasingLineInput>,
  fulfilledPOResults: FulfilledResult<GroupedPOSummary, PurchaseOrderResponseDTO>[],
  fulfilledTOResults: FulfilledResult<TransferSummary, TransferOrderResponseDTO>[],
): Obj<PurchasingLineInput> {
  for (const transferResult of fulfilledTOResults) {
    const transferSummary = transferResult.request;
    for (const transferLine of transferSummary.lines) {
      const purchasingLine = purchasingLinesById[transferLine.purchaseLineKey];
      if (purchasingLine) {
        purchasingLine.transfers = purchasingLine.transfers?.filter(
          (transfer) => transfer.transferFromLocationId !== transferSummary.sourceLocation.foreignId,
        );
        if ((purchasingLine.transfers ?? []).length === 0 && (purchasingLine.qtyToOrder ?? 0) === 0) {
          delete purchasingLinesById[transferLine.purchaseLineKey];
        }
      }
    }
  }

  for (const poResult of fulfilledPOResults) {
    const poSummary = poResult.request;
    for (const poChild of poSummary.children) {
      for (const poLine of poChild.lines) {
        const purchasingLine = purchasingLinesById[poLine.key];
        if (purchasingLine) {
          purchasingLine.qtyToOrder = 0;
          if ((purchasingLine.transfers ?? []).length === 0) {
            delete purchasingLinesById[poLine.key];
          }
        }
      }
    }
  }

  return purchasingLinesById;
}

export function getLinesForSupplierLocation(
  targetLines: GenericTargetLineDTO[],
  supplierLocations: SupplierLocationWTarget[],
): GenericTargetLineDTO[] {
  return targetLines.filter((tl) =>
    supplierLocations.some(
      (sl) =>
        sl.supplierId === tl.supplierId && (sl.locationId === tl.locationId || sl.locationId === tl.purchaseLocationId),
    ),
  );
}
