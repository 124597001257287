import React from 'react';

import { Link } from 'react-router-dom';

import { DeploymentUnitOutlined, FileOutlined, ScheduleOutlined } from '@ant-design/icons';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { QuoteOrderLineNoteDTO } from '@recurrency/core-api-schema/dist/common/quoteOrderLineNotesDTO';
import { QuoteOrderLineScheduleDTO } from '@recurrency/core-api-schema/dist/common/quoteOrderLineScheduleDTO';
import { SalesOrderLineDTO } from '@recurrency/core-api-schema/dist/salesOrderLines/getSalesOrderLines';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import { QuoteOrderLineNotesModal } from 'pages/orders/modals/QuoteOrderLineNotesModal';

import { Button } from 'components/Button';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';
import { OrderLineSchedulesTable } from 'components/recipes/OrderLineSchedulesTable';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';
import { Table } from 'components/Table';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableIdColumn,
  sortableNumberColumn,
  sortableStringColumn,
  sortableDollarWithCentsColumn,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { lineItemDispositionMap } from 'types/legacy-api';

import { OrderAssemblyLinesModal } from '../modals/OrderAssemblyLinesModal';

export const OrderLinesTable = ({ orderLines }: { orderLines: SalesOrderLineDTO[] }) => {
  const { activeTenant } = useGlobalApp();

  const hasLineNotes = orderLines.some((line) => line.notes && line.notes.length > 0);
  const hasCustomerPartNo = orderLines.some((line) => line.customerPartId);
  const hasLineDueDate = orderLines.some((line) => line.dueDate);
  const hasLineSchedules = orderLines.some((line) => line.schedules && line.schedules.length > 0);
  const orderLineColumns: (ColumnType<SalesOrderLineDTO> | null)[] = [
    sortableIdColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      fixed: 'left',
      render: (itemId: string, record: SalesOrderLineDTO) => (
        <>
          <Link to={routes.sales.itemDetails(itemId)}> {itemId} </Link>
          {record.assemblyFlag && (
            <Button
              size="small"
              onClick={() => {
                showAsyncModal(OrderAssemblyLinesModal, {
                  itemId: record.itemId,
                  orderLineUid: record.orderLineUid || '0',
                  orderLines,
                });
              }}
            >
              <DeploymentUnitOutlined />
            </Button>
          )}
        </>
      ),
    }),
    sortableStringColumn({
      title: 'Item',
      fixed: 'left',
      dataIndex: 'itemName',
    }),
    sortableStringColumn({
      title: 'Status',
      dataIndex: 'status',
      render: (status: ErpOrderStatus) => <ErpOrderStatusBadge erpOrderStatus={status} />,
    }),
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Disposition',
          dataIndex: 'disposition',
          render: (disposition: string) => lineItemDispositionMap[disposition] || disposition || '-',
        }
      : null,
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'quantityOrdered',
    }),
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty Allocated',
          dataIndex: 'quantityAllocated',
        })
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty on Pick Ticket',
          dataIndex: 'quantityOnPickTickets',
        })
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty Canceled',
          dataIndex: 'quantityCanceled',
        })
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty Invoiced',
          dataIndex: 'quantityInvoiced',
        })
      : null,
    sortableNumberColumn({
      title: 'Qty Open',
      dataIndex: 'quantityOpen',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Price',
      dataIndex: 'extendedPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Margin',
      dataIndex: 'grossMargin',
    }),
    hasLineNotes
      ? sortableStringColumn({
          title: 'Notes',
          dataIndex: 'notes',
          align: 'center',
          render: (value: QuoteOrderLineNoteDTO[], obj: SalesOrderLineDTO) => {
            if (value && value.length > 0) {
              return (
                <FileOutlined
                  color={theme.colors.neutral[500]}
                  onClick={async () => {
                    await showAsyncModal(QuoteOrderLineNotesModal, {
                      orderLines,
                      itemId: obj.itemId,
                    });
                  }}
                />
              );
            }
            return null;
          },
        })
      : null,
    hasCustomerPartNo
      ? sortableStringColumn({
          title: 'Customer Part No',
          dataIndex: 'customerPartId',
        })
      : null,
    hasLineDueDate
      ? sortableStringColumn({
          title: 'Due Date',
          dataIndex: 'dueDate',
          render: (value) => formatDate(value),
        })
      : null,
    hasLineSchedules
      ? sortableStringColumn({
          title: 'Schedules',
          dataIndex: 'schedules',
          align: 'center',
          render: (value: QuoteOrderLineScheduleDTO[], obj: SalesOrderLineDTO) => {
            if (value && value.length > 0) {
              return (
                <ScheduleOutlined
                  color={theme.colors.neutral[500]}
                  onClick={() => {
                    showAsyncModal(ViewAllModal, {
                      title: `Schedules for Item #${obj.itemId}`,
                      content: <OrderLineSchedulesTable orderLineSchedules={value} />,
                    });
                  }}
                />
              );
            }
            return null;
          },
        })
      : null,
  ];

  return (
    <Table
      columns={orderLineColumns.filter(filterCostAndGM)}
      scroll={{ x: true }}
      data={orderLines.filter((line) => !line.parentOrderLineUid || line.parentOrderLineUid === '0')}
    />
  );
};
