import { schemas } from '@recurrency/core-api-schema';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';

import { getGlobalAppState } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent, PlanningModalType } from 'utils/track';

import { IncludeExcludeModal } from './IncludeExcludeModal';
import { MultiModalSelection, getPlanningListSelectionFromFilterSelection } from './utils';

export async function changeRecordExcludedStatus(selection: MultiModalSelection, exclude: boolean): Promise<boolean> {
  const { activeTenant } = getGlobalAppState();
  // api expects updateValue to be numeric and is shared with other update types
  const updateValue = exclude ? 1 : 0;

  // confirm with user before applying action
  const didUserConfirm = await showAsyncModal(IncludeExcludeModal, {
    selection,
    exclude,
  });
  if (!didUserConfirm) {
    return false;
  }

  const submitNotification = createSubmissionNotification({
    entityName: 'Excluded status',
    submittingMessage: 'Changing excluded status',
    expectedWaitSeconds: 30,
    erpType: activeTenant.erpType,
  });

  try {
    if (selection.type === 'filter') {
      selection = await getPlanningListSelectionFromFilterSelection(selection);
    }

    await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
      pathParams: {
        updateType: UpdateItemLocationInfoType.Exclude,
      },
      bodyParams: {
        updates: selection.items.map((record) => ({
          itemUid: record.item_uid,
          locationId: splitIdNameStr(record.location).foreignId,
          updateValue,
        })),
      },
    });

    submitNotification.success({
      successMessage: 'Successfully changed excluded status',
    });

    track(TrackEvent.DemandPlanning_MinMaxInfo_Submission, {
      modalType: exclude ? PlanningModalType.Exclude : PlanningModalType.Include,
      itemCount: selection.count,
    });
  } catch (err) {
    submitNotification.error(err);
  }

  return true;
}
