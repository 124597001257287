import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { IntegratedErps, RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { Button, Radio, Space } from 'antd';

import { ItemSetupMinMaxRadioGroup } from 'pages/planning/OnboardingPage/ItemSetupMinMaxRadioGroup';

import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { formatNumber, getErpName, splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { PlanningModalType, track, TrackEvent } from 'utils/track';

import { SearchIndexMinMax } from 'types/search-collections';

import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import { getPlanningListSelectionFromFilterSelection, MultiModalSelection } from './utils';

enum MinMaxSubmissionType {
  Recommended = 'recommended',
  RecommendedMinMax = 'recommended min/max',
  Custom = 'custom',
}

export function MultiUpdateReplenishmentSettingsModal({
  selection,
  onClose,
  searchIndexReload,
  isOpOqVariant,
  isOnboardingVariant = false,
}: {
  selection: MultiModalSelection;
  onClose: (isSubmit: boolean) => void;
  searchIndexReload: () => void;
  isOpOqVariant: boolean;
  isOnboardingVariant?: boolean;
}) {
  const [customMin, setCustomMin] = useState<number | undefined>();
  const [customMax, setCustomMax] = useState<number | undefined>();
  const [submissionType, setSubmissionType] = useState<MinMaxSubmissionType>(MinMaxSubmissionType.Recommended);
  const { activeTenant } = useGlobalApp();

  /** always use custom min value or recommended min */
  const getExportedMin = (record: SearchIndexMinMax): number => {
    if (submissionType === MinMaxSubmissionType.Custom) {
      // This is required to be defined by the time the user submits
      return customMin!;
    }
    return record.recommended_min;
  };

  /** use custom max values for custom, recommended max for min/max versions and current max for op/oq */
  const getExportedMax = (record: SearchIndexMinMax): number => {
    if (submissionType === MinMaxSubmissionType.Custom) {
      // This is required to be defined by the time the user submits
      return customMax!;
    }
    if (isOpOqVariant && submissionType === MinMaxSubmissionType.Recommended) {
      return record.current_max;
    }
    return record.recommended_max;
  };

  /** if it is min/max, always specify min/max.
   * if it is op/oq but recommended min/max, then set min/max
   * otherwise, return op/oq
   */
  const exportedReplenishmentMethod = useMemo(() => {
    if (!isOpOqVariant) {
      return RecurrencyReplenishmentMethod.MinMax;
    }
    if (submissionType === MinMaxSubmissionType.RecommendedMinMax) {
      return RecurrencyReplenishmentMethod.MinMax;
    }
    return RecurrencyReplenishmentMethod.OPOQ;
  }, [isOpOqVariant, submissionType]);

  const handleMinMaxUpdate = async () => {
    const entityName = 'replenishment settings';
    const submitNotification = createSubmissionNotification({
      entityName: 'Replenishment Settings',
      expectedWaitSeconds: 45,
      erpType: activeTenant.erpType,
      submittingMessage:
        activeTenant.erpType === IntegratedErps.P21
          ? `Updating ${entityName} to ${activeTenant.erpType.toUpperCase()}`
          : `Saving ${entityName}`,
    });

    try {
      onClose(true);

      if (selection.type === 'filter') {
        selection = await getPlanningListSelectionFromFilterSelection(selection, [
          'item_id',
          'item_uid',
          'location',
          'current_min',
          'current_max',
          'replenishment_method',
          'recommended_min',
          'recommended_max',
          'safety_stock_type',
          'safety_stock_value',
          'review_cycle',
          'lead_time',
          'avg_demand',
        ]);
      }

      await coreApiFetch(schemas.purchasing.postUpdateItemLocationMinMax, {
        bodyParams: {
          updates: selection.items.map((record) => ({
            itemId: record.item_id,
            itemUid: record.item_uid,
            locationId: splitIdNameStr(record.location).foreignId,
            currentMin: record.current_min,
            currentMax: record.current_max,
            currentReplenishmentMethod: record.replenishment_method,
            newReplenishmentMethod: exportedReplenishmentMethod,
            newMin: getExportedMin(record),
            newMax: getExportedMax(record),
            safetyStockType: record.safety_stock_type,
            safetyStockValue: record.safety_stock_value,
            reviewCycle: record.review_cycle,
            leadTime: record.lead_time,
            avgDemand: record.avg_demand,
          })),
        },
      });

      track(TrackEvent.DemandPlanning_MinMax_Submission, {
        modalType: isOpOqVariant ? PlanningModalType.MultiOpOq : PlanningModalType.MultiMinMax,
        itemCount: selection.count,
        submissionType,
      });
      searchIndexReload();
      submitNotification.success();
    } catch (err) {
      submitNotification.error(err);
    }
  };

  return (
    <Modal
      visible
      title={
        isOnboardingVariant
          ? `Title: Setting Up ${formatNumber(selection.count)} Items to use Recurrency`
          : `Updating Replenishment Settings for ${formatNumber(selection.count)} Items`
      }
      onCancel={() => onClose(false)}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button
            onClick={handleMinMaxUpdate}
            type="primary"
            disabled={
              submissionType === MinMaxSubmissionType.Custom &&
              (customMax === undefined || customMin === undefined || (!isOpOqVariant && customMin > customMax))
            }
          >
            {activeTenant.erpType === IntegratedErps.P21 ? `Send to ${getErpName(activeTenant.erpType)}` : 'Save'}
          </Button>
        </>
      }
      width={900}
    >
      <FlexSpace direction="column" alignItems="stretch">
        {isOnboardingVariant && (
          <p
            className={css`
              margin-bottom: 20px;
            `}
          >
            {isTenantErpTypeP21(activeTenant.erpType) ? (
              <>
                Setting up an item to use Recurrency will change the replenishment method in your ERP to use Min/Max in
                addition to updating the minimum and maximum values. From there, the item will have continuous
                recommendations updated in the Planning page that can be reviewed. Learn more about setting up items in
                Recurrency here.
              </>
            ) : (
              <>
                Setting up an item to use Recurrency will update the minimum and maximum values in Recurrency. From
                there, the item will have continuous recommendations updated in the Planning page that can be reviewed.
                Learn more about setting up items in Recurrency here.
              </>
            )}
          </p>
        )}

        {isOnboardingVariant ? (
          <ItemSetupMinMaxRadioGroup
            value={submissionType}
            options={{ recommended: MinMaxSubmissionType.Recommended, custom: MinMaxSubmissionType.Custom }}
            onChange={setSubmissionType}
            customMin={customMin}
            customMax={customMax}
            setCustomMin={setCustomMin}
            setCustomMax={setCustomMax}
          />
        ) : (
          <RadioGroup value={submissionType} onChange={({ target: { value } }) => setSubmissionType(value)}>
            <Space direction="vertical">
              <Radio value={MinMaxSubmissionType.Recommended}>Recommended {isOpOqVariant ? 'OP/OQ' : ''}</Radio>
              {isOpOqVariant && <Radio value={MinMaxSubmissionType.RecommendedMinMax}>Recommended Min/Max</Radio>}
              <Radio value={MinMaxSubmissionType.Custom}>
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  `}
                >
                  Custom {isOpOqVariant ? 'OP/OQ' : ''}
                  <PropertyListItem
                    label={isOpOqVariant ? 'Order Point' : 'Min'}
                    value={
                      <Input
                        type="number"
                        onChange={(ev) => {
                          setSubmissionType(MinMaxSubmissionType.Custom);
                          setCustomMin(Number(ev.currentTarget.value));
                        }}
                        value={customMin}
                      />
                    }
                  />
                  <PropertyListItem
                    label={isOpOqVariant ? 'Order Quantity' : 'Max'}
                    value={
                      <Input
                        type="number"
                        onChange={(ev) => {
                          setSubmissionType(MinMaxSubmissionType.Custom);
                          setCustomMax(Number(ev.currentTarget.value));
                        }}
                        value={customMax}
                      />
                    }
                  />
                </div>
              </Radio>
            </Space>
          </RadioGroup>
        )}
        <DividerLine />
        <MultiUpdateReplenishmentSettingsTable
          isOnboardingVariant={isOnboardingVariant}
          selection={selection}
          bulkUpdate={{
            type: 'minMax',
            customMin,
            customMax,
            isOpOqVariant,
            submissionType,
          }}
        />
      </FlexSpace>
    </Modal>
  );
}
