import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { ErpOrderStatus, P21LeadTimeSource, P21SafetyStockType } from '@recurrency/core-api-schema/dist/common/enums';

import { ActionButton } from 'components/Button/ActionButton';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { FillRatePanelContent } from 'components/recipes/sidePane/FillRatePanelContent';
import { InventoryUsagePanelContent } from 'components/recipes/sidePane/InventoryUsagePanelContent';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { LocationAvailabilityPanelContent } from 'components/recipes/sidePane/LocationAvailabilityPanelContent';
import { MinMaxPanelContent } from 'components/recipes/sidePane/MinMaxPanelContent';
import { OrderPointOrderQuantityPanelContent } from 'components/recipes/sidePane/OrderPointOrderQuantityPanelContent';
import { PurchaseOrderLinesPanelContent } from 'components/recipes/sidePane/PurchaseOrderLinesPanelContent';
import { SalesInvoiceLinesPanelContent } from 'components/recipes/sidePane/SalesInvoiceLinesPanelContent';
import { SalesOrderLinesPanelContext } from 'components/recipes/sidePane/SalesOrderLinesPanelContext';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { UniqueCustomersOrdersPanelContent } from 'components/recipes/sidePane/UniqueCustomersOrdersPanelContent';
import { UnitOfMeasuresPanelContent } from 'components/recipes/sidePane/UnitOfMeasuresPanelContent';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { pluralize, splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

import { SearchIndexMinMax } from 'types/search-collections';

import { ItemNotesPanelContent } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/ItemNotesPanelContent';
import { SupplierNotesPanelContent } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/SupplierNotesPanelContent';
import { DrillDownChartConfig } from '../PurchasingDashboardPage/DrillDownChartConfig';
import { shouldShowOpOqVariant } from '../utils';
import { MultiLeadTimeModal } from './modals/MultiLeadTimeModal';
import { MultiSafetyStockModal } from './modals/MultiSafetyStockModal';
import { PlanningSupplierLocationSettingsModal } from './modals/PlanningSupplierLocationSettingsModal';
import { formatSafetyStock } from './modals/utils';

export function PlanningSidePane({
  record,
  isOnboardingVariant = false,
  searchIndexReload,
}: {
  record: SearchIndexMinMax | null;
  isOnboardingVariant?: boolean;
  searchIndexReload: () => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const showOpOqVariant = shouldShowOpOqVariant(activeTenant, activeUser, record?.replenishment_method);

  const supplierId = splitIfIdNameStr(record?.primary_supplier)?.foreignId ?? '';
  const itemId = record?.item_id ?? '';
  const location = splitIdNameStr(record?.location ?? ':');
  const vendor = splitIfIdNameStr(record?.primary_vendor);

  if (!record) {
    return (
      <SidePane
        title=""
        content={<div>{isOnboardingVariant ? 'No item selected' : 'Please select a Planning Table row'}</div>}
      />
    );
  }

  const editableProps: Array<LabelValueProperty | null> = [
    {
      label: 'Lead Time',
      value: (
        <>
          <ConditionalWrapper
            condition={record.lead_time_source === P21LeadTimeSource.override}
            wrapper={(children: React.ReactChild) => (
              <InfoTooltip useInfoIcon title="Overridden">
                {children}
              </InfoTooltip>
            )}
          >
            <>{pluralize(record.lead_time, 'day', 'days', true)}</>
          </ConditionalWrapper>
          <>
            {` | `}
            <ActionButton
              label={
                <>
                  <EditOutlined /> Edit
                </>
              }
              onClick={() => {
                showAsyncModal(MultiLeadTimeModal, {
                  selection: { type: 'list', items: [record], count: 1 },
                  searchIndexReload,
                });
              }}
            />
          </>
        </>
      ),
    },
    {
      label: 'Safety Stock',
      value: (
        <>
          {record.safety_stock_value === undefined ? (
            '-'
          ) : (
            <ConditionalWrapper
              condition={record.safety_stock_type === P21SafetyStockType.overrideDays}
              wrapper={(children: React.ReactChild) => (
                <InfoTooltip useInfoIcon title="Overridden">
                  {children}
                </InfoTooltip>
              )}
            >
              <>{`${formatSafetyStock(record.safety_stock_value, record.safety_stock_type)}`}</>
            </ConditionalWrapper>
          )}
          <>
            {` | `}
            <ActionButton
              label={
                <>
                  <EditOutlined /> Edit
                </>
              }
              onClick={() => {
                showAsyncModal(MultiSafetyStockModal, {
                  selection: { type: 'list', items: [record], count: 1 },
                  searchIndexReload,
                });
              }}
            />
          </>
        </>
      ),
    },
    supplierId
      ? {
          label: 'Order Cycle',
          value: (
            <>
              <InfoTooltip
                useInfoIcon
                title="Order Cycle is defined at the supplier and location level and affects all items from this supplier-location pair"
              >
                {pluralize(Number(record.order_cycle_days), 'day', 'days', true)}
              </InfoTooltip>

              {` | `}
              <ActionButton
                label={
                  <>
                    <EditOutlined /> Edit
                  </>
                }
                onClick={() => {
                  showAsyncModal(PlanningSupplierLocationSettingsModal, {
                    supplierId,
                    locationId: location.foreignId,
                    searchIndexReload,
                  });
                }}
              />
            </>
          ),
        }
      : null,
  ];

  const replenishmentPanel = showOpOqVariant
    ? {
        settingKey: 'opOq',
        title: 'Order Point / Order Quantity',
        content: (
          <OrderPointOrderQuantityPanelContent minMaxRecord={record} allowOpOqEdit onOpOqChange={searchIndexReload} />
        ),
      }
    : {
        settingKey: 'minMax',
        title: 'Min / Max',
        content: <MinMaxPanelContent minMaxRecord={record} allowMinMaxEdit onMinMaxChange={searchIndexReload} />,
      };

  const infoProps: Array<LabelValueProperty | null> = [
    record.short_code ? { label: 'Short Code', value: record.short_code } : null,
    record.primary_supplier ? { label: 'Primary Supplier', value: record.primary_supplier } : null,
    record.supplier_part_id ? { label: 'Supplier Part ID', value: record.supplier_part_id } : null,
    !isOnboardingVariant && record.replenishment_method
      ? { label: 'ERP Replenishment Method', value: record.replenishment_method }
      : null,
  ];

  return (
    <SidePane
      title={`Item #${record.item_id} at ${record.location}`}
      settingKey={ViewSettingKey.PlanningSidePane}
      description={record.item_name}
      extendedDescription={record.item_desc}
      content={
        <>
          <LabelValueProperties properties={editableProps} />
          <br />
          <LabelValueProperties properties={infoProps} />
        </>
      }
      panels={[
        !isOnboardingVariant ? replenishmentPanel : null,
        {
          settingKey: 'itemNotes',
          title: 'Item Notes',
          content: (
            <ItemNotesPanelContent
              uniqueItems={[{ itemId: record.item_uid, foreignId: itemId, name: record.item_name || '' }]}
            />
          ),
        },
        {
          settingKey: 'usage',
          title: 'Usage',
          content: <InventoryUsagePanelContent itemId={itemId} locations={[location]} />,
        },
        {
          settingKey: 'unitOfMeasures',
          title: 'Unit of Measures',
          content: <UnitOfMeasuresPanelContent itemId={itemId} />,
        },
        {
          settingKey: 'purchaseOrders',
          title: 'Purchase Orders',
          content: (
            <PurchaseOrderLinesPanelContent
              itemId={itemId}
              locationIds={[location.foreignId]}
              vendorId={vendor?.foreignId}
            />
          ),
        },
        {
          settingKey: 'salesHistory',
          title: 'Sales History',
          content: <SalesInvoiceLinesPanelContent itemId={itemId} locationIds={[location.foreignId]} />,
        },
        {
          settingKey: 'openOrders',
          title: 'Open Orders',
          content: (
            <SalesOrderLinesPanelContext
              itemId={itemId}
              locationIds={[location.foreignId]}
              orderStatus={ErpOrderStatus.Open}
            />
          ),
        },
        {
          settingKey: 'locationAvailability',
          title: 'Location Availability',
          content: <LocationAvailabilityPanelContent itemUid={record.item_uid} />,
        },
        {
          settingKey: 'fillRate',
          title: 'Fill Rate',
          tooltip: 'DrillDownChartConfig.fillRate.description',
          content: <FillRatePanelContent itemId={itemId} locationId={location.foreignId} />,
        },
        {
          settingKey: 'uniqueCustomersAndHits',
          title: 'Unique Customers & Hits',
          tooltip: DrillDownChartConfig.fillRate.description,
          content: <UniqueCustomersOrdersPanelContent itemId={itemId} locationId={location.foreignId} />,
        },
        record.primary_supplier
          ? {
              settingKey: 'supplierNotes',
              title: 'Supplier Notes',
              content: <SupplierNotesPanelContent uniqueSuppliers={[record.primary_supplier]} />,
            }
          : null,
      ]}
    />
  );
}
