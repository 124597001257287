import { useCallback, useMemo } from 'react';

import { useHistory, useParams as useReactRouterParams, matchPath } from 'react-router-dom';

import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import urltron from 'urltron';

import { getGlobalAppState } from 'hooks/useGlobalApp';

import { isObjEmpty, objFlattenedValues, objKeys } from 'utils/object';

import {
  PricingHashState,
  QuoteEditHashStateP21,
  DraftQuotesListHashState,
  SearchFrameHashState,
  ReportingHashState,
  ProspectHashState,
  OrderEditHashStateSAPB1,
  QuoteEditHashStateSAPB1,
  PurchaseOrderEditHashState,
  PurchaseTargetsHashState,
  DetailsPageWithCompanyIdHashState,
  PurchaseTargetLinesHashState,
  PaymentsTransactionsHashState,
  InventoryDashboardDetailsHashState,
  InventoryDashboardHashState,
  InventoryDashboardStatusHashState,
} from 'types/hash-state';

import { LocalStorageKey, useLocalStorage } from './localStorage';
import { isTenantErpTypeSAPB1 } from './tenants';

export interface IdPathParams {
  id: string;
}

export interface ParsePathResult {
  /** matching routePath e.g '/:tenant/sales/customer/:id' */
  routePath: string;
  /** params in path e.g {tenant: 'rai', id: '123'} */
  pathParams: Obj<string> | undefined;
  tenantSlug: string;
  moduleSlug: string;
  subModuleSlug: string;
  /** trimmed path, split by '/' */
  pathSlashParts: string[];
}

export const routePaths = {
  home: '/',
  sales: {
    dashboard: '/:tenant/sales/dashboard',
    customerList: '/:tenant/sales/customers',
    customerDetails: '/:tenant/sales/customer/:id',
    customerNew: '/:tenant/sales/customers/new',
    itemList: '/:tenant/sales/items',
    itemDetails: '/:tenant/sales/item/:id',
    pricing: '/:tenant/sales/pricing',
  },
  orders: {
    orderList: '/:tenant/orders/orders',
    orderDetails: '/:tenant/orders/order/:id',
    orderNew: '/:tenant/orders/orders/new',
    draftQuoteList: '/:tenant/orders/quote-drafts',
    quoteList: '/:tenant/orders/quotes',
    quoteNew: '/:tenant/orders/quotes/new',
    quoteEdit: '/:tenant/orders/quote/:id/edit',
    quoteDetails: '/:tenant/orders/quote/:id',
    opportunities: '/:tenant/orders/opportunities',
  },
  demandPlanning: {
    dashboard: '/:tenant/demand-planning/dashboard',
    reporting: '/:tenant/demand-planning/dashboard/inventory-explorer',
    currentInventory: '/:tenant/demand-planning/dashboard/current-explorer',
    inventorySimulationDashboard: '/:tenant/demand-planning/simulation-dashboard',
    forecasting: '/:tenant/demand-planning/forecasting',
    onboarding: '/:tenant/demand-planning/onboarding',
    planning: '/:tenant/demand-planning/planning',
  },
  payments: {
    disputes: '/:tenant/payments/disputes',
    payments: '/:tenant/payments/payments',
    paymentDetails: '/:tenant/payments/order',
    payouts: '/:tenant/payments/payouts',
    payoutDetails: '/:tenant/payments/payout',
    openInvoices: '/:tenant/payments/open-invoices',
  },
  purchasing: {
    purchaseOrderList: '/:tenant/purchasing/purchase-orders',
    purchaseOrderDetails: '/:tenant/purchasing/purchase-order/:id',
    purchaseOrderNew: '/:tenant/purchasing/purchase-orders/new',
    vendorList: '/:tenant/purchasing/vendors',
    vendorDetails: '/:tenant/purchasing/vendor/:id',
    itemList: '/:tenant/purchasing/items',
    itemDetails: '/:tenant/purchasing/item/:id',
    purchaseTargets: '/:tenant/purchasing/purchase-targets',
    purchaseGroups: '/:tenant/purchasing/purchase-groups',
    purchaseTargetLines: '/:tenant/purchasing/purchase-targets/lines',
    purchaseStatus: '/:tenant/purchasing/purchase-status',
  },
  reporting: {
    // TODO: remove /reporting/reporting after users have had time to migrate links
    reporting: '/:tenant/reporting/reporting',
    explorer: '/:tenant/reporting/explorer',
    report: '/:tenant/reporting/report/:id',
    reports: '/:tenant/reporting/reports',
  },
  tasks: {
    taskList: '/:tenant/tasks/tasks',
    taskDetails: '/:tenant/tasks/task/:id',
  },
  settings: {
    legacySalesSettings: '/:tenant/settings/legacy-sales-settings',
    settings: '/:tenant/settings/settings',
    users: '/:tenant/settings/users',
    replenishmentPaths: '/:tenant/settings/replenishment-paths',
  },
  internal: {
    userList: '/internal/users',
    userDetails: '/internal/user/:id',
    userNew: '/internal/users/new',
    userEdit: '/internal/user/:id/edit',
    tenantList: '/internal/tenants',
    tenantDetails: '/internal/tenant/:id',
    tenantNew: '/internal/tenants/new',
    tenantEdit: '/internal/tenant/:id/edit',
  },
  account: {
    accountEdit: '/account/edit',
  },
  setup: {
    integration: '/:tenant/setup/integration',
  },
};

/** Used to preserve backward compatibility for urls */
export const oldToNewRoutePaths = {
  '/sales/dashboard': routePaths.sales.dashboard,
};

export const routes = {
  home: () => routePaths.home,
  sales: {
    dashboard: () => makePath(routePaths.sales.dashboard),
    customerList: (hashState?: SearchFrameHashState) => makePath(routePaths.sales.customerList, null, hashState),
    customerDetails: (id: string, hashState?: DetailsPageWithCompanyIdHashState) =>
      makePath(routePaths.sales.customerDetails, { id }, hashState),
    customerNew: (hashState?: ProspectHashState) => makePath(routePaths.sales.customerNew, null, hashState),
    itemList: (hashState?: SearchFrameHashState) =>
      isProductEnabled(TenantProductName.SalesSearchAndLookup)
        ? makePath(routePaths.sales.itemList, null, hashState)
        : makePath(routePaths.purchasing.itemList, null, hashState),
    itemDetails: (id: string) =>
      isProductEnabled(TenantProductName.SalesSearchAndLookup)
        ? makePath(routePaths.sales.itemDetails, { id })
        : makePath(routePaths.purchasing.itemDetails, { id }),
    pricing: (hashState?: PricingHashState) => makePath(routePaths.sales.pricing, null, hashState),
  },
  orders: {
    orderList: (hashState?: SearchFrameHashState) => makePath(routePaths.orders.orderList, null, hashState),
    orderDetails: (id: string) => makePath(routePaths.orders.orderDetails, { id }),
    orderNewP21: (hashState?: QuoteEditHashStateP21) => makePath(routePaths.orders.orderNew, null, hashState),
    orderNewSAPB1: (hashState?: OrderEditHashStateSAPB1) => makePath(routePaths.orders.orderNew, null, hashState),
    // orderNew is ERP agnostic route builder, that calls orderNewP21 or orderNewSAPB1 depending on activeTenant erpType.
    // For ERP specific routing, use orderNewP21 or orderNewSAPB1
    orderNew: (hashState?: QuoteEditHashStateP21) =>
      hashState && isSAPB1Tenant()
        ? routes.orders.orderNewSAPB1(getOrderEditSAPB1HashStateFromP21HashState(hashState))
        : routes.orders.orderNewP21(hashState),
    draftQuoteList: (hashState?: DraftQuotesListHashState) =>
      makePath(routePaths.orders.draftQuoteList, null, hashState),
    quoteList: (hashState?: SearchFrameHashState) => makePath(routePaths.orders.quoteList, null, hashState),
    quoteDetails: (id: string) => makePath(routePaths.orders.quoteDetails, { id }),
    quoteNewP21: (hashState?: QuoteEditHashStateP21) => makePath(routePaths.orders.quoteNew, null, hashState),
    quoteNewSAPB1: (hashState?: QuoteEditHashStateSAPB1) => makePath(routePaths.orders.quoteNew, null, hashState),
    // quoteNew is ERP agnostic route builder, that calls quoteNewP21 or quoteNewSAPB1 depending on activeTenant erpType.
    // For ERP specific routing, use quoteNewP21 or quoteNewSAPB1
    quoteNew: (hashState?: QuoteEditHashStateP21) =>
      hashState && isSAPB1Tenant()
        ? routes.orders.quoteNewSAPB1(getOrderEditSAPB1HashStateFromP21HashState(hashState))
        : routes.orders.quoteNewP21(hashState),
    quoteEdit: (id: string) => makePath(routePaths.orders.quoteEdit, { id }),
    opportunities: () => makePath(routePaths.orders.opportunities),
  },
  demandPlanning: {
    dashboard: (hashState?: InventoryDashboardHashState) =>
      makePath(routePaths.demandPlanning.dashboard, null, hashState),
    inventoryHistoryExplorer: (hashState?: InventoryDashboardDetailsHashState) =>
      makePath(routePaths.demandPlanning.reporting, null, hashState),
    inventoryStatusExplorer: (hashState?: InventoryDashboardStatusHashState) =>
      makePath(routePaths.demandPlanning.currentInventory, null, hashState),
    inventorySimulationDashboard: () => makePath(routePaths.demandPlanning.inventorySimulationDashboard),
    forecasting: (hashState?: SearchFrameHashState) => makePath(routePaths.demandPlanning.forecasting, null, hashState),
    onboarding: (hashState?: SearchFrameHashState) => makePath(routePaths.demandPlanning.onboarding, null, hashState),
    planning: (hashState?: SearchFrameHashState) => makePath(routePaths.demandPlanning.planning, null, hashState),
  },
  payments: {
    disputes: () => makePath(routePaths.payments.disputes),
    paymentDetails: () => makePath(routePaths.payments.paymentDetails),
    payouts: () => makePath(routePaths.payments.payouts),
    payoutDetails: () => makePath(routePaths.payments.payoutDetails),
    payments: () => makePath(routePaths.payments.payments),
    openInvoices: () => makePath(routePaths.payments.openInvoices),
    transactions: (hashState?: PaymentsTransactionsHashState) =>
      makePath(routePaths.payments.payoutDetails, null, hashState),
  },
  purchasing: {
    purchaseOrderList: (hashState?: SearchFrameHashState) =>
      makePath(routePaths.purchasing.purchaseOrderList, null, hashState),
    purchaseOrderDetails: (id: string) => makePath(routePaths.purchasing.purchaseOrderDetails, { id }),
    purchaseOrderNew: (hashState?: PurchaseOrderEditHashState) =>
      makePath(routePaths.purchasing.purchaseOrderNew, null, hashState),
    vendorList: (hashState?: SearchFrameHashState) => makePath(routePaths.purchasing.vendorList, null, hashState),
    vendorDetails: (id: string, hashState?: DetailsPageWithCompanyIdHashState) =>
      makePath(routePaths.purchasing.vendorDetails, { id }, hashState),
    itemList: (hashState?: SearchFrameHashState) =>
      isProductEnabled(TenantProductName.DfpaSearchAndLookup)
        ? makePath(routePaths.purchasing.itemList, null, hashState)
        : makePath(routePaths.sales.itemList, null, hashState),
    itemDetails: (id: string) =>
      isProductEnabled(TenantProductName.DfpaSearchAndLookup)
        ? makePath(routePaths.purchasing.itemDetails, { id })
        : makePath(routePaths.sales.itemDetails, { id }),
    purchaseTargets: (hashState?: PurchaseTargetsHashState) =>
      makePath(routePaths.purchasing.purchaseTargets, null, hashState),
    purchaseGroups: (hashState?: PurchaseTargetsHashState) =>
      makePath(routePaths.purchasing.purchaseGroups, null, hashState),
    purchaseTargetLines: (hashState?: PurchaseTargetLinesHashState) =>
      makePath(routePaths.purchasing.purchaseTargetLines, null, hashState),
    purchaseStatus: (hashState?: SearchFrameHashState) =>
      makePath(routePaths.purchasing.purchaseStatus, null, hashState),
  },
  reporting: {
    explorer: (hashState?: ReportingHashState) => makePath(routePaths.reporting.explorer, null, hashState),
    reports: () => makePath(routePaths.reporting.reports),
    report: (id: string) => makePath(routePaths.reporting.report, { id }),
  },
  tasks: {
    taskList: () => makePath(routePaths.tasks.taskList),
    taskDetails: (id: string) => makePath(routePaths.tasks.taskDetails, { id }),
  },
  settings: {
    legacySalesSettings: () => makePath(routePaths.settings.legacySalesSettings),
    settings: () => makePath(routePaths.settings.settings),
    users: () => makePath(routePaths.settings.users),
    replenishmentPaths: (hashState?: SearchFrameHashState) =>
      makePath(routePaths.settings.replenishmentPaths, null, hashState),
  },
  internal: {
    userList: () => makePath(routePaths.internal.userList),
    userDetails: (id: string) => makePath(routePaths.internal.userDetails, { id }),
    userNew: () => makePath(routePaths.internal.userNew),
    userEdit: (id: string) => makePath(routePaths.internal.userEdit, { id }),
    tenantList: () => makePath(routePaths.internal.tenantList),
    tenantDetails: (id: string) => makePath(routePaths.internal.tenantDetails, { id }),
    tenantNew: () => makePath(routePaths.internal.tenantNew),
    tenantEdit: (id: string) => makePath(routePaths.internal.tenantEdit, { id }),
  },
  account: {
    accountEdit: () => makePath(routePaths.account.accountEdit),
  },
  setup: {
    integration: () => makePath(routePaths.setup.integration),
  },
};

///  Uri builder helpers ///

/**
 * React router does magic to automatically decode encoded urls
 * Not a great decision on their part, because it causes all sorts of consistency issues
 * depending on whether use use history.push or <Link>
 * @see https://github.com/ReactTraining/history/issues/505
 * We work around it by encoding and decoding % as ％ (U+FF05)
 */

export function encodePercentChars(str: string): string {
  return str.replace(/%/g, '％');
}

export function decodePercentChars(str: string): string {
  return str.replace(/％/g, '%');
}

export function encodeHashState(hashState: Obj): string {
  return `#${urltron.stringify(hashState)}`;
}

function encodePathParams(path: string, pathParams: Obj): string {
  let pathStr = path;
  for (const key of Object.keys(pathParams)) {
    pathStr = pathStr.replace(`/:${key}`, `/${encodePercentChars(encodeURIComponent(pathParams[key]))}`);
  }
  return pathStr;
}

/** singleton module var so tenant path param doesn't need to be set for every makePath call */
let defaultTenantSlug = '';
export function setDefaultTenantSlugForMakePath(tenantSlug: string) {
  defaultTenantSlug = tenantSlug;
}

/**
 * Build uri string with path params and query params
 */
export function makePath(routePath: string, pathParams?: Obj<string | number> | null, hashState?: Obj): string {
  if (routePath.includes('/:tenant/') && (!pathParams || !pathParams.tenant)) {
    // automatically insert defaultTenantSlug in paths
    if (!defaultTenantSlug) {
      throw new Error('No defaultTenantSlug set, makePath function called too early');
    }
    pathParams = { tenant: defaultTenantSlug, ...pathParams };
  }

  const pathStr = pathParams ? encodePathParams(routePath, pathParams) : routePath;
  const hashStr = hashState ? encodeHashState(hashState) : '';
  return pathStr + hashStr;
}

export const encodeLegacyApiParam = (id: string) => encodeURIComponent(id);

export const encodeLegacyApiItemIdParam = (itemId: string) =>
  // we double encode because, legacy api uses flask which already decodes before passing to req handler
  // item ids can contain all sorts of characters e.g. "/", which flask doesn't handle well.
  // until we fully refactor our frontend and backend to use inv_mast_uid (inventory master uid), this is a necessary evil
  // @see https://github.com/pallets/flask/issues/900#issuecomment-582499607
  // @see https://github.com/recurrency/frontend/issues/1273#issuecomment-845180347
  // @see https://github.com/recurrency/api/pull/257/files
  encodeURIComponent(encodeURIComponent(itemId));

export const routePathsList = objFlattenedValues(routePaths);

/**
 * @returns matching routePath e.g '/sales/customer/:id'
 */
export function getMatchingRoutePath(path: string): string | undefined {
  const match = matchPath(path, { path: routePathsList, exact: true });
  return match ? match.path : undefined;
}

/** translates old route path to new route path */
export function oldToNewRoutePath(oldRoutePath: string): string | undefined {
  const oldRoutePathsList = objKeys(oldToNewRoutePaths);
  const match = matchPath(oldRoutePath, { path: oldRoutePathsList, exact: true });
  if (match) {
    // @ts-expect-error matchPath should match this properly
    return makePath(oldToNewRoutePaths[match.path], match.params);
  }
  return undefined;
}

/** parses location path into routePath, tenant, module and subModule slugs */
export function parsePath(path: string): ParsePathResult {
  // ignore leading and trailing slashes
  const slashTrimmedPath = path.replace(/^\/|\/$/g, '');
  const pathSlashParts = slashTrimmedPath.split('/');
  const routeMatch = matchPath(path, { path: routePathsList, exact: true });

  const result: ParsePathResult = {
    routePath: routeMatch?.path || '',
    pathParams: routeMatch?.params,
    tenantSlug: '',
    moduleSlug: '',
    subModuleSlug: '',
    pathSlashParts,
  };

  if (result.routePath.startsWith('/:tenant/')) {
    [result.tenantSlug, result.moduleSlug, result.subModuleSlug] = pathSlashParts;
  } else {
    [result.moduleSlug, result.subModuleSlug] = pathSlashParts;
  }
  return result;
}

export function isSAPB1Tenant(): boolean {
  return isTenantErpTypeSAPB1(getGlobalAppState().activeTenant?.erpType);
}

function isProductEnabled(product: TenantProductName): boolean | undefined {
  // Can't use shouldShowProduct without creating a dependency cycle, use this only within this file
  return getGlobalAppState().activeTenant?.products?.[product].productEnabled;
}

export function getOrderEditSAPB1HashStateFromP21HashState(hashState: QuoteEditHashStateP21): OrderEditHashStateSAPB1 {
  return {
    customer: hashState.customer,
    customerPORef: hashState.poNo,
    shipTo: hashState.shipTo,
    // NOTE: SAPB1 OCRD table doesn't have CntctCode (contactId) field, only CntctPrsn (contactName) field
    // which is insufficient to correct select a contact, hence ignoring contact
    carrier: hashState.carrier,
    location: hashState.location,
    items: (hashState.items || []).map((lineItem) => ({
      foreignId: lineItem.foreignId,
      name: lineItem.name,
      unitOfMeasure: lineItem.unitOfMeasure,
      unitQuantity: lineItem.quantity,
      unitPrice: lineItem.price,
    })),
  };
}

/// Location hooks ///

/**
 * @returns path params from location e.g /item/:id -> {id: 123}
 */
export function usePathParams<PathParamsT>(): PathParamsT {
  const routerParams = useReactRouterParams<FIXME>();
  const pathParams: Obj = {};
  for (const key of Object.keys(routerParams)) {
    pathParams[key] = decodeURIComponent(decodePercentChars(routerParams[key]));
  }
  return pathParams as unknown as PathParamsT;
}

export interface UpdateHashStateOptions {
  pushToHistory?: boolean;
}

/**
 * @returns [hashState from location.hash, updateHashState function]
 */
export function useHashState<HashStateT>() {
  const history = useHistory();
  const hashState: HashStateT = useMemo(
    () => urltron.parse(window.location.hash),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.hash],
  );
  const updateHashState = useCallback(
    (update: Partial<HashStateT>, options?: UpdateHashStateOptions) => {
      // use Object.assign so we don't clear out existing hashState values
      const newHashState = Object.assign(urltron.parse(window.location.hash), update);
      const newHash = encodeHashState(newHashState);

      if (window.location.hash !== newHash) {
        // use history.replace by default so we can change url without adding to history stack
        // hashState updates can be very frequent
        if (options?.pushToHistory) {
          history.push(newHash);
        } else {
          history.replace(newHash);
        }
      }
    },
    [history],
  );
  return [hashState, updateHashState] as const;
}

export interface UpdateFormHashStateOptions extends UpdateHashStateOptions {
  // Used to tell if the value should be autosaved into local storage
  defaultValue?: boolean;
}

/**
 * A wrapper of useHashState that will automatically save form state in local storage
 * @param shouldSaveToLocalStorage Used to determine when this hook should edit the local storage state.
 * eg. saving state when the quote is a draft would cause confusion
 */
export function useFormHashState<HashStateT>(localStorageKey: LocalStorageKey, shouldSaveToLocalStorage = true) {
  const [formState, updateHashState] = useHashState<HashStateT>();
  const [savedState, setSavedState] = useLocalStorage<LocalStorageKey>(localStorageKey, {});
  const updateFormState = (update: Partial<HashStateT>, options?: UpdateFormHashStateOptions) => {
    if (!isObjEmpty(update)) {
      updateHashState(update, options);
      if (shouldSaveToLocalStorage && !options?.defaultValue) {
        setSavedState(Object.assign(urltron.parse(window.location.hash), update));
      }
    }
  };
  return {
    formState,
    updateFormState,
    savedFormState: savedState as HashStateT,
    clearSavedFormState: () => setSavedState({}),
  };
}
