import React from 'react';

import { SearchInventoryStatusDTO } from '@recurrency/core-api-schema/dist/search/getSearchInventoryStatus';

import { InventoryUsagePanelContent } from 'components/recipes/sidePane/InventoryUsagePanelContent';
import { LocationAvailabilityPanelContent } from 'components/recipes/sidePane/LocationAvailabilityPanelContent';
import { PurchaseOrderLinesPanelContent } from 'components/recipes/sidePane/PurchaseOrderLinesPanelContent';
import { SalesInvoiceLinesPanelContent } from 'components/recipes/sidePane/SalesInvoiceLinesPanelContent';
import { SalesOrderLinesPanelContext } from 'components/recipes/sidePane/SalesOrderLinesPanelContext';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { UnitOfMeasuresPanelContent } from 'components/recipes/sidePane/UnitOfMeasuresPanelContent';

import { splitIdNameStr } from 'utils/formatting';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

import { ItemNotesPanelContent } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/ItemNotesPanelContent';
import { SupplierNotesPanelContent } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/SupplierNotesPanelContent';

export function PurchaseStatusSidePane({ record }: { record: SearchInventoryStatusDTO | null }) {
  if (!record) {
    return <SidePane title="" content={<div>Please select a table row</div>} />;
  }

  const { foreignId: locationId, name: locationName } = splitIdNameStr(record.location);

  return (
    <SidePane
      title={`Item #${record.itemId} at ${record.location}`}
      settingKey={ViewSettingKey.PurchaseStatusSidePane}
      description={record.itemName}
      panels={[
        {
          settingKey: 'usage',
          title: 'Usage',
          content: (
            <InventoryUsagePanelContent
              itemId={record.itemId}
              locations={[{ foreignId: locationId, name: locationName }]}
            />
          ),
        },
        {
          settingKey: 'itemNotes',
          title: 'Item Notes',
          content: (
            <ItemNotesPanelContent
              uniqueItems={[{ itemId: record.itemUid, foreignId: record.itemId, name: record.itemName }]}
            />
          ),
        },
        {
          settingKey: 'locationStock',
          title: 'Location Stock',
          content: <LocationAvailabilityPanelContent itemUid={record.itemUid} />,
        },
        {
          settingKey: 'unitOfMeasures',
          title: 'Unit of Measures',
          content: <UnitOfMeasuresPanelContent itemId={record.itemId} />,
        },
        {
          settingKey: 'purchaseOrders',
          title: 'Purchase Orders',
          content: (
            <PurchaseOrderLinesPanelContent
              itemId={record.itemId}
              companyId={record.company_id}
              locationIds={[locationId]}
            />
          ),
        },
        {
          settingKey: 'salesOrder',
          title: 'Sales Orders',
          content: (
            <SalesOrderLinesPanelContext
              itemId={record.itemId}
              locationIds={[locationId]}
              companyId={record.company_id}
            />
          ),
        },
        {
          settingKey: 'salesHistory',
          title: 'Sales History',
          content: (
            <SalesInvoiceLinesPanelContent
              itemId={record.itemId}
              locationIds={[locationId]}
              companyId={record.company_id}
            />
          ),
        },
        {
          settingKey: 'supplierNotes',
          title: 'Supplier Notes',
          content: <SupplierNotesPanelContent uniqueSuppliers={[record.supplier]} />,
        },
      ]}
    />
  );
}
