import React from 'react';

import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { ColumnType } from 'antd/lib/table';
import { CompareFn, SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';
import { DataIndex } from 'rc-table/lib/interface';

import { ChangeDeltaWithArrow } from 'components/recipes/ChangeDeltaWithArrow';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';

import { formatUSD, formatDate, formatPercent, formatNumber, formatId } from 'utils/formatting';
import { objGet } from 'utils/object';

interface ColumnTypeNoKey<ObjT> extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  render?: (val: ObjT, obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnType1Key<ObjT, Key1T extends keyof ObjT, T = Key1T>
  extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  dataIndex: T;
  render?: (val: ObjT[Key1T], obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnType2Keys<ObjT, Key1T extends keyof ObjT, Key2T extends keyof ObjT[Key1T], T = [Key1T, Key2T]>
  extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  dataIndex: T;
  render?: (val: ObjT[Key1T][Key2T], obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnType3Keys<
  ObjT,
  Key1T extends keyof ObjT,
  Key2T extends keyof ObjT[Key1T],
  Key3T extends keyof ObjT[Key1T][Key2T],
  T = [Key1T, Key2T, Key3T],
> extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  dataIndex: T;
  render?: (val: ObjT[Key1T][Key2T][Key3T], obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnTypeGeneric<ObjT> extends ColumnType<ObjT> {
  dataIndex?: string | Readonly<string[]>;
  render?: (val: Any, obj: ObjT, index: number) => React.ReactNode;
}

/** string implies the value is dataIndex of field to be exported */
export type ExportValueFn = string | ((value: Any) => string | number);

export type ExportColumn<HitT> = {
  exportOnly?: boolean;
  exportTitle?: string;
  exportType?: 'string' | 'number';
  /** export value to be used in download or return multiple values for a single column */
  exportValue?: ExportValueFn | Record<string, ExportValueFn>;
  children?: ExportColumn<HitT>[];
} & ColumnType<HitT>;

export function typedColumn<ObjT extends Obj, Key1T extends keyof ObjT>(
  config: ColumnType1Key<ObjT, Key1T>,
): ColumnType<ObjT>;
export function typedColumn<ObjT extends Obj, Key1T extends keyof ObjT, Key2T extends keyof ObjT>(
  config: ColumnType2Keys<ObjT, Key1T, Key2T>,
): ColumnType<ObjT>;
export function typedColumn<
  ObjT extends Obj,
  Key1T extends keyof ObjT,
  Key2T extends keyof ObjT[Key1T],
  Key3T extends keyof ObjT[Key1T][Key2T],
>(config: ColumnType3Keys<ObjT, Key1T, Key2T, Key3T>): ColumnType<ObjT>;
export function typedColumn<ObjT extends Obj>(config: ColumnTypeNoKey<ObjT>): ColumnType<ObjT>;
export function typedColumn<ObjT>(config: ColumnTypeGeneric<ObjT>): ColumnType<ObjT> {
  return config;
}

/** so we can have type referenced usage of dataIndex */
export function asKeyOf<T>(key: keyof T): keyof T {
  return key;
}

// usually ant tables go from (descend -> ascend -> no sort), this makes it (ascend -> descend) toggle
export const sortDirections = ['ascend' as const, 'descend' as const, 'ascend' as const];

/**
 * Use-case: sortable table column with empty values. Typically you don't want the empty values on top,
 * so this function will always sort them to the end of the list for either ascending or descending.
 */
export const falseyAlwaysLast =
  (sortFn: CompareFn<Obj>, dataIndex: DataIndex | undefined): CompareFn<Obj> =>
  (aObj: Obj, bObj: Obj, sortOrder?: SortOrder) => {
    const a = objGet(aObj, dataIndex);
    const b = objGet(bObj, dataIndex);

    // If both falsey, they count as equal
    // NOTE: Special exception, zeroes should sort normally
    if (!a && !b && a !== 0 && b !== 0) {
      return 0;
    }
    if (!a && a !== 0) {
      return sortOrder === 'descend' ? -1 : 1;
    }
    if (!b && b !== 0) {
      return sortOrder === 'descend' ? 1 : -1;
    }

    return sortFn(aObj, bObj, sortOrder);
  };

/** function that returns a sorter function that sorts off the field given by dataIndex */
export const strFieldSorter =
  (dataIndex: DataIndex | undefined) =>
  (a: Obj, b: Obj): number =>
    String(objGet(a, dataIndex) || '').localeCompare(String(objGet(b, dataIndex) || ''), undefined, {
      // This keyword makes the comparison case-insensitive
      sensitivity: 'base',
    });

export const numFieldSorter =
  (dataIndex: DataIndex | undefined) =>
  (a: Obj, b: Obj): number =>
    Number(objGet(a, dataIndex)) - Number(objGet(b, dataIndex));

export const sortableStringColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    sorter: falseyAlwaysLast(strFieldSorter(dataIndex), dataIndex),
    sortDirections,
    ...rest,
  } as unknown as ColumnT);

export const numberColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (num: number) => formatNumber(num),
    align: 'right' as const,
    ...rest,
  } as unknown as ColumnT);

export const sortableNumberColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    ...numberColumn({ dataIndex }),
    sorter: numFieldSorter(dataIndex),
    sortDirections,
    ...rest,
  } as ColumnT);

export const dollarColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (amt: number) => formatUSD(amt),
    align: 'right' as const,
    ...rest,
  } as unknown as ColumnT);

export const sortableDollarColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    ...dollarColumn({ dataIndex }),
    sorter: numFieldSorter(dataIndex),
    sortDirections,
    ...rest,
  } as unknown as ColumnT);

export const sortableChangeNumberColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={formatNumber} />,
    sorter: numFieldSorter(dataIndex),
    sortDirections,
    align: 'right' as const,
    ...rest,
  } as unknown as ColumnT);

export const changeDollarColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={formatUSD} />,
    align: 'right' as const,
    ...rest,
  } as unknown as ColumnT);

export const sortableChangeDollarColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    ...changeDollarColumn({ dataIndex }),
    sorter: numFieldSorter(dataIndex),
    sortDirections,
    ...rest,
  } as unknown as ColumnT);

export const sortableDollarWithCentsColumn = <ColumnT extends ColumnType<Any>>(
  { dataIndex, ...rest }: ColumnT,
  precision?: number,
): ColumnT =>
  ({
    dataIndex,
    render: (amt: number) => formatUSD(amt, true, precision),
    sorter: numFieldSorter(dataIndex),
    sortDirections,
    align: 'right' as const,
    ...rest,
  } as unknown as ColumnT);

export const sortableDateColumn = <ColumnT extends ColumnType<Any>>({
  dataIndex,
  withTimestamp = false,
  ...rest
}: { withTimestamp?: boolean } & ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (date: string) => formatDate(date, withTimestamp),
    sorter: (a: Obj, b: Obj) => {
      const aNotValid = !objGet(a, dataIndex) || !moment(objGet(a, dataIndex)).isValid();
      const bNotValid = !objGet(b, dataIndex) || !moment(objGet(b, dataIndex)).isValid();

      if (aNotValid && bNotValid) {
        return 0;
      }
      if (aNotValid) {
        return -1;
      }
      if (bNotValid) {
        return 1;
      }
      return objGet(a, dataIndex)?.localeCompare(objGet(b, dataIndex));
    },
    sortDirections,
    ...rest,
  } as unknown as ColumnT);

export const sortablePercentColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (val: number) => formatPercent(val),
    sorter: numFieldSorter(dataIndex),
    sortDirections,
    align: 'right' as const,
    ...rest,
  } as unknown as ColumnT);

export const sortableChangePercentColumn = <ColumnT extends ColumnType<Any>>({
  dataIndex,
  ...rest
}: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={(val) => formatPercent(val, 0)} />,
    sorter: numFieldSorter(dataIndex),
    sortDirections,
    align: 'right' as const,
    ...rest,
  } as unknown as ColumnT);

export const booleanColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (val: boolean) => (val ? `Yes` : `No`),
    ...rest,
  } as unknown as ColumnT);

export const sortableBooleanColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    ...booleanColumn({ dataIndex }),
    sorter: (a: Obj, b: Obj) => (objGet(a, dataIndex) ? 1 : 0) - (objGet(b, dataIndex) ? 1 : 0),
    sortDirections,
    ...rest,
  } as unknown as ColumnT);

export const sortableIdColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    render: (id: string) => formatId(id),
    sorter: strFieldSorter(dataIndex),
    sortDirections,
    ...rest,
  } as unknown as ColumnT);

export const combinedIdStringColumn = <ColumnT extends ColumnType<Any>>({ dataIndex, ...rest }: ColumnT): ColumnT =>
  ({
    dataIndex,
    sorter: strFieldSorter('foreignId'),
    sortDirections,
    render: (value: { foreignId: string; name: string } | undefined) => {
      if (value) {
        return `${value.foreignId}: ${value.name}`;
      }
      return '';
    },
    ...rest,
  } as unknown as ColumnT);

export const percentChangeColumn = ({
  dividend,
  divisor,
  title,
}: {
  dividend: (row: any) => number;
  divisor: (row: any) => number;
} & Omit<ColumnType<any>, 'dataIndex'>): ColumnType<any> => ({
  title: title || '∆%',
  align: 'right' as const,
  render: (row) => {
    if (!divisor(row) || divisor(row) === 0) {
      return '-';
    }

    return formatPercent(dividend(row) / divisor(row));
  },
});

export const erpStatusColumn = ({ ...rest }: ColumnType<any> = {}): ColumnType<any> => ({
  title: 'Status',
  dataIndex: 'status',
  render: (status: ErpOrderStatus) => <ErpOrderStatusBadge erpOrderStatus={status} />,
  ...rest,
});

/** sets the sortOrder for the currently sorted column */
export const withSortedColumn = (
  columns: ColumnType<any>[],
  sortBy: string | undefined,
  sortDir: 'asc' | 'desc' | undefined,
): ColumnType<any>[] => {
  if (!sortBy || !sortDir) {
    return columns;
  }

  for (const column of columns) {
    if (column.dataIndex === sortBy) {
      column.sortOrder = sortDir === 'asc' ? 'ascend' : 'descend';
    }
  }
  return columns;
};
