import React from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Steps } from 'antd';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { getErpName } from 'utils/formatting';
import { useHashState } from 'utils/routes';

import { TransferStep, TransferTargetLinesHashState } from 'types/hash-state';

import { TransferTargetLinesAllLines } from './TransferTargetLinesAllLines';

export function TransferTargetLinesPage() {
  const { activeTenant } = useGlobalApp();
  const [hashState, updateHashState] = useHashState<TransferTargetLinesHashState>();
  const { step: currentStep = TransferStep.AllLines } = hashState;

  const {
    data: targetLinesData,
    isLoading,
    error,
  } = useCoreApi(schemas.transferOrders.getTransferTargetLines, {
    queryParams: {
      filter: {
        sourceLocations: hashState.sourceLocationIds,
        destinationLocations: hashState.destinationLocationIds,
        statuses: hashState.statuses,
      },
    },
  });

  const goToStep = (step: TransferStep) => {
    updateHashState({ step });
  };

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !targetLinesData) {
    return <CenteredLoader />;
  }

  return (
    <Container>
      {!isLoading && (
        <PageHeader
          title={<>Transfer Target Lines</>}
          entity={{ kind: `Transfer Targets` }}
          headerActions={
            <>
              {currentStep > TransferStep.AllLines && (
                <Button onClick={() => goToStep(currentStep - 1)}>
                  <LeftOutlined />
                  Previous
                </Button>
              )}
              {currentStep < TransferStep.Finalize && (
                <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
                  Next
                  <RightOutlined />
                </Button>
              )}
              {currentStep === TransferStep.Finalize && (
                // TODO @brunno: add logic that sends the transfer target to the ERP
                <AsyncButton type="primary" disabled>
                  Send to {getErpName(activeTenant.erpType)}
                </AsyncButton>
              )}
            </>
          }
        />
      )}

      <Steps
        className={css`
          margin-bottom: 16px;
        `}
        current={currentStep}
        onChange={(newStep) => updateHashState({ step: newStep })}
      >
        <Steps.Step
          key={TransferStep.AllLines}
          title={
            <InfoTooltip placement="bottom" useInfoIcon title="">
              All Lines
            </InfoTooltip>
          }
        />
        <Steps.Step key={TransferStep.Finalize} title="Finalize" />
      </Steps>

      {currentStep === TransferStep.AllLines && <TransferTargetLinesAllLines targetLines={targetLinesData.items} />}
      {/* TODO: Add Finalize Step component and logic */}
      {currentStep === TransferStep.Finalize && null}
    </Container>
  );
}
