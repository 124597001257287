import React from 'react';

import { useHistory } from 'react-router';

import { CalendarOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  DeadstockThresholdCriteria,
  PurchaseTargetStatus,
  RecurrencyReplenishmentMethod,
  SortDirection,
  TargetGroupType,
  TenantFeatureFlag,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { InventoryDashboardTimeFilters } from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import { InventoryDashboardStatus } from '@recurrency/core-api-schema/dist/inventory/inventoryDashboardResultDTO';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Col, Empty, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment from 'moment';
import { Cell, Legend, LegendPayload, LegendProps, Pie, PieChart, Tooltip, TooltipProps } from 'recharts';
import { theme } from 'theme';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { LinkButton } from 'components/Button/LinkButton';
import { Card, CardHeader, CardSection } from 'components/Card';
import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { Grid } from 'components/Grid';
import { CenteredError } from 'components/Loaders';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatDateShorthand, formatNumber, formatPercent, formatUSD, formatUSDAbbreviated } from 'utils/formatting';
import { LocalStorageKey, useLocalStorage } from 'utils/localStorage';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';
import { useSearchIndex } from 'utils/search/search';
import { SearchResponse } from 'utils/search/types';
import { isTenantErpTypeNetSuite } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';

import {
  InventoryDashboardDetailsHashState,
  InventoryDashboardHashState,
  InventoryDashboardHashStateFilters,
} from 'types/hash-state';
import { SearchIndexMinMax } from 'types/search-collections';

import { DrillDownChartConfig, DrillDownChartConfigItem } from './DrillDownChartConfig';
import { InventoryDashboardAreaChart } from './InventoryDashboardAreaChart';
import { CardLoader } from './shared/CardLoader';
import { DashboardUnitSelect } from './shared/DashboardUnitSelect';
import { ExploreButton } from './shared/ExploreButton';
import { InventoryDashboardFilters } from './shared/InventoryDashboardFilters';
import { RecommendedMaxToggle } from './shared/RecommendedMaxToggle';

export const InventoryMissionControl = () => {
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();
  const [hashState, updateHashState] = useHashState<InventoryDashboardHashState>();
  const defaultTimeSpan = InventoryDashboardTimeFilters['12M'];
  const { md: isDesktop } = useBreakpoint();
  const shouldShowToggle = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.PlanningShowMaxToggle);
  const useRecommendedMax = shouldShowToggle && (hashState?.useRecommendedMax ?? false);
  const [localStorageRecommendedMaxEnabled, setLocalStorageRecommendedMaxEnabled] = useLocalStorage(
    LocalStorageKey.Demand_Planning_Recommended_Max_Toggle_Enabled,
    getTenantSetting(TenantSettingKey.FeaturePlanningMaxToggleDefault) ?? false,
  );

  const onboardingPageEnabled = getTenantSetting(TenantSettingKey.FeatureOnboardingPage) ?? false;

  const deadstockInterval = getTenantSetting(TenantSettingKey.CalculationDeadstockThresholdInterval);
  // Used to determine if deadstock is calculated based on purchasing, sales, or both. This could be changed in the future to be more enum dependent
  const deadstockCriteria = getTenantSetting(TenantSettingKey.CalculationDeadstockThresholdCriteria);
  const deadstockUsesPurchasing =
    deadstockCriteria === DeadstockThresholdCriteria.LastPurchaseDate ||
    deadstockCriteria === DeadstockThresholdCriteria.LastInvoiceOrLastPurchaseDate ||
    deadstockCriteria === DeadstockThresholdCriteria.LastPurchaseOurLastSoldDate ||
    deadstockCriteria === DeadstockThresholdCriteria.MostRecentDate;
  const deadstockUsesSales = deadstockCriteria !== DeadstockThresholdCriteria.LastPurchaseDate;
  let deadstockDescription: string;
  if (deadstockUsesPurchasing && deadstockUsesSales) {
    deadstockDescription = 'bought or sold';
  } else if (deadstockUsesPurchasing) {
    deadstockDescription = 'bought';
  } else {
    deadstockDescription = 'sold';
  }

  const COLORS = Object.keys(theme.colors.chart).map(
    (key: string) => theme.colors.chart[key as keyof typeof theme.colors.chart],
  );

  if (!hashState || !hashState.filters || !hashState.displayUnit) {
    requestAnimationFrame(async () => {
      updateHashState({ filters: { timeSpan: defaultTimeSpan }, displayUnit: 'value' });
    });
  }
  if (!hashState || hashState.useRecommendedMax === undefined) {
    updateHashState({
      useRecommendedMax: localStorageRecommendedMaxEnabled,
    });
  }

  const timeSpanSelectOptions: MultiSelectOption[] = [
    {
      value: InventoryDashboardTimeFilters['3M'],
      label: 'Last 3 Months',
    },
    {
      value: InventoryDashboardTimeFilters['6M'],
      label: 'Last 6 Months',
    },
    {
      value: InventoryDashboardTimeFilters['12M'],
      label: 'Last 12 Months',
    },
    {
      value: InventoryDashboardTimeFilters['18M'],
      label: 'Last 18 Months',
    },
  ];

  const onHashStateFilterChange = (filters: InventoryDashboardHashStateFilters) => {
    updateHashState({ ...hashState, filters });
  };

  const onTimeSpanFilterChange = (timeSpan: InventoryDashboardTimeFilters) => {
    const filters = { ...hashState.filters };
    filters.timeSpan = timeSpan;
    updateHashState({ ...hashState, filters });
  };

  const apiQueryParams = {
    filters: {
      timeSpan: hashState.filters?.timeSpan ?? defaultTimeSpan,
      companyIds: hashState.filters?.companyIds,
      locationIds: hashState.filters?.locationIds,
      itemIds: hashState.filters?.itemIds,
      itemGroupIds: hashState.filters?.itemGroupIds,
      supplierIds: hashState.filters?.supplierIds,
      buyerIds: hashState.filters?.buyerIds,
      purchaseClasses: hashState.filters?.purchaseClasses,
      stockable: hashState.filters?.stockable?.length === 1 ? hashState.filters.stockable[0] === 'true' : undefined,
    },
    compareFrom: [],
    compareTo: [],
  };

  const {
    data: historicalData,
    error: historicalDataError,
    isLoading: historicalDataIsLoading,
  } = useCoreApi(schemas.inventory.getInventoryDashboardHistory, {
    queryParams: apiQueryParams,
  });

  const {
    data: currentData,
    error: currentDataError,
    isLoading: currentDataIsLoading,
  } = useCoreApi(schemas.inventory.getInventoryDashboardStatusBreakdown, {
    queryParams: apiQueryParams,
  });

  // PURCHASE TARGET ACTIONS
  const {
    data: purchaseTargetData,
    error: purchaseTargetError,
    isLoading: purchaseTargetLoading,
  } = useCoreApi(schemas.purchasing.getPurchaseTargets, {
    queryParams: {
      targetGroupType: TargetGroupType.Targets,
      filter: { status: PurchaseTargetStatus.Ready },
    },
  });

  // NEEDS UPDATE ACTIONS
  const {
    data: minMaxData,
    isLoading: minMaxIsLoading,
    error: minMaxError,
  } = useSearchIndex<SearchIndexMinMax>({
    indexName: SearchIndexName.Planning,
    hitsPerPage: 1,
    fieldsToRetrieve: ['item_id', 'planning_status', 'recurrency_replenishment_method'],
    filters: {
      planning_status: ['Review'],
    },
    facetFields: ['planning_status', 'recurrency_replenishment_method'],
  });

  const {
    data: onboardingData,
    isLoading: onboardingLoading,
    error: onboardingError,
  } = useSearchIndex<SearchIndexMinMax>({
    indexName: SearchIndexName.Planning,
    hitsPerPage: 0,
    fieldsToRetrieve: [],
    filters: {
      recurrency_replenishment_method: [RecurrencyReplenishmentMethod.Unsupported],
    },
  });

  const getCurrentStockLevels = () => [
    {
      name: 'Normal Stock',
      description: 'Stock that is in a healthy state',
      ...((useRecommendedMax ? currentData?.items[0]?.recommendedNormalstock : currentData?.items[0]?.normalstock) ??
        0),
    },
    {
      name: 'Dead Stock',
      description: `Stock that hasn’t been ${deadstockDescription} in the past ${deadstockInterval} months. Change how dead stock is calculated in settings`,
      ...(currentData?.items[0]?.deadstock ?? 0),
    },
    {
      name: 'Overstock',
      description: 'Stock that is above the current max',
      ...((useRecommendedMax ? currentData?.items[0]?.recommendedOverstock : currentData?.items[0]?.overstock) ?? 0),
    },
  ];

  const formatVolume = hashState.displayUnit === 'volume';

  interface LegendEntryPayload extends LegendPayload {
    payload?: { name: string; value: string; description?: string };
  }

  const RenderLegend = (props: LegendProps) => {
    const { payload } = props;

    return (
      <ul
        className={css`
          list-style-type: none;
          margin: 0;
          padding: 0;
          width: 145px;
        `}
      >
        {payload
          ?.filter((e) => e.value !== 0)
          .map((entry: LegendEntryPayload, index: number) => (
            <li
              key={`item-${index}`}
              className={css`
                margin-bottom: 8px;
              `}
            >
              <div
                className={css`
                  background-color: ${entry.color};
                  display: inline-block;
                  height: 14px;
                  width: 14px;
                  border-radius: 7px;
                  margin-right: 8px;
                  margin-top: 5px;
                  vertical-align: top;
                `}
              />
              <div
                className={css`
                  display: inline-block;
                `}
              >
                <InfoTooltip title={entry.payload?.description ?? ''}>
                  <Typography
                    style={{ color: theme.colors.neutral[900], fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}
                  >
                    {entry.payload?.name}
                  </Typography>
                </InfoTooltip>
                <Typography
                  style={{ color: theme.colors.primary[500], fontWeight: 400, fontSize: '14px', lineHeight: '17px' }}
                >
                  {props.formatter ? props.formatter(entry.payload?.value) : entry.payload?.value}
                </Typography>
              </div>
            </li>
          ))}
      </ul>
    );
  };

  interface AlertRowProps {
    title: string;
    description?: string;
    actionUrl?: string;
  }

  const AlertRow = ({ title, description, actionUrl }: AlertRowProps) => (
    <div
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div>
        <Typography type="middle" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        {description && (
          <Typography type="middle" style={{ fontSize: '14px' }}>
            {description}
          </Typography>
        )}
      </div>
      <div>
        {actionUrl && (
          <LinkButton route={actionUrl} type="primary">
            Review
          </LinkButton>
        )}
      </div>
    </div>
  );

  const getCurrentStages = () => {
    const data = currentData?.items[0] ?? null;
    const breakdown = data
      ? [
          {
            name: 'On Hand',
            ...data.onHand,
            rateOfChange: null,
          },
          {
            name: 'Allocated',
            ...data.allocated,
            rateOfChange: null,
          },
          {
            name: 'On Order',
            ...data.onOrder,
            rateOfChange: null,
          },
          {
            name: 'Backordered',
            ...data.backordered,
            rateOfChange: null,
          },
          {
            name: 'In Process',
            ...data.inProcess,
            rateOfChange: null,
          },
          {
            name: 'In Transit',
            ...data.inTransit,
            rateOfChange: null,
          },
        ]
      : [
          {
            name: 'On Hand',
            value: 0,
            volume: 0,
            rateOfChange: null,
          },
          {
            name: 'Allocated',
            value: 0,
            volume: 0,
            rateOfChange: null,
          },
          {
            name: 'On Order',
            value: 0,
            volume: 0,
            rateOfChange: null,
          },
          {
            name: 'Backordered',
            value: 0,
            volume: 0,
            rateOfChange: null,
          },
          {
            name: 'In Process',
            value: 0,
            volume: 0,
            rateOfChange: null,
          },
          {
            name: 'In Transit',
            value: 0,
            volume: 0,
            rateOfChange: null,
          },
        ];
    return breakdown;
  };

  // Take ISO date string anf consistently format it
  const formatChartDate = (dateStr: string) => moment(dateStr).add(1, 'day').format('MMM YY');

  const statusNameToStatusFilter = (name: string) => {
    if (name.toLowerCase().includes('normal')) return InventoryDashboardStatus.Normal;
    if (name.toLowerCase().includes('over')) return InventoryDashboardStatus.Overstock;
    if (name.toLowerCase().includes('dead')) return InventoryDashboardStatus.Deadstock;

    return InventoryDashboardStatus.Normal;
  };

  const toggleMax = (value: boolean) => {
    updateHashState({ useRecommendedMax: value });
    setLocalStorageRecommendedMaxEnabled(value);
  };

  const getMinMaxItemsToReviewCount = (minMaxData: SearchResponse<SearchIndexMinMax>) =>
    minMaxData.facets.recurrency_replenishment_method
      ?.filter(
        (element, _) =>
          element.value === RecurrencyReplenishmentMethod.MinMax ||
          (!onboardingPageEnabled && element.value === RecurrencyReplenishmentMethod.Unsupported),
      )
      .reduce((total, facet) => total + facet.count ?? 0, 0) ?? 0;

  // if Everyting errors, block the page
  if (historicalDataError && currentDataError && purchaseTargetError && minMaxError && onboardingError) {
    return (
      <CenteredError
        error={historicalDataError ?? currentDataError ?? purchaseTargetError ?? minMaxError ?? onboardingError}
      />
    );
  }

  function goToExplorer(
    type: 'current' | 'history',
    filters: InventoryDashboardHashStateFilters | undefined,
    chartConfig?: DrillDownChartConfigItem,
  ) {
    const path =
      type === 'current'
        ? routes.demandPlanning.inventoryStatusExplorer({
            filters: filters ?? {},
            page: 1,
            displayUnit: hashState.displayUnit,
            sortBy: [['onHandValue', SortDirection.Desc]],
          })
        : routes.demandPlanning.inventoryHistoryExplorer({
            ...chartConfig?.hashStateConfig,
            filters: filters ?? {},
          } as InventoryDashboardDetailsHashState);

    history.push(path);
  }

  const HistoricalCardHeader = ({ chartConfig }: { chartConfig: DrillDownChartConfigItem }) => (
    <CardHeader
      title={chartConfig.title}
      description={chartConfig.description}
      useTooltip
      className={css`
        cursor: pointer;
      `}
      onClick={() => goToExplorer('history', hashState.filters ?? {}, chartConfig)}
      headerActions={<ExploreButton />}
    />
  );

  return (
    <div
      className={css`
        .grid {
          margin-bottom: 18px;
        }
        .ant-select-arrow {
          color: ${theme.colors.neutral[400]};
        }
      `}
    >
      <FlexSpace
        gap={8}
        className={css`
          margin-bottom: 12px;
        `}
      >
        <div>View </div>
        <AsyncMultiSelect
          mode="single"
          icon={<CalendarOutlined />}
          selectProps={{ options: timeSpanSelectOptions }}
          selectedValues={[hashState.filters?.timeSpan ?? InventoryDashboardTimeFilters['12M']]}
          onSelectedValuesChange={(values) => {
            onTimeSpanFilterChange(values[0] as InventoryDashboardTimeFilters);
          }}
        />
        <div style={{ paddingLeft: '4px' }}>measured in</div>
        <DashboardUnitSelect
          state={hashState.displayUnit}
          onChange={(value) => updateHashState({ displayUnit: value })}
        />
      </FlexSpace>
      <InventoryDashboardFilters
        label="Filter"
        filterState={hashState.filters}
        onFiltersChange={onHashStateFilterChange}
        showStockableFilter
        showBuyerFilter={getTenantSetting(TenantSettingKey.UiShowBuyerFilter)}
        showPurchaseClassFilter={!isTenantErpTypeNetSuite(activeTenant.erpType)}
        showProductGroupFilter={!isTenantErpTypeNetSuite(activeTenant.erpType)}
      />
      <DividerLine />
      <Row gutter={18} wrap={isDesktop ? false : undefined}>
        <Col
          flex={isDesktop ? `0 1 432px` : undefined}
          span={isDesktop ? undefined : `18`}
          className={css`
            margin-bottom: 24px;
          `}
        >
          <Grid colMin="1fr">
            <Card style={{ minWidth: '435px' }}>
              <CardHeader
                title="Alerts"
                description="Actions to be taken in Recurrency across the entire company"
                useTooltip
              />
              {!minMaxIsLoading && minMaxData && (
                <CardSection>
                  <AlertRow
                    title={`${formatNumber(
                      minMaxData.facets.recurrency_replenishment_method?.find(
                        (element, _) => element.value === RecurrencyReplenishmentMethod.OPOQ,
                      )?.count ?? 0,
                    )} Items`}
                    description="require updates to OP/OQ"
                    actionUrl={routes.demandPlanning.planning({
                      where: {
                        planning_status: ['Review'],
                        recurrency_replenishment_method: [RecurrencyReplenishmentMethod.OPOQ],
                      },
                    })}
                  />
                </CardSection>
              )}
              {!minMaxIsLoading && minMaxData && (
                <CardSection>
                  <AlertRow
                    title={`${formatNumber(getMinMaxItemsToReviewCount(minMaxData))} Items`}
                    description="require updates to Min/Max"
                    actionUrl={routes.demandPlanning.planning({ where: { planning_status: ['Review'] } })}
                  />
                </CardSection>
              )}
              {!purchaseTargetLoading && purchaseTargetData && (
                <CardSection>
                  <AlertRow
                    title={`${formatNumber(purchaseTargetData.totalCount)} Purchase Orders`}
                    description="ready to purchase"
                    actionUrl={routes.purchasing.purchaseTargets({ status: PurchaseTargetStatus.Ready })}
                  />
                </CardSection>
              )}
              {onboardingPageEnabled && !onboardingLoading && onboardingData && (
                <CardSection>
                  <AlertRow
                    title={`${formatNumber(onboardingData.nbHits ?? 0)} Items`}
                    description="to set up"
                    actionUrl={routes.demandPlanning.onboarding()}
                  />
                </CardSection>
              )}
            </Card>
            <Card>
              <CardHeader
                title="Status"
                description="Breakdown of total on hand inventory by the amount of dead stock, overstock, and normal stock"
                useTooltip
                className={css`
                  cursor: pointer;
                `}
                onClick={() => goToExplorer('current', hashState.filters)}
                headerActions={<ExploreButton />}
              />
              <CardSection
                className={css`
                  min-height: 200px;
                  align-items: center;
                `}
              >
                {currentDataIsLoading ? (
                  <CardLoader height={200} />
                ) : (
                  <>
                    <PieChart width={400} height={200}>
                      <Legend
                        align="right"
                        layout="vertical"
                        iconType="circle"
                        verticalAlign="middle"
                        content={RenderLegend}
                        formatter={(value: string) => (formatVolume ? formatNumber(value, 0, 0) : formatUSD(value))}
                      />
                      <Pie
                        data={[{ none: 1 }]}
                        cx="40%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={90}
                        fill={theme.colors.neutral[200]}
                        dataKey="none"
                      />
                      <Pie
                        data={getCurrentStockLevels()}
                        cx="40%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={90}
                        dataKey={hashState.displayUnit}
                      >
                        {getCurrentStockLevels().map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            cursor="pointer"
                            onClick={() =>
                              goToExplorer('current', {
                                ...hashState.filters,
                                status: [statusNameToStatusFilter(entry.name)],
                              })
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip
                        formatter={(value) => (formatVolume ? formatNumber(Number(value)) : formatUSD(Number(value)))}
                        content={(e: TooltipProps) => {
                          const payload = e.payload?.filter((payloadx) => payloadx.name !== 'connection');
                          if (e.active && payload && payload.length) {
                            const payloadData = payload[0];
                            return (
                              <div
                                className={css`
                                  background-color: rgba(255, 255, 255, 0.9);
                                  font-weight: 500;
                                  border-radius: 8px;
                                  border: 2px solid ${payloadData.payload.fill};
                                  box-shadow: 0px 0px 2px 0px ${theme.colors.neutral[400]};
                                `}
                              >
                                <div
                                  className={css`
                                    padding: 6px;
                                  `}
                                >
                                  <Typography type="subtitle" style={{ fontWeight: 500 }}>
                                    {payloadData.payload.name}
                                  </Typography>
                                  <Typography type="subtitle" style={{ fontWeight: 500 }}>
                                    {formatVolume
                                      ? formatNumber(Number(payloadData.value), 0)
                                      : formatUSD(Number(payloadData.value))}
                                  </Typography>
                                </div>
                              </div>
                            );
                          }

                          return null;
                        }}
                      />
                    </PieChart>
                    <RecommendedMaxToggle
                      isChecked={hashState.useRecommendedMax ?? false}
                      onToggle={toggleMax}
                      labelCssStyle={css`
                        margin-right: 8px;
                        margin-left: 14px;
                        font-weight: 500;
                      `}
                      tooltipPlacement="bottom"
                    />
                  </>
                )}
              </CardSection>
            </Card>
            <Card>
              <CardHeader
                title="Inventory Breakdown"
                description="Breakdown of current inventory by stage"
                useTooltip
                className={css`
                  cursor: pointer;
                `}
                onClick={() => goToExplorer('current', hashState.filters)}
                headerActions={<ExploreButton />}
              />
              {currentDataIsLoading ? (
                <CardSection>
                  <CardLoader height={200} />
                </CardSection>
              ) : (
                <>
                  {getCurrentStages().map((stage, index) => (
                    <CardSection
                      key={`${stage.name}-${index}`}
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div style={{ display: 'inline-block' }}>{stage.name}</div>
                      <div style={{ display: 'inline-block' }}>
                        {formatVolume ? formatNumber(stage.volume, 0, 0) : formatUSD(stage.value)}
                      </div>
                    </CardSection>
                  ))}
                </>
              )}
            </Card>
          </Grid>
        </Col>
        <Col flex={isDesktop ? 'auto' : undefined} span={isDesktop ? undefined : 24} style={{ minWidth: '650px' }}>
          <Grid gap={18} className="grid">
            <Card>
              <HistoricalCardHeader chartConfig={DrillDownChartConfig.inventory} />
              <CardSection>
                {historicalDataIsLoading || !historicalData?.items ? (
                  <CardLoader height={400} />
                ) : historicalDataError ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                  <InventoryDashboardAreaChart
                    data={historicalData?.items.map((record) => ({
                      date: formatChartDate(record.date),
                      value: formatVolume ? record.inventoryVolume : record.inventoryValue,
                      units: formatVolume ? '' : '$',
                      type: 'historical',
                    }))}
                    containerHeights={{ height: 400 }}
                    themeChartColor="sunriseYellow"
                    onClick={() => goToExplorer('history', hashState.filters, DrillDownChartConfig.inventory)}
                    yAxisProps={{
                      dataKey: 'value',
                      tickFormatter: (value) =>
                        formatVolume
                          ? formatNumber(value, 2, undefined, { notation: 'compact', useGrouping: true })
                          : formatUSDAbbreviated(value, 0),
                    }}
                    tooltip={{
                      cursor: false,
                      formatter: (value) =>
                        formatVolume ? formatNumber(Number(value), 0, 0) : formatUSD(Number(value)),
                      labelFormatter: (value) => formatDateShorthand(String(value)),
                    }}
                  />
                )}
              </CardSection>
            </Card>
          </Grid>
          <Grid colMin="360px" colMax="2fr" gap={18} className="grid">
            <Card>
              <HistoricalCardHeader chartConfig={DrillDownChartConfig.sales} />
              <CardSection>
                {historicalDataIsLoading || !historicalData?.items ? (
                  <CardLoader height={200} />
                ) : historicalDataError ? (
                  <Empty />
                ) : (
                  <InventoryDashboardAreaChart
                    data={historicalData.items.map((record) => ({
                      date: formatChartDate(record.date),
                      value: formatVolume ? record.salesVolume : record.sales,
                      units: formatVolume ? '' : '$',
                    }))}
                    onClick={() => goToExplorer('history', hashState.filters, DrillDownChartConfig.sales)}
                    containerHeights={{ minHeight: 200, maxHeight: 400 }}
                    themeChartColor="sunsetPurple"
                    yAxisProps={{
                      dataKey: 'value',
                      tickFormatter: (value) =>
                        formatVolume
                          ? formatNumber(value, 0, 0, {
                              notation: 'compact',
                            })
                          : formatUSDAbbreviated(value),
                    }}
                    tooltip={{
                      cursor: false,
                      formatter: (value) => (formatVolume ? formatNumber(Number(value)) : formatUSD(Number(value))),
                      labelFormatter: (value) => formatDateShorthand(String(value)),
                    }}
                    size="small"
                  />
                )}
              </CardSection>
            </Card>
            <Card>
              <HistoricalCardHeader chartConfig={DrillDownChartConfig.turns} />
              <CardSection>
                {historicalDataIsLoading || !historicalData?.items ? (
                  <CardLoader height={200} />
                ) : historicalDataError ? (
                  <Empty />
                ) : (
                  <InventoryDashboardAreaChart
                    data={historicalData.items.map((record) => ({
                      date: formatChartDate(record.date),
                      value: record.turns,
                      units: '',
                    }))}
                    onClick={() => goToExplorer('history', hashState.filters, DrillDownChartConfig.turns)}
                    containerHeights={{ minHeight: 200, maxHeight: 400 }}
                    themeChartColor="aloeGreen"
                    yAxisProps={{
                      dataKey: 'value',
                      tickFormatter: (value) => formatNumber(Number(value), 2, 2),
                    }}
                    tooltip={{
                      cursor: false,
                      formatter: (value) => formatNumber(Number(value), 2, 2, { notation: 'compact' }),
                      labelFormatter: (value) => formatDateShorthand(String(value)),
                    }}
                    size="small"
                  />
                )}
              </CardSection>
            </Card>
            {activeTenant.erpType === 'p21' && (
              <Card>
                <HistoricalCardHeader chartConfig={DrillDownChartConfig.backorders} />
                <CardSection>
                  {historicalDataIsLoading || !historicalData?.items ? (
                    <CardLoader height={200} />
                  ) : historicalDataError ? (
                    <Empty />
                  ) : (
                    <InventoryDashboardAreaChart
                      data={historicalData.items.map((record) => ({
                        date: formatChartDate(record.date),
                        value: formatVolume ? record.backorderVolume : record.backorderValue,
                        units: formatVolume ? '' : '$',
                      }))}
                      onClick={() => goToExplorer('history', hashState.filters, DrillDownChartConfig.backorders)}
                      containerHeights={{ minHeight: 200, maxHeight: 400 }}
                      themeChartColor="malibuOrange"
                      yAxisProps={{
                        dataKey: 'value',
                        tickFormatter: (value) =>
                          formatVolume
                            ? formatNumber(value, 0, 0, {
                                notation: 'compact',
                              })
                            : formatUSDAbbreviated(value),
                      }}
                      tooltip={{
                        cursor: false,
                        formatter: (value) => (formatVolume ? formatNumber(Number(value)) : formatUSD(Number(value))),
                        labelFormatter: (value) => formatDateShorthand(String(value)),
                      }}
                      size="small"
                    />
                  )}
                </CardSection>
              </Card>
            )}
            <Card>
              <HistoricalCardHeader chartConfig={DrillDownChartConfig.grossMarginROI} />
              <CardSection>
                {historicalDataIsLoading || !historicalData?.items ? (
                  <CardLoader height={200} />
                ) : historicalDataError ? (
                  <Empty />
                ) : (
                  <InventoryDashboardAreaChart
                    data={historicalData.items.map((record) => ({
                      date: formatChartDate(record.date),
                      value: record.grossMarginROI,
                      units: '$',
                    }))}
                    onClick={() => goToExplorer('history', hashState.filters, DrillDownChartConfig.grossMarginROI)}
                    containerHeights={{ minHeight: 200, maxHeight: 400 }}
                    themeChartColor="oceanTeal"
                    yAxisProps={{
                      dataKey: 'value',
                      tickFormatter: (value) => formatUSDAbbreviated(value),
                    }}
                    tooltip={{
                      cursor: false,
                      formatter: (value) => formatUSDAbbreviated(Number(value)),
                      labelFormatter: (value) => formatDateShorthand(String(value)),
                    }}
                    size="small"
                  />
                )}
              </CardSection>
            </Card>
            <Card>
              <HistoricalCardHeader chartConfig={DrillDownChartConfig.fillRate} />
              <CardSection>
                {historicalDataIsLoading || !historicalData?.items ? (
                  <CardLoader height={200} />
                ) : historicalDataError ? (
                  <Empty />
                ) : (
                  <InventoryDashboardAreaChart
                    data={historicalData.items.map((record) => ({
                      date: formatChartDate(record.date),
                      value: record.fillRate ?? 100,
                      units: '',
                    }))}
                    onClick={() => goToExplorer('history', hashState.filters, DrillDownChartConfig.fillRate)}
                    containerHeights={{ minHeight: 200, maxHeight: 400 }}
                    themeChartColor="oceanTeal"
                    yAxisProps={{
                      dataKey: 'value',
                      tickFormatter: (value) => formatNumber(value, 0),
                      unit: '%',
                    }}
                    tooltip={{
                      cursor: false,
                      formatter: (value) => formatPercent(Number(value) / 100),
                      labelFormatter: (value) => formatPercent(Number(value) / 100),
                    }}
                    size="small"
                  />
                )}
              </CardSection>
            </Card>
          </Grid>
        </Col>
      </Row>
    </div>
  );
};
