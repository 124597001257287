/**
 * We often switch on frontend for tenant specific functionality to release an alpha/beta feature.
 * Please don't abuse this, ideally we use tenant feature flags for switching,
 * Use the constants instead of hardcoding tenantIds throughout the app.
 */

import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';

export const tenantIds = {
  prod: {
    arnoldpackaging: '64a72f72-b1a5-4ca4-81dc-d77d9f9bdb28',
    azparts: '0f057d85-af33-4f29-930e-9cb2491abd63',
    behlerYoung: 'bc58d413-4e57-4bf1-93de-93a1c9a1ae78',
    cwc: 'd8aa8977-7e0b-4cb3-8659-d1024a141b3f',
    eoh: 'bfd6ad79-8df5-4af6-9287-cd30c2e52c30',
    galarson: '955341f3-7dc9-40e7-95c7-67b85a37d46c',
    gtire: '74e763ef-1a52-488b-9dfd-8bdde6675db8',
    hoff: '511fa1ab-5fb7-4296-8182-478c63f77425',
    ims: 'c5b9619e-6c9e-432f-92ec-7ec31f2f2b68',
    pvfind: '4bbe73ff-fc20-4b46-986b-c972159b90e4',
    safeware: '4a709767-9354-4dca-9e09-a6d39d697185',
    richards_supply: '9d177f29-14ff-4b44-8741-e549530a6ce8',
    heitek: '22f90b84-b653-4226-b8b6-e33d7ffd5b0a',
    seafoodsales: 'dfa4b664-9945-4a9d-9304-6597c5d27dbe',
    miamicorp: '94074a00-36b3-4621-acaa-1f37de76b084',
    bricos: 'ca45cdea-7e97-456a-8de0-6115c3c62228',
    paceinternational: '00e87877-f917-4810-997c-93c60c4abc5c',
    chariotplumbingsupply: '607abb85-0293-4071-8258-26dd3e828755',
    zeiglers: '538976b2-293e-4f51-bcb4-2a652223f3ac',
  },
  staging: {
    cwcplay: '12c83b51-7ca6-4fde-8f9c-0aea27b37d75',
    cwc: 'd9d4b3c7-c1e1-45ae-b803-92c25f4001b4',
    ims: '9054965b-cb46-45bc-a51d-62617a15c77e',
    galarson: 'd7237b40-4d3d-473e-84b3-a7f13d028e69',
    gtire: 'a9a3e975-1b65-4c1b-9926-273e5d28331f',
    seafoodsales: 'de14cd2e-c665-445e-aad0-21b3aa2873ab',
    miamicorp: 'f4793842-ff7e-4042-871b-94b49b79f95e',
  },
};

export function isTenantArnoldPackaging(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.arnoldpackaging;
}

export function isTenantBehlerYoung(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.behlerYoung;
}

export function isTenantIMS(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.ims || activeTenantId === tenantIds.staging.ims;
}

export function isTenantCWC(activeTenantId: string): boolean {
  return (
    activeTenantId === tenantIds.prod.cwc ||
    activeTenantId === tenantIds.staging.cwc ||
    activeTenantId === tenantIds.staging.cwcplay
  );
}

export function isTenantSafeware(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.safeware;
}

export function isTenantPVFIndustrialSupply(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.pvfind;
}

export function isTenantGALarson(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.galarson || activeTenantId === tenantIds.staging.galarson;
}

export function isTenantGallagherTire(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.gtire || activeTenantId === tenantIds.staging.gtire;
}

export function isTenantRichardsSupply(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.richards_supply;
}

export function isTenantHeitek(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.heitek;
}

export function isTenantSeafoodSales(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.seafoodsales || activeTenantId === tenantIds.staging.seafoodsales;
}

export function isTenantMiamiCorp(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.miamicorp || activeTenantId === tenantIds.staging.miamicorp;
}

export function isTenantBricos(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.bricos;
}

export function isTenantPaceInternational(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.paceinternational;
}

export function isTenantChariotPlumbingSupply(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.chariotplumbingsupply;
}
export function isTenantZeiglers(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.zeiglers;
}
export function isTenantEOH(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.eoh;
}

export function isTenantErpTypeP21(erpType: IntegratedErps): boolean {
  return erpType === IntegratedErps.P21;
}

export function isTenantErpTypeSAPB1(erpType: IntegratedErps): boolean {
  return erpType === IntegratedErps.SAPB1;
}

export function isTenantErpTypeNetSuite(erpType: IntegratedErps): boolean {
  return erpType === IntegratedErps.NETSUITE;
}
