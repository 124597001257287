import React, { ReactNode } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

import { ActionButton } from 'components/Button/ActionButton';

import { showAsyncModal } from 'utils/asyncModal';

import { SupplierLocationSettingsModal, SupplierLocationTargetSettings } from './SupplierLocationSettingsModal';

export const EditSupplierLocationTargetActionButton = ({
  value,
  locationId,
  supplierId,
  onChange,
}: {
  value: ReactNode;
  supplierId: string;
  locationId: string;
  onChange: (newSettings: SupplierLocationTargetSettings) => void;
}) => (
  <div
    className={css`
      display: inline-flex;
      align-items: center;
      gap: 4px;
    `}
  >
    {value}
    <ActionButton
      label={<EditOutlined />}
      onClick={async () => {
        const newSettings = await showAsyncModal(SupplierLocationSettingsModal, {
          supplierId,
          locationId,
        });
        if (newSettings) {
          onChange(newSettings);
        }
      }}
    />
  </div>
);
