import moment from 'moment';

// getNextUsageUpdateDate: returns the number of days until the next update on the 1st, 8th, 15th, 22nd, or 29th of the month
export const getNextUsageUpdateDate = () => {
  const today = moment();
  const updateDays = [1, 8, 15, 22, 29];
  const currentDay = today.date();
  let nextUpdateDay = updateDays.find((day) => day > currentDay);

  // If there's no next update day this month, use the first update day of the next month
  if (!nextUpdateDay) {
    [nextUpdateDay] = updateDays;
    today.add(1, 'month'); // Move to next month
  }

  // Calculate the next update date
  const nextUpdateDate = moment(today).date(nextUpdateDay);
  const daysUntilNextUpdate = nextUpdateDate.diff(moment(), 'days') + 1;

  return Math.max(0, daysUntilNextUpdate);
};
