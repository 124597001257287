import React, { useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';

import {
  AppstoreOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  DownOutlined,
  EnvironmentOutlined,
  ImportOutlined,
  MonitorOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  StockOutlined,
  StopOutlined,
  TagOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { PlanningHubAndSpokeType, RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Empty, Menu } from 'antd';

import { changeRecordExcludedStatus } from 'pages/purchasing/PlanningPage/modals/IncludeExcludeStatusUpdater';
import { MultiLeadTimeModal } from 'pages/purchasing/PlanningPage/modals/MultiLeadTimeModal';
import { MultiSafetyStockModal } from 'pages/purchasing/PlanningPage/modals/MultiSafetyStockModal';
import { RecommendedMaxPopover } from 'pages/purchasing/PlanningPage/modals/RecommendedMaxPopover';
import { RecommendedMinPopover } from 'pages/purchasing/PlanningPage/modals/RecommendedMinPopover';
import { UpdateReplenishmentSettingsModal } from 'pages/purchasing/PlanningPage/modals/UpdateReplenishmentSettingsModal';
import { MultiModalSelection } from 'pages/purchasing/PlanningPage/modals/utils';
import { PlanningSearchFrameHeader } from 'pages/purchasing/PlanningPage/PlanningSearchFrameHeader';
import { PlanningSidePane } from 'pages/purchasing/PlanningPage/PlanningSidePane';
import { ReplenishmentPathPopover } from 'pages/purchasing/PlanningPage/ReplenishmentPathPopover';
import { demandPatternOptions, demandPredictabilityOptions } from 'pages/purchasing/PlanningPage/utils';
import { ReplenishmentMethod } from 'pages/purchasing/utils';

import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Dropdown } from 'components/Dropdown';
import { LastUpdatedMomentForecast } from 'components/recipes/LastUpdatedMoment';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { FacetRenderType, SearchFrameContext } from 'components/recipes/SearchFrame/types';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { DemandPatternTooltip } from 'components/Tooltip/DemandPatternTooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { formatNumber } from 'utils/formatting';
import { routes } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { asKeyOf, sortDirections } from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';

import { SearchIndexMinMax } from 'types/search-collections';

import { OnboardingProgress } from './OnboardingProgress';

export const getColumns = (config: {
  isPlanningHubAndSpoke: boolean;
  isP21: boolean;
  searchIndexReload?: () => void;
}): (PersistedColumn<SearchIndexMinMax> | null)[] => [
  {
    title: 'Item',
    settingKey: 'itemId',
    dataIndex: asKeyOf<SearchIndexMinMax>('item_id'),
    fixed: 'left',
    sorter: true,
    sortDirections,
    render: (_: string, record) => (
      <div>
        <Link to={routes.purchasing.itemDetails(record.item_id)}>{record.item_id}</Link>
        <div>{record.item_name}</div>
      </div>
    ),
    exportValue: {
      'Item ID': asKeyOf<SearchIndexMinMax>('item_id'),
      'Item Name': asKeyOf<SearchIndexMinMax>('item_name'),
    },
  },
  {
    title: `${config.isPlanningHubAndSpoke ? 'Requirement' : ''} Location`,
    dataIndex: asKeyOf<SearchIndexMinMax>('location'),
    settingKey: 'location',
    fixed: 'left',
    sorter: config.isPlanningHubAndSpoke,
  },
  config.isPlanningHubAndSpoke
    ? {
        title: <InfoTooltip title="The location where this item is sourced from">Replenishment Location</InfoTooltip>,
        dataIndex: asKeyOf<SearchIndexMinMax>('replenishment_location'),
        settingKey: 'replenishmentLocation',
        fixed: 'left',
        sorter: true,
        render: (replenishmentLocation, record) => (
          <ConditionalWrapper
            condition={
              record.location !== record.replenishment_location && record.replenishment_location.trim() !== ':'
            }
            wrapper={(children) => (
              <ReplenishmentPathPopover
                itemId={record.item_uid}
                location={record.location}
                supplier={record.primary_supplier}
              >
                {children}
              </ReplenishmentPathPopover>
            )}
          >
            {replenishmentLocation}
          </ConditionalWrapper>
        ),
      }
    : null,
  config.isP21
    ? {
        title: 'ABC Class',
        settingKey: 'purchaseClass',
        dataIndex: asKeyOf<SearchIndexMinMax>('purchase_class'),
        sorter: true,
        sortDirections,
      }
    : null,
  {
    title: 'Current Replenishment Method',
    settingKey: 'replenishmentMethod',
    dataIndex: asKeyOf<SearchIndexMinMax>(config.isP21 ? 'replenishment_method' : 'recurrency_replenishment_method'),
  },
  {
    title: <b>Recommended</b>,
    settingKey: 'recommended',
    children: [
      {
        exportTitle: 'Recommended Minimum',
        title: 'Min',
        dataIndex: asKeyOf<SearchIndexMinMax>('recommended_min'),
        align: 'center',
        render: (recommendedMin, record) => (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            {formatNumber(recommendedMin)}
            <RecommendedMinPopover record={record} />
          </div>
        ),
      },
      {
        exportTitle: 'Recommended Maximum',
        title: 'Max',
        dataIndex: asKeyOf<SearchIndexMinMax>('recommended_max'),
        align: 'center',
        render: (recommendedMax, record) => (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            {formatNumber(recommendedMax)} <RecommendedMaxPopover record={record} />
          </div>
        ),
      },
    ],
  },

  {
    title: 'UOM',
    settingKey: 'uom',
    dataIndex: asKeyOf<SearchIndexMinMax>('unit_of_measure'),
  },
  {
    title: <b>Demand</b>,
    settingKey: 'demand',
    children: [
      {
        title: (
          <InfoTooltip title="Demand patterns group items based on consistency of orders and variance in demand quantity to forecast in the optimal manner">
            Pattern
          </InfoTooltip>
        ),
        dataIndex: asKeyOf<SearchIndexMinMax>('demand_pattern'),
        render: (demandPattern) => <DemandPatternTooltip demandPattern={demandPattern} />,
      },
      // using forecast_confidence as field for sorting, but showing predictability_tag in UI
      {
        title: (
          <InfoTooltip title="Estimated predictability of an item based on historical trends. Higher predictability leads to more accurate forecasts">
            Predictability
          </InfoTooltip>
        ),
        dataIndex: asKeyOf<SearchIndexMinMax>('forecast_confidence'),
        defaultSortOrder: 'descend' as const,
        sorter: true,
        sortDirections,
        render: (_, record) => record.predictability_tag,
      },
    ],
  },
  // this is an exportOnly field Predictability in UI uses 'forecast_confidence' as the field
  {
    title: 'Predictability Tag',
    settingKey: 'predictabilityTag',
    dataIndex: asKeyOf<SearchIndexMinMax>('predictability_tag'),
    optional: true,
  },
  {
    title: 'Status',
    settingKey: 'status',
    render: () => <StatusBadge status={BadgeStatus.Review} labelText="Needs Setup" />,
  },
  {
    title: 'Actions',
    settingKey: 'actions',
    render: (_, record) => (
      <div
        className={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <Button
          type="primary"
          size="small"
          className={css`
            margin-right: 4px;
          `}
          onClick={() => {
            showAsyncModal(UpdateReplenishmentSettingsModal, {
              record,
              isOnboardingVariant: true,
              searchIndexReload: config.searchIndexReload,
            });
          }}
        >
          <ToolOutlined /> Set Up
        </Button>

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="safetyStock"
                icon={<ReconciliationOutlined />}
                onClick={() => {
                  showAsyncModal(MultiSafetyStockModal, {
                    selection: { type: 'list', items: [record], count: 1 },
                    searchIndexReload: config.searchIndexReload,
                  });
                }}
              >
                Safety Stock
              </Menu.Item>
              <Menu.Item
                key="leadTime"
                icon={<CalendarOutlined />}
                onClick={() => {
                  showAsyncModal(MultiLeadTimeModal, {
                    selection: { type: 'list', items: [record], count: 1 },
                    searchIndexReload: config.searchIndexReload,
                  });
                }}
              >
                Lead Time
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="exclude"
                icon={record.planning_status === BadgeStatus.Excluded ? <CheckCircleOutlined /> : <StopOutlined />}
                onClick={async () => {
                  const shouldExclude = record.planning_status !== BadgeStatus.Excluded;
                  const selection: MultiModalSelection = {
                    type: 'list',
                    items: [record],
                    count: 1,
                  };
                  const didChange = await changeRecordExcludedStatus(selection, shouldExclude);
                  if (didChange && config.searchIndexReload) config.searchIndexReload();
                }}
              >
                {record.planning_status === BadgeStatus.Excluded ? 'Include' : 'Exclude'}
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            size="small"
            className={css`
              width: 50px;
            `}
          >
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    ),
  },
  // download only fields
  {
    title: 'Safety Stock (days)',
    settingKey: 'safetyStockDays',
    dataIndex: asKeyOf<SearchIndexMinMax>('safety_stock_value'),
    optional: true,
  },
  {
    title: 'Safety Stock Source',
    settingKey: 'safetyStockSource',
    dataIndex: asKeyOf<SearchIndexMinMax>('safety_stock_type'),
    optional: true,
  },
  {
    title: 'Lead Time (days)',
    settingKey: 'leadTimeDays',
    dataIndex: asKeyOf<SearchIndexMinMax>('lead_time'),
    optional: true,
  },
  {
    title: 'Lead Time Source',
    settingKey: 'leadTimesSource',
    dataIndex: asKeyOf<SearchIndexMinMax>('lead_time_source'),
    optional: true,
  },
];

export const getValueFacets = (config: {
  isPlanningHubAndSpoke: boolean;
  isP21: boolean;
}): (ValueFacet<SearchIndexMinMax> | null)[] => [
  config.isP21
    ? {
        title: 'Replenishment Method',
        field: asKeyOf<SearchIndexMinMax>('replenishment_method'),
        renderType: FacetRenderType.Dropdown,
        icon: <ImportOutlined />,
        hidden: true,
        defaultValue: [ReplenishmentMethod.EOQ, ReplenishmentMethod.UpTo],
      }
    : {
        hidden: true,
        field: asKeyOf<SearchIndexMinMax>('recurrency_replenishment_method'),
        defaultValue: RecurrencyReplenishmentMethod.Unsupported,
      },
  {
    title: `${config.isPlanningHubAndSpoke ? 'Requirement ' : ''}Location`,
    field: asKeyOf<SearchIndexMinMax>('location'),
    queryPlaceholder: `Search ${config.isPlanningHubAndSpoke ? 'requirement ' : ''}locations`,
    icon: <EnvironmentOutlined />,
  },
  config.isPlanningHubAndSpoke
    ? {
        title: 'Replenishment Location',
        field: asKeyOf<SearchIndexMinMax>('replenishment_location'),
        queryPlaceholder: 'Search replenishment locations',
        icon: <EnvironmentOutlined />,
      }
    : null,
  config.isP21
    ? {
        title: 'ABC Class',
        field: asKeyOf<SearchIndexMinMax>('purchase_class'),
        sortBy: 'alpha',
        icon: <TagOutlined />,
      }
    : null,
  {
    title: 'Primary Supplier',
    field: asKeyOf<SearchIndexMinMax>('primary_supplier'),
    queryPlaceholder: 'Search primary suppliers',
    icon: <ShopOutlined />,
  },
  config.isP21
    ? {
        title: 'Vendor',
        field: asKeyOf<SearchIndexMinMax>('primary_vendor'),
        queryPlaceholder: 'Search primary vendors',
        icon: <ShopOutlined />,
      }
    : null,
  {
    title: 'Product Group',
    field: asKeyOf<SearchIndexMinMax>('item_group'),
    queryPlaceholder: 'Search product groups',
    icon: <AppstoreOutlined />,
  },
  getTenantSetting(TenantSettingKey.UiShowBuyerFilter)
    ? {
        title: 'Buyer',
        field: asKeyOf<SearchIndexMinMax>('buyer'),
        queryPlaceholder: 'Search buyers',
        icon: <UserOutlined />,
      }
    : null,
  {
    title: 'Demand Pattern',
    field: asKeyOf<SearchIndexMinMax>('demand_pattern'),
    icon: <StockOutlined />,
    options: demandPatternOptions,
    sortBy: 'none',
  },
  {
    title: 'Predictability',
    field: asKeyOf<SearchIndexMinMax>('predictability_tag'),
    icon: <MonitorOutlined />,
    options: demandPredictabilityOptions,
    sortBy: 'none',
  },
];

export const OnboardingPage = () => {
  const { activeTenant } = useGlobalApp();
  const searchFrameContextRef = useRef<SearchFrameContext<SearchIndexMinMax>>();

  const isP21 = isTenantErpTypeP21(activeTenant.erpType);
  const planningHubAndSpokeSetting = getTenantSetting(TenantSettingKey.FeaturePlanningHubAndSpoke);
  const isPlanningHubAndSpoke =
    planningHubAndSpokeSetting === PlanningHubAndSpokeType.Flexible ||
    planningHubAndSpokeSetting === PlanningHubAndSpokeType.Rigid;

  const columns = getColumns({
    isPlanningHubAndSpoke,
    isP21,
  });

  const valueFacets = getValueFacets({
    isPlanningHubAndSpoke,
    isP21,
  });

  return (
    <SearchFrame<SearchIndexMinMax>
      title={
        <InfoTooltip title="Set up items to use Recurrency by moving them from their old Replenishment methods onto using Recurrency's dynamic Min/Max">
          Item Setup
        </InfoTooltip>
      }
      headerDescription={
        <>
          <LastUpdatedMomentForecast includeForecastPrefix />
          <OnboardingProgress />
          <Alert
            className={css`
              margin-top: 10px;
            `}
            message={`Set up items to use Recurrency by moving from their old replenishment methods onto Recurrency's dynamically calculated Min/Max. As items are set up to use Recurrency, they will then be reviewed in the Planning page.`}
            banner
            type="info"
            onClose={() => {}}
          />
        </>
      }
      indexName={SearchIndexName.Planning}
      queryPlaceholder="Search by item"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      tableColumnsSettingKey={ViewSettingKey.PlanningOnboardingTable}
      sidePane={(props) => <PlanningSidePane {...props} isOnboardingVariant />}
      tableRowSelection="selectAll"
      contextRef={searchFrameContextRef}
      headerActions={(searchFrameContext) => (
        <PlanningSearchFrameHeader
          valueFacets={valueFacets}
          searchFrameContext={searchFrameContext}
          isOnboardingVariant
        />
      )}
      locale={{
        emptyText: <EmptyText />,
        notFoundText: <EmptyText useNotFoundText />,
      }}
    />
  );
};

function EmptyText({ useNotFoundText = false }: { useNotFoundText?: boolean }) {
  const history = useHistory();

  const planningPageLink = (
    <ActionButton
      className={css`
        display: inline;
      `}
      label="planning page"
      onClick={() => {
        history.push(routes.demandPlanning.planning());
      }}
    />
  );

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <div>
          {useNotFoundText ? (
            <>We couldn't find the item you're looking for, check out the {planningPageLink} to see if it's there</>
          ) : (
            <>There are no more items that need to be setup, check out the {planningPageLink} to review items</>
          )}
        </div>
      }
    />
  );
}
