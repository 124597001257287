import React, { useEffect } from 'react';

import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Form } from 'antd';
import { RuleObject } from 'antd/lib/form';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  useBranchesSelectProps,
  useCompaniesSelectProps,
  useFreightsSelectProps,
  useLocationsSelectProps,
  useSalesRepsSelectProps,
  useTaxRegimeMxSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { CheckboxFormItem, InputFormItem } from 'components/FormItems';
import { AddressFormField, AddressFormItem } from 'components/FormItems/AddressFormItem';
import { Select, SelectOption } from 'components/Select';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { optArrFromVal } from 'utils/array';
import { joinIfIdNameObj, splitIfIdNameStr } from 'utils/formatting';
import { isAdmin, shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { UpdateFormHashStateOptions } from 'utils/routes';

import { ProspectHashState } from 'types/hash-state';

export const ProspectCustomerStep = ({
  prospectState,
  onProspectStateChange,
}: {
  prospectState: ProspectHashState;
  onProspectStateChange: (newState: Partial<ProspectHashState>, options?: UpdateFormHashStateOptions) => void;
}) => {
  const { activeTenant, activeUser, activeErpRole } = useGlobalApp();

  const defaultCompany = activeTenant.defaultData.prospect.company;
  const companiesSelectProps = useCompaniesSelectProps();
  const companyId = splitIfIdNameStr(prospectState.company)?.foreignId;
  const salesRepsSelectProps = useSalesRepsSelectProps({ companyId });
  const locationsSelectProps = useLocationsSelectProps({ companyIds: optArrFromVal(companyId) });
  const branchesSelectProps = useBranchesSelectProps({ companyId });
  const freightsSelectProps = useFreightsSelectProps({ companyId });
  const ivaTaxRegistrationSelectProps = useTaxRegimeMxSelectProps();
  const exportProspectUsingTransactionApi = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesExportProspectUsingTransactionApi,
  );
  if (!prospectState.company) {
    if (defaultCompany) {
      // Default to set default if one exists
      onProspectStateChange({ company: joinIfIdNameObj(defaultCompany) }, { defaultValue: true });
    } else if (companiesSelectProps.options[0]?.value) {
      // Otherwise use the first value in the list
      onProspectStateChange({ company: companiesSelectProps.options[0]?.value }, { defaultValue: true });
    }
  }
  if (prospectState.makeShipTo === undefined) {
    // Form should default to making a ship to
    onProspectStateChange({ makeShipTo: true }, { defaultValue: true });
  }
  if (!isAdmin(activeErpRole.foreignId, activeErpRole.name) && !prospectState.salesRep) {
    // If the user is not an admin, use the sales rep id of the current user
    onProspectStateChange({ salesRep: joinIfIdNameObj(activeErpRole) }, { defaultValue: true });
  }

  // when companyPerson = 'None', set ivaExemptionNumber = undefined
  useEffect(() => {
    if (prospectState.companyPerson === 'None') {
      onProspectStateChange({ ivaExemptionNumber: undefined }, { defaultValue: true });
    }
  }, [onProspectStateChange, prospectState.companyPerson]);

  // This selection is for Bricos only
  const companyPersonTypes: SelectOption[] = ['Company', 'Person', 'None'].map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <>
      <Form.Item
        name="company"
        label="Company"
        rules={[{ required: true, message: 'Please add a company.' }]}
        hidden={companiesSelectProps.options.length <= 1}
      >
        <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" />
      </Form.Item>
      {isAdmin(activeErpRole.foreignId, activeErpRole.name) && (
        <Form.Item
          name="salesRep"
          label="Assigned Sales Rep"
          rules={[{ required: true, message: 'Please assign a sales rep.' }]}
        >
          <AsyncSelect selectProps={salesRepsSelectProps} entityPlural="Sales Reps" />
        </Form.Item>
      )}
      <InputFormItem
        label="Customer Name"
        name="customerName"
        rules={[
          { required: true, message: 'Please add a customer name.' },
          { max: 50, message: 'Customer name cannot be more than 255 characters' },
        ]}
      />
      <InputFormItem
        label="Central Phone"
        name="customerPhone"
        rules={[
          { required: true, message: 'Please add a phone number.' },
          { max: 20, message: 'Phone number cannot be more than 20 characters' },
        ]}
      />
      <InputFormItem
        label="Email"
        name="customerEmail"
        rules={[
          { type: 'email', message: 'Must be an email' },
          { max: 255, message: 'Email cannot be more than 255 characters' },
        ]}
      />
      <InputFormItem
        label="Central Fax"
        name="customerFax"
        rules={[{ max: 20, message: 'Fax number cannot be more than 20 characters' }]}
      />
      {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.CustomersIvaTaxInfo) && (
        <>
          <Typography type="middle">Customer IVA Tax</Typography>
          <Form.Item name="companyPerson" label="Company/Person" rules={[{ required: true }]}>
            <Select options={companyPersonTypes} defaultValue={companyPersonTypes[0].value} />
          </Form.Item>
          <InputFormItem
            label="IVA Exemption Number"
            name="ivaExemptionNumber"
            rules={[
              { required: prospectState.companyPerson !== 'None' },
              {
                validator: async (_rule: RuleObject, value: string) => {
                  const { companyPerson } = prospectState;
                  const isNone = companyPerson === 'None';
                  const isCompany = companyPerson === 'Company';

                  if (isNone) {
                    return Promise.resolve();
                  }
                  // RFC pattern: https://tin-check.com/en/mexico/
                  const pattern = isCompany ? /^[A-Za-z]{3}\d{6}[A-Za-z\d]{3}$/ : /^[A-Za-z]{4}\d{6}[A-Za-z\d]{3}$/;

                  if (!value || value.match(pattern)) {
                    return Promise.resolve();
                  }
                  throw new Error(
                    `IVA Exemption Number must match ${isCompany ? 'RFC Entity' : 'RFC Individual'} format`,
                  );
                },
              },
            ]}
          />
          <Form.Item
            name="taxRegime"
            label="Tax Regime"
            rules={[{ required: true, message: 'Please add a tax regime' }]}
          >
            <AsyncSelect selectProps={ivaTaxRegistrationSelectProps} entityPlural="tax regimes" />
          </Form.Item>
        </>
      )}
      <Typography type="middle">Physical Address</Typography>
      {/* Ship to will be always required until we figure out how to properly deal with default branch */}
      {/* <CheckboxFormItem label="New Ship To" name="makeShipTo" /> */}
      {prospectState.makeShipTo && (
        <>
          <Form.Item
            name="shipToDefaultBranch"
            label="Ship To Default Branch"
            rules={[{ required: true, message: 'Please add a default branch.' }]}
          >
            <AsyncSelect selectProps={branchesSelectProps} entityPlural="branches" />
          </Form.Item>
          <Form.Item
            name="shipToPreferredLocation"
            label="Ship To Preferred Location"
            rules={[{ required: true, message: 'Please add a preferred location.' }]}
          >
            <AsyncSelect selectProps={locationsSelectProps} entityPlural="non-consignment locations" />
          </Form.Item>
          {!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableFreightExport) && (
            <Form.Item
              name="shipToDefaultFreight"
              label="Ship To Default Freight"
              rules={[{ required: true, message: 'Please add a default freight.' }]}
            >
              <AsyncSelect selectProps={freightsSelectProps} entityPlural="freights" />
            </Form.Item>
          )}
        </>
      )}
      <AddressFormItem
        formNamePrefix="physical"
        // zipcode is required when making a shipto
        requiredFields={prospectState.makeShipTo ? [AddressFormField.zipcode] : undefined}
      />
      <Typography type="middle">Mailing Address</Typography>
      <CheckboxFormItem label="Same as physical" name="addressesSame" />
      {!prospectState.addressesSame && (
        <AddressFormItem
          formNamePrefix="mailing"
          // mailing zipcode is required for txn-api
          requiredFields={
            prospectState.makeShipTo && exportProspectUsingTransactionApi ? [AddressFormField.zipcode] : undefined
          }
        />
      )}
    </>
  );
};
