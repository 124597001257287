import React, { ReactElement, ReactNode, cloneElement, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Popover } from 'antd';
import { colors } from 'theme/colors';

import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Counter } from 'components/Counter';
import { Tooltip } from 'components/Tooltip';

import { arrIsEqual } from 'utils/array';

import { SelectableItemList } from './SelectableItemList';
import { UseAsyncMultiSelectProps } from './types';

export interface AsyncMultiSelectProps {
  selectProps: UseAsyncMultiSelectProps | (() => UseAsyncMultiSelectProps);
  selectedValues: string[];
  onSelectedValuesChange: (values: string[]) => void;
  label?: string;
  icon?: ReactElement;
  mode?: 'multiple' | 'single';
  queryPlaceholder?: string;
  triggerButton?: ReactNode;
  containerClassName?: string;
  popoverClassName?: string;
  disableValuesTooltip?: boolean;
  orderable?: boolean;
}

export function AsyncMultiSelect({
  label,
  icon,
  selectProps,
  mode = 'multiple',
  selectedValues,
  onSelectedValuesChange,
  queryPlaceholder,
  triggerButton,
  containerClassName,
  popoverClassName,
  disableValuesTooltip,
  orderable,
}: AsyncMultiSelectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [curSelectedValues, setCurSelectedValues] = useState<string[]>(selectedValues);

  const selectPropsObj = typeof selectProps === 'function' ? selectProps() : selectProps;

  const applyValues = (values: string[]) => {
    onSelectedValuesChange(values);
    setIsOpen(false);
    selectPropsObj.setSearchQuery?.('');
  };

  return (
    <ConditionalWrapper
      condition={!disableValuesTooltip && mode === 'multiple' && selectedValues.length > 0}
      wrapper={(children) => (
        <Tooltip title={selectedValues.join('\n')} overlayInnerStyle={{ whiteSpace: 'pre-line' }}>
          {children}
        </Tooltip>
      )}
    >
      <Popover
        placement="bottomLeft"
        trigger="click"
        visible={isOpen}
        onVisibleChange={(_isOpen: boolean) => {
          setIsOpen(_isOpen);
          if (_isOpen) setCurSelectedValues(selectedValues);
        }}
        content={
          <>
            <SelectableItemList
              selectProps={selectPropsObj}
              selectedValues={curSelectedValues}
              onSelectedValuesChange={(values) =>
                mode === 'single' ? applyValues(values) : setCurSelectedValues(values)
              }
              orderable={orderable}
              mode={mode}
              queryPlaceholder={queryPlaceholder}
              popoverClassName={popoverClassName}
            />

            {mode === 'multiple' ? (
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  justify-content: flex-end;
                `}
              >
                <Button
                  size="small"
                  onClick={() => {
                    applyValues([]);
                  }}
                >
                  Clear
                </Button>
                <Button
                  onClick={() => applyValues(curSelectedValues)}
                  size="small"
                  type="primary"
                  disabled={arrIsEqual(curSelectedValues, selectedValues)}
                >
                  Apply
                </Button>
              </div>
            ) : null}
          </>
        }
      >
        {triggerButton || (
          <Button
            size="small"
            className={`
              ${containerClassName}
              ${css`
                &.ant-btn {
                  border-color: ${colors.neutral[300]};

                  &:focus,
                  &:hover {
                    border-color: ${colors.primary[500]};
                    color: inherit;
                  }
                }
              `}
            `}
          >
            <div
              className={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 6px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  gap: 6px;
                `}
              >
                {icon && cloneElement(icon, { style: { color: colors.neutral[500] } })}
                {label}
                {mode === 'single' ? (
                  <>
                    {label ? `:` : ``}
                    <span
                      className={css`
                        color: ${label ? colors.primary[500] : 'inherit'};
                      `}
                    >
                      {selectPropsObj.options.find((option) => option.value === selectedValues[0])?.label ??
                        selectedValues[0]}
                    </span>
                  </>
                ) : null}
                {mode === 'multiple' ? (
                  <Counter value={selectedValues.length} selected={selectedValues.length > 0} variant="primary" />
                ) : null}
              </div>
              <DownOutlined style={{ color: colors.neutral[400], fontSize: '12px' }} />
            </div>
          </Button>
        )}
      </Popover>
    </ConditionalWrapper>
  );
}

export function filterMultiSelectOptions(
  multiSelectProps: UseAsyncMultiSelectProps,
  values?: string[],
): UseAsyncMultiSelectProps {
  if (!values) return multiSelectProps;
  return { ...multiSelectProps, options: multiSelectProps.options.filter((option) => values.includes(option.value)) };
}
