import React, { useState } from 'react';

import { css } from '@emotion/css';
import { AuditLogEntryDTO, GetAuditLogQueryParams } from '@recurrency/core-api-schema/dist/auditLog/commonParams';
import { CompactUserDTO } from '@recurrency/core-api-schema/dist/common/compactUserDTO';
import { EndpointSchema } from '@recurrency/core-api-schema/dist/utils/apiSchema';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { colors } from 'theme/colors';
import { ZipCelXSheet } from 'zipcelx';

import { AsyncSelect } from 'components/AsyncSelect';
import { useTenantUsersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DateRangePicker } from 'components/DatePicker';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import {
  convertSnakeCaseToSpaceSplit,
  formatDate,
  formatLocaleDate,
  formatName,
  formatNumber,
  snakeCaseToTitleCase,
} from 'utils/formatting';
import { asKeyOf, sortableDateColumn, sortableStringColumn } from 'utils/tables';
import { track, TrackEvent } from 'utils/track';

import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from '../DownloadButton';

// Based on when item_location and supplier_location audit tables were created.
// Likely needs to be dynamic as future audit tables are added.
const EARLIEST_AUDIT_DATE = moment('2024-07-25');

export interface AuditLogProps {
  endpoint: EndpointSchema;
  queryParams: GetAuditLogQueryParams;
  pageSize?: number;
}

const auditValueSpecialCases: Record<string, React.ReactNode> = {
  true: 'Yes',
  false: 'No',
};

const auditFieldNameSpecialCases: Record<string, string> = {
  lead_time: 'Lead Time Override',
  safety_stock_days: 'Safety Stock Days Override',
};

const UndefinedValue = () => (
  <div
    className={css`
      color: ${colors.neutral[400]};
    `}
  >
    None
  </div>
);

const renderAuditValue = (value: string | undefined) => {
  if (!value) return <UndefinedValue />;
  if (auditValueSpecialCases[value]) return auditValueSpecialCases[value];
  if (!isNaN(value as unknown as number)) return formatNumber(value);
  if (moment(value, moment.ISO_8601, true).isValid()) return formatDate(value);
  return snakeCaseToTitleCase(value);
};

const renderAuditFieldName = (field: string) =>
  convertSnakeCaseToSpaceSplit(field in auditFieldNameSpecialCases ? auditFieldNameSpecialCases[field] : field);

export const AuditLog = ({ endpoint, queryParams, pageSize = 5 }: AuditLogProps) => {
  const defaultDateFilter = getDefaultDateFilter();
  const auditLogDefaultDateFilter = {
    ...defaultDateFilter,
    from:
      defaultDateFilter.from && defaultDateFilter.from > EARLIEST_AUDIT_DATE
        ? defaultDateFilter.from
        : EARLIEST_AUDIT_DATE,
  };
  const [filteredDate, setFilteredDate] = useState<DateFilter>(auditLogDefaultDateFilter);
  const [filteredUser, setFilteredUser] = useState<string>();

  const setDate = (newDate: DateFilter) => {
    track(TrackEvent.AuditLog_Filter, { filter: 'date', value: JSON.stringify(newDate), endpoint: endpoint.endpoint });
    setFilteredDate(newDate);
  };
  const setUserId = (newUserId: string) => {
    track(TrackEvent.AuditLog_Filter, { filter: 'userId', value: newUserId, endpoint: endpoint.endpoint });
    setFilteredUser(newUserId);
  };

  const inputFilter = queryParams.filter ?? {};
  const filteredQueryParams = {
    ...queryParams,
    filter: {
      ...inputFilter,
      updatedAtFrom: formatMomentDateToSqlDate(filteredDate.from),
      updatedAtTo: formatMomentDateToSqlDate(filteredDate.to),
      updatedByUserId: filteredUser ?? undefined,
    },
  };

  const tableProps = useCoreApiTableProps({
    schema: endpoint,
    queryParams: filteredQueryParams,
    pageSize,
  });
  const tenantsUserSelectProps = useTenantUsersSelectProps();

  async function getDownloadData(): Promise<ZipCelXSheet> {
    const response = await coreApiFetch(endpoint, {
      queryParams: {
        ...filteredQueryParams,
        // override pagination for download
        offset: 0,
        limit: 1000,
      },
    });

    const exportColumns: Array<DownloadXSheetColumn<AuditLogEntryDTO>> = [
      {
        title: 'Updated At',
        type: 'string',
        value: (record) => formatLocaleDate(record.updatedAt),
      },
      {
        title: 'Updated By',
        type: 'string',
        value: (record) => formatName(record.updatedBy?.firstName, record.updatedBy?.lastName),
      },
      { title: 'Updated Field', type: 'string', value: (record) => renderAuditFieldName(record.field) },
      { title: 'Previous Value', type: 'string', value: (record) => record.previousValue ?? 'None' },
      { title: 'New Value', type: 'string', value: (record) => record.nextValue ?? 'None' },
    ];

    return recordsToXSheet(response.data.items, exportColumns);
  }

  const columns: ColumnType<AuditLogEntryDTO>[] = [
    sortableDateColumn({
      title: 'Updated At',
      dataIndex: asKeyOf<AuditLogEntryDTO>('updatedAt'),
      render: (date: string) => formatLocaleDate(date),
      withTimestamp: true,
      defaultSortOrder: 'descend',
      sorter: true,
    }),
    {
      title: 'Updated By',
      dataIndex: asKeyOf<AuditLogEntryDTO>('updatedBy'),
      render: (user: CompactUserDTO) => formatName(user.firstName, user.lastName),
    },
    sortableStringColumn({
      title: 'Updated Field',
      dataIndex: asKeyOf<AuditLogEntryDTO>('field'),
      render: (field: string) => renderAuditFieldName(field),
      sorter: true,
    }),
    {
      title: 'Previous Value',
      dataIndex: asKeyOf<AuditLogEntryDTO>('previousValue'),
      render: renderAuditValue,
    },
    {
      title: 'New Value',
      dataIndex: asKeyOf<AuditLogEntryDTO>('nextValue'),
      render: renderAuditValue,
    },
  ].filter(truthy);

  return (
    <>
      <FlexSpace
        justify="flex-end"
        className={css`
          margin-bottom: 16px;
        `}
      >
        <AsyncSelect
          onChange={setUserId}
          selectProps={tenantsUserSelectProps}
          entityPlural="users"
          placeholder="Search users"
          className={css`
            width: 250px;
          `}
          allowClear
          size="small"
        />
        <FlexSpacer />
        <DateRangePicker
          disabledDate={(current) => current && current < EARLIEST_AUDIT_DATE}
          value={[filteredDate.from, filteredDate.to]}
          onChange={(values) => setDate(values ? { from: values[0], to: values[1] } : auditLogDefaultDateFilter)}
          format="MM/DD/YYYY"
          renderExtraFooter={() => (
            <div
              className={css`
                color: ${colors.neutral[400]};
              `}
            >
              Audit log data goes back as far as {formatDate(EARLIEST_AUDIT_DATE.toString())}
            </div>
          )}
        />
        <DownloadButton getDownloadData={getDownloadData} recordType="Audit Log" size="small" />
      </FlexSpace>
      <AsyncTable columns={columns} tableProps={tableProps} />
    </>
  );
};
