import React from 'react';

import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { Modal } from 'components/Modal';
import { ItemLocationSettingsAuditLog } from 'components/recipes/AuditLog/ItemLocationSettingsAuditLog';
import { Tabs } from 'components/Tabs';

import { splitIdNameStr } from 'utils/formatting';

import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import { MultiModalSelection } from './utils';

export function IncludeExcludeModal({
  selection,
  exclude,
  onClose,
}: {
  selection: MultiModalSelection;
  exclude: boolean;
  onClose: (didConfirm: boolean) => void;
}) {
  const isSingleRecord = selection.type === 'list' && selection.items.length === 1;
  const title = `${exclude ? 'Exclude' : 'Include'} ${
    isSingleRecord
      ? `Item #${selection.items[0].item_id} at Location ${selection.items[0].location}`
      : `${selection.count} Items`
  }`;

  return (
    <Modal
      visible
      title={title}
      onCancel={() => onClose(false)}
      width={900}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={() => onClose(true)} type="primary">
            {exclude ? 'Exclude' : 'Include'}
          </Button>
        </>
      }
    >
      <>
        <span>
          Status will be set as{' '}
          {exclude
            ? 'Excluded, and will not be set as Needs Review in the future.'
            : 'Needs Review or OK in the future.'}
        </span>
        <DividerLine />
        <MultiUpdateReplenishmentSettingsTable
          selection={selection}
          bulkUpdate={{
            type: 'includeExclude',
            exclude,
          }}
        />
        {isSingleRecord && (
          <>
            <DividerLine marginTop={42} />
            <Tabs
              tabs={[
                {
                  header: 'Audit Log',
                  infoTooltip: `Excluded updates for Item #${selection.items[0].item_id} at Location #${selection.items[0].location}`,
                  content: (
                    <ItemLocationSettingsAuditLog
                      itemUid={selection.items[0].item_uid}
                      locationId={splitIdNameStr(selection.items[0].location).foreignId}
                      field="excluded"
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </>
    </Modal>
  );
}
