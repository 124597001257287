import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';

export enum TrackEvent {
  Api_Error = 'Api.Error',
  Api_Response = 'Api.Response',
  AuditLog_Filter = 'AuditLog.Filter',
  Components_AlgoliaConnectedFormItem_LoadMore = 'Components.AlgoliaConnectedFormItem.LoadMore',
  Components_Loaders_ShowError = 'Components.Loaders.ShowError',
  Components_Notification_ShowError = 'Components.Notification.ShowError',
  Components_PdfButtonClick = 'Components.PdfButtonClick',
  Components_SearchFrameQuery = 'Components.SearchFrameQuery',
  Components_Modal_VisibilityChange = 'Components.Modal.VisibilityChange',
  Components_ExportButton_Export = 'Components.ExportButton.Export',
  Contacts_CreateContact_Submit = 'Contacts.CreateContact.Submit',
  Customers_NewCustomer_StepChange = 'Customers.NewCustomer.StepChange',
  Customers_NewCustomer_Submit = 'Customers.NewCustomer.Submit',
  Customers_NewProspect_StepChange = 'Customers.NewProspect.StepChange',
  Customers_NewProspect_Submit = 'Customers.NewProspect.Submit',
  DemandPlanning_MinMaxInfo_Submission = 'DemandPlanning.MinMaxInfo_Submission',
  DemandPlanning_MinMax_Submission = 'DemandPlanning.MinMax_Submission',
  DemandPlanning_PurchaseRequest_Send = 'DemandPlanning.PurchaseRequest.Send',
  FreeTrial_ViewUpgradeInfo = 'FreeTrial.ViewUpgradeInfo',
  Items_CreateItem_Submit = 'Items.CreateItem.Submit',
  Nav_CtaClick = 'Nav.CtaClick',
  Nav_GlobalSearch_Abandon = 'Nav.GlobalSearch.Abandon',
  Nav_GlobalSearch_Focus = 'Nav.GlobalSearch.Focus',
  Nav_GlobalSearch_OptionSelect = 'Nav.GlobalSearch.OptionSelect',
  Nav_HistoryChange = 'Nav.HistoryChange',
  Nav_NoAccess = 'Nav.NoAccess',
  Nav_PageLoad = 'Nav.PageLoad',
  Nav_ReferClick = 'Nav.ReferClick',
  Nav_TabChange = 'Nav.TabChange',
  Orders_ConvertQuoteToOrder = 'Orders.ConvertQuoteToOrder',
  Orders_CreateInvoice = 'Orders.CreateInvoice',
  Orders_EditOrder_Submit = 'Orders.EditOrder.Submit',
  Purchasing_AutoFillTrimModal_Open = 'Purchasing.AutoTargetFillTrimModal.Open',
  Purchasing_EditPurchaseOrder_Submit = 'Purchasing.EditPurchaseOrder.Submit',
  Purchasing_EditTransferOrder_Submit = 'Purchasing.EditTransferOrder.Submit',
  Purchasing_SetSupplierLocationTarget_Submit = 'Purchasing.SetSupplierLocationTarget.Submit',
  Purchasing_Optimization_ResetButtonClick = 'Purchasing.Optimization.ResetButtonClick',
  Quotes_EditQuote_AddRecommendedItem = 'Quotes.EditQuote.AddRecommendedItem',
  Quotes_EditQuote_AddAccessoryItem = 'Quotes.EditQuote.AddAccessoryItem',
  Quotes_EditQuote_SubstituteItem = 'Quotes.EditQuote.SubstituteItem',
  Quotes_EditQuote_LineItemLinkClick = 'Quotes.EditQuote.LineItemLinkClick',
  Quotes_EditQuote_LineItem_DropDownClick = 'Quotes.EditQuote.LineItem.DropDownClick',
  Quotes_EditQuote_SaveDraft = 'Quotes.EditQuote.SaveDraft',
  Quotes_EditQuote_StepChange = 'Quotes.EditQuote.StepChange',
  Quotes_EditQuote_Submit = 'Quotes.EditQuote.Submit',
  Quotes_EditQuote_SidePane_MoreButtonClick = 'Quotes.EditQuote.SidePane.MoreButtonClick',
  RecommendedQuotes_ShipToList_SelectRow = 'RecommendedQuotes.ShipToList.SelectRow',
  RecommendedQuotes_ItemList_Action = 'RecommendedQuotes.ItemList.Action',
  RecommendedQuotes_ItemCard_Action = 'RecommendedQuotes.ItemCardAction',
  Reporting_CreateReport = 'Reporting.CreateReport',
  Reporting_DeleteReport = 'Reporting.DeleteReport',
  Reporting_ReportingQuery = 'Reporting.ReportingQuery',
  Reporting_UpdateReport = 'Reporting.UpdateReport',
  Settings_UpdateSettings = 'Settings.UpdateSettings',
  ShipTos_CreateShipTo_Submit = 'ShipTos.CreateShipTo.Submit',
  Tasks_CreateTask_Save = 'Tasks.CreateTask.Save',
  Tasks_EditTask_Save = 'Tasks.EditTask.Save',
  Tasks_Delete = 'Tasks.Delete',
  Tasks_ToggleStatus = 'Tasks.ToggleStatus',
}

export enum CtaName {
  NewCustomer = 'newCustomer',
  NewQuote = 'newQuote',
  NewTask = 'newTask',
  NewOrder = 'newOrder',
  NewPurchaseOrder = 'newPurchaseOrder',
  OptimizePurchaseOrder = 'optimizePurchaseOrder',
  NewProspect = 'newProspect',
  FreeTrialUpgradeNow = 'freeTrialUpgradeNow',
  FreeTrialLearnMore = 'freeTrialLearnMore',
  NewContact = 'newContact',
  NewItem = 'newItem',
  NewShipTo = 'newShipTo',
}

export enum CtaType {
  Button = 'Button',
  GlobalSearch = 'GlobalSearch',
  PrimaryNavNewRecord = 'PrimaryNavNewRecord',
}

export enum OrderType {
  Quote = 'quote',
  Order = 'order',
}

export enum RecommendedItemType {
  Recent = 'recent',
  Reorders = 'reorders',
  New = 'new',
}

export enum AccessoryItemAddLocation {
  Modal = 'modal',
  SidePane = 'sidePane',
}

export enum PdfDeliveryType {
  Email = 'email',
  NewTab = 'newTab',
}

export enum PdfDocumentType {
  Quote = 'quote',
  Order = 'order',
  Invoice = 'invoice',
  DraftQuote = 'draftQuote',
  PackingList = 'packingList',
  WorkOrder = 'workOrder',
  PurchaseOrder = 'purchaseOrder',
}

export enum SidePanePanelType {
  AccessoryItems = 'accessoryItems',
  SalesOfItemToCustomer = 'salesOfItemToCustomer',
  QuotesOfItemToCustomer = 'quotesOfItemToCustomer',
  OrdersOfItemToCustomer = 'ordersOfItemToCustomer',
  LocationAvailability = 'locationAvailability',
  SalesOfItemAllCustomers = 'salesOfItemAllCustomers',
  Notes = 'notes',
}

export enum QuoteEditLineItemActionsClickType {
  Transfer = 'transfer',
  ShipLocation = 'shipLocation',
  DirectShip = 'directShip',
  SpecialOrder = 'specialOrder',
  Backorder = 'backorder',
  Substitute = 'substitute',
  MoveUp = 'moveUp',
  MoveDown = 'moveDown',
  Duplicate = 'duplicate',
  Delete = 'delete',
}

export enum PlanningModalType {
  LeadTime = 'lead_time',
  SafetyStock = 'safety_stock',
  Settings = 'settings',
  SingleMinMax = 'single_min_max',
  SingleOpOq = 'single_op_oq',
  MultiMinMax = 'multi_min_max',
  MultiOpOq = 'multi_op_oq',
  MultiLeadTime = 'multi_lead_time',
  MultiSafetyStock = 'multi_safety_stock',
  Exclude = 'exclude',
  Include = 'include',
  SAPB1Export = 'sapb1_export',
}

export enum MinMaxSubmissionType {
  Recommended = 'recommended',
  Custom = 'custom',
  RecommendedMinMax = 'recommended min/max',
  CustomMinMax = 'custom min/max',
}

export enum RecommendedQuoteItemListAction {
  OpenContactsModal = 'openContactsModal',
  ComposeEmail = 'composeEmail',
  NewTask = 'newTask',
  NewQuote = 'newQuote',
  NewOrder = 'newOrder',
}

export enum RecommendedQuoteItemCardAction {
  OpenHistoryModal = 'openHistoryModal',
}

export interface EventProps {
  /**
   * When an api call errors with a non 2XX status code
   */
  [TrackEvent.Api_Error]: {
    reqUrl: string;
    /** inferred url pattern e.g 'GET /tasks/:id' */
    reqRoutePath: string;
    resStatusCode: string;
    resDurationMs: number;
    resErrorMessage: string;
    /** timing from server-timing header */
    resServerDurationMs?: number;
    resServerRequestIdHeader?: string;
  };

  /**
   * When an api response returns from core-api
   */
  [TrackEvent.Api_Response]: {
    reqUrl: string;
    reqRoutePath: string;
    resStatusCode: string;
    resDurationMs: number;
    resServerDurationMs?: number;
    resServerRequestIdHeader?: string;
  };

  /**
   * When a user filters an audit log
   */
  [TrackEvent.AuditLog_Filter]: {
    endpoint: string;
    filter: string;
    value: string;
  };

  [TrackEvent.Components_Modal_VisibilityChange]: {
    actionType: 'open' | 'close';
    modalName: string;
  };

  /** When submitting a min max info exports in dfpa  */
  [TrackEvent.DemandPlanning_MinMaxInfo_Submission]: {
    modalType:
      | PlanningModalType.LeadTime
      | PlanningModalType.SafetyStock
      | PlanningModalType.MultiLeadTime
      | PlanningModalType.MultiSafetyStock
      | PlanningModalType.Exclude
      | PlanningModalType.Include;
    itemCount: number;
  };

  /** When submitting a multi export in dfpa  */
  [TrackEvent.DemandPlanning_MinMax_Submission]: {
    modalType:
      | PlanningModalType.MultiMinMax
      | PlanningModalType.SingleMinMax
      | PlanningModalType.MultiOpOq
      | PlanningModalType.SingleOpOq
      | PlanningModalType.SAPB1Export;
    itemCount: number;
    submissionType: MinMaxSubmissionType;
  };

  /** When sending a purchase request in dfpa  */
  [TrackEvent.DemandPlanning_PurchaseRequest_Send]: Obj<never>;

  /**
   * When a free trial user views an upgrade CTA
   */
  [TrackEvent.FreeTrial_ViewUpgradeInfo]: Obj<never>;

  /** When initial page loads  */
  [TrackEvent.Nav_PageLoad]: {
    windowUserAgent: string;
    windowInnerWidth: number;
    windowInnerHeight: number;
    appVersion: string;
    timeMsToIndexHtmlLoad?: number;
    timeMsToAppCssLoad?: number;
    timeMsToAppJsLoad?: number;
    timeMsToDomContentLoad?: number;
    timeMsToAppInit?: number;
    timeMsToNavShellRender?: number;
    sizeKbCompressedAppJs?: number;
    sizeKbCompressedAppCss?: number;
  };

  /**
   * When the url is changed via react-router in-app navigation.
   */
  [TrackEvent.Nav_HistoryChange]: {
    /** prevPath is undefined on initial load */
    prevPath: string | undefined;
    prevRoutePath: string | undefined;
    /** Time spent by user on previous page in seconds. Seconds not counted if user tab/browser is inactive */
    prevTimeSpentS: number;
  };

  /**
   * When a user reaches a page that they do not have access to.
   */
  [TrackEvent.Nav_NoAccess]: {
    blockedProducts: TenantProductName[];
  };

  /**
   * When ?tab=x is changed either via history or tab click of NavTabs component
   */
  [TrackEvent.Nav_TabChange]: {
    tab: string;
  };

  /**
   * When user focuses on global search input either via keyboard or mouse.
   */
  [TrackEvent.Nav_GlobalSearch_Focus]: {
    interactType: 'KeyboardShortcut' | 'Mouse';
  };

  /**
   * When user clicks or keyboard selects an search result.
   */
  [TrackEvent.Nav_GlobalSearch_OptionSelect]: {
    searchQuery: string;
    searchQueryLength: number;
    categoryLabel: string;
    optionLabel: string;
    optionHref: string;
    numOptions: number;
  };

  /**
   * When user starts a search but does not select an option
   */
  [TrackEvent.Nav_GlobalSearch_Abandon]: {
    searchQuery: string;
    searchQueryLength: number;
    numOptions: number;
  };

  /** When user clicks a CTA */
  [TrackEvent.Nav_CtaClick]: {
    ctaName: CtaName;
    ctaType: CtaType;
  };

  /** When user clicks the "Refer" button */
  [TrackEvent.Nav_ReferClick]: Obj<never>;

  /**
   * When user clicks 'Load More' on AlgoliaConnectedFormItem.
   * This event helps us evaluate whether this is discoverable UX.
   */
  [TrackEvent.Components_AlgoliaConnectedFormItem_LoadMore]: {
    searchQuery: string;
    searchQueryLength: number;
    /** number of results returned from algolia */
    numResults: number;
  };

  /**
   * When CenteredError is shown due to an uncaught error that bubbles up while rendering,
   * Or when an api call fails.
   */
  [TrackEvent.Components_Loaders_ShowError]: {
    errorName: string;
    errorMessage: string;
    errorStack: string;
    statusCode?: number;
    componentStack?: string;
    sentryUrl?: string;
  };

  /**
   * When Error notification is shown to user via captureAndShowError
   */
  [TrackEvent.Components_Notification_ShowError]: {
    notificationTitle: string;
    errorName: string;
    errorMessage: string;
    errorStack: string;
    sentryUrl?: string;
  };

  /**
   * When a user exports data to excel
   */
  [TrackEvent.Components_ExportButton_Export]: {
    recordCount: number;
    recordType?: string;
  };

  /** When user creates a contact  */
  [TrackEvent.Contacts_CreateContact_Submit]: {
    contactId: string;
  };

  /** When user creates an item  */
  [TrackEvent.Items_CreateItem_Submit]: {
    itemId: string;
    itemUid: string;
  };

  /** When user continues to another step in quote flow */
  [TrackEvent.Quotes_EditQuote_StepChange]: {
    step: string;
    orderType: OrderType;
  };

  /** When user saves a quote as draft */
  [TrackEvent.Quotes_EditQuote_SaveDraft]: {
    numLineItems: number;
    totalPrice: number;
    totalGrossMargin: number;
    avgGrossMarginPercentage: number;
    step: string;
    /** undefined means new draft, string id means an existing saved draft */
    quoteId: string | undefined;
  };

  /** When user submits a quote */
  [TrackEvent.Quotes_EditQuote_Submit]: {
    numLineItems: number;
    totalPrice: number;
    totalGrossMargin?: number;
    avgGrossMarginPercentage?: number;
    quoteId?: string | undefined;
  };

  /** When user submits an order */
  [TrackEvent.Orders_EditOrder_Submit]: {
    numLineItems: number;
    totalPrice: number;
    totalGrossMargin?: number;
    avgGrossMarginPercentage?: number;
  };

  /**
   * When user loads the Auto Trim Modal
   *
   * Tracks the number of line items where the recommended quantity matches the order quantity
   * in the Auto Fill/Trim Modal for PO Purchase Targets flow.
   */
  [TrackEvent.Purchasing_AutoFillTrimModal_Open]: {
    /** The number of line accepted, where accepted is recommended quantity = order quantity */
    numAcceptedLines: number;
  };

  /** When user submits a purchaseOrder */
  [TrackEvent.Purchasing_EditPurchaseOrder_Submit]: {
    numLineItems: number;
    totalCost: number;
  };

  /** When user submits a transferOrder */
  [TrackEvent.Purchasing_EditTransferOrder_Submit]: {
    numLineItems: number;
    totalCost: number;
  };

  /** When user submits a purchaseOrder */
  [TrackEvent.Purchasing_Optimization_ResetButtonClick]: Obj<never>;

  /** When user updates a Supplier Location Target Setting */
  [TrackEvent.Purchasing_SetSupplierLocationTarget_Submit]: {
    supplierId: string;
    locationId: string;
    targetType: string | undefined;
    targetValue: number | undefined;
  };

  /** When user clicks to add a recommended item from the sidebar */
  [TrackEvent.Quotes_EditQuote_AddRecommendedItem]: {
    recommendedItemType: RecommendedItemType;
    orderType: OrderType;
  };

  /** When user clicks to add an accessory item from the sidebar or modal */
  [TrackEvent.Quotes_EditQuote_AddAccessoryItem]: {
    addLocation: AccessoryItemAddLocation;
    orderType: OrderType;
  };

  /** When user clicks to substitute item from the modal */
  [TrackEvent.Quotes_EditQuote_SubstituteItem]: {
    orderType: OrderType;
  };

  /** When user clicks on link icon besides item selector in LineItems step */
  [TrackEvent.Quotes_EditQuote_LineItemLinkClick]: Obj<never>;

  /** When user clicks on a dropdown button for a line Item */
  [TrackEvent.Quotes_EditQuote_LineItem_DropDownClick]: {
    dropdownClickType: QuoteEditLineItemActionsClickType;
    orderType: OrderType;
  };

  /** When user clicks 'More' on the contextual panel */
  [TrackEvent.Quotes_EditQuote_SidePane_MoreButtonClick]: {
    panelType: SidePanePanelType;
    orderType: OrderType;
  };

  /** When user submits a new customer  */
  [TrackEvent.Customers_NewCustomer_Submit]: Obj<never>;

  /** When user continues to another step in customer flow */
  [TrackEvent.Customers_NewCustomer_StepChange]: {
    step: string;
  };

  /** When user submits a new prospect  */
  [TrackEvent.Customers_NewProspect_Submit]: Obj<never>;

  /** When user continues to another step in prospect flow */
  [TrackEvent.Customers_NewProspect_StepChange]: {
    step: string;
  };

  /** When user converts quote to order */
  [TrackEvent.Orders_ConvertQuoteToOrder]: Obj<never>;

  /** When order is invoiced */
  [TrackEvent.Orders_CreateInvoice]: Obj<never>;

  [TrackEvent.Components_SearchFrameQuery]: {
    indexName: string;
    searchQuery: string;
    searchQueryLength: number;
    facetValueFilterKeys: string[]; // name of filtered keys only e.g 'salesrep'
    sortByField?: string; // e.g company_stock
    sortByDir?: string; // asc | desc
    resDurationMs: number; // time taken in ms to get results from search backend
  };

  /** When user open or email pdf button */
  [TrackEvent.Components_PdfButtonClick]: {
    documentType: PdfDocumentType;
    deliveryType: PdfDeliveryType;
  };

  /** When user clicks on RecommendedQuotes/Opportunities ship to table row  */
  [TrackEvent.RecommendedQuotes_ShipToList_SelectRow]: {
    customer: string;
    shipTo: string;
    potentialValue: number;
    numLineItems: number;
  };

  /** When user clicks on email/task/quote/order after item selection  */
  [TrackEvent.RecommendedQuotes_ItemList_Action]: {
    action: RecommendedQuoteItemListAction;
    shipTo: string;
    numLineItems: number;
  };

  /** When user clicks on any of action buttons in item card  */
  [TrackEvent.RecommendedQuotes_ItemCard_Action]: {
    action: RecommendedQuoteItemCardAction;
    shipTo: string;
    item: string;
  };

  /** When user creates a report  */
  [TrackEvent.Reporting_CreateReport]: {
    query?: string;
    groupBy?: string;
    sortBy?: string;
    sortDir?: 'asc' | 'desc';
    numFilters: number;
  };

  /** When user deletes a report  */
  [TrackEvent.Reporting_DeleteReport]: Obj<never>;

  /** When user performs a reporting query  */
  [TrackEvent.Reporting_ReportingQuery]: {
    query?: string;
    groupBy?: string;
    sortBy?: string;
    sortDir?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    'filter.locationId'?: string;
    'filter.salesrepId'?: string;
    'filter.customerId'?: string;
    'filter.productGroupId'?: string;
    'filter.itemId'?: string;
    'filter.month'?: string;
    numFilters: number;
  };

  /** When user updates a report  */
  [TrackEvent.Reporting_UpdateReport]: {
    changedIsPinned: boolean;
    changedName: boolean;
    changedData: boolean;
    isPinned: boolean;
    query?: string;
    groupBy?: string;
    sortBy?: string;
    sortDir?: 'asc' | 'desc';
    numFilters?: number;
  };

  /** When user updates settings  */
  [TrackEvent.Settings_UpdateSettings]: Obj<never>;

  /** When user creates a ship_to  */
  [TrackEvent.ShipTos_CreateShipTo_Submit]: {
    shipToId: string;
  };

  /** When user edits a task  */
  [TrackEvent.Tasks_EditTask_Save]: {
    taskId: string;
    assigneeIsCreator: boolean;
  };

  /** When user creates a task  */
  [TrackEvent.Tasks_CreateTask_Save]: {
    taskId: string;
    assigneeIsCreator: boolean;
  };

  /** When user deletes a task */
  [TrackEvent.Tasks_Delete]: {
    taskId: string;
  };

  /** When user toggles status on a task */
  [TrackEvent.Tasks_ToggleStatus]: {
    taskId: string;
  };
}

interface TrackSuperProps {
  userId: string;
  userName: string;
  userEmail: string;
  userRole: string;
  tenantId: string;
  tenantName: string;
  tenantSlug: string;
  tenantEnabledProducts: TenantProductName[];
  sessionId: string;

  /** current window.location.pathname when event was fired */
  curPath: string;
  /** matching route path e.g '/sales/customers/details/:id'.
   * undefined if no matching route */
  curRoutePath: string | undefined;
}

export const trackSuperProps: Partial<TrackSuperProps> = {};
export function setSuperProps(props: Partial<TrackSuperProps>) {
  Object.assign(trackSuperProps, props);
}

export function identify({
  id,
  name,
  email,
  role,
  sessionId,
  tenantId,
  tenantName,
  tenantSlug,
  userCreatedAt,
  tenantCreatedAt,
  tenantEnabledProducts,
}: {
  id: string;
  name: string;
  email: string;
  role: string;
  sessionId: string;
  tenantId: string;
  tenantName: string;
  tenantSlug: string;
  userCreatedAt: string;
  tenantCreatedAt: string;
  tenantEnabledProducts: TenantProductName[];
}) {
  setSuperProps({
    userId: id,
    userName: name,
    userEmail: email,
    userRole: role,
    sessionId,
    tenantId,
    tenantName,
    tenantSlug,
    tenantEnabledProducts,
  });

  if (window.segment) {
    window.segment.identify(id, {
      id,
      name,
      email,
      tenantId,
      tenantName,
    });
  }

  if (window.FS) {
    window.FS.identify(id, {
      displayName: name,
      email,
      tenantId,
    });
  }

  if (window.Cohere) {
    window.Cohere.identify(id, {
      displayName: name,
      email,
    });
  }

  if (window.zeIdentify) {
    window.zeIdentify({
      name,
      email,
    });
  }

  if (window.chmln) {
    window.chmln.identify(id, {
      email,
      name,
      role,
      created: userCreatedAt,
      company: { uid: tenantId, name: tenantName, created: tenantCreatedAt, plan: tenantEnabledProducts },
    });
  }
}

export function track<EventNameT extends TrackEvent>(eventName: EventNameT, eventProps: EventProps[EventNameT]) {
  const mergedEventProps: Obj = { ...trackSuperProps, ...eventProps };
  // console.log('track', eventName, mergedEventProps); // uncomment to enable track logging
  try {
    // some adblockers set window.analytics as null, so we guard with .track as a function
    if (window.segment && typeof window.segment.track === 'function') {
      if (window.FS?.getCurrentSessionURL) {
        // see https://developer.fullstory.com/current-session-url
        mergedEventProps.fullStoryUrl = window.FS.getCurrentSessionURL(/* withTimestamp */ true);
      }
      window.segment.track(eventName, mergedEventProps);
    }

    if (window.FS && typeof window.FS.event === 'function') {
      window.FS.event(eventName, eventProps);
    }
  } catch (err) {
    // tracking shouldn't ever fail, but incase it does due to unknown reason
    // don't break UI, gracefully log to console and move on
    console.error('track.error', err);
  }
}

/** Helper utility function to track the duration of a promise and call a callback function with durationMs */
export function timePromise<PromiseT extends Promise<Any>>(
  promise: PromiseT,
  callbackFn: (durationMs: number) => void,
): PromiseT {
  const startTimeMs = Date.now();
  promise.finally(() => {
    const durationMs = Math.round(Date.now() - startTimeMs);
    callbackFn(durationMs);
  });
  return promise;
}
