import React, { useEffect } from 'react';

import { useHistory } from 'react-router';

import { DashboardOutlined, EnvironmentOutlined, RightOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { PurchaseTargetStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TransferTargetDTO } from '@recurrency/core-api-schema/dist/transferOrders/getTransferTargets';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { PageHeader } from 'components/PageHeader';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { EditTargetActionButton } from 'components/recipes/EditTargetActionButton';
import { LocationTransferSettingsModal } from 'components/recipes/LocationTransferSettingsModal';
import { StatusBadge } from 'components/recipes/StatusBadge';
import { ResultCount } from 'components/ResultCount';
import { SelectionCountAndClear } from 'components/SelectionCountAndClear';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { capitalizeFirst, formatUSD } from 'utils/formatting';
import { objPickKeys } from 'utils/object';
import { routes, useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import {
  asKeyOf,
  sortableDateColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortDirections,
  withSortedColumn,
} from 'utils/tables';

import { TransferTargetsHashState } from 'types/hash-state';

const statusOptions: MultiSelectOption[] = [
  { label: 'All', value: '' },
  { label: 'Ready', value: PurchaseTargetStatus.Ready },
  { label: 'Not Ready', value: PurchaseTargetStatus.NotReady },
];

export function TransferTargetsPage() {
  const history = useHistory();
  const [hashState, updateHashState] = useHashState<TransferTargetsHashState>();
  const { sourceLocationIds = [], destinationLocationIds = [], selectedTransferTargets = [] } = hashState;
  const locationsSelectProps = useLocationsSelectProps({});

  const tableProps = useCoreApiTableProps({
    schema: schemas.transferOrders.getTransferTargets,
    queryParams: {
      filter: {
        sourceLocationIds,
        destinationLocationIds,
        statuses: hashState.status ? [hashState.status] : [],
      },
    },
  });
  const { setPage, reload: reloadTransferTargets } = tableProps;

  /** the purpose of this string is to memoize these values so that the useEffect below doesn't trigger when the values are the same.
   * The arrays in hashState are recreated per render and considered new objects, causing re-renders if added directly to the useEffect dependency array */
  const stringifyHashState = JSON.stringify(
    objPickKeys(hashState, 'status', 'sourceLocationIds', 'destinationLocationIds', 'selectedTransferTargets'),
  );

  useEffect(() => {
    setPage(1);
  }, [stringifyHashState, setPage]);

  const tableColumns: PersistedColumn<TransferTargetDTO>[] = [
    sortableIdColumn({
      title: 'Source Location',
      dataIndex: asKeyOf<TransferTargetDTO>('sourceLocationId'),
      sorter: true,
      settingKey: 'sourceLocationId',
      required: true,
      render: (_, record: TransferTargetDTO) => `${record.sourceLocationId}: ${record.sourceLocationName}`,
    }),
    sortableIdColumn({
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferTargetDTO>('destinationLocationId'),
      sorter: true,
      settingKey: 'destinationLocationId',
      required: true,
      render: (_, record: TransferTargetDTO) => `${record.destinationLocationId}: ${record.destinationLocationName}`,
    }),
    sortableDateColumn({
      title: 'Last Transfered',
      dataIndex: asKeyOf<TransferTargetDTO>('lastTransferDate'),
      align: 'right',
      settingKey: 'lastTransferDate',
    }),
    sortableDateColumn({
      title: 'Last Reviewed',
      dataIndex: asKeyOf<TransferTargetDTO>('lastReviewDate'),
      align: 'right' as const,
      settingKey: 'lastReviewDate',
    }),
    sortableNumberColumn({
      title: 'Review method',
      dataIndex: asKeyOf<TransferTargetDTO>('reviewScheduleCron'),
      align: 'right' as const,
      sorter: true,
      settingKey: 'reviewScheduleCron',
      render: (_, record: TransferTargetDTO) => (
        <EditTargetActionButton
          value={record.reviewMethod ? capitalizeFirst(record.reviewMethod) : '-'}
          onClick={async () => {
            const newSettings = await showAsyncModal(LocationTransferSettingsModal, {
              sourceLocationId: record.sourceLocationId,
              destinationLocationId: record.destinationLocationId,
            });
            if (newSettings) {
              reloadTransferTargets();
            }
          }}
        />
      ),
    }),
    sortableDateColumn({
      title: 'Next Review',
      dataIndex: asKeyOf<TransferTargetDTO>('nextReviewDate'),
      align: 'right' as const,
      settingKey: 'nextReviewDate',
    }),
    sortableNumberColumn({
      title: 'Lines',
      dataIndex: asKeyOf<TransferTargetDTO>('transferLineCount'),
      settingKey: 'transferLineCount',
    }),
    {
      title: 'Current ($)',
      dataIndex: asKeyOf<TransferTargetDTO>('totalCost'),
      sorter: true,
      sortDirections,
      align: 'right' as const,
      settingKey: 'totalCost',
      render: (currentValue: number) => formatUSD(currentValue),
    },
    {
      title: 'Current ($) - Backorders',
      dataIndex: asKeyOf<TransferTargetDTO>('totalBackorderedCost'),
      sorter: true,
      sortDirections,
      align: 'right' as const,
      settingKey: 'totalBackorderedCost',
      render: (totalBackorderedCost: number) => formatUSD(totalBackorderedCost),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<TransferTargetDTO>('status'),
      settingKey: 'status',
      render: (status) => <StatusBadge status={status} />,
    },
    {
      title: 'Actions',
      width: '100px',
      align: 'left',
      settingKey: 'actions',
      render: (record: TransferTargetDTO) => (
        <Button
          size="small"
          data-test-id="transferTargetLinesButton"
          disabled={selectedTransferTargets.length > 0}
          onClick={() => {
            history.push(
              routes.purchasing.transferTargetLines({
                sourceLocationIds: record.sourceLocationId ? [record.sourceLocationId] : undefined,
                destinationLocationIds: record.destinationLocationId ? [record.destinationLocationId] : undefined,
              }),
            );
          }}
        >
          Lines <RightOutlined />
        </Button>
      ),
    },

    // optional
    sortableNumberColumn({
      title: 'Current Weight',
      dataIndex: asKeyOf<TransferTargetDTO>('totalWeight'),
      settingKey: 'totalWeight',
      sorter: true,
      optional: true,
    }),
    sortableNumberColumn({
      title: 'Current Volume',
      dataIndex: asKeyOf<TransferTargetDTO>('totalVolume'),
      settingKey: 'totalVolume',
      sorter: true,
      optional: true,
    }),
    sortableNumberColumn({
      title: 'Transfer Days',
      dataIndex: asKeyOf<TransferTargetDTO>('transferDays'),
      settingKey: 'transferDays',
      sorter: true,
      optional: true,
    }),
  ];

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.TransferTargetsTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip
            title={[
              'Transfer Targets are locations that have been identified as needing to transfer inventory to another location.',
            ].join(' ')}
          >
            Transfer Targets
          </InfoTooltip>
        }
        headerActions={
          // TODO: @brunno Add route to TO Builder button
          <Button type="primary">TO Builder</Button>
        }
      />
      <FilterBarBox dividerLine>
        <FlexSpace direction="column" gap={16} fullWidth>
          <FlexSpace wrap fullWidth>
            <span>Filter</span>
            <AsyncMultiSelect
              label="Status"
              mode="single"
              icon={<DashboardOutlined />}
              selectProps={{ options: statusOptions }}
              selectedValues={[hashState.status || statusOptions[0].value]}
              onSelectedValuesChange={(values) =>
                updateHashState({ status: (values[0] || '') as PurchaseTargetStatus })
              }
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(locationsSelectProps)}
              label="Source Location"
              queryPlaceholder="Search locations"
              selectedValues={sourceLocationIds}
              onSelectedValuesChange={(values) => updateHashState({ sourceLocationIds: values })}
              icon={<EnvironmentOutlined />}
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(locationsSelectProps)}
              label="Destination Location"
              queryPlaceholder="Search locations"
              selectedValues={destinationLocationIds}
              onSelectedValuesChange={(values) => updateHashState({ destinationLocationIds: values })}
              icon={<EnvironmentOutlined />}
            />
            {hashState.status || hashState.destinationLocationIds || hashState.sourceLocationIds ? (
              <ActionButton
                onClick={() =>
                  updateHashState({
                    status: undefined,
                    sourceLocationIds: undefined,
                    destinationLocationIds: undefined,
                  })
                }
                label="Clear All"
              />
            ) : null}
            <FlexSpacer />
            <ResultCount count={tableProps.totalCount} />
          </FlexSpace>
        </FlexSpace>
      </FilterBarBox>

      <SelectionCountAndClear
        numSelected={selectedTransferTargets.length}
        entitySingle="row"
        onClear={() => updateHashState({ selectedTransferTargets: undefined })}
      />

      <ColumnChooserSection
        tableKey={ViewSettingKey.TransferTargetsTable}
        columns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        setVisibleColumnKeys={setVisibleColumnKeys}
      />

      <AsyncTable
        tableProps={tableProps}
        columns={withSortedColumn(
          visibleColumnKeys.map((key) => tableColumns.find((column) => column.settingKey === key)).filter(truthy),
          tableProps.sortBy,
          tableProps.sortDir,
        )}
        rowKey={getRowKey}
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedTransferTargets.map(getRowKey),
          onChange: (newSelectedRowKeys: React.Key[]) => {
            const transferTargetsData = [...selectedTransferTargets, ...tableProps.items];
            const newSelection = newSelectedRowKeys
              .map((key) => transferTargetsData.find((st) => getRowKey(st) === key))
              .filter(truthy);
            updateHashState({ selectedTransferTargets: newSelection });
          },
        }}
      />
    </Container>
  );
}

const getRowKey = (record: TransferTargetDTO) => `${record.sourceLocationId}|${record.destinationLocationId}`;
