import React from 'react';

import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { LeadTimeHistoryChartTable } from 'pages/purchasing/PlanningPage/modals/LeadTimeHistoryChartTable';

import { ActionButton } from 'components/Button/ActionButton';
import { MiniTableSkeleton } from 'components/MiniTable';
import { InventoryUsagePanelContent } from 'components/recipes/sidePane/InventoryUsagePanelContent';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { MinMaxPanelContent } from 'components/recipes/sidePane/MinMaxPanelContent';
import { OrderPointOrderQuantityPanelContent } from 'components/recipes/sidePane/OrderPointOrderQuantityPanelContent';
import { PurchaseOrderLinesPanelContent } from 'components/recipes/sidePane/PurchaseOrderLinesPanelContent';
import { SalesInvoiceLinesPanelContent } from 'components/recipes/sidePane/SalesInvoiceLinesPanelContent';
import { SalesOrderLinesPanelContext } from 'components/recipes/sidePane/SalesOrderLinesPanelContext';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { UnitOfMeasuresPanelContent } from 'components/recipes/sidePane/UnitOfMeasuresPanelContent';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';

import { usePromise } from 'hooks/usePromise';

import { showAsyncModal } from 'utils/asyncModal';
import { captureError } from 'utils/error';
import { formatUSD, joinIdNameObj, pluralize } from 'utils/formatting';
import { objRemoveUndefinedValues } from 'utils/object';
import { searchIndex } from 'utils/search/search';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

import { PurchaseGroupHashState } from 'types/hash-state';
import { SearchIndexMinMax } from 'types/search-collections';

import { GenericTargetLineRow } from '../../types';
import { ItemNotesPanelContent } from './ItemNotesPanelContent';
import { LocationStockPanelContent } from './LocationStockPanelContent';
import { SupplierNotesPanelContent } from './SupplierNotesPanelContent';

export function ItemDetailsSidePane({
  itemInfo,
  allowMinMaxEdit = false,
  isHubAndSpoke = false,
  onMinMaxChange,
  purchaseGroup,
}: {
  itemInfo: GenericTargetLineRow;
  allowMinMaxEdit?: boolean;
  onMinMaxChange?: () => void;
  isHubAndSpoke?: boolean;
  purchaseGroup?: PurchaseGroupHashState;
}) {
  const { itemId, itemName: name, itemUid } = itemInfo;
  const locationId = isHubAndSpoke ? itemInfo.purchaseLocationId : itemInfo.locationId;
  const locationName = isHubAndSpoke ? itemInfo.purchaseLocationName : itemInfo.locationName;
  const supplierStr = joinIdNameObj({ foreignId: itemInfo.supplierId, name: itemInfo.supplierName });

  const {
    data: searchIndexResponse,
    isLoading: isPlanningIndexLoading,
    reload: reloadPlanningIndex,
  } = usePromise(
    () =>
      searchIndex<SearchIndexMinMax>({
        indexName: SearchIndexName.Planning,
        filters: objRemoveUndefinedValues({
          ...(locationId && locationName
            ? { location: [joinIdNameObj({ foreignId: locationId, name: locationName })] }
            : {}),
          item_id: [itemId],
        }),
        hitsPerPage: 1,
      }),
    [itemId, locationId],
    {
      cacheKey: `search:${JSON.stringify([SearchIndexName.Planning, itemId, locationId])}`,
      onError: captureError,
    },
  );

  const minMaxRecord = searchIndexResponse?.hits?.[0];

  const detailProps: Array<LabelValueProperty | null> = [
    minMaxRecord?.short_code ? { label: 'Short Code', value: minMaxRecord?.short_code } : null,
    minMaxRecord?.item_group ? { label: 'Product Group', value: minMaxRecord?.item_group } : null,
    itemInfo.supplierPartId ? { label: 'Supplier Part ID', value: itemInfo.supplierPartId } : null,
    minMaxRecord?.lead_time
      ? {
          label: 'Lead Time',
          value: (
            <>
              {pluralize(minMaxRecord.lead_time, 'day', 'days', true)}
              {` | `}
              <ActionButton
                label="View History"
                onClick={() =>
                  showAsyncModal(ViewAllModal, {
                    title: `Lead Time History for Item #${minMaxRecord.item_id} at Location ${minMaxRecord.location}`,
                    content: <LeadTimeHistoryChartTable record={minMaxRecord} />,
                  })
                }
              />
            </>
          ),
        }
      : null,
    itemInfo.unitCost ? { label: 'System Unit Cost', value: formatUSD(itemInfo.unitCost, true) } : null,
  ];

  const isOpOqItem = minMaxRecord?.replenishment_method === 'OP/OQ';
  const itemLocations =
    isHubAndSpoke && purchaseGroup
      ? [
          { foreignId: purchaseGroup.purchaseLocationId, name: purchaseGroup.purchaseLocationName },
          ...(purchaseGroup.spokeLocations ?? []),
        ]
      : [
          {
            foreignId: itemInfo.locationId as string,
            name: itemInfo.locationName as string,
          },
        ];

  const itemLocationIds = itemLocations.map((i) => i.foreignId);

  return (
    <SidePane
      title={`Item #${itemInfo.itemId} at ${
        isHubAndSpoke ? itemInfo.groupName : `${itemInfo.locationId}: ${itemInfo.locationName}`
      }`}
      settingKey={ViewSettingKey.PurchaseTargetItemDetailsSidePane}
      description={itemInfo.itemName}
      extendedDescription={itemInfo.itemDescription}
      content={isPlanningIndexLoading ? <MiniTableSkeleton /> : <LabelValueProperties properties={detailProps} />}
      panels={[
        {
          settingKey: 'usage',
          title: 'Usage',
          content: (
            <InventoryUsagePanelContent
              itemId={itemId}
              itemUid={itemUid}
              locations={itemLocations}
              purchaseGroupName={purchaseGroup?.groupName}
            />
          ),
        },
        !isHubAndSpoke
          ? {
              settingKey: 'opOqMinMax',
              title: isOpOqItem ? 'Order Point / Order Quantity' : 'Min / Max',
              content: isPlanningIndexLoading ? (
                <MiniTableSkeleton />
              ) : isOpOqItem ? (
                <OrderPointOrderQuantityPanelContent
                  minMaxRecord={minMaxRecord}
                  itemInfo={itemInfo}
                  allowOpOqEdit={allowMinMaxEdit}
                  onOpOqChange={() => {
                    reloadPlanningIndex();
                    onMinMaxChange?.();
                  }}
                />
              ) : (
                <MinMaxPanelContent
                  minMaxRecord={minMaxRecord}
                  itemInfo={itemInfo}
                  allowMinMaxEdit={allowMinMaxEdit}
                  onMinMaxChange={() => {
                    reloadPlanningIndex();
                    onMinMaxChange?.();
                  }}
                />
              ),
            }
          : null,
        {
          settingKey: 'itemNotes',
          title: 'Item Notes',
          content: <ItemNotesPanelContent uniqueItems={[{ itemId: itemUid, foreignId: itemId, name }]} />,
        },
        {
          settingKey: 'locationStock',
          title: 'Location Stock',
          content: <LocationStockPanelContent targetLine={itemInfo} />,
        },
        {
          settingKey: 'unitOfMeasures',
          title: 'Unit of Measures',
          content: <UnitOfMeasuresPanelContent itemId={itemInfo.itemId} />,
        },
        {
          settingKey: 'purchaseOrders',
          title: 'Purchase Orders',
          content: (
            <PurchaseOrderLinesPanelContent
              itemId={itemId}
              companyId={itemInfo.companyId}
              vendorId={itemInfo.vendorId}
              locationIds={itemLocationIds}
            />
          ),
        },
        {
          settingKey: 'salesOrders',
          title: 'Sales Orders',
          content: (
            <SalesOrderLinesPanelContext itemId={itemId} locationIds={itemLocationIds} companyId={itemInfo.companyId} />
          ),
        },
        {
          settingKey: 'salesHistory',
          title: 'Sales History',
          content: (
            <SalesInvoiceLinesPanelContent
              itemId={itemId}
              locationIds={itemLocationIds}
              companyId={itemInfo.companyId}
            />
          ),
        },
        {
          settingKey: 'supplierNotes',
          title: 'Supplier Notes',
          content: <SupplierNotesPanelContent uniqueSuppliers={[supplierStr]} />,
        },
      ]}
    />
  );
}
