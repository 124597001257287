import { PanelContent } from 'components/recipes/sidePane/CollapsePanels';

import { ExportColumn } from 'utils/tables';

export interface PersistedItem {
  /** key that identifies this column/pane */
  settingKey: string;
  /** Column is always shown. User cannot hide it from column selector. */
  required?: boolean;
  /** Column is not be shown by default. User can show it via column selector. */
  optional?: boolean;
}

export type PersistedColumn<ColumnT> = PersistedItem & ExportColumn<ColumnT>;

export type PersistedPanel = PersistedItem & PanelContent;

export enum ViewSettingKey {
  ForecastingSidePane = 'forecastingSidePane',
  PlanningSidePane = 'planningSidePane',
  PurchaseStatusSidePane = 'purchaseStatusSidePane',
  PurchaseTargetItemDetailsSidePane = 'purchaseTargetItemDetailsSidePane',
  PurchaseTargetPOSummarySidePane = 'purchaseTargetPOSummarySidePane',
  PlanningTable = 'planningTable',
  PlanningTableOPOQ = 'planningTableOPOQ',
  PlanningOnboardingTable = 'planningOnboardingTable',
  PurchaseStatusTable = 'purchaseStatusTable',
  PurchaseTargetsTable = 'purchaseTargetsTable',
  TransferTargetsTable = 'transferTargetsTable',
  PurchaseGroupsTable = 'purchaseGroupsTable',
  PurchaseTargetLinesTable = 'purchaseTargetLinesTable',
  InventoryStatusExplorerTable = 'inventoryStatusExplorerTable',
}
