import React, { useState } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Empty } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { ForecastChart } from 'components/Charts/ForecastChart';
import { FilterBarBox } from 'components/FilterBarBox';
import { SmallLoader } from 'components/Loaders';
import { Table } from 'components/Table';

import { splitIdNameStr } from 'utils/formatting';
import { sortableNumberColumn } from 'utils/tables';

import { IdNameObj } from 'types/legacy-api';
import { SearchIndexForecast } from 'types/search-collections';

import { LastUpdatedMomentForecast } from './LastUpdatedMoment';
import { getMergedForecastRecords } from './sidePane/sidePane.utils';

export const InventoryUsageTable = ({
  exactHits,
  locations,
  isLoading,
}: {
  exactHits?: SearchIndexForecast[];
  locations?: IdNameObj[];
  isLoading: boolean;
}) => {
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);

  const tableColumns: ColumnType<{ date: string; usage: number }>[] = [
    {
      title: 'Year',
      dataIndex: 'date',
      defaultSortOrder: 'descend',
      render: (value) => moment(value).format('YYYY'),
    },
    {
      title: (
        <div
          className={css`
            padding-left: 50%;
          `}
        >
          Month
        </div>
      ),
      dataIndex: 'date',
      render: (value) => (
        <div
          className={css`
            padding-left: 50%;
          `}
        >
          {moment(value).format('MMMM')}
        </div>
      ),
    },
    sortableNumberColumn({
      title: 'Usage',
      dataIndex: 'usage',
    }),
    {
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    },
  ];

  const filteredHits = exactHits?.filter((hit) => {
    const hitLocationId = splitIdNameStr(hit.location).foreignId;
    return filteredLocationIds.length > 0 ? filteredLocationIds.includes(hitLocationId) : true;
  });

  const record = getMergedForecastRecords(filteredHits);

  return (
    <>
      {locations && locations.length > 1 && (
        <FilterBarBox>
          <AsyncMultiSelect
            selectProps={{
              options: locations.map((l) => ({
                value: l.foreignId,
                label: l.name,
              })),
            }}
            label="Location"
            queryPlaceholder="Search locations"
            selectedValues={filteredLocationIds}
            onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
            icon={<EnvironmentOutlined />}
          />
        </FilterBarBox>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '12px' }}>
        <LastUpdatedMomentForecast />
      </div>
      {isLoading || record ? (
        <>
          {record ? (
            <ForecastChart
              data={[
                ...record.historical_dates.map((_, i) => ({
                  date: moment(record.historical_dates[i]).valueOf(),
                  historical: record.historical_demand[i],
                  type: 'historical' as const,
                  connection: record.historical_demand.length - 1 === i ? record.historical_demand[i] : undefined,
                  unitOfMeasure: record.unit_of_measure,
                })),
                ...record.forecast_dates.map((_, i) => ({
                  date: moment(record.forecast_dates[i]).valueOf(),
                  forecast: record.forecast_demand[i],
                  type: 'forecast' as const,
                  connection: i === 0 ? record.forecast_demand[i] : undefined,
                  unitOfMeasure: record.unit_of_measure,
                })),
              ]}
            />
          ) : (
            <SmallLoader />
          )}
          <Table
            pageSize={10}
            isLoading={isLoading}
            data={getUsageTableRowsFromForecastRecord(record)}
            columns={tableColumns}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export function getUsageTableRowsFromForecastRecord(record: SearchIndexForecast | undefined) {
  return record
    ? record.historical_dates
        .map((value, index) => ({
          date: value,
          usage: record.historical_demand[index],
          unitOfMeasure: record.unit_of_measure,
        }))
        .sort((a, b) => (a.date < b.date ? 1 : -1))
    : [];
}
