import React from 'react';

import { css } from '@emotion/css';

import { FlexSpace } from 'components/FlexSpace';
import { Select } from 'components/Select';

import { extractDaysOfWeek } from './utils';

interface RepeatingScheduleSelectorProps {
  id?: string;
  value: string;
  onChange?: (value: string) => void;
}

const DEFAULT_SCHEDULE = '0 0 * * 1-1';

export const RepeatingScheduleSelector = ({ id, value, onChange }: RepeatingScheduleSelectorProps) => {
  const daysOfTheWeek = [
    { value: 0, label: 'Sun' },
    { value: 1, label: 'Mon' },
    { value: 2, label: 'Tue' },
    { value: 3, label: 'Wed' },
    { value: 4, label: 'Thu' },
    { value: 5, label: 'Fri' },
    { value: 6, label: 'Sat' },
  ];

  const repeatOptions = [...Array(16).keys()].map((_, i) => ({ value: i + 1, label: `${i + 1}` }));

  const [cron, weeksString] = (value || DEFAULT_SCHEDULE).split('-');
  const weeks = parseInt(weeksString, 10);
  const days = extractDaysOfWeek(cron);

  function onEdit(days: number[], weeks: number) {
    if (onChange) onChange(`0 0 * * ${days.sort().join(',')}-${weeks}`);
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 12px;
      `}
      id={id}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
        `}
      >
        <FlexSpace>
          {daysOfTheWeek.map((day) => {
            const checked = days.includes(day.value);
            return (
              <div
                key={day.value}
                className={css`
                  background-color: ${checked ? 'lightgray' : 'white'};
                  border: 1px solid lightgray;
                  padding: 6px;
                  border-radius: 4px;
                  cursor: pointer;
                  width: 45px;
                  text-align: center;
                `}
                onClick={() =>
                  checked
                    ? onEdit(
                        days.filter((oldDay) => oldDay !== day.value),
                        weeks,
                      )
                    : onEdit([...days, day.value], weeks)
                }
              >
                {day.label}
              </div>
            );
          })}
        </FlexSpace>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            gap: 4px;
            width: 100%;
            align-items: center;
            justify-content: center;
          `}
        >
          Repeat every{' '}
          <Select style={{ width: 70 }} onChange={(e) => onEdit(days, e)} value={weeks} options={repeatOptions} />
          week{weeks > 1 ? 's' : ''}
        </div>
      </div>
    </div>
  );
};
