import React from 'react';

import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { InventoryUsageTable } from 'components/recipes/InventoryUsageTable';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';

import { usePromise } from 'hooks/usePromise';

import { formatIdNameObj, joinObjectId, splitIdNameStr } from 'utils/formatting';
import { searchIndex } from 'utils/search/search';

import { SearchIndexForecast } from 'types/search-collections';

export function DemandPlanningContextModal({
  itemId,
  itemUid,
  location,
  onClose,
}: {
  itemId: string;
  itemUid: string;
  location: string;
  onClose: () => void;
}) {
  const locationObj = splitIdNameStr(location);
  const locationId = locationObj.foreignId;
  const { data: searchIndexResponse, isLoading } = usePromise(
    () =>
      searchIndex<SearchIndexForecast>({
        indexName: SearchIndexName.Forecasts,
        filters: { id: [joinObjectId([itemUid, locationId])] },
        hitsPerPage: 1,
      }),
    [locationId, itemId],
  );

  const data = searchIndexResponse?.hits;

  return ViewAllModal({
    title: `Usage for Item #${itemId} at Location ${formatIdNameObj(locationObj)}`,
    content: <InventoryUsageTable exactHits={data} itemUid={itemUid} locations={[locationObj]} isLoading={isLoading} />,
    onClose,
  });
}
