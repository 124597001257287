export const colors = {
  neutral: {
    900: 'hsl(240, 35%, 8%)',
    800: 'hsl(240, 26%, 21%)',
    700: 'hsl(240, 26%, 28%)',
    600: 'hsl(240, 26%, 37%)',
    500: 'hsl(240, 14%, 51%)',
    400: 'hsl(240, 16%, 66%)',
    300: 'hsl(240, 19%, 85%)',
    200: 'hsl(240, 23%, 95%)',
    100: 'hsl(240, 29%, 97%)',
    75: 'hsl(240, 23%, 97%)',
    50: 'hsl(240, 33%, 99%)',
    0: 'hsl(240, 33%, 100%)',
  },
  primary: {
    800: 'hsl(201, 100%, 14%)',
    700: 'hsl(197, 67%, 21%)',
    600: 'hsl(190, 63%, 27%)',
    500: 'hsl(188, 41%, 38%)',
    400: 'hsl(188, 25%, 60%)',
    300: 'hsl(184, 31%, 80%)',
    200: 'hsl(184, 32%, 89%)',
    100: 'hsl(187, 33%, 95%)',
  },
  danger: {
    700: 'hsl(0, 64%, 40%)',
    600: 'hsl(360, 64%, 50%)',
    500: 'hsl(0, 64%, 60%)',
    200: 'hsl(0, 64%, 94%)',
    100: 'hsl(0, 64%, 97%)',
  },
  info: {
    700: 'hsl(222, 64%, 40%)',
    600: 'hsl(222, 64%, 50%)',
    500: 'hsl(222, 64%, 60%)',
    200: 'hsl(222, 64%, 94%)',
    100: 'hsl(222, 64%, 97%)',
  },
  success: {
    700: 'hsl(160, 64%, 20%)',
    600: 'hsl(160, 64%, 30%)',
    500: 'hsl(160, 64%, 40%)',
    200: 'hsl(160, 64%, 94%)',
    100: 'hsl(160, 64%, 97%)',
  },
  warning: {
    700: 'hsl(36, 80%, 30%)',
    600: 'hsl(36, 80%, 40%)',
    500: 'hsl(36, 80%, 60%)',
    200: 'hsl(36, 80%, 94%)',
    100: 'hsl(40, 75%, 97%)',
  },
  link: {
    700: 'hsl(222, 70%, 30%)',
    600: 'hsl(222, 70%, 40%)',
    500: 'hsl(222, 70%, 50%)',
    400: 'hsl(222, 80%, 75%)',
    300: 'hsl(221, 79%, 85%)',
  },
  malibu: {
    700: 'hsl(14, 60%, 48%)',
    600: 'hsl(14, 100%, 68%)',
    500: 'hsl(14, 100%, 78%)',
    200: 'hsl(14, 100%, 94%)',
    100: 'hsl(14, 100%, 97%)',
  },
  ocean: {
    700: 'hsl(177, 66%, 33%)',
    600: 'hsl(177, 66%, 43%)',
    500: 'hsl(177, 66%, 53%)',
    200: 'hsl(177, 66%, 94%)',
    100: 'hsl(177, 66%, 97%)',
  },
  chart: {
    oceanTeal: 'hsl(177, 66%, 53%)',
    oceanTealLight: 'hsl(177, 66%, 80%)',
    malibuOrange: 'hsl(14, 100%, 78%)',
    malibuOrangeLight: 'hsl(14, 100%, 90%)',
    sunriseYellow: 'hsl(40, 90%, 70%)',
    sunriseYellowLight: 'hsl(40, 90%, 80%)',
    flamingoPink: 'hsl(316, 60%, 60%)',
    flamingoPinkLight: 'hsl(316, 60%, 80%)',
    sunsetPurple: 'hsl(267, 50%, 60%)',
    sunsetPurpleLight: 'hsl(267, 50%, 80%)',
    lagoonBlue: 'hsl(210, 100%, 70%)',
    lagoonBlueLight: 'hsl(210, 100%, 90%)',
    aloeGreen: 'hsl(146, 60%, 60%)',
    aloeGreenLight: 'hsl(146, 59%, 80%)',
  },
};

export type ColorPalette = typeof colors;
