import React, { useMemo, useState } from 'react';

import { DashboardOutlined, DownOutlined } from '@ant-design/icons';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TransferTargetLineDTO } from '@recurrency/core-api-schema/dist/transferOrders/getTransferTargetLines';
import { Menu } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import { allStatuses } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ResultCount } from 'components/ResultCount';
import { SplitPage } from 'components/SplitPage';

import { useHashState } from 'utils/routes';

import { TransferTargetLinesHashState } from 'types/hash-state';

import { TransferTargetLinesTable } from './TransferTargetLinesTable';

export function TransferTargetLinesAllLines({ targetLines }: { targetLines: TransferTargetLineDTO[] }) {
  const [hashState, updateHashState] = useHashState<TransferTargetLinesHashState>();
  const [focusedLine, setFocusedLine] = useState<TransferTargetLineDTO | undefined>();

  const [sortField, setSortField] = useState<keyof TransferTargetLineDTO>('qtyRequired');
  const [sortDir, setSortDir] = useState<SortOrder>('descend');
  const statusesFilter = hashState.statuses;

  const filteredLines = useMemo(() => {
    const lines = targetLines.filter(
      (line) => !statusesFilter || statusesFilter.length === 0 || statusesFilter.includes(line.status),
    );

    const sortedLines = lines.sort((a, b) => {
      if (a[sortField] > b[sortField]) return sortDir === 'ascend' ? 1 : -1;
      if (a[sortField] < b[sortField]) return sortDir === 'ascend' ? -1 : 1;
      return 0;
    });

    return sortedLines;
  }, [sortDir, sortField, statusesFilter, targetLines]);

  return (
    <>
      <FilterBarBox dividerLine>
        <AsyncMultiSelect
          label="Status"
          icon={<DashboardOutlined />}
          selectedValues={statusesFilter || []}
          onSelectedValuesChange={(values) =>
            updateHashState({ statuses: values.length > 0 ? (values as PurchaseTargetLineStatus[]) : undefined })
          }
          selectProps={{ options: allStatuses.map((status) => ({ label: status, value: status })) }}
        />

        <FlexSpacer />

        {/* TODO @brunno: Handle clear/reset actions */}
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>Clear All Transfers</Menu.Item>
              <Menu.Item>Reset All to Recommended</Menu.Item>
            </Menu>
          }
        >
          <Button size="small">
            Reset <DownOutlined />
          </Button>
        </Dropdown>

        <ResultCount count={filteredLines.length} />
      </FilterBarBox>
      <SplitPage
        left={
          <TransferTargetLinesTable
            targetLines={filteredLines}
            focusedLine={focusedLine}
            setSortDir={setSortDir}
            setSortField={setSortField}
            setFocusedLine={setFocusedLine}
          />
        }
        // TODO @brunno-recurrency Add sidepane for item details
        right={null}
      />
    </>
  );
}
