import React, { ReactNode } from 'react';

import {
  GetSalesHistoryReportSortByField,
  GetSalesInvoiceLinesReportGroupBy,
} from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { SavedViewDataPayload } from '@recurrency/core-api-schema/dist/savedViews/getSavedView';
import { theme } from 'theme';

import SupplyShelfSvg from 'assets/svgs/supply-shelf.svg';
import TopSalesReportSvg from 'assets/svgs/top-sales-report.svg';

export interface DefaultReport {
  id: string;
  name: string;
  description: string;
  color: string;
  background?: ReactNode;
  data?: SavedViewDataPayload;
}

export const defaultSalesReports: DefaultReport[] = [
  {
    id: 'customers',
    name: 'My Customers',
    description: 'Identify customers who are buying more or less than last year and give them a call.',
    color: theme.colors.chart.malibuOrangeLight,
    background: <TopSalesReportSvg />,
    data: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.Customer,
      sortBy: GetSalesHistoryReportSortByField.SalesDelta,
    },
  },
  {
    id: 'ship-tos',
    name: 'My Ship Tos',
    description: 'Find specific ship tos to focus your calls and sales efforts on.',
    color: theme.colors.warning[200],
    background: <TopSalesReportSvg />,
    data: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.ShipTo,
      sortBy: GetSalesHistoryReportSortByField.SalesDelta,
    },
  },
  {
    id: 'items',
    name: 'My Items',
    description: 'Identify items you sell that are over and underperforming versus last year.',
    color: theme.colors.chart.oceanTealLight,
    background: <SupplyShelfSvg />,
    data: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.Item,
      sortBy: GetSalesHistoryReportSortByField.SalesDelta,
    },
  },
];

export const defaultAdminReports: DefaultReport[] = [
  {
    id: 'territories',
    name: 'Top Territories',
    description: 'View the rising sales reps on your team, and monitor their success to help the rest of your team.',
    color: theme.colors.chart.oceanTealLight,
    background: <TopSalesReportSvg />,
    data: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.SalesRep,
      sortBy: GetSalesHistoryReportSortByField.SalesDelta,
    },
  },
  {
    id: 'customers',
    name: 'Top Customers',
    description: 'Identify customers who are buying more or less than last year and give them a call.',
    color: theme.colors.chart.malibuOrangeLight,
    background: <TopSalesReportSvg />,
    data: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.Customer,
      sortBy: GetSalesHistoryReportSortByField.SalesDelta,
    },
  },
  {
    id: 'items',
    name: 'Top Items',
    description: 'Identify items you sell that are over and underperforming versus last year.',
    color: theme.colors.warning[200],
    background: <SupplyShelfSvg />,
    data: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.Item,
      sortBy: GetSalesHistoryReportSortByField.SalesDelta,
    },
  },
];
