import React from 'react';

import { Link } from 'react-router-dom';

import { CustomerType } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { Alert } from 'components/Alert';
import { Description, EntityDescriptions } from 'components/Descriptions';
import { Typography } from 'components/Typography';

import { usePromise } from 'hooks/usePromise';

import { truthy } from 'utils/boolean';
import { formatPartialAddress, splitIfIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import { searchIndex } from 'utils/search/search';

import { ProspectHashState } from 'types/hash-state';
import { SearchIndexCustomer } from 'types/search-collections';

export const ProspectReviewStep = ({ prospectState }: { prospectState: ProspectHashState }) => {
  const prospectDescriptions: Description[] = [
    { label: 'Customer Name', value: prospectState.customerName },
    {
      label: 'Physical Address',
      value: formatPartialAddress({
        address1: prospectState['physical-address1'],
        address2: prospectState['physical-address2'],
        city: prospectState['physical-city'],
        state: prospectState['physical-state'],
        zip: prospectState['physical-zipcode'],
      }),
    },
    {
      label: 'Mailing Address',
      value: prospectState.addressesSame
        ? formatPartialAddress({
            address1: prospectState['physical-address1'],
            address2: prospectState['physical-address2'],
            city: prospectState['physical-city'],
            state: prospectState['physical-state'],
            zip: prospectState['physical-zipcode'],
          })
        : formatPartialAddress({
            address1: prospectState['mailing-address1'],
            address2: prospectState['mailing-address2'],
            city: prospectState['mailing-city'],
            state: prospectState['mailing-state'],
            zip: prospectState['mailing-zipcode'],
          }),
    },
    { label: 'Central Phone', value: prospectState.customerPhone },
    { label: 'Central Fax', value: prospectState.customerFax },
    { label: 'Email', value: prospectState.customerEmail },
    { label: 'Assigned Sales Rep', value: splitIfIdNameStr(prospectState.salesRep)?.foreignId },
    { label: 'Company', value: prospectState.company },
    prospectState.companyPerson ? { label: 'IVA Company/Person', value: prospectState.companyPerson } : null,
    prospectState.ivaExemptionNumber
      ? { label: 'IVA Exemption Number', value: prospectState.ivaExemptionNumber.toLocaleUpperCase() }
      : null,
    prospectState.taxRegime ? { label: 'Tax Regime', value: prospectState.taxRegime } : null,
  ].filter(truthy);

  const contactDescriptions: Description[] = [
    {
      label: 'First Name',
      value: prospectState.contactFirstName,
    },
    { label: 'Last Name', value: prospectState.contactLastName },
    { label: 'Title', value: prospectState.contactTitle },
    { label: 'Direct Phone', value: prospectState.contactPhone },
    { label: 'Direct Fax', value: prospectState.contactFax },
    { label: 'Direct Email', value: prospectState.contactEmail },
  ];

  const shipToDescriptions: Description[] = [
    { label: 'Ship To Name', value: prospectState.customerName },
    {
      label: 'Address',
      value: formatPartialAddress({
        address1: prospectState['physical-address1'],
        address2: prospectState['physical-address2'],
        city: prospectState['physical-city'],
        state: prospectState['physical-state'],
        zip: prospectState['physical-zipcode'],
      }),
    },
    { label: 'Default Branch', value: prospectState.shipToDefaultBranch },
    { label: 'Default Freight', value: prospectState.shipToDefaultFreight },
    { label: 'Default Location', value: prospectState.shipToPreferredLocation },
  ];

  const {
    data: searchIndexResponse,
    isLoading: isHitsLoading,
    error,
  } = usePromise(
    () =>
      searchIndex<SearchIndexCustomer>({
        indexName: SearchIndexName.Customers,
        query: prospectState.customerName,
        hitsPerPage: 5,
      }),
    [prospectState.customerName],
  );

  return (
    <>
      <Typography type="large">{prospectState.type === CustomerType.Customer ? 'Customer' : 'Prospect'}</Typography>
      <EntityDescriptions descriptions={prospectDescriptions} />
      {prospectState.customerName && !isHitsLoading && !error && (searchIndexResponse?.hits?.length ?? 0) > 0 && (
        <Alert
          message="Similar Customers Found"
          description={
            <>
              <div>The customer name you used is similar to these customers:</div>
              <ul>
                {searchIndexResponse?.hits.map(({ customer_name, customer_id }) => (
                  <li key={customer_id}>
                    <Link to={routes.sales.customerDetails(customer_id)}>{customer_id}</Link>: {customer_name}
                  </li>
                ))}
              </ul>
            </>
          }
          type="warning"
          closable
        />
      )}
      {
        // Only show contact if one is going to be created
        (prospectState.contactFirstName ||
          prospectState.contactLastName ||
          prospectState.contactTitle ||
          prospectState.contactPhone ||
          prospectState.contactFax ||
          prospectState.contactEmail) && (
          <>
            <Typography type="large">Contact</Typography>
            <EntityDescriptions descriptions={contactDescriptions} />
          </>
        )
      }
      {
        // Only show shipto if one is going to be created
        prospectState.makeShipTo && (
          <>
            <Typography type="large">Ship To</Typography>
            <EntityDescriptions descriptions={shipToDescriptions} />
          </>
        )
      }
    </>
  );
};
