import React from 'react';

import { css } from '@emotion/css';
// import { RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Progress, Skeleton } from 'antd';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';

import { formatNumber, formatPercent } from 'utils/formatting';
import { useSearchIndex } from 'utils/search/search';

import { SearchIndexMinMax } from 'types/search-collections';

export function OnboardingProgress() {
  const {
    data: searchPlanningData,
    isLoading: isLoadingSearchPlanningData,
    error: searchPlanningDataError,
  } = useSearchIndex<SearchIndexMinMax>({
    indexName: SearchIndexName.Planning,
    hitsPerPage: 0,
    fieldsToRetrieve: ['item_id', 'planning_status', 'recurrency_replenishment_method'],
    facetFields: ['recurrency_replenishment_method'],
  });

  // Calculating percentages
  const facets = searchPlanningData?.facets.recurrency_replenishment_method;
  const unsupportedCount = facets?.find((f) => f.value === RecurrencyReplenishmentMethod.Unsupported)?.count ?? 0;
  const totalValue = searchPlanningData?.nbHits;
  const currentValue = totalValue ? totalValue - unsupportedCount : undefined;

  if (isLoadingSearchPlanningData)
    return (
      <OnboardingProgressWrapper>
        <Skeleton.Button
          active
          shape="round"
          className={css`
            .ant-skeleton-button {
              height: 20px;
              width: 364px;
            }
          `}
        />
      </OnboardingProgressWrapper>
    );

  if (searchPlanningDataError || !currentValue || !totalValue) {
    return (
      <OnboardingProgressWrapper>
        <b>Error loading items setup progress</b>
      </OnboardingProgressWrapper>
    );
  }

  return (
    <OnboardingProgressWrapper>
      <span
        className={css`
          white-space: nowrap;
        `}
      >
        {formatNumber(currentValue)} of {formatNumber(totalValue)} items set up{' '}
        <b
          className={css`
            margin-left: 10px;
            color: ${theme.colors.primary[600]};
          `}
        >
          {formatPercent(currentValue / totalValue, 1)}
        </b>
      </span>
      <Progress percent={(currentValue / totalValue) * 100} showInfo={false} strokeColor={theme.colors.primary[600]} />
    </OnboardingProgressWrapper>
  );
}

const OnboardingProgressWrapper = ({ children }: { children: React.ReactNode }) => (
  <FlexSpace
    className={css`
      margin-top: 10px;
      width: 390px;
      height: 40px;
      padding: 8px 12px;
      gap: 8px;
      border-radius: 8px;
      color: ${theme.colors.primary[800]};
      border: 1px solid ${theme.colors.primary[300]};
    `}
  >
    {children}
  </FlexSpace>
);
