import React from 'react';

import { css } from '@emotion/css';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';

import { variants } from 'components/Badge';
import { colorByStatus } from 'components/recipes/PurchaseTargetLineStatusBadge';
import { Typography } from 'components/Typography';

import { formatNumber } from 'utils/formatting';

export function LineStatusCountsPanelContent({
  lineStatusCounts,
}: {
  lineStatusCounts: Record<PurchaseTargetLineStatus, number>;
}) {
  const statusRows = Object.entries(lineStatusCounts)
    .map(([status, count]) => ({ status, count }))
    .sort((a, b) => (a.count > b.count ? -1 : 1));

  return (
    <>
      <ResponsiveContainer height={200} width="100%">
        <PieChart width={200} height={400}>
          <Pie
            data={statusRows}
            cx="50%"
            cy="50%"
            outerRadius="100%"
            dataKey="count"
            valueKey="status"
            nameKey="status"
            isAnimationActive={false}
          >
            {statusRows.map(({ status }) => (
              <Cell
                key={`cell-${status}`}
                fill={variants[colorByStatus[status as PurchaseTargetLineStatus]].borderColor as string}
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value) => formatNumber(Number(value))}
            content={(e: TooltipProps) => {
              const { payload } = e;
              if (e.active && payload && payload.length) {
                return (
                  <div
                    className={css`
                      background-color: rgba(255, 255, 255, 0.9);
                      font-weight: 500;
                      border-radius: 8px;
                      border: 2px solid
                        ${variants[colorByStatus[payload[0].name as PurchaseTargetLineStatus]].borderColor};
                    `}
                  >
                    <div
                      className={css`
                        padding: 6px;
                      `}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>{payload[0].name}</Typography>
                      <Typography>{formatNumber(payload[0].value as number)}</Typography>
                    </div>
                  </div>
                );
              }

              return null;
            }}
          />
          {/* @ts-expect-error recharts typing is missing that it actually returns payload as well */}
          <Legend formatter={(_, entry) => `${entry?.value}: ${entry?.payload?.value}`} align="center" />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}
