import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { MultiSelectOption } from 'components/AsyncSelect/types';

import { arrUnique } from 'utils/array';
import { truthy } from 'utils/boolean';
import { formatIdNameObj } from 'utils/formatting';

import { GenericTargetLineDTO, GenericTargetLineRow } from '../types';

export const allStatuses = Object.values(PurchaseTargetLineStatus);
export const nonStockStatuses = allStatuses.filter((s) => s !== PurchaseTargetLineStatus.Nonstock);

export function focusedRowClassName(record: GenericTargetLineRow, focusedLine?: GenericTargetLineRow) {
  return [
    'focusable-row',
    record.key === focusedLine?.key ? 'focused-row' : '',
    record.userQtyToOrder > 0 ? 'purchasing-row' : '',
  ]
    .filter(truthy)
    .join(' ');
}

export function getUniqueItemGroupOptions(targetLines: GenericTargetLineDTO[]) {
  const options: MultiSelectOption[] = arrUnique(
    targetLines.filter((tl) => !!tl.itemGroupId),
    'itemGroupId',
  ).map((tl) => ({
    value: tl.itemGroupId!,
    label: formatIdNameObj({ foreignId: tl.itemGroupId!, name: tl.itemGroupName ?? '' }),
  }));

  return options;
}
