import React, { useMemo, useState } from 'react';

import { CloseOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  IntegratedErps,
  TenantIntegrationStatus,
  TenantProductName,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantUserErpRolePayload } from '@recurrency/core-api-schema/dist/tenants/postCreateTenantUser';
import { PutUpdateTenantUserBodyParams } from '@recurrency/core-api-schema/dist/tenants/putUpdateTenantUser';
import { TenantUserDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';
import {
  ErpRole,
  ErpRoleForeignIdType,
  ErpRoleMetadataMap,
  RecurrencyRole,
  RecurrencyRoleMetadataMap,
} from '@recurrency/core-api-schema/dist/users/common';
import { Form, FormInstance, notification } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { AsyncSelect } from 'components/AsyncSelect';
import { useSalesRepsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Badge } from 'components/Badge';
import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { CheckboxFormItem } from 'components/FormItems';
import { Input } from 'components/Input';
import { SmallLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { ModalSectionTitle } from 'components/Modal/ModalSectionTitle';
import { LabelWithDescription } from 'components/recipes/select/LabelWithDescription';
import { Select } from 'components/Select';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { splitIfIdNameStr } from 'utils/formatting';
import { shouldShowProduct } from 'utils/roleAndTenant';
import { asKeyOf } from 'utils/tables';

import { UserPermissionsFields } from './types';
import { UserCompaniesSelect } from './UserCompaniesSelect';

const defaultErpRole = { name: ErpRole.Admin, foreignId: undefined };

export const convertUserPermissionsForSubmit = (fields: UserPermissionsFields): PutUpdateTenantUserBodyParams => {
  // Aggregate recurrency roles that are true into final array
  const recurrencyRoles = Object.entries(fields.recurrencyRoles)
    .filter(([, value]) => value)
    .map(([role]) => ({
      name: role as RecurrencyRole,
    }));

  // Clear allowedCompanyIds if company restriction is not enabled
  if (!fields.isCompanyRestricted) {
    fields.allowedCompanyIds = [];
  }

  // Deduplicate erp roles and format foreignIds
  const allRoles = fields.roles
    ? fields.roles.map((role) => {
        const { name, foreignId } = role;
        const foreignIdStrings = Array.isArray(foreignId) ? foreignId : [foreignId ?? ''];
        const foreignIds = foreignIdStrings.map((id) => splitIfIdNameStr(id)?.foreignId ?? id);
        if (
          ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.SingleRep ||
          ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.Text
        ) {
          return { name, foreignId: foreignIds[0] };
        }
        if (ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.MultipleRep) {
          return { name, foreignId: foreignIds.join(',') };
        }
        return { name, foreignId: '' };
      })
    : [defaultErpRole];
  const deduplicatedRoles = [
    ...new Map<string, TenantUserErpRolePayload>(allRoles.map((r) => [`${r.name}:${r.foreignId}`, r])).values(),
  ];

  return { isActive: true, roles: deduplicatedRoles, recurrencyRoles, allowedCompanyIds: fields.allowedCompanyIds };
};

const UserPermissionsInternalBadge = () => {
  const { activeUser } = useGlobalApp();
  return activeUser.isRecurrencyInternalUser ? (
    <Tooltip
      title="Only visible to Recurrency internal users, unless enabled for a specific user. If a user is assigned an internal role or permission, admins for that tenant can see and modify that role or permission."
      placement="right"
    >
      <Badge variant="danger" label="Internal" size="small" />
    </Tooltip>
  ) : (
    <Tooltip
      title="This role or permission is managed by the Recurrency team. To assign it to additional users, contact support."
      placement="right"
    >
      <Badge variant="info" label="Managed" size="small" />
    </Tooltip>
  );
};

export function UserPermissionsModal({
  tenantUser,
  onClose,
}: {
  tenantUser: TenantUserDTO;
  onClose: (shouldReload?: boolean) => void;
}) {
  const [form] = Form.useForm<UserPermissionsFields>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdatePermissions = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      notification.error({ message: 'Please resolve all validation errors and then resubmit.' });
      return;
    }
    setIsSubmitting(true);
    try {
      const fields = form.getFieldsValue();
      const filledFields = { ...fields, roles: fields.roles ?? tenantUser.roles };
      await coreApiFetch(schemas.tenants.putUpdateTenantUser, {
        bodyParams: convertUserPermissionsForSubmit(filledFields),
        pathParams: { tenantId: tenantUser.tenantId, userId: tenantUser.userId },
      });
      notification.success({ message: `Updated permissions for ${tenantUser.user.email}` });
      onClose(true);
    } catch (err) {
      captureAndShowError(err, `Error while updating user permissions. Please try again.`);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      visible
      onCancel={() => onClose()}
      title={`Manage Access (${tenantUser.user.email})`}
      onOk={handleUpdatePermissions}
      okText="Save Changes"
      confirmLoading={isSubmitting}
    >
      <UserPermissionsForm tenantUser={tenantUser} form={form} />
    </Modal>
  );
}

export function UserPermissionsForm({ form, tenantUser }: { form: FormInstance; tenantUser?: TenantUserDTO }) {
  const { activeTenant, activeUser } = useGlobalApp();

  const salesRepsSelectProps = useSalesRepsSelectProps({});

  const initialValues = useMemo(
    () => ({
      recurrencyRoles: Object.fromEntries(tenantUser?.recurrencyRoles.map((role) => [role.name, true]) ?? []),
      isCompanyRestricted: (tenantUser?.allowedCompanyIds?.length ?? 0) > 0,
      allowedCompanyIds: tenantUser?.allowedCompanyIds ?? [],
      roles:
        tenantUser && tenantUser.roles.length > 0
          ? tenantUser.roles.map((role) => {
              if (ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.Text) {
                return role;
              }
              const foreignIds = role.foreignId.split(',');
              // Convert from foreignId to string including foreignId and sales rep name
              const foreignIdStrings = foreignIds.map((id) =>
                id ? salesRepsSelectProps.options.find((o) => o.value.startsWith(`${id}:`))?.value ?? id : undefined,
              );
              return {
                ...role,
                foreignId:
                  ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.MultipleRep
                    ? foreignIdStrings
                    : foreignIdStrings[0],
              };
            })
          : [defaultErpRole],
    }),
    [tenantUser, salesRepsSelectProps.options],
  );

  // Mirroring antd form values for the sake of accurate DOM updates for conditional fields, see https://github.com/ant-design/ant-design/issues/21829
  const [formValues, setFormValues] = useState<UserPermissionsFields>(initialValues);

  const setValue = (key: string, value: unknown) => {
    form.setFieldsValue({ [key]: value });
    setFormValues({ ...formValues, [key]: value });
  };

  const columns: ColumnType<TenantUserErpRolePayload>[] = [
    {
      title: '',
      dataIndex: asKeyOf<TenantUserErpRolePayload>('name'),
      width: '30px',
      render: (_name, _role, i) => (
        <Button
          className={css`
            margin: 8px 0;
          `}
          disabled={formValues.roles?.length === 1}
          danger
          type="text"
          size="small"
          onClick={() => {
            const roles = form.getFieldValue('roles');
            setValue('roles', roles.slice(0, i).concat(roles.slice(i + 1, roles.length)));
          }}
        >
          <CloseOutlined />
        </Button>
      ),
    },
    {
      title: 'Role',
      dataIndex: asKeyOf<TenantUserErpRolePayload>('name'),
      render: (name, _role, i) => (
        <Form.Item
          className={css`
            width: 200px;
            margin-bottom: 0;
          `}
          name={[asKeyOf<UserPermissionsFields>('roles'), i, asKeyOf<TenantUserErpRolePayload>('name')]}
        >
          <Select
            options={Object.entries(ErpRoleMetadataMap)
              .filter(([erpRole, erpRoleMetadata]) =>
                // Show internal fields if the user is internal or that role has been assigned
                erpRoleMetadata.internalOnly ? activeUser.isRecurrencyInternalUser || erpRole === name : true,
              )
              .map(([erpRole, erpRoleMetadata]) => ({
                value: erpRole,
                label: (
                  <LabelWithDescription
                    title={
                      <>
                        {erpRoleMetadata.name}
                        {activeUser.isRecurrencyAdmin && (
                          <span
                            className={css`
                              color: ${colors.neutral[500]};
                            `}
                          >
                            {' '}
                            ({erpRole})
                          </span>
                        )}
                      </>
                    }
                    description={erpRoleMetadata.description}
                    badge={erpRoleMetadata.internalOnly ? <UserPermissionsInternalBadge /> : undefined}
                  />
                ),
              }))}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Rep Code(s)',
      dataIndex: asKeyOf<TenantUserErpRolePayload>('name'),
      width: '100%',
      render: (_name, role, i) =>
        ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.None ? (
          <Select
            disabled
            placeholder="Admins see all reps"
            className={css`
              width: 100%;
            `}
          />
        ) : (
          <Form.Item
            required
            className={css`
              margin-bottom: 0;
            `}
            name={[asKeyOf<UserPermissionsFields>('roles'), i, asKeyOf<TenantUserErpRolePayload>('foreignId')]}
            rules={[{ required: true, message: 'Select a sales rep code' }]}
          >
            {ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.Text ? (
              // In future, this may hold foreign ids other than rep code such as buyer code
              <Input placeholder="Enter a rep code" />
            ) : (
              <AsyncSelect
                defaultValue={[]}
                selectProps={salesRepsSelectProps}
                entityPlural="sales reps"
                mode={
                  ErpRoleMetadataMap[role.name].foreignIdType === ErpRoleForeignIdType.MultipleRep
                    ? 'multiple'
                    : undefined
                }
              />
            )}
          </Form.Item>
        ),
    },
  ];

  if (salesRepsSelectProps.isLoading) {
    return <SmallLoader />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onValuesChange={(_, values) => setFormValues(values)}
    >
      {/* Only show ERP role management for Sales tenants, DPPA tenants use Admin for all users */}
      {shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) && (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 24px;
          `}
        >
          <ModalSectionTitle
            title={
              <>
                Roles{' '}
                <Tooltip
                  placement="right"
                  title={
                    <div>
                      Determines which set of orders, invoices, and customers a user can access. Note that each user can
                      be assigned to multiple roles, with the ability toggle between them in the top left dropdown of
                      their Recurrency page.{' '}
                      <a
                        className={css`
                          color: ${colors.link[300]};
                        `}
                        href="https://help.recurrency.com/hc/en-us/articles/25536329954971-Self-Serve-User-Management#h_01HXAD4N8BMWZA8Z9WJ41M6VAM"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more about roles.
                      </a>
                    </div>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          />
          <div>
            <Table data={formValues.roles ?? []} columns={columns} />
            <Button
              type="link"
              onClick={() => {
                const roles = form.getFieldValue('roles');
                setValue('roles', (roles || []).concat(defaultErpRole));
              }}
            >
              <PlusOutlined />
              Add Role
            </Button>
          </div>
          <DividerLine marginTop={10} marginBottom={0} />
        </div>
      )}
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <ModalSectionTitle
          title={
            <>
              Permissions{' '}
              <Tooltip
                placement="right"
                title={
                  <div>
                    Control access to specific pages and features.{' '}
                    <a
                      className={css`
                        color: ${colors.link[300]};
                      `}
                      href="https://help.recurrency.com/hc/en-us/articles/25536329954971-Self-Serve-User-Management#h_01HXAD4N8B3HBVEFVR683THY8Q"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more about permissions.
                    </a>
                  </div>
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }
        />
        <div>
          {Object.values(RecurrencyRole).map((role) => {
            const roleMetadata = RecurrencyRoleMetadataMap[role];
            if (
              (roleMetadata.internalOnly &&
                !activeUser.isRecurrencyInternalUser &&
                !initialValues.recurrencyRoles[role]) ||
              (roleMetadata.products &&
                !roleMetadata.products.some((product) => shouldShowProduct(activeTenant, product)))
            ) {
              return null;
            }
            return (
              <CheckboxFormItem
                key={role}
                name={[asKeyOf<UserPermissionsFields>('recurrencyRoles'), role]}
                className={css`
                  margin-bottom: 0;
                `}
                checkboxLabel={
                  <>
                    {RecurrencyRoleMetadataMap[role].description}
                    {roleMetadata.internalOnly && (
                      <>
                        {' '}
                        <UserPermissionsInternalBadge />
                      </>
                    )}
                    {activeUser.isRecurrencyAdmin && (
                      <span
                        className={css`
                          color: ${colors.neutral[500]};
                        `}
                      >
                        {' '}
                        ({role})
                      </span>
                    )}
                  </>
                }
              />
            );
          })}
          {activeTenant.erpType === IntegratedErps.P21 &&
            [TenantIntegrationStatus.Active, TenantIntegrationStatus.Paused].includes(
              activeTenant.integrations?.[0]?.status,
            ) && <UserCompaniesSelect formValues={formValues} setValue={setValue} />}
        </div>
      </div>
    </Form>
  );
}
