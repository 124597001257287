import React from 'react';

import { css } from '@emotion/css';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import moment from 'moment';

import { FORECAST_COLOR, HISTORICAL_COLOR } from 'pages/purchasing/utils';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import { getUsageTableRowsFromForecastRecord, InventoryUsageTable } from 'components/recipes/InventoryUsageTable';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';
import { Sparkline } from 'components/Sparkline';
import { getMonthlyFilledSeries } from 'components/Sparkline/utils';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { usePromise } from 'hooks/usePromise';

import { showAsyncModal } from 'utils/asyncModal';
import { captureError } from 'utils/error';
import { joinIdNameObj } from 'utils/formatting';
import { searchIndex } from 'utils/search/search';

import { IdNameObj } from 'types/legacy-api';
import { SearchIndexForecast } from 'types/search-collections';

import { getMergedForecastRecords } from './sidePane.utils';

export function InventoryUsagePanelContent({
  itemId,
  locations,
  purchaseGroupName,
}: {
  itemId: string;
  locations: IdNameObj[];
  purchaseGroupName?: string;
}) {
  const locationsFilter = locations.map((l) => joinIdNameObj(l));

  const {
    data: searchIndexResponse,
    isLoading,
    error,
  } = usePromise(
    () =>
      searchIndex<SearchIndexForecast>({
        indexName: SearchIndexName.Forecasts,
        filters: { item_id: [itemId], location: locationsFilter },
      }),
    [itemId, locations],
    {
      cacheKey: `search:${JSON.stringify([SearchIndexName.Forecasts, itemId, ...locations.map((l) => l.foreignId)])}`,
      onError: captureError,
    },
  );

  // Filter for exact hits before summing due to typesense special character strangeness
  // More context: https://recurrency.atlassian.net/browse/PE-3064
  const exactHits = searchIndexResponse?.hits?.filter((hit) => hit.item_id === itemId);
  const forecastRecord = getMergedForecastRecords(exactHits);
  const usageTableRows = getUsageTableRowsFromForecastRecord(forecastRecord);

  return (
    <>
      {forecastRecord && usageTableRows.length > 0 && !isLoading ? (
        <Sparkline
          className={css`
            margin-bottom: 12px;
          `}
          height={32}
          series={[
            ...getMonthlyFilledSeries({
              data: usageTableRows,
              dateField: 'date',
              valueField: 'usage',
              numPrevYears: 2,
              // usage data is calculated at end of month, so current month is always 0, we show forecast instead
              curMonthOffset: -1,
              title: 'Qty Used',
              formatFn: (value: number) => `${value} ${forecastRecord.unit_of_measure}`,
              color: HISTORICAL_COLOR,
            }),
            {
              data: forecastRecord.forecast_dates.slice(0, 3 /* 3 month forecast */).map((_, i) => ({
                date: forecastRecord.forecast_dates[i],
                value: forecastRecord.forecast_demand[i] ?? 0,
                dotted: true,
              })),
              title: 'Qty Forecasted',
              formatFn: (value: number) => `${value} ${forecastRecord.unit_of_measure}`,
              color: FORECAST_COLOR,
            },
          ]}
        />
      ) : null}
      <MiniTable
        columns={[
          { render: (record) => moment(record.date).format('YYYY MMMM') },
          { render: (record) => `${record.usage} ${record.unitOfMeasure || ``}`, align: 'right' },
        ]}
        data={usageTableRows}
        isLoading={isLoading}
        error={error}
        numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
        onMoreButtonClick={() => {
          showAsyncModal(ViewAllModal, {
            title: (
              <ConditionalWrapper
                condition={!!purchaseGroupName}
                wrapper={(children) => (
                  <InfoTooltip useInfoIcon title={[locations?.map((sl, i) => <div key={i}>{sl.name}</div>)]}>
                    {children}
                  </InfoTooltip>
                )}
              >
                <>
                  Usage for Item #{itemId} at {purchaseGroupName ?? locations[0].name}
                </>
              </ConditionalWrapper>
            ),
            content: <InventoryUsageTable exactHits={exactHits} locations={locations} isLoading={isLoading} />,
          });
        }}
      />
    </>
  );
}
