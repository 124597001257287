import React, { useMemo } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseTargetLineDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargetLines';
import { ColumnType } from 'antd/lib/table';

import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import { NetStockPopover } from 'components/recipes/equation/NetStockPopover';
import { PurchaseTargetLineStatusBadge } from 'components/recipes/PurchaseTargetLineStatusBadge';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { showAsyncModal } from 'utils/asyncModal';
import { formatNumber } from 'utils/formatting';
import { typedColumn } from 'utils/tables';

import { QtyToOrderPopover } from '../../../../../components/recipes/equation/QtyToOrderPopover';
import { GenericTargetLineDTO } from '../../types';

export interface TargetLineWOverUnderBy extends PurchaseTargetLineDTO {
  overUnderBy: number;
}

export function LocationStockPanelContent({ targetLine }: { targetLine: GenericTargetLineDTO }) {
  const {
    data: targetLinesData,
    isLoading: isTargetLinesLoading,
    error: targetLinesError,
  } = useCoreApi(schemas.purchasing.getPurchaseTargetLines, {
    queryParams: {
      filter: {
        itemIds: [targetLine.itemId],
      },
    },
  });

  const targetLines: TargetLineWOverUnderBy[] = useMemo(
    () =>
      (targetLinesData?.items || [])
        .map((record) => ({
          ...record,
          overUnderBy:
            record.netStock > record.invMax
              ? record.netStock - record.invMax
              : record.netStock < record.invMin
              ? record.netStock - record.invMin
              : 0,
        }))
        .sort((a, b) =>
          // sort by overUnderBy, then by locationId
          a.overUnderBy - b.overUnderBy === 0
            ? a.locationId.localeCompare(b.locationId)
            : (a.overUnderBy - b.overUnderBy) *
              // if targetLine is overstock, show understocked locations first, otherwise show overstocked locations first
              (targetLine.status === PurchaseTargetLineStatus.Understock ? -1 : 1),
        ),
    [targetLine.status, targetLinesData?.items],
  );

  return (
    <MiniTable
      numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
      data={targetLines}
      columns={[
        {
          render: (record) => `${record.locationId}: ${record.locationName}`,
          maxWidth: '130px',
        },
        {
          render: (record) =>
            record.status +
            (record.status === PurchaseTargetLineStatus.Overstock ||
            record.status === PurchaseTargetLineStatus.Understock
              ? ` by ${formatNumber(Math.abs(record.overUnderBy), 0)}`
              : ''),
          align: 'left',
        },
        {
          render: (record) => `${formatNumber(record.qtyAvailable)} ${record.unitOfMeasure || ``}`,
          align: 'right',
        },
      ]}
      isLoading={isTargetLinesLoading}
      error={targetLinesError}
      onMoreButtonClick={() => {
        showAsyncModal(ViewAllModal, {
          title: 'Location Stock',
          content: <LocationStockTable targetLines={targetLines} />,
        });
      }}
    />
  );
}

function LocationStockTable({ targetLines }: { targetLines: TargetLineWOverUnderBy[] }) {
  const columns: ColumnType<TargetLineWOverUnderBy>[] = [
    typedColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record) => `${record.locationId}: ${record.locationName}`,
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      align: 'left' as const,
      render: (value) => value || '-',
    }),
    typedColumn({
      title: 'Stockable',
      dataIndex: 'stockable',
      align: 'center' as const,
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    }),
    typedColumn({
      title: 'Status',
      dataIndex: 'status',
      align: 'center' as const,
      render: (status) => <PurchaseTargetLineStatusBadge status={status} />,
    }),
    typedColumn({
      title: 'Net Stock',
      dataIndex: 'netStock',
      align: 'right' as const,
      render: (value, record) => (
        <>
          {formatNumber(value)}
          <NetStockPopover record={record} />
        </>
      ),
    }),
    typedColumn({
      title: 'Min',
      dataIndex: 'invMin',
      align: 'right' as const,
      render: (value) => formatNumber(value),
    }),
    typedColumn({
      title: 'Max',
      dataIndex: 'invMax',
      align: 'right' as const,
      render: (value) => formatNumber(value),
    }),
    typedColumn({
      title: 'Under/Over by',
      dataIndex: 'overUnderBy',
      align: 'right' as const,
      render: (value) => formatNumber(value),
    }),
    typedColumn({
      title: 'Qty to Order',
      dataIndex: 'qtyToOrder',
      align: 'right' as const,
      render: (value, record) => (
        <>
          {formatNumber(value)}
          <QtyToOrderPopover record={record} />
        </>
      ),
    }),
    typedColumn({
      title: 'Qty Available',
      dataIndex: 'qtyAvailable',
      align: 'right' as const,
      render: (value) => formatNumber(value),
    }),
  ];
  return <Table data={targetLines} columns={columns} />;
}
