import React, { useMemo, useState } from 'react';

import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PurchaseTargetLineStatus, RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseTargetLineDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargetLines';
import { Spin } from 'antd';
import { ColumnType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';

import { ItemDetailsSidePane } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/ItemDetailsSidePane';
import { TargetLineWOverUnderBy } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/LocationStockPanelContent';
import { focusedRowClassName } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';
import { PurchaseTargetLineRow } from 'pages/purchasing/PurchaseTargetsPage/types';

import { Button } from 'components/Button';
import { FlexSpace } from 'components/FlexSpace';
import { ParsedInput } from 'components/Input/ParsedInput';
import { Modal } from 'components/Modal';
import { NetStockPopover } from 'components/recipes/equation/NetStockPopover';
import { SplitPage } from 'components/SplitPage/SplitPage';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { formatNumber } from 'utils/formatting';
import { sortDirections, typedColumn } from 'utils/tables';
import { floatOrDefault } from 'utils/units';

import { PurchasingLineTransfer } from 'types/hash-state';

import { InfoTooltip } from '../Tooltip/InfoTooltip';
import { QtyToOrderPopover } from './equation/QtyToOrderPopover';
import { PurchaseTargetLineStatusBadge } from './PurchaseTargetLineStatusBadge';

export interface EditPurchaseLineTransfersModalProps {
  targetLine: PurchaseTargetLineRow;
  onClose: (transfers?: PurchasingLineTransfer[]) => void;
}

interface TransferTargetLine extends TargetLineWOverUnderBy {
  transferDays?: number;
}

export function EditPurchaseLineTransfersModal({ targetLine, onClose }: EditPurchaseLineTransfersModalProps) {
  const { itemId, userTransfers } = targetLine;

  const [focusedLine, setFocusedLine] = useState<PurchaseTargetLineRow>(targetLine);
  const [transferLines, setTransferLines] = useState<PurchasingLineTransfer[]>(userTransfers || []);

  const totalQtyToTransfer = useMemo(
    () => transferLines.reduce((totalQty, transfer) => totalQty + transfer.qtyToTransfer, 0),
    [transferLines],
  );

  const transferQtyByLocationId: Record<string, number> = useMemo(
    () =>
      Object.fromEntries(transferLines.map((transfer) => [transfer.transferFromLocationId, transfer.qtyToTransfer])),
    [transferLines],
  );

  const { data: targetLinesData, isLoading: isLoadingTargetLinesData } = useCoreApi(
    schemas.purchasing.getPurchaseTargetLines,
    {
      queryParams: {
        filter: {
          itemIds: [itemId],
        },
      },
    },
  );

  const { data: transferDaysData, isLoading: isLoadingTransferDaysData } = useCoreApi(
    schemas.transferDays.getTransferDays,
    {
      queryParams: {
        toLocationId: targetLine.locationId,
      },
    },
  );

  const transferDaysByLocationId: Map<string, number> = useMemo(
    () =>
      (transferDaysData?.items || [])
        .filter((item) => item.toLocationId === targetLine.locationId)
        .reduce((map, item) => {
          map.set(item.fromLocationId, item.days);
          return map;
        }, new Map<string, number>()),
    [targetLine.locationId, transferDaysData?.items],
  );

  const getOverUnderBy = (record: PurchaseTargetLineDTO) => {
    if (record.replenishmentMethod === RecurrencyReplenishmentMethod.OPOQ) {
      if (record.netStock > record.invMax + record.invMin) {
        return record.netStock - record.invMax - record.invMin;
      }
      if (record.netStock < record.invMin) {
        return record.netStock - record.invMin;
      }
      return 0;
    }
    if (record.netStock > record.invMax) {
      return record.netStock - record.invMax;
    }
    if (record.netStock < record.invMin) {
      return record.netStock - record.invMin;
    }
    return 0;
  };

  // TODO @IsaacGelman abstract to common function used by Location Stock feature
  const targetLines: TransferTargetLine[] = useMemo(
    () =>
      (targetLinesData?.items || [])
        .map((record) => ({
          ...record,
          overUnderBy: getOverUnderBy(record),
          transferDays: transferDaysByLocationId.get(record.locationId),
        }))
        // filter out location of original target line
        .filter((record) => record.locationId !== targetLine.locationId && record.companyId === targetLine.companyId)
        // sort by overUnderBy, then by locationId
        .sort((a, b) =>
          a.overUnderBy - b.overUnderBy === 0
            ? a.locationId.localeCompare(b.locationId)
            : (a.overUnderBy - b.overUnderBy) *
              // if targetLine is overstock, show understocked locations first, otherwise show overstocked locations first
              (targetLine.status === PurchaseTargetLineStatus.Understock ? -1 : 1),
        ),
    [targetLine.companyId, targetLine.locationId, targetLine.status, targetLinesData?.items, transferDaysByLocationId],
  );

  function handleSave() {
    onClose(transferLines);
  }

  const handleQtyToTransferChange = (newQty: number, line: TransferTargetLine) => {
    const newTransferLines = [...transferLines];
    const existingTransferIdx = newTransferLines.findIndex(
      (transfer) => transfer.transferFromLocationId === line.locationId,
    );

    if (existingTransferIdx >= 0) {
      if (newQty > 0) {
        // edit existing qtyToTransfer
        newTransferLines[existingTransferIdx] = { ...newTransferLines[existingTransferIdx], qtyToTransfer: newQty };
      } else {
        // remove transfer line
        newTransferLines.splice(existingTransferIdx, 1);
      }
    } else if (newQty > 0) {
      // add new transfer line
      newTransferLines.push({
        transferFromLocationId: line.locationId,
        transferFromLocationName: line.locationName,
        qtyToTransfer: newQty,
      });
    }

    setTransferLines(newTransferLines);
  };

  const tableColumns = ({
    isTransferFromTable,
  }: {
    isTransferFromTable: boolean;
  }): ColumnType<TransferTargetLine>[] => [
    {
      title: 'Location',
      dataIndex: 'locationId',
      width: `200px`,
      fixed: true,
      render: (_: string, record: TransferTargetLine) => (
        <div>
          <div>{record.locationId}</div>
          <div>{record.locationName}</div>
        </div>
      ),
    },
    {
      title: 'Stockable',
      dataIndex: 'stockable',
      align: 'center' as const,
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: PurchaseTargetLineStatus) => <PurchaseTargetLineStatusBadge status={status} />,
    },
    {
      title: 'Transfer Days',
      dataIndex: 'transferDays',
      align: 'right' as const,
      width: 100,
      render: (value) => formatNumber(value),
      sorter: isTransferFromTable ? false : (a, b) => (a.transferDays ?? 0) - (b.transferDays ?? 0),
    },
    typedColumn({
      title: 'Min',
      dataIndex: 'invMin',
      align: 'right' as const,
      width: 100,
      render: (value, record) => (
        <>
          {formatNumber(value)}
          {record.replenishmentMethod === RecurrencyReplenishmentMethod.OPOQ ? (
            <InfoTooltip title="This is an OP for this OP/OQ item" />
          ) : null}
        </>
      ),
    }),
    typedColumn({
      title: 'Max',
      dataIndex: 'invMax',
      align: 'right' as const,
      width: 100,
      render: (value, record) => (
        <>
          {formatNumber(value)}
          {record.replenishmentMethod === RecurrencyReplenishmentMethod.OPOQ ? (
            <InfoTooltip title="This is an OQ for this OP/OQ item" />
          ) : null}
        </>
      ),
    }),
    {
      title: 'Net Stock',
      dataIndex: 'netStock',
      align: 'right' as const,
      sorter: isTransferFromTable ? false : (a, b) => a.netStock - b.netStock,
      sortDirections,
      render: (value: number, record: TransferTargetLine) => (
        <>
          {formatNumber(value)} {record.unitOfMeasure}
          <NetStockPopover record={record} />
        </>
      ),
    },
    {
      title: 'Qty Available',
      dataIndex: 'qtyAvailable',
      align: 'right' as const,
      sorter: isTransferFromTable ? false : (a, b) => a.qtyAvailable - b.qtyAvailable,
      sortDirections,
      render: (value: number, record: TransferTargetLine) => `${formatNumber(value)} ${record.unitOfMeasure || ''}`,
    },
    isTransferFromTable
      ? {
          title: 'Required',
          dataIndex: 'qtyToOrder',
          align: 'right' as const,
          render: (value: number, record: TransferTargetLine) => (
            <div
              className={css`
                display: inline-flex;
              `}
            >
              {formatNumber(value)} {record.unitOfMeasure}
              <QtyToOrderPopover record={record} />
            </div>
          ),
        }
      : {
          title: 'Over/Under by',
          dataIndex: 'overUnderBy',
          align: 'right' as const,
          sorter: (a, b) => a.overUnderBy - b.overUnderBy,
          defaultSortOrder: 'descend' as const,
          sortDirections,
          render: (value: number, record: TransferTargetLine) => `${formatNumber(value)} ${record.unitOfMeasure || ''}`,
        },
    {
      title: `Qty To Transfer`,
      dataIndex: 'userQtyToOrder',
      width: '140px',
      sorter: isTransferFromTable
        ? false
        : (a, b) => (transferQtyByLocationId[a.locationId] ?? 0) - (transferQtyByLocationId[b.locationId] ?? 0),
      sortDirections,
      render: (_: number, record: TransferTargetLine) =>
        isTransferFromTable ? (
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            {totalQtyToTransfer}
          </div>
        ) : (
          <FlexSpace>
            <ParsedInput<number>
              size="small"
              valueParser={(value) => floatOrDefault(value, 0)}
              value={transferQtyByLocationId[record.locationId]}
              onValueChange={(value) => handleQtyToTransferChange(value, record)}
              triggerChangeOnBlankValue
            />
            <Button
              size="small"
              disabled={!(transferQtyByLocationId[record.locationId] > 0)}
              onClick={() => handleQtyToTransferChange(0, record)}
            >
              <CloseOutlined />
            </Button>
          </FlexSpace>
        ),
    },
  ];

  return (
    <Modal
      visible
      title={`Transfer Item #${itemId} to Location #${targetLine.locationId}`}
      onCancel={() => onClose()}
      centered
      width={1600}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button key="save" type="primary" htmlType="submit" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <SplitPage
        left={
          <div
            className={css`
              display: flex;
              flex-direction: column;
              gap: 20px;
            `}
          >
            <Title level={5}>Transfer To</Title>
            <Table
              columns={tableColumns({ isTransferFromTable: true })}
              data={[targetLine]}
              rowClassName={(record: PurchaseTargetLineRow) => focusedRowClassName(record, focusedLine)}
              onRow={(record) => ({
                onClick: () => setFocusedLine(record),
              })}
            />
            <Title level={5}>Transfer From</Title>
            {isLoadingTargetLinesData || isLoadingTransferDaysData ? (
              <Spin />
            ) : (
              <Table
                columns={tableColumns({ isTransferFromTable: false })}
                data={targetLines}
                rowClassName={(record: PurchaseTargetLineRow) => focusedRowClassName(record, focusedLine)}
                onRow={(record) => ({
                  onClick: () => setFocusedLine(record),
                })}
              />
            )}
          </div>
        }
        right={<ItemDetailsSidePane itemInfo={focusedLine} allowMinMaxEdit={false} />}
      />
    </Modal>
  );
}
