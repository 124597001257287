import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  IdcardOutlined,
  MessageOutlined,
  NotificationOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { CustomerType, TenantFeatureFlag, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { Menu } from 'antd';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { useTenantSlugSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { TaskModal } from 'components/recipes/TaskModal';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { setLocalStorageItem, LocalStorageKey } from 'utils/localStorage';
import {
  shouldShowFeatureFlag,
  roleToString,
  reloadPageWithTenantSlug,
  shouldShowProduct,
  shouldShowFreeProduct,
} from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';
import { openZendeskModal } from 'utils/zendesk';

import { GlobalCompanyFilterSelect } from './GlobalCompanyFilterSelect';
import { GlobalSearch } from './GlobalSearch';
import { InviteTeammateModal } from './InviteTeammateModal';
import * as Styled from './PrimaryNavigation.style';
import { UserProfileMenu } from './UserProfileMenu';

export interface PrimaryNavigationProps {
  isDesktopView: boolean;
  onLogoClick: () => void;
  isPopupMode?: boolean;
}

export const PrimaryNavigation = ({ isDesktopView, onLogoClick, isPopupMode }: PrimaryNavigationProps) => {
  const { tenants, activeTenant, activeErpRole, activeUser, roles } = useGlobalApp();

  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const shouldShowNonSearch = isDesktopView || !isMobileSearchOpen;
  const tenantSlugSelectProps = useTenantSlugSelectProps(tenants);

  const isInSetupMode = !activeTenant.isActive;
  const isInFreeTrial = shouldShowFreeProduct(activeTenant);

  return (
    <>
      <Styled.Container data-test-id="primaryNavContainer">
        <Styled.Content isDesktopView={isDesktopView}>
          <Styled.ContentChild isDesktopView={isDesktopView} flex={0}>
            {shouldShowNonSearch && (
              <Styled.LogoWrapper onClick={onLogoClick}>
                {isDesktopView ? <Styled.Logo /> : <Styled.MobileLogo />}
              </Styled.LogoWrapper>
            )}
            {tenants && activeTenant && tenants.length > 1 && shouldShowNonSearch && (
              <Styled.DropdownWrapper>
                <AsyncMultiSelect
                  mode="single"
                  queryPlaceholder="Search tenants"
                  selectedValues={[activeTenant.slug]}
                  onSelectedValuesChange={(values) => reloadPageWithTenantSlug(values[0])}
                  selectProps={tenantSlugSelectProps}
                  triggerButton={
                    <Button size="small">
                      <BankOutlined />
                      {isDesktopView && ` ${activeTenant.name} `}
                      {isDesktopView && <DownOutlined />}
                    </Button>
                  }
                />
              </Styled.DropdownWrapper>
            )}
            {roles && activeErpRole && roles.length > 1 && shouldShowNonSearch && (
              <Styled.DropdownWrapper>
                <Dropdown
                  overlay={
                    <Menu>
                      {roles.map((role, idx) => (
                        <Menu.Item
                          key={idx}
                          onClick={() => {
                            setLocalStorageItem(LocalStorageKey.ActiveErpRole, role);
                            window.location.hash = '';
                            window.location.reload();
                          }}
                        >
                          {!isDesktopView && role.foreignId === activeErpRole.foreignId && <CheckOutlined />}
                          {roleToString(role)}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button size="small">
                    <IdcardOutlined />
                    {isDesktopView && ` ${roleToString(activeErpRole)} `}
                    {isDesktopView && <DownOutlined />}
                  </Button>
                </Dropdown>
              </Styled.DropdownWrapper>
            )}
            {shouldShowNonSearch && <GlobalCompanyFilterSelect isDesktopView={isDesktopView} />}
            {isInFreeTrial && (
              <Button size="small" icon={<UserAddOutlined />} onClick={() => showAsyncModal(InviteTeammateModal, {})}>
                {isDesktopView && 'Invite a Teammate'}
              </Button>
            )}
          </Styled.ContentChild>
          {!isInSetupMode && !isPopupMode && shouldShowProduct(activeTenant, TenantProductName.GlobalSearch) && (
            <Styled.ContentChild isDesktopView={isDesktopView} isMobileSearchOpen={isMobileSearchOpen} flex={1}>
              <Styled.SearchInput isDesktopView={isDesktopView}>
                {(isDesktopView || isMobileSearchOpen) && <GlobalSearch isDesktopView={isDesktopView} />}
                {!isDesktopView &&
                  (isMobileSearchOpen ? (
                    <Styled.LeftPadButton>
                      <Button size="small" onClick={() => setIsMobileSearchOpen(false)}>
                        <CloseOutlined />
                      </Button>
                    </Styled.LeftPadButton>
                  ) : (
                    <Button size="small" onClick={() => setIsMobileSearchOpen(true)}>
                      <SearchOutlined />
                    </Button>
                  ))}
              </Styled.SearchInput>
              {shouldShowNonSearch && !isPopupMode && (
                <Styled.CreateButton>
                  <Dropdown
                    overlay={
                      <Menu>
                        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) && (
                          <Menu.Item>
                            <Link
                              to={routes.orders.quoteNew()}
                              onClick={() =>
                                track(TrackEvent.Nav_CtaClick, {
                                  ctaName: CtaName.NewQuote,
                                  ctaType: CtaType.PrimaryNavNewRecord,
                                })
                              }
                            >
                              Quote
                            </Link>
                          </Menu.Item>
                        )}
                        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) && (
                          <Menu.Item>
                            <Link
                              to={routes.orders.orderNew()}
                              onClick={() =>
                                track(TrackEvent.Nav_CtaClick, {
                                  ctaName: CtaName.NewOrder,
                                  ctaType: CtaType.PrimaryNavNewRecord,
                                })
                              }
                            >
                              Order
                            </Link>
                          </Menu.Item>
                        )}
                        {shouldShowFeatureFlag(
                          activeTenant,
                          activeUser,
                          TenantFeatureFlag.OrdersFrontCounterEnabled,
                        ) && (
                          <Menu.Item>
                            <Link
                              to={routes.orders.orderNew({ frontCounter: true })}
                              onClick={() =>
                                track(TrackEvent.Nav_CtaClick, {
                                  ctaName: CtaName.NewOrder,
                                  ctaType: CtaType.PrimaryNavNewRecord,
                                })
                              }
                            >
                              Front Counter
                            </Link>
                          </Menu.Item>
                        )}
                        <Menu.Item
                          onClick={() => {
                            track(TrackEvent.Nav_CtaClick, {
                              ctaName: CtaName.NewTask,
                              ctaType: CtaType.PrimaryNavNewRecord,
                            });
                            showAsyncModal(TaskModal, {});
                          }}
                        >
                          Task
                        </Menu.Item>
                        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateProspect) && (
                          <Menu.Item>
                            <Link
                              to={routes.sales.customerNew()}
                              onClick={() =>
                                track(TrackEvent.Nav_CtaClick, {
                                  ctaName: CtaName.NewProspect,
                                  ctaType: CtaType.PrimaryNavNewRecord,
                                })
                              }
                            >
                              Prospect
                            </Link>
                          </Menu.Item>
                        )}
                        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateCustomer) && (
                          <Menu.Item>
                            <Link
                              to={routes.sales.customerNew({ type: CustomerType.Customer })}
                              onClick={() =>
                                track(TrackEvent.Nav_CtaClick, {
                                  ctaName: CtaName.NewCustomer,
                                  ctaType: CtaType.PrimaryNavNewRecord,
                                })
                              }
                            >
                              Customer
                            </Link>
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                  >
                    <Button size="small">
                      <PlusOutlined />
                      {isDesktopView && ' New Record'}
                      {isDesktopView && <DownOutlined />}
                    </Button>
                  </Dropdown>
                </Styled.CreateButton>
              )}
            </Styled.ContentChild>
          )}
          {shouldShowNonSearch && (
            <Styled.ContentChild isDesktopView={isDesktopView} flex={0}>
              {!isPopupMode && (
                <Tooltip title="Recurrency Changelog" placement="bottom">
                  <Styled.IconOnlyButton className="headwayapp-badge">
                    <NotificationOutlined />
                  </Styled.IconOnlyButton>
                </Tooltip>
              )}
              <Tooltip title="Help Center" placement="bottom">
                <Styled.IconOnlyButton onClick={() => window.open('https://help.recurrency.com', '_blank')}>
                  <QuestionCircleOutlined />
                </Styled.IconOnlyButton>
              </Tooltip>
              <Tooltip title="Message us" placement="bottom">
                <Styled.IconOnlyButton onClick={openZendeskModal}>
                  <MessageOutlined />
                </Styled.IconOnlyButton>
              </Tooltip>
              <Styled.MenuItem>
                <Dropdown
                  arrow
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      <UserProfileMenu />
                    </Menu>
                  }
                >
                  <Styled.MenuItemChild>
                    <Styled.Icon>
                      <Styled.Avatar icon={<Styled.UserProfileIcon />} />
                    </Styled.Icon>
                    {isDesktopView && <Styled.Label>{activeUser?.fullName || 'Profile'}</Styled.Label>}
                    {isDesktopView && (
                      <Styled.SmallIcon>
                        <DownOutlined />
                      </Styled.SmallIcon>
                    )}
                  </Styled.MenuItemChild>
                </Dropdown>
              </Styled.MenuItem>
            </Styled.ContentChild>
          )}
        </Styled.Content>
      </Styled.Container>
    </>
  );
};
