import React from 'react';

import {
  CalculatorOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  DownOutlined,
  ReconciliationOutlined,
  StopOutlined,
  SyncOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Menu } from 'antd';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { notifyAndDownload } from 'components/recipes/DownloadButton';
import { ValueFacet } from 'components/recipes/SearchFrame';
import { SearchFrameContext } from 'components/recipes/SearchFrame/types';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { isSAPB1Tenant } from 'utils/routes';

import { SearchIndexMinMax } from 'types/search-collections';

import { changeRecordExcludedStatus } from './modals/IncludeExcludeStatusUpdater';
import { MultiLeadTimeModal } from './modals/MultiLeadTimeModal';
import { MultiSafetyStockModal } from './modals/MultiSafetyStockModal';
import { MultiUpdateReplenishmentSettingsModal } from './modals/MultiUpdateReplenishmentSettingsModal';
import { SAPB1PlanningExportMinMax } from './modals/SAPB1PlanningExportMinMax';
import { MultiModalSelection } from './modals/utils';

const MIN_MAX_TRANSACTION_API_EXPORT_LIMIT = 100;

export const PlanningSearchFrameHeader = (props: {
  searchFrameContext: SearchFrameContext<SearchIndexMinMax>;
  valueFacets: (ValueFacet<SearchIndexMinMax> | null)[];
  showOpOqVariant?: boolean;
  isOnboardingVariant?: boolean;
}) => {
  const { searchFrameContext, valueFacets, showOpOqVariant = false, isOnboardingVariant = false } = props;
  const { activeTenant, activeUser } = useGlobalApp();

  let selection: MultiModalSelection | undefined;
  if (searchFrameContext.tableRowSelection?.selectedAll) {
    selection = {
      type: 'filter',
      filters: searchFrameContext.searchRequest.filters,
      count: searchFrameContext.searchIndexResponse?.nbHits ?? 0,
    };
  } else if (searchFrameContext.tableRowSelection?.selectedRowKeys?.length) {
    const selectedRowKeys = searchFrameContext.tableRowSelection.selectedRowKeys as string[];
    const latestRowsById = new Map(searchFrameContext.searchIndexResponse?.hits.map((hit) => [hit.id, hit]));

    // if all selected row keys are present in latest response, then use list selection,
    // otherwise fetch latest from api via filter selection
    if (selectedRowKeys.every((id) => latestRowsById.has(id))) {
      selection = {
        type: 'list',
        items: selectedRowKeys.map((id) => latestRowsById.get(id)!),
        count: selectedRowKeys.length,
      };
    } else {
      selection = {
        type: 'filter',
        count: selectedRowKeys.length,
        filters: {
          id: selectedRowKeys,
        },
        filterTitleMapping: {
          id: 'ID',
        },
      };
    }
  }
  // for P21 txn-api only tenant, we limit the min/max export to 100 records at a time
  const isOverMinMaxLimit =
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemLocationMinMaxExportUsingTransactionApi) &&
    selection &&
    selection.count > MIN_MAX_TRANSACTION_API_EXPORT_LIMIT;

  const isSAPB1ExportMinMaxEnabled =
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.FeatureSyncB1MinMaxes) && isSAPB1Tenant();

  return (
    <>
      {isSAPB1ExportMinMaxEnabled && (
        <Tooltip title="Update all Min/Max values in SAP B1 with the current values in Recurrency" placement="left">
          <Button onClick={() => showAsyncModal(SAPB1PlanningExportMinMax, {})} icon={<SyncOutlined />}>
            Sync Min/Maxes
          </Button>
        </Tooltip>
      )}

      {isOnboardingVariant && (
        <Button
          disabled={!selection}
          type="primary"
          onClick={() => {
            // this will never happen, but we appease tsc to ensure it is defined
            if (!selection) return;

            // add filterTitleMapping so we can show Filters used in the modal
            if (selection.type === 'filter') {
              selection.filterTitleMapping = Object.fromEntries(
                valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
              );
            }
            showAsyncModal(MultiUpdateReplenishmentSettingsModal, {
              selection,
              searchIndexReload: searchFrameContext.searchIndexReload,
              isOpOqVariant: showOpOqVariant,
              isOnboardingVariant: true,
            });
          }}
        >
          <ToolOutlined /> Bulk Set Up
        </Button>
      )}

      <Dropdown
        disabled={!selection}
        overlay={
          <Menu>
            {isOnboardingVariant ? null : (
              <Menu.Item
                key="minMax"
                icon={<CalculatorOutlined />}
                disabled={isOverMinMaxLimit}
                onClick={() => {
                  // this will never happen, but we appease tsc to ensure it is defined
                  if (!selection) return;

                  // add filterTitleMapping so we can show Filters used in the modal
                  if (selection.type === 'filter') {
                    selection.filterTitleMapping = Object.fromEntries(
                      valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                    );
                  }
                  showAsyncModal(MultiUpdateReplenishmentSettingsModal, {
                    selection,
                    searchIndexReload: searchFrameContext.searchIndexReload,
                    isOpOqVariant: showOpOqVariant,
                    isOnboardingVariant: false,
                  });
                }}
              >
                <span
                  title={
                    isOverMinMaxLimit
                      ? `Export is limited to ${MIN_MAX_TRANSACTION_API_EXPORT_LIMIT} records at a time`
                      : undefined
                  }
                >
                  {isOnboardingVariant ? 'Item Setup' : showOpOqVariant ? 'OP/OQ' : 'Min/Max'}
                </span>
              </Menu.Item>
            )}
            <Menu.Item
              key="safetyStock"
              icon={<ReconciliationOutlined />}
              onClick={() => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                showAsyncModal(MultiSafetyStockModal, {
                  selection,
                  searchIndexReload: searchFrameContext.searchIndexReload,
                });
              }}
            >
              Safety Stock
            </Menu.Item>
            <Menu.Item
              key="leadTime"
              icon={<CalendarOutlined />}
              onClick={() => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                showAsyncModal(MultiLeadTimeModal, {
                  selection,
                  searchIndexReload: searchFrameContext.searchIndexReload,
                });
              }}
            >
              Lead Time
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="exclude"
              icon={<StopOutlined />}
              onClick={async () => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                const didChange = await changeRecordExcludedStatus(selection, true);
                if (didChange) searchFrameContext.searchIndexReload();
              }}
            >
              Exclude
            </Menu.Item>
            <Menu.Item
              key="include"
              icon={<CheckCircleOutlined />}
              onClick={async () => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                const didChange = await changeRecordExcludedStatus(selection, false);
                if (didChange) searchFrameContext.searchIndexReload();
              }}
            >
              Include
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="download"
              icon={<DownloadOutlined />}
              onClick={() => notifyAndDownload(searchFrameContext.getDownloadData, 'Planning')}
            >
              Download
            </Menu.Item>
          </Menu>
        }
      >
        <Button
          type={isOnboardingVariant ? 'default' : 'primary'}
          title={!selection ? `Select table records to enable bulk actions` : undefined}
        >
          Bulk Actions <DownOutlined />
        </Button>
      </Dropdown>
    </>
  );
};
