import React from 'react';

import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { AvailabilityTable } from 'pages/items/ItemDetailsPage/tabs/AvailabilityTable';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { InventoryUsageTable } from 'components/recipes/InventoryUsageTable';
import { PurchaseOrderLinesTable } from 'components/recipes/PurchaseOrderLinesTable';
import { SalesInvoiceLinesTable } from 'components/recipes/SalesInvoiceLinesTable';
import { SalesOrderLinesTable } from 'components/recipes/SalesOrderLinesTable';
import { VesselLinesTable } from 'components/recipes/VesselLinesTable';
import { Tabs } from 'components/Tabs';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { truthy } from 'utils/boolean';
import { splitIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { searchIndex } from 'utils/search/search';

import { SearchIndexForecast } from 'types/search-collections';

export function DemandPlanningContextModal({
  itemId,
  itemUid,
  location,
  onClose,
}: {
  itemId: string;
  itemUid: string;
  location: string;
  onClose: () => void;
}) {
  const locationId = splitIdNameStr(location).foreignId;
  const { activeTenant, activeUser } = useGlobalApp();
  const { data: searchIndexResponse, isLoading: searchIndexIsLoading } = usePromise(
    () =>
      searchIndex<SearchIndexForecast>({
        indexName: SearchIndexName.Forecasts,
        filters: { location: [location], item_id: [itemId] },
        hitsPerPage: 1,
      }),
    [locationId, itemId],
  );

  const data = searchIndexResponse?.hits;

  return (
    <Modal
      destroyOnClose
      visible
      title={`History of Item #${itemId} at Location ${location}`}
      width={1025}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="small">
          Close
        </Button>
      }
    >
      <Tabs
        tabs={[
          {
            header: 'Usage',
            content: <InventoryUsageTable exactHits={data} isLoading={searchIndexIsLoading} />,
          },
          {
            header: 'Sales History',
            content: <SalesInvoiceLinesTable itemId={itemId} locationIds={[locationId]} />,
          },
          {
            header: 'Order Lines',
            content: <SalesOrderLinesTable itemId={itemId} locationIds={[locationId]} />,
          },
          {
            header: 'PO Lines',
            content: <PurchaseOrderLinesTable itemId={itemId} locationIds={[locationId]} />,
          },
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesItemAvailabilityWithQtyOnVessel)
            ? {
                header: 'Vessel Lines',
                content: <VesselLinesTable itemUid={itemUid} locationId={locationId} />,
              }
            : null,
          { header: 'Availability & Cost', content: <AvailabilityTable itemUid={itemUid} /> },
        ].filter(truthy)}
      />
    </Modal>
  );
}
