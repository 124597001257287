import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { CustomerType } from '@recurrency/core-api-schema/dist/common/enums';
import { Form, Steps, Tooltip } from 'antd';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { responsiveFormLayout } from 'components/FormItems';
import { NotificationLink } from 'components/Links';
import { PageHeader } from 'components/PageHeader';
import { AutoSaveModal } from 'components/recipes/AutoSaveModal';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { capitalize, getErpName } from 'utils/formatting';
import { LocalStorageKey } from 'utils/localStorage';
import { isObjEmpty, objMapValues, objPickKeys } from 'utils/object';
import { encodeHashState, routes, useFormHashState } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent } from 'utils/track';

import { ProspectHashState, ProspectStep } from 'types/hash-state';

import { ProspectContactStep } from './ProspectContactStep';
import { ProspectCustomerStep } from './ProspectCustomerStep';
import { ProspectReviewStep } from './ProspectReviewStep';
import { prospectRequestFromHashState } from './prospectUtils';

export const ProspectNewPage = () => {
  const {
    formState: prospectState,
    updateFormState: updateProspectState,
    savedFormState,
    clearSavedFormState,
  } = useFormHashState<ProspectHashState>(LocalStorageKey.Form_Prospect_AutoSave);
  const [showAutoSaveModal, setShowAutoSaveModal] = useState(!isObjEmpty(savedFormState));

  const [validationErrorMsg, setValidationErrorMsg] = useState('');

  const history = useHistory();

  const [form] = Form.useForm<ProspectHashState>();

  const { activeTenant } = useGlobalApp();
  const tenantId = activeTenant.id;

  const customerType = prospectState.type || CustomerType.Prospect;
  const customerTypeTitle = customerType === CustomerType.Customer ? 'New Customer' : 'New Prospect';

  const handleProspectSubmit = async () => {
    await form.validateFields();
    const submitNotification = createSubmissionNotification({
      entityName: capitalize(customerType),
      expectedWaitSeconds: 15,
      erpType: activeTenant.erpType,
    });
    try {
      await form.validateFields();

      const prospectRequest = prospectRequestFromHashState(prospectState, tenantId);
      if (customerType === CustomerType.Customer) {
        track(TrackEvent.Customers_NewCustomer_Submit, {});
      } else {
        track(TrackEvent.Customers_NewProspect_Submit, {});
      }

      const response = await coreApiFetch(schemas.customers.createCustomer, {
        bodyParams: prospectRequest,
      });

      // @ts-expect-error bad typing in core-api-schema, it should have foreignId
      const createdCustomerId = response.data.foreignId;
      clearSavedFormState();
      submitNotification.success({
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.sales.customerDetails(createdCustomerId)}>
            View {entityName} #{createdCustomerId}
          </NotificationLink>
        ),
      });

      history.push(routes.sales.customerDetails(createdCustomerId));
    } catch (err) {
      submitNotification.error(err, {
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.sales.customerNew(prospectState)}>
            Return to {entityName}
          </NotificationLink>
        ),
      });
    }
  };

  useEffect(() => {
    // explicit pick, so undefined values are explicitly set, and override internal form state
    const fieldsValue = objPickKeys(
      prospectState,
      'customerName',
      'physical-address1',
      'physical-address2',
      'physical-city',
      'physical-state',
      'physical-zipcode',
      'mailing-address1',
      'mailing-address2',
      'mailing-state',
      'mailing-city',
      'mailing-zipcode',
      'customerPhone',
      'customerFax',
      'customerEmail',
      'salesRep',
      'company',
      'addressesSame',
      'contactFirstName',
      'contactLastName',
      'contactTitle',
      'contactPhone',
      'contactFax',
      'contactEmail',
      'makeShipTo',
      'shipToPreferredLocation',
      'shipToDefaultFreight',
      'shipToDefaultBranch',
      'companyPerson',
      'ivaExemptionNumber',
      'taxRegime',
      'type',
    );
    // use prospectState as the source of state
    form.setFieldsValue(fieldsValue);
  }, [form, prospectState]);
  const { step: currentStep = ProspectStep.Customer } = prospectState;

  useEffect(() => {
    if (customerType === CustomerType.Customer) {
      track(TrackEvent.Customers_NewCustomer_StepChange, { step: ProspectStep[currentStep] });
    } else {
      track(TrackEvent.Customers_NewProspect_StepChange, { step: ProspectStep[currentStep] });
    }
  }, [currentStep, customerType]);

  const canGoToStep = useCallback(
    async (step: ProspectStep): Promise<boolean> => {
      if (step > ProspectStep.Customer) {
        try {
          // antd Form makes you jumps through async try/catch hoop just to know whether a form is valid or not
          await form.validateFields();
        } catch (err) {
          setValidationErrorMsg('Please ensure that all required customer fields are filled.');
          return false;
        }
      }
      setValidationErrorMsg('');
      return true;
    },
    [form],
  );

  const isCurrentStepValid = useCallback(
    async (): Promise<boolean> => canGoToStep(currentStep + 1),
    [canGoToStep, currentStep],
  );

  useEffect(() => {
    // Clear error message immediately when user has fixed the errors
    if (validationErrorMsg !== '') {
      isCurrentStepValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectState, validationErrorMsg]);

  const goToStep = async (step: ProspectStep) => {
    // don't go to next step, until every previous state is valid
    if (step >= currentStep && !(await canGoToStep(step))) {
      return;
    }
    updateProspectState({ step });
  };

  return (
    <Container>
      <AutoSaveModal
        visible={showAutoSaveModal}
        onNo={() => {
          clearSavedFormState();
          setShowAutoSaveModal(false);
        }}
        onYes={() => {
          history.replace(encodeHashState({ ...savedFormState, step: 0 }));
          clearSavedFormState();
          setShowAutoSaveModal(false);
        }}
      />
      <PageHeader title={customerTypeTitle} />
      <Steps current={currentStep} style={{ marginTop: 24 }} onChange={(newStep) => goToStep(newStep)}>
        {['Customer', 'Contact', 'Review'].map((step) => (
          <Steps.Step key={step} title={step} />
        ))}
      </Steps>
      <Form
        name="prospectForm"
        form={form}
        onFinishFailed={console.error}
        onValuesChange={(newState: ProspectHashState) =>
          updateProspectState(objMapValues(newState, (value) => (value === '' ? undefined : value)))
        }
        {...responsiveFormLayout}
        style={{ paddingTop: '30px' }}
      >
        {currentStep === ProspectStep.Customer && (
          <ProspectCustomerStep prospectState={prospectState} onProspectStateChange={updateProspectState} />
        )}
        {currentStep === ProspectStep.Contact && <ProspectContactStep prospectState={prospectState} />}

        {currentStep === ProspectStep.Review && <ProspectReviewStep prospectState={prospectState} />}
      </Form>
      {validationErrorMsg && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 16,
            color: theme.colors.danger[500],
          }}
        >
          {validationErrorMsg}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          padding: '0 8px',
        }}
      >
        <Button onClick={() => goToStep(currentStep - 1)} disabled={currentStep === 0}>
          <LeftOutlined />
          Previous
        </Button>
        {currentStep === ProspectStep.Review ? (
          <Tooltip
            title={`This ${customerType} will be sent to ${getErpName(
              activeTenant.erpType,
            )} and will no longer be editable in Recurrency.`}
          >
            <AsyncButton type="primary" onClick={handleProspectSubmit} htmlType="submit">
              Send to {getErpName(activeTenant.erpType)}
            </AsyncButton>
          </Tooltip>
        ) : (
          <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
            Next
            <RightOutlined />
          </Button>
        )}
      </div>
    </Container>
  );
};
