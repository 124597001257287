import React from 'react';

import { css } from '@emotion/css';
import { Divider } from 'antd';

import { FlexSpace } from 'components/FlexSpace';
import { BorderedPropertyItem } from 'components/recipes/equation/BorderedPropertyItem';
import { DemandPattern } from 'components/Tooltip/DemandPatternTooltip';
import { Typography } from 'components/Typography';

import { formatNumber } from 'utils/formatting';

import { SearchIndexMinMax } from 'types/search-collections';

import { EquationPopover } from '../../../../components/recipes/equation/EquationPopover';
import { EOQFormulaMaxEquation } from './equations/EOQFormulaMaxEquation';

export const RecommendedMaxPopover = ({ record }: { record: SearchIndexMinMax }) => (
  <EquationPopover title="Recommended Max Calculation">
    <FlexSpace
      direction="column"
      align-items="start"
      className={css`
        align-content: flex-start;
      `}
    >
      {record.recommended_min === record.recommended_max && (
        <>
          <FlexSpace direction="row">
            <BorderedPropertyItem
              label="Recommended Min"
              value={`${formatNumber(record.recommended_min)} ${record.unit_of_measure}`}
            />
            <div
              className={css`
                align-self: stretch;
                padding-top: 16px;
              `}
            >
              <Typography type="large">=</Typography>
            </div>
            <BorderedPropertyItem
              label="Recommended Max"
              value={`${formatNumber(record.recommended_min)} ${record.unit_of_measure}`}
            />
          </FlexSpace>
          {(record.demand_pattern !== DemandPattern.SPARSE && (
            <>
              <div>
                Recommended max value is less than the recommended min, therefore the recommended min value will be
                used.
              </div>
              <Divider
                className={css`
                  padding: 0px;
                  margin: 0px;
                `}
              />
            </>
          )) || (
            <div>
              Sparse items are set to hold a specified number of months of
              <br />
              demand, so recommended min and recommended max will be the same
            </div>
          )}
        </>
      )}
      {record.demand_pattern !== DemandPattern.SPARSE && <EOQFormulaMaxEquation record={record} />}
    </FlexSpace>
  </EquationPopover>
);
