import React from 'react';

import { css } from '@emotion/css';
import { Tabs as AntTabs, Empty, ConfigProvider } from 'antd';
import type { TabsProps } from 'antd/lib/tabs';

import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { toDashCase } from 'utils/formatting';

interface TabContent {
  header: string;
  content: JSX.Element;
  infoTooltip?: string;
  count?: number;
  tabKey?: string;
}

interface Props extends TabsProps {
  tabs: TabContent[];
  activeTab?: string;
  onChange?: (activeKey: string) => void;
}

export function Tabs({ tabs, activeTab, onChange }: Props) {
  return (
    <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results found." />}>
      <AntTabs
        className={css`
          .ant-tabs-tab.ant-tabs-tab-active {
            font-weight: 600;
          }
          .ant-tabs-tab-btn {
            transition: none;
          }
        `}
        activeKey={activeTab}
        onChange={onChange}
      >
        {tabs.map((tab) => {
          const tabKey = tab.tabKey ?? toDashCase(tab.header);
          return (
            <AntTabs.TabPane
              key={tabKey}
              tab={
                tab.infoTooltip ? (
                  <InfoTooltip title={tab.infoTooltip}>{tab.header}</InfoTooltip>
                ) : tab.count !== undefined ? (
                  <span>
                    {tab.header} ({tab.count})
                  </span>
                ) : (
                  tab.header
                )
              }
            >
              {tab.content}
            </AntTabs.TabPane>
          );
        })}
      </AntTabs>
    </ConfigProvider>
  );
}
