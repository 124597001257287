import React from 'react';

import {
  ApiOutlined,
  CarryOutOutlined,
  ControlOutlined,
  DollarCircleOutlined,
  FilterOutlined,
  LineChartOutlined,
  ProfileOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {
  IntegratedErps,
  TenantFeatureFlag,
  TenantIntegrationStatus,
  TenantProductName,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { TenantUserErpRoleDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';
import { RecurrencyRole } from '@recurrency/core-api-schema/dist/users/common';

import { recurrencyRolesToShowSettingsPage } from 'pages/admin/SettingsPage';

import { ExtendedUser } from 'hooks/useGlobalApp';

import {
  isAtLeastOneOfRecurrencyRoles,
  isPurchaserRole,
  shouldShowFeatureFlag,
  shouldShowProduct,
} from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { getTenantSetting } from 'utils/tenantSettings';

import { NavModule, NavSubModule, NavMode } from './types';

export function filterNav<Type extends NavModule>(
  array: Type[],
  activeTenant: TenantDTO,
  activeUser: ExtendedUser,
  activeErpRole: Maybe<TenantUserErpRoleDTO>,
) {
  return filterEmptyModules(
    array.map((navModule) => ({
      ...navModule,
      subModules: applyAllSubmoduleFilters(navModule.subModules, activeTenant, activeUser, activeErpRole),
    })),
  );
}

function applyAllSubmoduleFilters(
  subModules: NavSubModule[],
  activeTenant: TenantDTO,
  activeUser: ExtendedUser,
  activeErpRole: Maybe<TenantUserErpRoleDTO>,
) {
  let f: NavSubModule[];
  f = filterByNavMode(subModules, activeTenant, activeUser);
  f = filterByActiveErpRole(f, activeErpRole);
  f = filterByRecurrencyRoles(f, activeTenant);
  f = filterByErpType(f, activeTenant);
  f = filterByProducts(f, activeTenant);
  f = filterByFeatureFlag(f, activeTenant, activeUser);
  f = filterByTenantIntegrationStatus(f, activeTenant);
  f = filterByTenantSetting(f);
  f = filterByCustom(f, activeTenant, activeUser);
  return f;
}

function filterByNavMode(subModules: NavSubModule[], activeTenant: TenantDTO, activeUser: ExtendedUser) {
  let returnArray = subModules;

  // probably want to check if tenantAdmin here
  const isInSetupMode = !activeTenant.isActive;
  if (isInSetupMode) {
    returnArray = returnArray.filter((item) => item.navMode === NavMode.Setup || item.navMode === NavMode.Internal);
  } else {
    returnArray = returnArray.filter((item) => item.navMode !== NavMode.Setup);
  }

  const { isRecurrencyAdmin } = activeUser;
  if (!isRecurrencyAdmin) {
    returnArray = returnArray.filter((item) => item.navMode !== NavMode.Internal);
  }

  return returnArray;
}

function filterByActiveErpRole(subModules: NavSubModule[], activeErpRole: Maybe<TenantUserErpRoleDTO>) {
  return subModules.filter((subModule) =>
    subModule.isEnabledForErpRole !== undefined ? subModule.isEnabledForErpRole(activeErpRole) : true,
  );
}

function filterByRecurrencyRoles(subModules: NavSubModule[], activeTenant: TenantDTO) {
  return subModules.filter((subModule) =>
    subModule.recurrencyRoles !== undefined
      ? isAtLeastOneOfRecurrencyRoles(activeTenant, subModule.recurrencyRoles)
      : true,
  );
}

function filterByErpType(subModules: NavSubModule[], activeTenant: TenantDTO) {
  return subModules.filter((subModule) => (subModule.erpType ? subModule.erpType === activeTenant.erpType : true));
}

function filterByProducts(subModules: NavSubModule[], activeTenant: TenantDTO) {
  return subModules.filter(
    (submodule) => submodule.products?.some((product) => shouldShowProduct(activeTenant, product)) ?? true,
  );
}

function filterByFeatureFlag(subModules: NavSubModule[], activeTenant: TenantDTO, activeUser: ExtendedUser) {
  return subModules.filter((submodule) =>
    submodule.featureFlag ? shouldShowFeatureFlag(activeTenant, activeUser, submodule.featureFlag) : true,
  );
}

function filterEmptyModules(modules: NavModule[]) {
  return modules.filter((module) => module.subModules.length > 0);
}

function filterByTenantIntegrationStatus(subModules: NavSubModule[], activeTenant: TenantDTO) {
  const integrationStatuses = activeTenant.integrations.map((i) => i.status);
  return subModules.filter((item) =>
    item.tenantIntegrationStatuses ? item.tenantIntegrationStatuses.some((r) => integrationStatuses.includes(r)) : true,
  );
}

function filterByTenantSetting(subModules: NavSubModule[]) {
  return subModules.filter((submodule) => (submodule.tenantSetting ? getTenantSetting(submodule.tenantSetting) : true));
}

function filterByCustom(subModules: NavSubModule[], activeTenant: TenantDTO, activeUser: ExtendedUser) {
  return subModules.filter((submodule) =>
    submodule.customFilter ? submodule.customFilter(activeTenant, activeUser) : true,
  );
}

const IconWithStyle = (Icon: React.ElementType) => <Icon height={18} width={24} flex={1} />;

export const upperNavModules: NavModule[] = [
  {
    slug: 'demand-planning',
    label: 'Demand Planning',
    selectedIcon: IconWithStyle(TableOutlined),
    unselectedIcon: IconWithStyle(TableOutlined),
    subModules: [
      {
        slug: 'dashboard',
        entitySlug: 'dashboard',
        label: 'Dashboard',
        products: [TenantProductName.DfpaMissionControl],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.demandPlanning.dashboard(),
      },
      {
        slug: 'forecasting',
        label: 'Forecasting',
        products: [TenantProductName.DfpaDemandPlanning],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.demandPlanning.forecasting(),
      },
      {
        slug: 'onboarding',
        label: 'Item Setup',
        products: [TenantProductName.DfpaDemandPlanning],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.demandPlanning.onboarding(),
        tenantSetting: TenantSettingKey.FeatureOnboardingPage,
      },
      {
        slug: 'planning',
        label: 'Planning',
        products: [TenantProductName.DfpaDemandPlanning],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.demandPlanning.planning(),
      },
      {
        slug: 'simulation-dashboard',
        label: 'Simulation Dashboard',
        products: [TenantProductName.DfpaMissionControl],
        featureFlag: TenantFeatureFlag.FeatureInventorySimulationDashboard,
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.demandPlanning.inventorySimulationDashboard(),
      },
    ],
  },
  {
    slug: 'purchasing',
    label: 'Purchasing',
    selectedIcon: IconWithStyle(ShoppingOutlined),
    unselectedIcon: IconWithStyle(ShoppingOutlined),
    subModules: [
      {
        slug: 'purchase-targets',
        entitySlug: 'purchase-targets',
        label: 'Purchase Targets',
        products: [TenantProductName.DfpaPurchasingAutomation],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.purchasing.purchaseTargets(),
      },
      {
        slug: 'transfer-targets',
        entitySlug: 'transfer-targets',
        label: 'Transfer Targets',
        products: [TenantProductName.DfpaPurchasingAutomation],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.purchasing.transferTargets(),
        featureFlag: TenantFeatureFlag.FeatureTransferOrderRecommendations,
      },
      {
        slug: 'purchase-groups',
        entitySlug: 'purchase-groups',
        label: 'Purchase Groups',
        products: [TenantProductName.DfpaPurchasingAutomation],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.purchasing.purchaseGroups(),
      },
      {
        slug: 'purchase-orders',
        entitySlug: 'purchase-order',
        label: 'Purchase Orders',
        products: [TenantProductName.DfpaSearchAndLookup],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.purchasing.purchaseOrderList(),
      },
      {
        slug: 'purchase-status',
        entitySlug: 'purchase-status',
        label: 'Purchase Status',
        products: [TenantProductName.DfpaSearchAndLookup],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.purchasing.purchaseStatus(),
      },
      {
        slug: 'vendors',
        entitySlug: 'vendor',
        label: 'Vendors',
        products: [TenantProductName.DfpaSearchAndLookup],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.purchasing.vendorList(),
      },
      {
        slug: 'items',
        entitySlug: 'item',
        label: 'Inventory',
        products: [TenantProductName.DfpaSearchAndLookup],
        isEnabledForErpRole: isPurchaserRole,
        routeFn: () => routes.purchasing.itemList(),
      },
    ],
  },
  {
    slug: 'sales',
    label: 'Sales',
    selectedIcon: IconWithStyle(FilterOutlined),
    unselectedIcon: IconWithStyle(FilterOutlined),
    subModules: [
      {
        slug: 'dashboard',
        label: 'Dashboard',
        products: [TenantProductName.SalesMissionControl],
        routeFn: () => routes.sales.dashboard(),
      },
      {
        slug: 'customers',
        entitySlug: 'customer',
        label: 'Customers',
        products: [TenantProductName.SalesSearchAndLookup],
        routeFn: () => routes.sales.customerList(),
      },
      {
        slug: 'items',
        entitySlug: 'item',
        label: 'Inventory',
        products: [TenantProductName.SalesSearchAndLookup],
        routeFn: () => routes.sales.itemList(),
      },
      {
        slug: 'pricing',
        label: 'Pricing',
        erpType: IntegratedErps.P21,
        products: [TenantProductName.SalesSearchAndLookup],
        routeFn: () => routes.sales.pricing(),
      },
    ],
  },
  {
    slug: 'orders',
    label: 'Quotes & Orders',
    selectedIcon: IconWithStyle(ProfileOutlined),
    unselectedIcon: IconWithStyle(ProfileOutlined),
    subModules: [
      {
        slug: 'opportunities',
        label: 'Opportunities', // aka recommended quotes
        products: [TenantProductName.SalesRecommendations],
        routeFn: () => routes.orders.opportunities(),
      },
      {
        slug: 'quote-drafts',
        entitySlug: 'quote-drafts',
        label: 'Draft Quotes',
        products: [TenantProductName.SalesQuoting],
        erpType: IntegratedErps.P21,
        routeFn: () => routes.orders.draftQuoteList(),
      },
      {
        slug: 'quotes',
        entitySlug: 'quote',
        label: 'Quotes',
        products: [TenantProductName.SalesSearchAndLookup],
        routeFn: () => routes.orders.quoteList(),
      },
      {
        slug: 'orders',
        entitySlug: 'order',
        label: 'Orders',
        products: [TenantProductName.SalesSearchAndLookup, TenantProductName.DfpaSearchAndLookup],
        routeFn: () => routes.orders.orderList(),
      },
    ],
  },
  {
    slug: 'payments',
    label: 'Payments',
    selectedIcon: IconWithStyle(DollarCircleOutlined),
    unselectedIcon: IconWithStyle(DollarCircleOutlined),
    subModules: [
      {
        slug: 'payouts',
        entitySlug: 'payout',
        label: 'Payouts',
        products: [TenantProductName.Payments],
        routeFn: () => routes.payments.payouts(),
      },
      {
        slug: 'payments',
        entitySlug: 'payments',
        label: 'Payments',
        products: [TenantProductName.Payments],
        routeFn: () => routes.payments.payments(),
      },
      {
        slug: 'open-invoices',
        entitySlug: 'open-invoices',
        label: 'Open Invoices',
        products: [TenantProductName.Payments],
        routeFn: () => routes.payments.openInvoices(),
      },
      {
        slug: 'disputes',
        entitySlug: 'disputes',
        label: 'Disputes',
        products: [TenantProductName.Payments],
        routeFn: () => routes.payments.disputes(),
      },
    ],
  },
  {
    slug: 'reporting',
    label: 'Sales Reporting',
    selectedIcon: IconWithStyle(LineChartOutlined),
    unselectedIcon: IconWithStyle(LineChartOutlined),
    subModules: [
      {
        slug: 'explorer',
        entitySlug: 'report',
        label: 'Explorer',
        products: [TenantProductName.SalesReporting],
        routeFn: () => routes.reporting.explorer(),
      },
      {
        slug: 'reports',
        label: 'Reports',
        products: [TenantProductName.SalesReporting],
        routeFn: () => routes.reporting.reports(),
      },
    ],
  },
  {
    slug: 'tasks',
    label: 'Tasks',
    selectedIcon: IconWithStyle(CarryOutOutlined),
    unselectedIcon: IconWithStyle(CarryOutOutlined),
    subModules: [
      {
        slug: 'tasks',
        entitySlug: 'task',
        label: 'Tasks',
        products: [TenantProductName.SalesCrm],
        routeFn: () => routes.tasks.taskList(),
      },
    ],
  },
  {
    slug: 'setup',
    label: 'Setup',
    selectedIcon: IconWithStyle(ApiOutlined),
    unselectedIcon: IconWithStyle(ApiOutlined),
    subModules: [
      {
        slug: 'integration',
        label: 'Database Connection',
        navMode: NavMode.Setup,
        routeFn: () => routes.setup.integration(),
      },
    ],
  },
  {
    slug: 'settings',
    label: 'Settings',
    selectedIcon: IconWithStyle(SettingOutlined),
    unselectedIcon: IconWithStyle(SettingOutlined),
    subModules: [
      {
        slug: 'settings',
        label: 'Settings',
        routeFn: () => routes.settings.settings(),
        recurrencyRoles: recurrencyRolesToShowSettingsPage,
        tenantIntegrationStatuses: [
          TenantIntegrationStatus.Onboarding,
          TenantIntegrationStatus.Active,
          TenantIntegrationStatus.Paused,
        ],
      },
      {
        slug: 'users',
        label: 'Users',
        routeFn: () => routes.settings.users(),
        recurrencyRoles: [RecurrencyRole.TenantAdmin],
      },
      {
        slug: 'replenishment-paths',
        entitySlug: 'replenishment-paths',
        label: 'Replenishment Paths',
        recurrencyRoles: [RecurrencyRole.TenantAdmin],
        routeFn: () => routes.settings.replenishmentPaths(),
      },
      {
        // Legacy settings, sales only and only visible to internal Recurrency users. To be migrated to the new Settings page
        slug: 'legacy-sales-settings',
        label: 'Legacy Sales Settings',
        navMode: NavMode.Internal,
        routeFn: () => routes.settings.legacySalesSettings(),
        tenantIntegrationStatuses: [TenantIntegrationStatus.Active],
      },
    ],
  },
];

export const lowerNavModules: NavModule[] = [
  {
    slug: 'internal',
    label: 'Recurrency Internal',
    selectedIcon: IconWithStyle(ControlOutlined),
    unselectedIcon: IconWithStyle(ControlOutlined),
    subModules: [
      {
        slug: 'users',
        entitySlug: 'user',
        label: 'Users',
        navMode: NavMode.Internal,
        routeFn: () => routes.internal.userList(),
      },
      {
        slug: 'tenants',
        entitySlug: 'tenant',
        label: 'Tenants',
        navMode: NavMode.Internal,
        routeFn: () => routes.internal.tenantList(),
      },
    ],
  },
];

export const navModules = upperNavModules.concat(lowerNavModules);
