import React from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { colors } from 'theme/colors';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { reactNodeToText } from 'utils/react';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';

import { ChooseViewSettingButton } from './ChooseViewSettingButton';

interface ColumnChooserSectionProps {
  columns: PersistedColumn<any>[];
  visibleColumnKeys: string[];
  setVisibleColumnKeys: (keys: string[]) => void;
}

export function ColumnChooserSection({ columns, visibleColumnKeys, setVisibleColumnKeys }: ColumnChooserSectionProps) {
  const { activeTenant, activeUser } = useGlobalApp();

  const shouldShowColumnChooser = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeatureUserTableAndSidePaneSettings,
  );

  const defaultColumnKeys = columns.filter((column) => !column.optional).map((column) => column.settingKey);

  return shouldShowColumnChooser ? (
    <div
      className={css`
        display: flex;
        padding-bottom: 8px;
        justify-content: flex-end;
      `}
    >
      <ChooseViewSettingButton
        options={{
          options: columns.map((col) => ({
            label: (
              <>
                {reactNodeToText(col.title)}
                {col.required ? (
                  <div
                    className={css`
                      color: ${colors.neutral[400]};
                      font-style: italic;
                    `}
                  >
                    Required
                  </div>
                ) : (
                  <></>
                )}
              </>
            ),
            value: col.settingKey,
            searchLabel: reactNodeToText(col.title),
            disabled: col.required,
          })),
        }}
        selectedValues={visibleColumnKeys}
        onSelectedValuesChange={setVisibleColumnKeys}
        descriptor="Columns"
        defaultValues={defaultColumnKeys}
      />
    </div>
  ) : (
    <></>
  );
}
