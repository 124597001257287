import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

import { RecommendedMaxPopover } from 'pages/purchasing/PlanningPage/modals/RecommendedMaxPopover';
import { RecommendedMinPopover } from 'pages/purchasing/PlanningPage/modals/RecommendedMinPopover';
import { UpdateReplenishmentSettingsModal } from 'pages/purchasing/PlanningPage/modals/UpdateReplenishmentSettingsModal';
import { GenericTargetLineRow } from 'pages/purchasing/PurchaseTargetsPage/types';

import { ActionButton } from 'components/Button/ActionButton';

import { showAsyncModal } from 'utils/asyncModal';

import { SearchIndexMinMax } from 'types/search-collections';

import { MinMaxCell } from './MinMaxCell';

export interface MinMax {
  min: number | undefined;
  max: number | undefined;
}

export const MinMaxPanelContent = ({
  minMaxRecord,
  itemInfo,
  allowMinMaxEdit = false,
  onMinMaxChange,
}: {
  minMaxRecord: SearchIndexMinMax | undefined;
  itemInfo?: GenericTargetLineRow;
  allowMinMaxEdit?: boolean;
  onMinMaxChange?: () => void;
}) => (
  <div
    className={css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
    `}
  >
    {minMaxRecord ? (
      <>
        <MinMaxCell label="Current Min" value={minMaxRecord.current_min} unitOfMeasure={minMaxRecord.unit_of_measure} />
        <MinMaxCell
          label="Recommended"
          value={minMaxRecord.recommended_min}
          unitOfMeasure={minMaxRecord.unit_of_measure}
          equationPopover={<RecommendedMinPopover record={minMaxRecord} />}
        />
        <MinMaxCell label="Current Max" value={minMaxRecord.current_max} unitOfMeasure={minMaxRecord.unit_of_measure} />
        <MinMaxCell
          label="Recommended"
          value={minMaxRecord.recommended_max}
          unitOfMeasure={minMaxRecord.unit_of_measure}
          equationPopover={<RecommendedMaxPopover record={minMaxRecord} />}
        />
        {allowMinMaxEdit && (
          <ActionButton
            label={
              <>
                <EditOutlined /> Edit
              </>
            }
            onClick={() => {
              showAsyncModal(UpdateReplenishmentSettingsModal, {
                record: minMaxRecord,
                searchIndexReload: onMinMaxChange,
              });
            }}
          />
        )}
      </>
    ) : itemInfo ? (
      // Only inventory items with usage have min/max records in search index.
      // If we don't have min/max with recommended values, show the current min/max from the inv_loc item.
      <>
        <MinMaxCell label="Current Min" value={itemInfo.invMin} unitOfMeasure={itemInfo.unitOfMeasure} />
        <MinMaxCell label="Recommended" value={undefined} unitOfMeasure="" />
        <MinMaxCell label="Current Max" value={itemInfo.invMax} unitOfMeasure={itemInfo.unitOfMeasure} />
        <MinMaxCell label="Recommended" value={undefined} unitOfMeasure="" />
      </>
    ) : null}
  </div>
);
