import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantProductDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';

export const productDependencies: Partial<Record<TenantProductName, TenantProductName[]>> = {
  [TenantProductName.GlobalSearch]: [TenantProductName.DfpaSearchAndLookup, TenantProductName.SalesSearchAndLookup],
  [TenantProductName.DfpaPurchasingAutomation]: [TenantProductName.DfpaDemandPlanning],
};

export enum ProductsPreset {
  FreeReporting = 'Free Reporting',
  FreeReportingActiveUpsell = 'Free Reporting - Active Upsell',
  FullProductDPPAOnly = 'Full DPPA Product',
  FullProductDPOnly = 'Full DP Product',
}

const PRODUCT_FULLY_ENABLED: TenantProductDTO = {
  purchased: true,
  productEnabled: true,
  backendEnabled: true,
};

const PRODUCT_BACKEND_ENABLED: TenantProductDTO = {
  purchased: false,
  productEnabled: false,
  backendEnabled: true,
};

export const productsPresetTemplates: Record<ProductsPreset, Partial<Record<TenantProductName, TenantProductDTO>>> = {
  [ProductsPreset.FreeReporting]: {
    [TenantProductName.DfpaMissionControl]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesReporting]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesMissionControl]: PRODUCT_FULLY_ENABLED,
  },
  [ProductsPreset.FreeReportingActiveUpsell]: {
    [TenantProductName.DfpaMissionControl]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaSearchAndLookup]: PRODUCT_BACKEND_ENABLED,
    [TenantProductName.DfpaDemandPlanning]: PRODUCT_BACKEND_ENABLED,
    [TenantProductName.DfpaPurchasingAutomation]: PRODUCT_BACKEND_ENABLED,
    [TenantProductName.SalesReporting]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesMissionControl]: PRODUCT_FULLY_ENABLED,
  },
  [ProductsPreset.FullProductDPPAOnly]: {
    [TenantProductName.EnterpriseBasic]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.GlobalSearch]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaMissionControl]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaSearchAndLookup]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaDemandPlanning]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaPurchasingAutomation]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesReporting]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesMissionControl]: PRODUCT_FULLY_ENABLED,
  },
  [ProductsPreset.FullProductDPOnly]: {
    [TenantProductName.EnterpriseBasic]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.GlobalSearch]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaMissionControl]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaSearchAndLookup]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaDemandPlanning]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesReporting]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesMissionControl]: PRODUCT_FULLY_ENABLED,
  },
};
