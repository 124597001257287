import React, { useRef } from 'react';

import { Link } from 'react-router-dom';

import {
  AppstoreOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CodeOutlined,
  DashboardOutlined,
  DownOutlined,
  EnvironmentOutlined,
  MonitorOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  StockOutlined,
  StopOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { PlanningHubAndSpokeType, RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey, TenantSettingsFields } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { RecurrencyRole } from '@recurrency/core-api-schema/dist/users/common';
import { Menu } from 'antd';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Dropdown } from 'components/Dropdown';
import { FlexSpace } from 'components/FlexSpace';
import { LastUpdatedMomentForecast } from 'components/recipes/LastUpdatedMoment';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { FacetRenderType, SearchFrameContext } from 'components/recipes/SearchFrame/types';
import { BadgeStatus, labelByStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { Tooltip } from 'components/Tooltip';
import { DemandPatternTooltip } from 'components/Tooltip/DemandPatternTooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import {
  asKeyOf,
  numberColumn,
  sortableChangePercentColumn,
  sortableDateColumn,
  sortableNumberColumn,
  sortDirections,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';

import { SearchFrameHashState } from 'types/hash-state';
import { SearchIndexMinMax } from 'types/search-collections';

import { ReplenishmentMethod, useDemandPlanningFilterSave } from '../utils';
import { changeRecordExcludedStatus } from './modals/IncludeExcludeStatusUpdater';
import { MultiLeadTimeModal } from './modals/MultiLeadTimeModal';
import { MultiSafetyStockModal } from './modals/MultiSafetyStockModal';
import { RecommendedMaxPopover } from './modals/RecommendedMaxPopover';
import { RecommendedMinPopover } from './modals/RecommendedMinPopover';
import { SparseStockGridModal } from './modals/SparseStockGridModal';
import { UpdateReplenishmentSettingsModal } from './modals/UpdateReplenishmentSettingsModal';
import { MultiModalSelection } from './modals/utils';
import { PlanningSearchFrameHeader } from './PlanningSearchFrameHeader';
import { PlanningSidePane } from './PlanningSidePane';
import { ReplenishmentPathPopover } from './ReplenishmentPathPopover';
import { demandPatternOptions, demandPredictabilityOptions } from './utils';

const eoqUpToValueWithTooltip = (value: number, record: SearchIndexMinMax) => (
  <FlexSpace
    alignItems="start"
    gap={0}
    className={css`
      height: 32px;
      justify-content: flex-end;
    `}
  >
    {formatNumber(value)}
    {(record.replenishment_method === ReplenishmentMethod.EOQ ||
      record.replenishment_method === ReplenishmentMethod.UpTo) && (
      <Tooltip
        title={`Min and Max are not used in system as this item is using the ${record.replenishment_method} replenishment method`}
        placement="right"
      >
        <div
          className={css`
            position: relative;
          `}
        >
          <span
            className={css`
              color: ${theme.colors.malibu[600]};
              top: 1px;
              right: 1px;
              margin-left: 2px;
            `}
          >
            *
          </span>
        </div>
      </Tooltip>
    )}
  </FlexSpace>
);

const getRecommendedMinColumn = (config: {
  isOpOqVariant: boolean;
  openSparseItemGrid?: () => void;
}): PersistedColumn<SearchIndexMinMax> => ({
  exportTitle: `Recommended ${config.isOpOqVariant ? 'Order Point' : 'Minimum'}`,
  title: 'Recommended',
  settingKey: 'recommendedMinimum',
  dataIndex: asKeyOf<SearchIndexMinMax>('recommended_min'),
  align: 'right',
  render: (recommendedMin, record) => (
    <div
      className={css`
        display: inline-flex;
      `}
    >
      {formatNumber(recommendedMin)}
      <RecommendedMinPopover
        record={record}
        showOpOqVariant={config.isOpOqVariant}
        openSparseItemGrid={config.openSparseItemGrid}
      />
    </div>
  ),
});

const getRecommendedMaxColumn = (): PersistedColumn<SearchIndexMinMax> => ({
  exportTitle: 'Recommended Maximum',
  settingKey: 'recommendedMaximum',
  title: `Recommended`,
  dataIndex: asKeyOf<SearchIndexMinMax>('recommended_max'),
  align: 'right',
  render: (recommendedMax, record) => (
    <div
      className={css`
        display: inline-flex;
      `}
    >
      {formatNumber(recommendedMax)} <RecommendedMaxPopover record={record} />
    </div>
  ),
});

export const getColumns = (config: {
  isHubAndSpoke: boolean;
  isOpOqVariant: boolean;
  shouldShowOpOq: boolean;
  showShortCode: boolean;
  showAbcClass: boolean;
  searchIndexReload?: () => void;
  openSparseItemGrid?: () => void;
}): (PersistedColumn<SearchIndexMinMax> | null)[] => [
  {
    title: 'Item',
    settingKey: 'itemId',
    dataIndex: asKeyOf<SearchIndexMinMax>('item_id'),
    fixed: 'left',
    sorter: true,
    sortDirections,
    required: true,
    render: (_: string, record) => (
      <div>
        <Link to={routes.purchasing.itemDetails(record.item_id)}>{record.item_id}</Link>
        <div>{record.item_name}</div>
      </div>
    ),
    exportValue: {
      'Item ID': asKeyOf<SearchIndexMinMax>('item_id'),
      'Item Name': asKeyOf<SearchIndexMinMax>('item_name'),
    },
  },
  {
    title: `${config.isHubAndSpoke ? 'Requirement' : ''} Location`,
    settingKey: 'requirementLocation',
    dataIndex: asKeyOf<SearchIndexMinMax>('location'),
    fixed: 'left',
    sorter: config.isHubAndSpoke,
    required: true,
  },
  config.isHubAndSpoke
    ? {
        title: <InfoTooltip title="The location where this item is sourced from">Replenishment Location</InfoTooltip>,
        settingKey: 'replenishmentLocation',
        dataIndex: asKeyOf<SearchIndexMinMax>('replenishment_location'),
        fixed: 'left',
        sorter: true,
        render: (replenishmentLocation, record) => (
          <ConditionalWrapper
            condition={
              record.location !== record.replenishment_location && record.replenishment_location.trim() !== ':'
            }
            wrapper={(children) => (
              <ReplenishmentPathPopover
                itemId={record.item_uid}
                location={record.location}
                supplier={record.primary_supplier}
              >
                {children}
              </ReplenishmentPathPopover>
            )}
          >
            {replenishmentLocation}
          </ConditionalWrapper>
        ),
      }
    : null,
  config.showShortCode
    ? {
        title: 'Short Code',
        settingKey: 'shortCode',
        dataIndex: asKeyOf<SearchIndexMinMax>('short_code'),
      }
    : null,
  config.showAbcClass
    ? {
        title: 'ABC Class',
        settingKey: 'abcClass',
        dataIndex: asKeyOf<SearchIndexMinMax>('purchase_class'),
        sorter: true,
        sortDirections,
      }
    : null,
  {
    title: <b>{config.isOpOqVariant ? 'Order Point' : 'Minimum'}</b>,
    settingKey: 'minimum',
    children: [
      sortableNumberColumn({
        exportTitle: `Current ${config.isOpOqVariant ? 'Order Point' : 'Minimum'}`,
        title: 'Current',
        settingKey: 'currentMinimum',
        dataIndex: asKeyOf<SearchIndexMinMax>('current_min'),
        sorter: true,
        render: config.shouldShowOpOq ? eoqUpToValueWithTooltip : (value) => formatNumber(value),
      }),
      getRecommendedMinColumn(config),
      sortableChangePercentColumn({
        exportTitle: `${config.isOpOqVariant ? 'Order Point' : 'Minimum'} ∆%`,
        title: '∆%',
        settingKey: 'minimumDelta',
        dataIndex: asKeyOf<SearchIndexMinMax>('min_delta'),
        sorter: true,
      }),
    ],
  },
  config.isOpOqVariant
    ? {
        title: <b>Order Quantity</b>,
        settingKey: 'orderQuantity',
        exportTitle: 'Order Quantity',
        dataIndex: asKeyOf<SearchIndexMinMax>('current_max'),
        sorter: true,
        sortDirections,
      }
    : {
        title: <b>Maximum</b>,
        settingKey: 'maximum',
        children: [
          sortableNumberColumn({
            exportTitle: 'Current Maximum',
            title: 'Current',
            settingKey: 'currentMaximum',
            dataIndex: asKeyOf<SearchIndexMinMax>('current_max'),
            sorter: true,
            render: config.shouldShowOpOq ? eoqUpToValueWithTooltip : (value) => formatNumber(value),
          }),
          getRecommendedMaxColumn(),
          sortableChangePercentColumn({
            exportTitle: 'Maximum ∆%',
            title: '∆%',
            settingKey: 'maximumDelta',
            dataIndex: asKeyOf<SearchIndexMinMax>('max_delta'),
            sorter: true,
          }),
        ],
      },
  {
    title: 'UOM',
    settingKey: 'unitOfMeasure',
    dataIndex: asKeyOf<SearchIndexMinMax>('unit_of_measure'),
  },
  {
    title: <b>Demand</b>,
    settingKey: 'demand',
    children: [
      {
        title: (
          <InfoTooltip title="Demand patterns group items based on consistency of orders and variance in demand quantity to forecast in the optimal manner">
            Pattern
          </InfoTooltip>
        ),
        exportTitle: 'Demand Pattern',
        dataIndex: asKeyOf<SearchIndexMinMax>('demand_pattern'),
        render: (demandPattern) => <DemandPatternTooltip demandPattern={demandPattern} />,
      },
      // using forecast_confidence as field for sorting, but showing predictability_tag in UI
      {
        title: (
          <InfoTooltip title="Estimated predictability of an item based on historical trends. Higher predictability leads to more accurate forecasts">
            Predictability
          </InfoTooltip>
        ),
        exportTitle: 'Demand Predictability',
        dataIndex: asKeyOf<SearchIndexMinMax>('forecast_confidence'),
        defaultSortOrder: 'descend' as const,
        sorter: true,
        sortDirections,
        render: (_, record) => record.predictability_tag,
        exportValue: asKeyOf<SearchIndexMinMax>('predictability_tag'),
      },
    ],
  },
  sortableDateColumn({
    title: 'Last Updated',
    settingKey: 'lastUpdated',
    dataIndex: asKeyOf<SearchIndexMinMax>('last_updated_at'),
    sorter: true,
  }),
  {
    title: 'Status',
    settingKey: 'planningStatus',
    dataIndex: asKeyOf<SearchIndexMinMax>('planning_status'),
    render: (status: BadgeStatus) => <StatusBadge status={status} />,
  },
  {
    title: 'Actions',
    settingKey: 'actions',
    render: (_, record) => (
      <div
        className={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="minMax"
                icon={<CalculatorOutlined />}
                onClick={() => {
                  showAsyncModal(UpdateReplenishmentSettingsModal, {
                    record,
                    isOnboardingVariant: false,
                    searchIndexReload: config.searchIndexReload,
                  });
                }}
              >
                {config.isOpOqVariant ? 'OP/OQ' : 'Min/Max'}
              </Menu.Item>

              <Menu.Item
                key="safetyStock"
                icon={<ReconciliationOutlined />}
                onClick={() => {
                  showAsyncModal(MultiSafetyStockModal, {
                    selection: { type: 'list', items: [record], count: 1 },
                    searchIndexReload: config.searchIndexReload,
                  });
                }}
              >
                Safety Stock
              </Menu.Item>
              <Menu.Item
                key="leadTime"
                icon={<CalendarOutlined />}
                onClick={() => {
                  showAsyncModal(MultiLeadTimeModal, {
                    selection: { type: 'list', items: [record], count: 1 },
                    searchIndexReload: config.searchIndexReload,
                  });
                }}
              >
                Lead Time
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="exclude"
                icon={record.planning_status === BadgeStatus.Excluded ? <CheckCircleOutlined /> : <StopOutlined />}
                onClick={async () => {
                  const shouldExclude = record.planning_status !== BadgeStatus.Excluded;
                  const selection: MultiModalSelection = {
                    type: 'list',
                    items: [record],
                    count: 1,
                  };
                  const didChange = await changeRecordExcludedStatus(selection, shouldExclude);
                  if (didChange && config.searchIndexReload) config.searchIndexReload();
                }}
              >
                {record.planning_status === BadgeStatus.Excluded ? 'Include' : 'Exclude'}
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            size="small"
            className={css`
              width: 50px;
            `}
          >
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    ),
  },
  // optional columns

  {
    title: 'Product group',
    settingKey: 'itemGroup',
    dataIndex: asKeyOf<SearchIndexMinMax>('item_group'),
    optional: true,
  },
  {
    title: 'Safety Stock (days)',
    settingKey: 'safetyStockDays',
    ...numberColumn({ dataIndex: asKeyOf<SearchIndexMinMax>('safety_stock_value') }),
    optional: true,
  },
  {
    title: 'Lead Time (days)',
    settingKey: 'leadTimeDays',
    ...numberColumn({ dataIndex: asKeyOf<SearchIndexMinMax>('lead_time') }),
    optional: true,
  },
  {
    title: 'Order Cycle (days)',
    settingKey: 'orderCycleDays',
    dataIndex: asKeyOf<SearchIndexMinMax>('order_cycle_days'),
    optional: true,
  },
  {
    title: 'Unit Cost',
    settingKey: 'unitCost',
    dataIndex: asKeyOf<SearchIndexMinMax>('unit_cost'),
    render: (amt) => formatUSD(amt, true),
    optional: true,
  },
  {
    title: 'Primary Supplier',
    settingKey: 'primarySupplier',
    dataIndex: asKeyOf<SearchIndexMinMax>('primary_supplier'),
    optional: true,
  },
];

const getReplenishmentMethodFacetSettings = (isOnboardingEnabled: boolean, shouldShowOpOq?: boolean) => {
  const minMaxOptionValue = isOnboardingEnabled
    ? RecurrencyReplenishmentMethod.MinMax
    : [RecurrencyReplenishmentMethod.MinMax, RecurrencyReplenishmentMethod.Unsupported];

  if (shouldShowOpOq) {
    return {
      title: 'Replenishment Method',
      renderType: FacetRenderType.Pill,
      field: asKeyOf<SearchIndexMinMax>('recurrency_replenishment_method'),
      defaultValue: minMaxOptionValue,
      options: [
        {
          label: RecurrencyReplenishmentMethod.MinMax,
          value: minMaxOptionValue,
        },
        {
          label: RecurrencyReplenishmentMethod.OPOQ,
          value: RecurrencyReplenishmentMethod.OPOQ,
        },
      ],
    };
  }

  return {
    field: asKeyOf<SearchIndexMinMax>('recurrency_replenishment_method'),
    defaultValue: minMaxOptionValue,
    hidden: true,
  };
};

export const PlanningPage = () => {
  const { activeTenant } = useGlobalApp();
  const [hashState] = useHashState<SearchFrameHashState>();
  const searchFrameContextRef = useRef<SearchFrameContext<SearchIndexMinMax>>();
  useDemandPlanningFilterSave();

  const shouldShowOpOq = isTenantErpTypeP21(activeTenant.erpType);
  const planningHubAndSpoke = getTenantSetting(TenantSettingKey.FeaturePlanningHubAndSpoke);
  const isPlanningHubAndSpoke =
    planningHubAndSpoke === PlanningHubAndSpokeType.Flexible || planningHubAndSpoke === PlanningHubAndSpokeType.Rigid;

  const isOnboardingEnabled = getTenantSetting(TenantSettingKey.FeatureOnboardingPage) ?? false;
  const showOpOqVariant = !!hashState.where?.recurrency_replenishment_method?.includes(ReplenishmentMethod.OPOQ);

  const sparseItemGridRoles = [
    RecurrencyRole.TenantAdmin,
    ...(TenantSettingsFields['calculation.sparseMonthsStock'].roles || []),
  ];

  const columns = getColumns({
    isOpOqVariant: showOpOqVariant,
    shouldShowOpOq,
    isHubAndSpoke: isPlanningHubAndSpoke,
    showShortCode: getTenantSetting(TenantSettingKey.UiShowShortCode) ?? false,
    showAbcClass: isTenantErpTypeP21(activeTenant.erpType),
    searchIndexReload: searchFrameContextRef.current?.searchIndexReload,
    openSparseItemGrid: !activeTenant.tenantUser.recurrencyRoles.some((role) => sparseItemGridRoles.includes(role.name))
      ? () => showAsyncModal(SparseStockGridModal, {})
      : undefined,
  }).filter(truthy);

  const replenishmentMethodFacetSettings = getReplenishmentMethodFacetSettings(isOnboardingEnabled, shouldShowOpOq);

  const valueFacets: (ValueFacet<SearchIndexMinMax> | null)[] = [
    {
      title: 'Status',
      field: asKeyOf<SearchIndexMinMax>('planning_status'),
      icon: <DashboardOutlined />,
      options: [
        {
          label: labelByStatus[BadgeStatus.Updated],
          value: BadgeStatus.Updated as string,
        },
        {
          label: labelByStatus[BadgeStatus.Review],
          value: BadgeStatus.Review as string,
        },
        {
          label: labelByStatus[BadgeStatus.Excluded],
          value: BadgeStatus.Excluded as string,
        },
      ],
    },
    {
      title: `${isPlanningHubAndSpoke ? 'Requirement ' : ''}Location`,
      field: asKeyOf<SearchIndexMinMax>('location'),
      queryPlaceholder: `Search ${isPlanningHubAndSpoke ? 'requirement ' : ''}locations`,
      icon: <EnvironmentOutlined />,
    },
    isPlanningHubAndSpoke
      ? {
          title: 'Replenishment Location',
          field: asKeyOf<SearchIndexMinMax>('replenishment_location'),
          queryPlaceholder: 'Search replenishment locations',
          icon: <EnvironmentOutlined />,
        }
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          field: asKeyOf<SearchIndexMinMax>('purchase_class'),
          sortBy: 'alpha',
          icon: <TagOutlined />,
        }
      : null,
    {
      title: 'Primary Supplier',
      field: asKeyOf<SearchIndexMinMax>('primary_supplier'),
      queryPlaceholder: 'Search primary suppliers',
      icon: <ShopOutlined />,
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Vendor',
          field: asKeyOf<SearchIndexMinMax>('primary_vendor'),
          queryPlaceholder: 'Search primary vendors',
          icon: <ShopOutlined />,
        }
      : null,
    {
      title: 'Product Group',
      field: asKeyOf<SearchIndexMinMax>('item_group'),
      queryPlaceholder: 'Search product groups',
      icon: <AppstoreOutlined />,
    },
    getTenantSetting(TenantSettingKey.UiShowShortCode)
      ? {
          title: 'Short Code',
          field: asKeyOf<SearchIndexMinMax>('short_code'),
          queryPlaceholder: 'Search short codes',
          icon: <CodeOutlined />,
        }
      : null,
    getTenantSetting(TenantSettingKey.UiShowBuyerFilter)
      ? {
          title: 'Buyer',
          field: asKeyOf<SearchIndexMinMax>('buyer'),
          queryPlaceholder: 'Search buyers',
          icon: <UserOutlined />,
        }
      : null,
    {
      title: 'Demand Pattern',
      field: asKeyOf<SearchIndexMinMax>('demand_pattern'),
      icon: <StockOutlined />,
      options: demandPatternOptions,
      sortBy: 'none',
    },
    {
      title: 'Predictability',
      field: asKeyOf<SearchIndexMinMax>('predictability_tag'),
      icon: <MonitorOutlined />,
      options: demandPredictabilityOptions,
      sortBy: 'none',
    },
    replenishmentMethodFacetSettings,
  ];

  return (
    <SearchFrame<SearchIndexMinMax>
      title={<InfoTooltip title="Used for setting mins, maxes, lead times, and safety stock">Planning</InfoTooltip>}
      subtitle={<LastUpdatedMomentForecast includeForecastPrefix />}
      indexName={SearchIndexName.Planning}
      queryPlaceholder="Search by item"
      columns={columns}
      valueFacets={valueFacets.filter(truthy)}
      sidePane={(props) => <PlanningSidePane {...props} isOnboardingVariant={false} />}
      tableColumnsSettingKey={showOpOqVariant ? ViewSettingKey.PlanningTable : ViewSettingKey.PlanningTableOPOQ}
      tableRowSelection="selectAll"
      contextRef={searchFrameContextRef}
      headerActions={(searchFrameContext) => (
        <PlanningSearchFrameHeader
          valueFacets={valueFacets}
          showOpOqVariant={showOpOqVariant}
          searchFrameContext={searchFrameContext}
        />
      )}
    />
  );
};
